import React from 'react';
import './index.scss';
import Table from '../../../../../../../../design/1/js/templates/molecules/table';
import TableBody from '../../../../../../../../design/1/js/templates/atoms/table-body';
import MutationsAndRequestLink from '../../molecules/MutationsAndRequestLink';
import LinkType from '../../../../../yourdocuments/ts/lib/LinkType';

interface MutationsAndServicesOverviewProps {
    id?: string;
    links: LinkType[];
    title?: string;
}

const MutationsAndServicesOverview = ({
    id, links, title,
}: MutationsAndServicesOverviewProps) => {
    const baseClassName = 'w-mutationsAndServicesOverview';

    return (
        <div className={`${baseClassName}`}>
            {
                title && <h2 id={id}>{title}</h2>
            }
            <Table>
                <TableBody>
                    {links.map((link, index) => (
                        <MutationsAndRequestLink title={link.name} url={link.link} key={`link-${index.toString()}`} target={link.target} />
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

MutationsAndServicesOverview.defaultProps = {
    id: undefined,
    title: undefined,
};

export default MutationsAndServicesOverview;

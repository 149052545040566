import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { FormikProps } from 'formik';
import Autosuggest from 'react-autosuggest';
import _debounce from 'lodash/debounce';
import JsonResponse from '../../../../../../../design/1/js/lib/entity/response/JsonResponse';
import ZipCodesResponseType from '../ZipCodesResponseType';
import CitiesResponseType from '../CitiesResponseType';
import TextField from '../../../../../../../design/1/js/templates/molecules/text-field';
import Select from '../../../../../../../design/1/js/templates/atoms/form-fields/select';
import CountryEntityType from '../entity/changeOfAddress/CountryEntityType';

export enum Country {
    Switzerland = 'CH',
}

export interface CityAndZipActions {
    cityByCityAction: string;
    cityByZipAction: string;
    zipByCityAction: string;
    zipByZipAction: string;
}

interface CityAndZipAutosuggestFieldsReturnType {
    cityField: JSX.Element;
    countryField: JSX.Element;
    zipField: JSX.Element;
}

export default function useCityAndZipAutosuggestFields(baseClassName: string, callbacks: { setCitySuggestions, setZipCodeSuggestions }, cityAndZipActions: CityAndZipActions, countries: CountryEntityType[], formik: FormikProps<any>): CityAndZipAutosuggestFieldsReturnType {
    const [cityValue, setCityValue] = useState('');
    const [zipCodeValue, setZipCodeValue] = useState('');

    const [citySuggestions, setCitySuggestions] = useState([]);
    const [zipCodeSuggestions, setZipCodeSuggestions] = useState([]);

    const [cityPossibilities, setCityPossibilities] = useState([]);
    const [zipCodePossibilities, setZipCodePossibilities] = useState([]);

    const [isCityDisabled, setCityDisabled] = useState(false);
    const [isZipCodeDisabled, setZipCodeDisabled] = useState(false);

    const [selectedCountry, setSelectedCountry] = useState(Country.Switzerland);

    useEffect(() => {
        callbacks.setCitySuggestions(citySuggestions);
        formik.validateField('city');
    }, [citySuggestions]);

    useEffect(() => {
        callbacks.setZipCodeSuggestions(zipCodeSuggestions);
        formik.validateField('zipCode');
    }, [zipCodeSuggestions]);

    const abortController = useRef(new AbortController());
    const ignoreAbort = (error: Error) => {
        if (error.name !== 'AbortError') {
            throw error;
        }
    };

    const resetZipCode = () => {
        setZipCodeValue('');
        setZipCodeSuggestions([]);
        setZipCodePossibilities([]);
        setZipCodeDisabled(false);
        formik.setFieldValue('zipCode', '');
    };

    const resetCity = () => {
        setCityValue('');
        setCitySuggestions([]);
        setCityPossibilities([]);
        setCityDisabled(false);
        formik.setFieldValue('city', '');
    };

    const getZipByZip = inputValue => {
        abortController.current.abort();
        abortController.current = new AbortController();
        const params = {
            country: selectedCountry,
            zipCode: inputValue,
        };
        fetch(cityAndZipActions.zipByZipAction, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
            signal: abortController.current.signal,
        }).then(res => res.json()).then(res => {
            setZipCodeSuggestions(res.context.zipCodes);
        }).catch(ignoreAbort);
    };

    const getCityByCity = inputValue => {
        abortController.current.abort();
        abortController.current = new AbortController();
        const params = {
            country: selectedCountry,
            city: inputValue,
        };

        fetch(cityAndZipActions.cityByCityAction, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
            signal: abortController.current.signal,
        }).then(res => res.json()).then(res => {
            setCitySuggestions(res.context.cities);
        }).catch(ignoreAbort);
    };

    const debounceHandleZipChange = useMemo(() => _debounce(getZipByZip, 500), [selectedCountry, cityAndZipActions.zipByZipAction]);
    const debounceHandleCityChange = useMemo(() => _debounce(getCityByCity, 500), [selectedCountry, cityAndZipActions.cityByCityAction]);

    useEffect(() => () => {
        debounceHandleZipChange.cancel();
    }, []);

    useEffect(() => () => {
        debounceHandleCityChange.cancel();
    }, []);

    const getZipByCity = async inputValue => {
        if (inputValue.length < 2) {
            return;
        }
        const params = {
            country: selectedCountry,
            city: inputValue,
        };
        const response: JsonResponse<ZipCodesResponseType> = await fetch(cityAndZipActions.zipByCityAction, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        }).then(res => res.json());

        const zipCodes = response.context.zipCodes;
        if (selectedCountry === Country.Switzerland) {
            setZipCodeDisabled(true);
            formik.setFieldTouched('zipCode', false);
            if (zipCodes.length > 1) {
                setZipCodeDisabled(false);
            }
        }
        if (zipCodes.length === 1) {
            setZipCodeValue(zipCodes[0]);
            formik.setFieldValue('zipCode', zipCodes[0]);
        }
        setZipCodePossibilities(zipCodes);
        formik.setFieldTouched('city', true, true);
    };

    const getCityByZip = async inputValue => {
        if (inputValue.length < 3) {
            return;
        }
        const params = {
            country: selectedCountry,
            zipCode: inputValue,
        };
        const response: JsonResponse<CitiesResponseType> = await fetch(cityAndZipActions.cityByZipAction, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        }).then(res => res.json());
        const cities = response.context.cities;
        if (selectedCountry === Country.Switzerland) {
            setCityDisabled(true);
            formik.setFieldTouched('city', false);
            if (cities.length > 1) {
                setCityDisabled(false);
            }
        }
        if (cities.length === 1) {
            setCityValue(cities[0]);
            formik.setFieldValue('city', cities[0]);
        }
        setCityPossibilities(cities);
        formik.setFieldTouched('zipCode', true, true);
    };

    const onCitySuggestionsFetchRequested = ({ value }) => {
        if (value.length < 2) {
            setCitySuggestions([]);
            return;
        }
        if (selectedCountry === Country.Switzerland) {
            if (value !== cityValue) {
                resetZipCode();
            }
        }
        debounceHandleCityChange(value);
    };

    const onZipCodeSuggestionsFetchRequested = ({ value }) => {
        if (value.length < 3) {
            setZipCodeSuggestions([]);
            return;
        }
        if (selectedCountry === Country.Switzerland) {
            if (value !== zipCodeValue) {
                resetCity();
            }
        }
        debounceHandleZipChange(value);
    };

    const handleCountryChange = e => {
        resetZipCode();
        resetCity();
        setSelectedCountry(e.target.value);
        formik.handleChange(e);
    };

    const onCityChange = (event, { newValue }) => {
        if (selectedCountry === Country.Switzerland) {
            if (newValue === '') {
                resetCity();
                resetZipCode();
            }
        }
        setCityValue(newValue);
        formik.setFieldValue('city', newValue);
    };

    const onZipChange = (event, { newValue }) => {
        if (selectedCountry === Country.Switzerland) {
            if (newValue === '') {
                resetCity();
                resetZipCode();
            }
        }
        setZipCodeValue(newValue);
        formik.setFieldValue('zipCode', newValue);
    };

    const handleZipBlur = e => {
        formik.setFieldTouched('zipCode', false, false);
        formik.setFieldValue('zipCode', e.target.value, false);
        getCityByZip(e.target.value);
    };

    const handleCityBlur = e => {
        formik.setFieldTouched('city', false, false);
        formik.setFieldValue('city', e.target.value, false);
        getZipByCity(e.target.value);
    };

    const zipInputProps = {
        value: zipCodeValue,
        onChange: onZipChange,
        onBlur: handleZipBlur,
    };

    const cityInputProps = {
        value: cityValue,
        onChange: onCityChange,
        onBlur: handleCityBlur,
    };

    const renderSuggestionsContainer = ({ containerProps, children }) => {
        const { className, ...restContainerProps } = containerProps;
        return (
            <div className={`${className} ${baseClassName}__suggestionContainer`} {...restContainerProps}>
                {children}
            </div>
        );
    };

    const renderSuggestion = suggestion => (
        <div className={`${baseClassName}__suggestionItem`}>
            {suggestion}
        </div>
    );

    const renderZipInputComponent = inputProps => (
        <TextField
            disabled={isZipCodeDisabled}
            id="zipCode"
            label={window.sv_resource.get('plf_servicesform_changeofaddress_zipcode')}
            name="zipCode"
            type="text"
            placeholder=""
            touched={!!formik.touched.zipCode}
            errors={formik.errors.zipCode}
            ref={inputProps.ref}
            onFocus={inputProps.onFocus}
            onBlur={inputProps.onBlur}
            onChange={inputProps.onChange}
            value={inputProps.value}
        />
    );

    const renderCityInputComponent = inputProps => (
        <TextField
            disabled={isCityDisabled}
            id="city"
            label={window.sv_resource.get('plf_servicesform_changeofaddress_city')}
            name="city"
            type="text"
            placeholder=""
            touched={!!formik.touched.city}
            errors={formik.errors.city}
            ref={inputProps.ref}
            onFocus={inputProps.onFocus}
            onBlur={inputProps.onBlur}
            onChange={inputProps.onChange}
            value={inputProps.value}
        />
    );

    const cityField = (
        cityPossibilities.length > 1
            ? (
                <Select
                    className="col-8 col-lg-4"
                    id="city"
                    label={window.sv_resource.get('plf_servicesform_changeofaddress_city')}
                    name="city"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.city}
                >
                    <option value="" disabled>{window.sv_resource.get('form_select_default')}</option>
                    {
                        cityPossibilities.map(entry => (
                            <option value={entry} key={entry}>{entry}</option>
                        ))
                    }
                </Select>
            ) : (
                <Autosuggest
                    containerProps={{ className: `${baseClassName}__autosuggestField col-8 col-lg-4` }}
                    suggestions={citySuggestions}
                    onSuggestionsFetchRequested={onCitySuggestionsFetchRequested}
                    onSuggestionsClearRequested={() => null}
                    onSuggestionSelected={(event, { suggestion, method }) => {
                        if (method === 'enter') {
                            event.preventDefault();
                        }
                        setCityValue(suggestion);
                        formik.setFieldValue('city', suggestion);
                        getZipByCity(suggestion);
                    }}
                    getSuggestionValue={suggestion => suggestion}
                    renderSuggestion={renderSuggestion}
                    renderSuggestionsContainer={renderSuggestionsContainer}
                    renderInputComponent={renderCityInputComponent}
                    inputProps={cityInputProps}
                />
            )
    );

    const zipField = (
        zipCodePossibilities.length > 1
            ? (
                <Select
                    className="col-4 col-lg-2"
                    id="zipCode"
                    label={window.sv_resource.get('plf_servicesform_changeofaddress_zipcode')}
                    name="zipCode"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.zipCode}
                >
                    <option value="" disabled>{window.sv_resource.get('form_select_default')}</option>
                    {
                        zipCodePossibilities.map(entry => (
                            <option value={entry} key={entry}>{entry}</option>
                        ))
                    }
                </Select>
            ) : (
                <Autosuggest
                    containerProps={{ className: `${baseClassName}__autosuggestField col-4 col-lg-2` }}
                    suggestions={zipCodeSuggestions}
                    onSuggestionsFetchRequested={onZipCodeSuggestionsFetchRequested}
                    onSuggestionsClearRequested={() => null}
                    onSuggestionSelected={(event, { suggestion, method }) => {
                        if (method === 'enter') {
                            event.preventDefault();
                        }
                        setZipCodeValue(suggestion);
                        formik.setFieldValue('zipCode', suggestion);
                        getCityByZip(suggestion);
                    }}
                    getSuggestionValue={suggestion => suggestion}
                    renderSuggestion={renderSuggestion}
                    renderSuggestionsContainer={renderSuggestionsContainer}
                    renderInputComponent={renderZipInputComponent}
                    inputProps={zipInputProps}
                />
            )
    );

    const countryField = (
        <Select
            className="col-12 col-lg-6"
            id="country"
            label={window.sv_resource.get('plf_servicesform_changeofaddress_country')}
            name="country"
            onBlur={formik.handleBlur}
            onChange={handleCountryChange}
            value={formik.values.country}
        >
            {
                countries.map(entry => (
                    <option value={entry.iso} key={entry.iso}>{entry.label}</option>
                ))
            }
        </Select>
    );

    return {
        countryField,
        cityField,
        zipField,
    };
}

import { useEffect, useRef } from 'react';

export default function useIntersectionObserver(elements: Element[], options: IntersectionObserverInit, callback: IntersectionObserverCallback) {
    const savedCallback = useRef<IntersectionObserverCallback>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const observerCallback: IntersectionObserverCallback = (entries, observer) => savedCallback.current(entries, observer);
        const observer = new IntersectionObserver(observerCallback, options);

        for (const element of elements) {
            observer.observe(element);
        }

        return () => {
            for (const element of elements) {
                observer.unobserve(element);
            }
        };
    }, [elements, options.root, options.rootMargin, options.threshold.toString()]);
}

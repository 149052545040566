import React, { ReactNodeArray } from 'react';

interface FormStepsProps {
    children: ReactNodeArray
    step: number;
}

const FormSteps = ({ children, step }: FormStepsProps) => (
    <>
        {children.filter((_, index) => index <= step)}
    </>
);

export default FormSteps;

import { useEffect, useRef, useState } from 'react';

const DEFAULT_OPTIONS = {
    attributes: false,
    childList: true,
    subtree: true,
};

export default function useMutationObserver(targetElement: HTMLElement, callback: MutationCallback, options: MutationObserverInit = DEFAULT_OPTIONS) {
    const savedCallback = useRef<MutationCallback>();

    const [observer, setObserver] = useState<MutationObserver>(null);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const mutationObserver = new MutationObserver((records, currentObserver) => savedCallback.current(records, currentObserver));
        setObserver(mutationObserver);
    }, [options]);

    useEffect(() => {
        if (!observer) {
            return () => {};
        }

        observer.observe(targetElement, options);

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [observer, targetElement, options]);
}

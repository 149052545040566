import React from 'react';
import Button, { ButtonType } from '../../../../../../../../design/1/js/templates/atoms/button';
import Category from '../../../../../../../../design/1/js/templates/molecules/accordion/lib/Category';
import './index.scss';
import AccordionData from '../../../../../../../../design/1/js/templates/molecules/accordion/lib/AccordionData';

interface Props {
    className?: string;
    onSelectCategory: (category: Category | null) => void;
    data: AccordionData[];
    selectedCategory: Category | null;
    categories: Category[];
}

const CategorySelector = ({
    className, onSelectCategory, data, selectedCategory, categories,
}: Props) => {
    const baseClassName = 'w-sharedFaq-categorySelector';

    return (
        <div className={`${baseClassName} ${className}`}>
            <Button
                active={selectedCategory === null}
                className={`${baseClassName}__button`}
                handleClick={() => onSelectCategory(null)}
                hasArrow={false}
                text={`${window.sv_resource.get('plf_shared_faq_all')} (${data.length})`}
                type={ButtonType.Action}
            />
            {categories.map(category => {
                const questionsCount = data.filter(item => item.categories.map(t => t.id).includes(category.id)).length;

                return (
                    <Button
                        active={selectedCategory?.id === category.id}
                        className={`${baseClassName}__button`}
                        handleClick={() => onSelectCategory(category)}
                        hasArrow={false}
                        key={category.id}
                        text={`${category.title} (${questionsCount})`}
                        type={ButtonType.Action}
                    />
                );
            })}
        </div>
    );
};

CategorySelector.defaultProps = {
    className: '',
};

export default CategorySelector;

import { loadWidget } from '@sitevision/react';
import React from 'react';
import ReactDOM from 'react-dom';
import ConsultantLogin from './templates/molecules/consultantlogin';

loadWidget('.plf-consultantlogin', consultantLoginWidget => {
    const loginButtonUrl = consultantLoginWidget.dataset.loginButtonUrl;
    const helpdeskMailto = consultantLoginWidget.dataset.helpdeskMailto;

    ReactDOM.render(<ConsultantLogin loginButtonUrl={loginButtonUrl} helpdeskMailto={helpdeskMailto} />, consultantLoginWidget);
});

import React from 'react';

const CHF: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="21" viewBox="0 0 34 21">
        <text id="Text_Inactive" data-name="Text Inactive" transform="translate(0 17)">
            <tspan x="0" y="0">
                CHF
            </tspan>
        </text>
    </svg>
);

export default CHF;

import React from "react";
import Button from '../../../../../../../../design/1/js/templates/atoms/button';
import RichText from '../../../../../../../../design/1/js/templates/atoms/richtext';
import Section from '../../../../../../../../design/1/js/templates/atoms/section';
import SectionTitle from '../../../../../../../../design/1/js/templates/atoms/section-title';

interface ConsultantLoginProps {
    helpdeskMailto: string;
    loginButtonUrl: string;
}

const ConsultantLogin = ({ helpdeskMailto, loginButtonUrl }: ConsultantLoginProps) => {
    return (
        <Section className={'col-6 mx-auto'}>
            <SectionTitle title={window.sv_resource.get('plf_consultantlogin_header')} />
            <Button className={'mb-3'} href={loginButtonUrl} text={window.sv_resource.get('plf_consultantlogin_button')} />
            <RichText text={window.sv_resource.get('plf_consultantlogin_help').replace('{{helpdeskEmail}}', helpdeskMailto)} />
        </Section>
    );
};

export default ConsultantLogin;

import * as Yup from 'yup';
import { NumberSchema } from 'yup';

export function basePercentageSchema(): NumberSchema
{
    const maxThreeDigitsAfterComma = /^\d+(\.\d{0,3})?$/;

    return Yup.number()
        .transform((_value, originalValue) => Number(cleanPercentageValue(originalValue)))
        .min(0, window.sv_resource.get('form_errormsg_percentage'))
        .max(100, window.sv_resource.get('form_errormsg_percentage'))
        .typeError(window.sv_resource.get('form_errormsg_percentage'))
        .required(window.sv_resource.get('form_errormsg_percentage'))
        .test(
            'max-three-digits-after-comma',
            window.sv_resource.get('form_errormsg_percentage_comma'),
            (value: any, context: any) => {
                if(value === undefined) {
                    return true;
                }
                // why originalValue -> because value is a number -> value: 20.111, originalValue: 20.1110
                return maxThreeDigitsAfterComma.test(cleanPercentageString(context.originalValue));
            }
        )
}

function cleanPercentageString(value: string): string
{
    return value
        // Removes percentage character
        .replace('%', '')
        // Removes spaces
        .replace(/ /g, '')
        // replace `,`
        .replace(/[,]/g, '.')
    ;
}

export function cleanPercentageValue(value: string): number
{
    return Number(cleanPercentageString(value));
}

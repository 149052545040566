import React, {
    MutableRefObject, ReactNode, useRef, useState,
} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormikProps } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { SchemaOf } from 'yup';
import RichContext from '../../../lib/entity/response/RichContext';
import JsonResponse from '../../../lib/entity/response/JsonResponse';
import AnswerText from '../../molecules/answer-text';
import Form from '../form';
import StatusMessageVariation from '../../molecules/status-message/lib/StatusMessageVariation';

interface Props<T> {
    actionUrl: string;
    children: (bag: FormikProps<T>) => ReactNode;
    className?: string;
    customOnSubmit?: (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<any>;
    beforeSubmit?: () => void;
    handleSubmit: (status: number) => void;
    handleValidate: SchemaOf<T>;
    initialValues: T;
    name: string;
    recaptchaRef?: MutableRefObject<ReCAPTCHA>;
    variation?: StatusMessageVariation;
}

const FormWithAnswerText = <T extends any>({
    actionUrl, children, className, customOnSubmit, beforeSubmit, handleSubmit, handleValidate, initialValues, name, recaptchaRef, variation,
}: Props<T>) => {
    const baseClassName = 'o-forms';

    const formRef = useRef(null);
    const [response, setResponse] = useState<JsonResponse<RichContext>>(null);

    const onResponse = (jsonResponse: JsonResponse<RichContext>) => {
        setResponse(jsonResponse);
        handleSubmit(jsonResponse.statusCode);
    };
    return (
        <div ref={formRef} className={`${baseClassName} ${className}`}>
            {response === null ? (
                <Form
                    actionUrl={actionUrl}
                    customOnSubmit={customOnSubmit}
                    beforeSubmit={beforeSubmit}
                    initialValues={initialValues}
                    handleResponse={onResponse}
                    name={name}
                    recaptchaRef={recaptchaRef}
                    validationSchema={handleValidate}
                >
                    {children}
                </Form>
            ) : (
                <AnswerText response={response} variation={variation} />
            )}
        </div>
    );
};

FormWithAnswerText.defaultProps = {
    className: '',
    customOnSubmit: undefined,
    beforeSubmit: undefined,
    recaptchaRef: undefined,
    variation: StatusMessageVariation.Subpage,
};

export default FormWithAnswerText;

import React from 'react';
import './index.scss';
import FormButton, {
    FormButtonVariation
} from "../../../../../../../../design/1/js/templates/atoms/form-fields/form-button";


interface StepNavigationProps {
    disabled?: boolean;
    hasErrors: boolean;
    isFirstStep?: boolean;
    handleBack: () => void;
    valuesAreSet? :boolean;
}

const StepNavigation = ({
    disabled, hasErrors, isFirstStep, handleBack, valuesAreSet
}: StepNavigationProps) => {
    const baseClassName = 'w-stepNavigation';

    return (
        <div className={`${baseClassName}__buttons d-flex justify-content-between`}>
            <FormButton disabled={disabled}
                        handleClick={handleBack}
                        text={window.sv_resource.get('plf_onlineform_previous_label')}
                        variation={FormButtonVariation.Previous}
                        className={`${isFirstStep ? 'invisible' : ''}`}
                        type="submit" />
            <FormButton disabled={disabled || (hasErrors || !valuesAreSet)}
                        text={window.sv_resource.get('plf_onlineform_next_label')}
                        variation={FormButtonVariation.Next}
                        type="submit" />
        </div>
    );
};

export default StepNavigation;

import React, {ReactNode} from 'react';
import './index.scss';
import BtnChevron, { ChevronDirection } from '../../atoms/btn-chevron';
import Button, { ButtonType } from '../../atoms/button';
import RichText from '../../atoms/richtext';

export enum CardType {
    Display = 'display',
    Primary = 'primary-button',
    More = 'more-button',
    Long = 'long',
}

interface CardProps {
    btnTxt?: string,
    buttonRenderer?: (button: ReactNode) => ReactNode,
    children: React.ReactNode,
    title: string
    type?: CardType,
    url?: string,
    video?: string,
}

const Card = ({
    btnTxt,
    buttonRenderer,
    children,
    title,
    type,
    url,
    video,
}: CardProps) => {
    const baseClassName = 'm-card';
    const CustomTag = url ? 'a' : 'div';
    const props = url ? {
        href: url,
    } : {};

    const getButton = () => {
        switch (type) {
        case CardType.More:
            return <BtnChevron direction={ChevronDirection.Right} standalone />;
        case CardType.Primary:
            return <Button text={btnTxt} type={ButtonType.Default} />;
        default:
            return '';
        }
    };

    return (
        <CustomTag {...props} className={`${baseClassName} -${type}`}>
            <div className={`${baseClassName}__title display3`}><RichText text={title} /></div>
            <div className={`${baseClassName}__content-wrapper`}>
                <div className={`${baseClassName}__content`}>{children}</div>
                <div>
                    {buttonRenderer ? buttonRenderer(getButton()) : getButton()}
                </div>
                {
                    video && <iframe className={`${baseClassName}__videoFrame`} title={title} src={video} frameBorder="0" allowFullScreen />
                }
            </div>
        </CustomTag>
    );
};

Card.defaultProps = {
    btnTxt: 'Weiter',
    type: CardType.Display,
    url: undefined,
    video: undefined,
};

export default Card;

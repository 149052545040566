import React, {MouseEventHandler, ReactNode, useCallback} from "react";
import Card, {CardType} from "../../../../../../../../design/1/js/templates/molecules/card";
import PolicyCardData from "../../../lib/PolicyCardData";
import IconButton from "../../../../../../../../design/1/js/templates/atoms/icon-button";
import {IconName} from "../../../../../../../../design/1/js/templates/atoms/icon";
import CardImage from "../../../../../../../../design/1/js/templates/atoms/card-image";
import NewsIndicatorOverlay from "../../../../../../../../design/1/js/templates/atoms/news-indicator-overlay";

interface Props {
    card: PolicyCardData,
    selectAction: string,
}

const PolicyCard = ({card, selectAction}: Props) => {
    const baseClassName = 'w-policySelector-policyCard';

    const getSelectUrl = useCallback((action?: string) => {
        const url = new URL(selectAction, document.location.origin);
        const newParams = new URLSearchParams({
            insuredId: card.insuredId,
            ...(action ? {action} : {})
        });

        return new URL(`${url.origin}${url.pathname}?${newParams}`).toString();
    }, [card, selectAction]);

    const onClickSetCustomLink = useCallback((link: string): MouseEventHandler => {
        return (event) => {
            const parentAnchor = (event.target as HTMLElement).closest('a');
            parentAnchor.href = link;
        };
    }, []);

    const buttonRender = useCallback((button: ReactNode) => (
        <div className="d-flex">
            {card.hasNewDocuments && (
                <span
                    className="position-relative d-block me-3"
                    onClickCapture={onClickSetCustomLink(getSelectUrl('documents'))}
                >
                    <NewsIndicatorOverlay/>
                    <IconButton icon={IconName.Document}/>
                </span>
            )}
            {card.hasNewMessages && (
                <span
                    className="position-relative d-block me-3"
                    onClickCapture={onClickSetCustomLink(getSelectUrl('messages'))}
                >
                    <NewsIndicatorOverlay/>
                    <IconButton icon={IconName.Mail}/>
                </span>
            )}
            {button}
        </div>
    ), []);

    return (
        <Card
            buttonRenderer={buttonRender}
            title={card.title}
            type={CardType.More}
            url={getSelectUrl()}
        >
            <CardImage
                image={card.image}
                cardName={card.title}
            />
            <div className="d-flex flex-column align-items-center">
                <span>
                    {window.sv_resource.get('plf_policyselector_insured_id')}
                </span>
                <br/>
                <span className={`${baseClassName}__insuredId display2`}>
                    {card.insuredId}
                </span>
            </div>
        </Card>
    );
};

export default PolicyCard;

import React, { forwardRef } from 'react';
import './index.scss';
import { FormikErrors, FormikValues } from 'formik';
import Icon, { IconName } from '../../icon';

export interface TextAreaProps {
    className?: string;
    disabled?: boolean;
    errors: string | string[] | FormikErrors<FormikValues> | FormikErrors<FormikValues>[];
    id: string;
    name: string;
    onBlur: (e) => void;
    onChange: (e) => void;
    onFocus?: (e) => void;
    placeholder: string;
    touched: boolean;
    value?: string;
    rows?: number;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props: TextAreaProps, ref) => {
    const baseClassName = 'a-textArea';
    return (
        <>
            <div className={`${baseClassName} ${props.className} ${props.touched ? '-touched' : ''} ${props.errors ? '-error' : ''}`}>
                <textarea
                    ref={ref}
                    disabled={props.disabled}
                    id={props.id}
                    name={props.name}
                    onBlur={props.onBlur}
                    onChange={props.onChange}
                    onFocus={props.onFocus}
                    placeholder={props.placeholder}
                    value={props.value}
                    rows={props.rows}
                />

                {props.errors && props.touched && <Icon name={IconName.ErrorCircle} className={`${baseClassName}__error-icon`} />}
                {!props.errors && props.touched && <Icon name={IconName.SuccessCircle} className={`${baseClassName}__success-icon`} />}
            </div>
            {props.errors && props.touched && <div className={`${baseClassName}__msg small-text`}>{props.errors}</div>}
        </>
    );
});

TextArea.defaultProps = {
    className: '',
    disabled: false,
    onFocus: undefined,
    value: undefined,
};

export default TextArea;

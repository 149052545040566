import React, {ReactNode} from "react";

type Step = ((isCurrent: boolean) => ReactNode);

export default function stepRenderer(currentStep: number, steps: Step[]): ReactNode {
    const output: Step[] = [];

    for (let i = 0; i <= currentStep; i++) {
        if (i >= steps.length) {
            throw new Error();
        }

        output.push(steps[i]);
    }

    return (
        <>
            {output.map((node, index) => (
                <React.Fragment key={index}>
                    {node(index === currentStep)}
                </React.Fragment>
            ))}
        </>
    );
}

import React from 'react';
import './index.scss';
import DataTableData from '../../../lib/DataTableData';
import Table from '../../../../../../../../design/1/js/templates/molecules/table';
import TableBody from '../../../../../../../../design/1/js/templates/atoms/table-body';
import TableHeightRow from '../../../../../../../../design/1/js/templates/atoms/table-height-row';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import JsonResponse from '../../../../../../../../design/1/js/lib/entity/response/JsonResponse';
import TableHead from '../../../../../../../../design/1/js/templates/atoms/table-head';
import { formatCurrency } from '../../../../../../../../design/1/js/lib/formatData';
import StatusMessageVariation
    from '../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation';

interface DataTableMaxWefProps {
    action: string;
    setMax: (value: number) => void;
}

const DataTableMaxWef = ({
    action, setMax,
}: DataTableMaxWefProps) => {
    const baseClassName = 'w-dataTableMaxWef';

    return (
        <div className={`${baseClassName}`}>
            <AsyncLoader
                action={action}
                renderContent={lazyChildren => (
                    <>
                        {lazyChildren}
                    </>
                )}
                onSuccess={(res: JsonResponse<DataTableData>) => {
                    setMax(+res.context.value);
                }}
                variation={StatusMessageVariation.Short}
            >
                {(maxTableData: DataTableData) => (
                    <Table>
                        <TableHead>
                            <div className="col-8 col-lg-10" />
                            <div className="col-4 col-lg-2">{window.sv_resource.get('plf_simulation_table_head_amount')}</div>
                        </TableHead>
                        <TableBody>
                            <TableHeightRow>
                                <div className={`${baseClassName}__column col-8 col-lg-10`}>
                                    {maxTableData.title}
                                </div>
                                <div className={`${baseClassName}__column justify-content-center col-4 col-lg-2`}>
                                    <>
                                        {formatCurrency(+maxTableData.value)}
                                        <span className={`${baseClassName}__currency small-text`}>{maxTableData.currency}</span>
                                    </>
                                </div>
                            </TableHeightRow>
                        </TableBody>
                    </Table>
                )}
            </AsyncLoader>
        </div>
    );
};

export default DataTableMaxWef;

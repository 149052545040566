import React from 'react';
import './index.scss';
import BenefitsEntry from '../../../lib/BenefitsEntry';
import TableHeightRow from '../../../../../../../../design/1/js/templates/atoms/table-height-row';
import { formatCurrency } from '../../../../../../../../design/1/js/lib/formatData';

interface BenefitsRowProps {
    emptyMessage: string;
    entry: BenefitsEntry;
}

const BenefitsRow = ({
    emptyMessage, entry,
}: BenefitsRowProps) => {
    const baseClassName = 'w-benefitsRow';

    return (
        <TableHeightRow className={baseClassName}>
            <div className="col-6">
                {entry.name}
            </div>
            <div className="col-6">
                { entry.amount ? (
                    <>
                        <strong className={`${baseClassName}__amount`}>{formatCurrency(entry.amount)}</strong>
                        {entry.currencyInterval}
                    </>
                ) : (
                    <>{emptyMessage}</>
                )}
            </div>
        </TableHeightRow>
    );
};

export default BenefitsRow;

import { loadWidget } from '@sitevision/react';
import React from 'react';
import ReactDOM from 'react-dom';
import OnlineFormArea from './lib/OnlineFormArea';
import ExitNoticeVeryLowWage from './templates/organism/exit-notice-very-low-wage';
import ExitNoticeVestedBenefitsInstitution from './templates/organism/exit-notice-vested-benefits-institution';
import VoluntaryContinuation from './templates/organism/voluntary-continuation';
import CivilStateChange from './templates/organism/civil-state-change';

loadWidget(`.plf-onlineform[data-area="${OnlineFormArea.ExitNoticeVeryLowWage}"]`, onlineFormWidget => {
    const masterDataAction = onlineFormWidget.dataset.masterDataAction;
    const formAction = onlineFormWidget.dataset.formAction;
    const bankTransferDataAction = onlineFormWidget.dataset.bankTransferDataAction;
    ReactDOM.render(<ExitNoticeVeryLowWage masterDataAction={masterDataAction} formAction={formAction} bankTransferDataAction={bankTransferDataAction} />, onlineFormWidget);
});

loadWidget(`.plf-onlineform[data-area="${OnlineFormArea.ExitNoticeVestedBenefitsInstitution}"]`, onlineFormWidget => {
    const masterDataAction = onlineFormWidget.dataset.masterDataAction;
    const formAction = onlineFormWidget.dataset.formAction;
    ReactDOM.render(<ExitNoticeVestedBenefitsInstitution masterDataAction={masterDataAction} formAction={formAction} />, onlineFormWidget);
});

loadWidget(`.plf-onlineform[data-area="${OnlineFormArea.VoluntaryContinuation}"]`, onlineFormWidget => {
    const masterDataAction = onlineFormWidget.dataset.masterDataAction;
    const formAction = onlineFormWidget.dataset.formAction;
    ReactDOM.render(<VoluntaryContinuation masterDataAction={masterDataAction} formAction={formAction} />, onlineFormWidget);
});

loadWidget(`.plf-onlineform[data-area="${OnlineFormArea.CivilStateChange}"]`, onlineFormWidget => {
    const masterDataAction = onlineFormWidget.dataset.masterDataAction;
    const formAction = onlineFormWidget.dataset.formAction;
    ReactDOM.render(<CivilStateChange masterDataAction={masterDataAction} formAction={formAction} />, onlineFormWidget);
});

import React from 'react';
import './index.scss';
import LinkType from '../../../lib/LinkType';
import YourDocumentType from '../../../lib/YourDocumentType';
import YourDocumentTextType from '../../../lib/YourDocumentTextType';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import Table from '../../../../../../../../design/1/js/templates/molecules/table';
import TableBody from '../../../../../../../../design/1/js/templates/atoms/table-body';
import Button from '../../../../../../../../design/1/js/templates/atoms/button';
import DownloadRow from '../../molecules/download-row';

interface YourDocumentsProps {
    links: LinkType[];
    text: YourDocumentTextType;
    yourDocuments: YourDocumentType[];
    showSelfGenerationInfo: boolean
}

const YourDocuments = ({
    links, text, yourDocuments, showSelfGenerationInfo
}: YourDocumentsProps) => {
    const baseClassName = 'w-yourDocuments';
    return (
        <div className={`${baseClassName}`}>
            {text.title && (
                <h2 className={`${baseClassName}__title`} id={text.id}>{text.title}</h2>
            )}
            <Paragraph>{text.description}</Paragraph>
            <Paragraph>{text.expireInfo}</Paragraph>
            <Table>
                <TableBody>
                    {yourDocuments.map((document, index) => (
                        <DownloadRow document={document} key={`documentRow-${index.toString()}`} />
                    ))}
                </TableBody>
            </Table>
            { showSelfGenerationInfo && (
                <>
                    <Paragraph>{text.description_links}</Paragraph>
                    <div className={`${baseClassName}__links row gap-xxs`}>
                {links.map((link, index) => (
                    <div className={`${baseClassName}__linkWrapper col-12 col-sm-6`} key={`link-${index.toString()}`}>
                    <Button text={link.name} href={link.link} />
                    </div>
                    ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default YourDocuments;

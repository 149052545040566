import React, { ChangeEvent } from 'react';
import * as Yup from 'yup';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import './index.scss';
import FormWithAnswerText from '../../../../../../../../design/1/js/templates/organisms/form-with-answer-text';
import Select from '../../../../../../../../design/1/js/templates/atoms/form-fields/select';
import Checkbox from '../../../../../../../../design/1/js/templates/atoms/form-fields/checkbox';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import RadioButton from '../../../../../../../../design/1/js/templates/atoms/form-fields/radiobutton';
import { bicSwiftRegex, validateIBAN } from '../../../../../../../../design/1/js/lib/validateData';
import { formatIBAN } from '../../../../../../../../design/1/js/lib/formatData';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import InitialAccountChangeData from '../../../lib/entity/changeOfAccount/InitialAccountChangeData';
import {hasInsuredMultipleIds} from "../../../../../../../../design/1/js/lib/util/InsuredUtils";
import StatusMessage, {MessageType} from "../../../../../../../../design/1/js/templates/molecules/status-message";
import StatusMessageVariation
    from "../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation";

export interface ChangeOfAccountFormProps {
    actionUrl: string,
    handleSubmit: (status: number) => void,
    initialDataAction: string,
}

const ChangeOfAccountForm = ({
    actionUrl, handleSubmit, initialDataAction,
}:ChangeOfAccountFormProps) => {
    const baseClassName = 'w-changeOfAccountForm';

    const validationSchema = Yup.object().shape({
        lastname: Yup.string()
            .required(window.sv_resource.get('form_errormsg_string')),
        firstname: Yup.string()
            .required(window.sv_resource.get('form_errormsg_string')),
        domestic: Yup.string(),
        accountType: Yup.string(),
        iban: Yup.string()
            .when('domestic', {
                is: 'domestic',
                then: Yup.string()
                    .test(
                        'valid iban',
                        window.sv_resource.get('form_errormsg_iban'),
                        value => (validateIBAN(value)),
                    )
                    .required(window.sv_resource.get('form_errormsg_iban')),
            })
            .when('accountType', {
                is: 'iban',
                then: Yup.string()
                    .test(
                        'valid iban',
                        window.sv_resource.get('form_errormsg_iban'),
                        value => (validateIBAN(value)),
                    )
                    .required(window.sv_resource.get('form_errormsg_iban')),
            }),
        accountNr: Yup.string()
            .when('accountType', {
                is: 'accountNr',
                then: Yup.string()
                    .required(window.sv_resource.get('form_errormsg_string')),
            }),
        bicSwift: Yup.string()
            .when('domestic', {
                is: 'foreign',
                then: Yup.string()
                    .matches(bicSwiftRegex, window.sv_resource.get('form_errormsg_bic'))
                    .required(window.sv_resource.get('form_errormsg_string')),
            }),
        currency: Yup.string(),
        financialInstitution: Yup.string()
            .when('domestic', {
                is: 'foreign',
                then: Yup.string()
                    .required(window.sv_resource.get('form_errormsg_string')),
            }),
        street1: Yup.string()
            .when('domestic', {
                is: 'foreign',
                then: Yup.string()
                    .required(window.sv_resource.get('form_errormsg_string')),
            }),
        street2: Yup.string(),
        zip: Yup.string()
            .when('domestic', {
                is: 'foreign',
                then: Yup.string()
                    .required(window.sv_resource.get('form_errormsg_string')),
            }),
        city: Yup.string()
            .when('domestic', {
                is: 'foreign',
                then: Yup.string()
                    .required(window.sv_resource.get('form_errormsg_string')),
            }),
        country: Yup.string(),
        confirmation: Yup.boolean()
            .oneOf([true], window.sv_resource.get('form_errormsg_radio'))
            .required(window.sv_resource.get('form_errormsg_radio')),
    });

    return (
        <AsyncLoader
            action={initialDataAction}
            renderContent={lazyChildren => (
                <>
                    {lazyChildren}
                </>
            )}
        >
            {(initialData: InitialAccountChangeData) => {
                const initialValues = Object.freeze({
                    lastname: initialData.user.lastname,
                    firstname: initialData.user.firstname,
                    domestic: 'domestic',
                    accountType: 'iban',
                    iban: '',
                    accountNr: '',
                    bicSwift: '',
                    currency: initialData.currencies[0].name,
                    financialInstitution: '',
                    street1: '',
                    street2: '',
                    zip: '',
                    city: '',
                    country: '',
                    confirmation: false,
                });

                return (
                    <FormWithAnswerText
                        actionUrl={actionUrl}
                        className={`${baseClassName}`}
                        handleSubmit={handleSubmit}
                        handleValidate={validationSchema}
                        initialValues={initialValues}
                        name="ChangeOfAccount"
                    >
                        {formik => {
                            const handleIbanBlur = (e: ChangeEvent<HTMLInputElement>) => {
                                if (e.target.value !== '' && validateIBAN(e.target.value)) {
                                    formik.setFieldValue('iban', formatIBAN(e.target.value));
                                }
                                formik.handleBlur(e);
                            };

                            return (
                                <>
                                    <h2>{window.sv_resource.get('plf_servicesform_changeofaccount_subtitle')}</h2>
                                    <Paragraph>{window.sv_resource.get('plf_servicesform_changeofaccount_account_name_note')}</Paragraph>
                                    <div className={`${baseClassName}__section -disabled row gap-xxs`}>
                                        <TextField
                                            disabled
                                            id="firstname"
                                            label={window.sv_resource.get('plf_servicesform_changeofaccount_firstname_label')}
                                            name="firstname"
                                            type="text"
                                            className="col-12 col-lg-6"
                                            placeholder=""
                                            touched={formik.touched.firstname}
                                            errors={formik.errors.firstname}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.firstname}
                                        />
                                        <TextField
                                            disabled
                                            id="lastname"
                                            label={window.sv_resource.get('plf_servicesform_changeofaccount_lastname_label')}
                                            name="lastname"
                                            type="text"
                                            className="col-12 col-lg-6"
                                            placeholder=""
                                            touched={formik.touched.lastname}
                                            errors={formik.errors.lastname}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.lastname}
                                        />
                                    </div>
                                    <Paragraph>{window.sv_resource.get('plf_servicesform_changeofaccount_transfer_note')}</Paragraph>
                                    <div className={`${baseClassName}__section`}>
                                        <div className={`${baseClassName}__radioWrapper`}>
                                            <RadioButton
                                                checked={formik.values.domestic === 'domestic'}
                                                errors={formik.errors.domestic}
                                                id="domestic"
                                                name="domestic"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                touched={!!formik.touched.domestic}
                                                label={window.sv_resource.get('plf_servicesform_changeofaccount_account_domestic')}
                                            />
                                            <RadioButton
                                                checked={formik.values.domestic === 'foreign'}
                                                errors={formik.errors.domestic}
                                                id="foreign"
                                                name="domestic"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                touched={!!formik.touched.domestic}
                                                label={window.sv_resource.get('plf_servicesform_changeofaccount_account_foreign')}
                                            />
                                        </div>
                                        {
                                            formik.values.domestic === 'domestic'
                                                ? (
                                                    <TextField
                                                        className={`${baseClassName}__field col-12 col-lg-6`}
                                                        errors={formik.errors.iban}
                                                        id="iban"
                                                        label={window.sv_resource.get('plf_servicesform_changeofaccount_iban_label')}
                                                        name="iban"
                                                        onBlur={handleIbanBlur}
                                                        onChange={formik.handleChange}
                                                        placeholder=""
                                                        touched={!!formik.touched.iban}
                                                        type="text"
                                                        value={formik.values.iban}
                                                    />
                                                ) : (
                                                    <>
                                                        <div className="row gap-xxs">
                                                            <div className={`${baseClassName}__radioWrapper`}>
                                                                <RadioButton
                                                                    checked={formik.values.accountType === 'iban'}
                                                                    errors={formik.errors.accountType}
                                                                    id="iban"
                                                                    name="accountType"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    touched={!!formik.touched.accountType}
                                                                    label={window.sv_resource.get('plf_servicesform_changeofaccount_iban_label')}
                                                                />
                                                                <RadioButton
                                                                    checked={formik.values.accountType === 'accountNr'}
                                                                    errors={formik.errors.accountType}
                                                                    id="accountNr"
                                                                    name="accountType"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    touched={!!formik.touched.accountType}
                                                                    label={window.sv_resource.get('plf_servicesform_changeofaccount_accountnr_label')}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row gap-xxs">
                                                            {
                                                                formik.values.accountType === 'iban'
                                                                    ? (
                                                                        <TextField
                                                                            className={`${baseClassName}__field col-12 col-lg-6`}
                                                                            errors={formik.errors.iban}
                                                                            id="iban"
                                                                            label={window.sv_resource.get('plf_servicesform_changeofaccount_iban_label')}
                                                                            name="iban"
                                                                            onBlur={handleIbanBlur}
                                                                            onChange={formik.handleChange}
                                                                            placeholder=""
                                                                            touched={!!formik.touched.iban}
                                                                            type="text"
                                                                            value={formik.values.iban}
                                                                        />
                                                                    ) : (
                                                                        <TextField
                                                                            className={`${baseClassName}__field col-12 col-lg-6`}
                                                                            errors={formik.errors.accountNr}
                                                                            id="accountNr"
                                                                            label={window.sv_resource.get('plf_servicesform_changeofaccount_accountnr_label')}
                                                                            name="accountNr"
                                                                            onBlur={formik.handleBlur}
                                                                            onChange={formik.handleChange}
                                                                            placeholder=""
                                                                            touched={!!formik.touched.accountNr}
                                                                            type="text"
                                                                            value={formik.values.accountNr}
                                                                        />
                                                                    )
                                                            }
                                                            <TextField
                                                                className={`${baseClassName}__field col-12 col-lg-6`}
                                                                errors={formik.errors.bicSwift}
                                                                id="bicSwift"
                                                                label={window.sv_resource.get('plf_servicesform_changeofaccount_bic_label')}
                                                                name="bicSwift"
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                                placeholder=""
                                                                touched={formik.touched.bicSwift}
                                                                type="text"
                                                                value={formik.values.bicSwift}
                                                            />
                                                        </div>
                                                        <div className="row gap-xxs ">
                                                            <Select
                                                                className={`${baseClassName}__field col-12 col-lg-6`}
                                                                id="currency"
                                                                label={window.sv_resource.get('plf_servicesform_changeofaccount_currency_label')}
                                                                name="currency"
                                                                touched
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                                value={formik.values.currency}
                                                            >
                                                                {
                                                                    initialData.currencies.map((currency, index) => (
                                                                        <option value={currency.name} key={`currency-${index.toString()}`}>
                                                                            {currency.name}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </Select>
                                                            <div className="row gap-xxs">
                                                                <TextField
                                                                    className={`${baseClassName}__field col-12 col-lg-6`}
                                                                    errors={formik.errors.financialInstitution}
                                                                    id="financialInstitution"
                                                                    label={window.sv_resource.get('plf_servicesform_changeofaccount_name_of_bank_label')}
                                                                    name="financialInstitution"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    placeholder=""
                                                                    touched={!!formik.touched.financialInstitution}
                                                                    type="text"
                                                                    value={formik.values.financialInstitution}
                                                                />
                                                            </div>
                                                            <div className="row gap-xxs">
                                                                <TextField
                                                                    className={`${baseClassName}__field col-12 col-lg-6`}
                                                                    errors={formik.errors.street1}
                                                                    id="street1"
                                                                    label={window.sv_resource.get('plf_servicesform_changeofaccount_street1_label')}
                                                                    name="street1"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    placeholder=""
                                                                    touched={!!formik.touched.street1}
                                                                    type="text"
                                                                    value={formik.values.street1}
                                                                />
                                                                <TextField
                                                                    className={`${baseClassName}__field col-12 col-lg-6`}
                                                                    errors={formik.errors.street2}
                                                                    id="street2"
                                                                    label={window.sv_resource.get('plf_servicesform_changeofaccount_street2_label')}
                                                                    name="street2"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    placeholder=""
                                                                    touched={formik.touched.street2}
                                                                    type="text"
                                                                    value={formik.values.street2}
                                                                />
                                                                <TextField
                                                                    className={`${baseClassName}__field col-4 col-lg-2`}
                                                                    errors={formik.errors.zip}
                                                                    id="zip"
                                                                    label={window.sv_resource.get('plf_servicesform_changeofaccount_zip_label')}
                                                                    name="zip"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    placeholder=""
                                                                    touched={formik.touched.zip}
                                                                    type="text"
                                                                    value={formik.values.zip}
                                                                />
                                                                <TextField
                                                                    className={`${baseClassName}__field col-8 col-lg-4`}
                                                                    errors={formik.errors.city}
                                                                    id="city"
                                                                    label={window.sv_resource.get('plf_servicesform_changeofaccount_city_label')}
                                                                    name="city"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    placeholder=""
                                                                    touched={formik.touched.city}
                                                                    type="text"
                                                                    value={formik.values.city}
                                                                />
                                                                <Select
                                                                    className="col-12 col-lg-6 align-self-end"
                                                                    id="country"
                                                                    label={window.sv_resource.get('plf_servicesform_changeofaccount_country_label')}
                                                                    name="country"
                                                                    touched
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.country}
                                                                >
                                                                    <option value="" disabled>{window.sv_resource.get('form_select_default')}</option>
                                                                    {
                                                                        initialData.countryList.map(entry => (
                                                                            <option value={entry.iso} key={entry.iso}>
                                                                                {entry.label}
                                                                            </option>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                        }
                                        <Checkbox
                                            className={`${baseClassName}__checkbox col-12`}
                                            errors={formik.errors.confirmation}
                                            touched={formik.touched.confirmation}
                                            id="confirmation"
                                            name="confirmation"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            label={window.sv_resource.get('plf_servicesform_changeofaccount_confirmation')}
                                        />
                                        {hasInsuredMultipleIds() && (
                                            <div className="mb-5">
                                                <StatusMessage
                                                    customMessageColor={MessageType.Info}
                                                    description={window.sv_resource.get('plf_yourprofile_account_data_description_multiple_insured_ids')}
                                                    inline
                                                    messageType={MessageType.Info}
                                                    variation={StatusMessageVariation.WithBackground}
                                                />
                                            </div>
                                        )}
                                        <FormButton
                                            className={`${baseClassName}__submit`}
                                            disabled={Object.keys(formik.errors).length > 0 || Object.keys(formik.touched).length === 0}
                                            text={window.sv_resource.get('plf_servicesform_changeofaccount_form_submit_btn')}
                                            type="submit"
                                            variation={FormButtonVariation.Primary}
                                        />
                                    </div>
                                </>
                            );
                        }}
                    </FormWithAnswerText>
                );
            }}
        </AsyncLoader>
    );
};

export default ChangeOfAccountForm;

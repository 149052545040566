import React from 'react';
import RichText from '../../../../../../../../design/1/js/templates/atoms/richtext';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';

export interface PurchasePossibilityCheckEvaluationListProps {
    linkToMessageTool: string;
    warnings: string[];
}

const PurchasePossibilityCheckEvaluationList = ({ linkToMessageTool, warnings }: PurchasePossibilityCheckEvaluationListProps) => {
    const baseClassName = 'w-purchasePossibilityCheckEvaluationList';

    return (
        <div className={baseClassName}>
            <Paragraph>{window.sv_resource.get('plf_simulation_purchase_calculated_errormsg_intro')}</Paragraph>
            <ul>
                {
                    warnings.map((warning, index) => (
                        <li key={`warning-${index.toString()}`}>{warning}</li>
                    ))
                }
            </ul>
            <Paragraph>
                {window.sv_resource.get('plf_simulation_purchase_calculated_errormsg_outro')}
                {' '}
                <RichText text={window.sv_resource.get('plf_simulation_purchase_calculated_errormsg_outro_btn_label').replace('{{link_messagetool}}', linkToMessageTool)} />
            </Paragraph>
        </div>
    );
};

export default PurchasePossibilityCheckEvaluationList;

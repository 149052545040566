import React from 'react';
import './index.scss';
import NavigationItem from '../../atoms/navigation-item';
import {ChevronDirection} from '../../atoms/btn-chevron';
import Navigation, {NavDirection, NavigationType} from '../navigation';
import NavItem from './lib/NavItem';
import {IconName} from '../../atoms/icon';
import {
    getCurrentInsuredId,
    getOtherInsuredIds,
    hasInsuredMultipleIds
} from "../../../lib/util/InsuredUtils";

export interface NavbarProps {
    navigationItems: NavItem[];
    newDocuments?: boolean;
    newMessages?: boolean;
}

const MenuRightIndex = {
    documents: 0,
    messages: 1,
    myMPK: 2,
}

const Navbar = ({
    navigationItems, newDocuments, newMessages,
}: NavbarProps) => {
    const baseClassName = 'm-navbar';
    const leftNavbarItems = navigationItems.slice(0, navigationItems.length -4)
    const rightNavbarItems = navigationItems.slice(-4);
    const showLeftNavbar = leftNavbarItems.length > 0;

    return (
        <Navigation direction={showLeftNavbar ? NavDirection.Row : NavDirection.RowReverse}>
            { showLeftNavbar &&
                <div className={`${baseClassName}__left d-none d-lg-flex`}>
                    {leftNavbarItems.map((item, index) => (
                        <NavigationItem active={item.active} text={item.title} key={`navigation-item-${index.toString()}`}>
                            {item.children.length > 0
                                && (
                                    <Navigation type={NavigationType.Sub}>
                                        {item.children.map((navLink, linkIndex) => (
                                            <NavigationItem
                                                active={navLink.active}
                                                direction={ChevronDirection.Right}
                                                href={navLink.url}
                                                key={`sub-navigation-item-${linkIndex.toString()}`}
                                                target={navLink.target}
                                                text={navLink.title}
                                                type={NavigationType.Sub}
                                            />
                                        ))}
                                    </Navigation>
                                )}
                        </NavigationItem>
                    ))}
                </div>
            }
            <div className={`${baseClassName}__right d-none d-lg-flex`}>
                <NavigationItem icon={IconName.Document} href={rightNavbarItems[MenuRightIndex.documents].url} news={newDocuments} />
                <NavigationItem icon={IconName.Mail} href={rightNavbarItems[MenuRightIndex.messages].url} news={newMessages} />
                <NavigationItem
                    active={rightNavbarItems[MenuRightIndex.myMPK].active}
                    text={`${rightNavbarItems[MenuRightIndex.myMPK].title}${hasInsuredMultipleIds() ? ` ${getCurrentInsuredId()}` : ''}`}
                    className={`${baseClassName}-logout-left-item`}
                >
                    {rightNavbarItems[MenuRightIndex.myMPK].children.length > 0
                    && (
                        <Navigation type={NavigationType.Sub}>
                            {rightNavbarItems[MenuRightIndex.myMPK].children.map((navLink, linkIndex) => (
                                <NavigationItem
                                    active={navLink.active}
                                    className={`${baseClassName}__lastNavItem`}
                                    direction={ChevronDirection.Right}
                                    href={navLink.url}
                                    key={`sub-navigation-item-${linkIndex.toString()}`}
                                    target={navLink.target}
                                    text={navLink.title}
                                    type={NavigationType.Sub}
                                />
                            ))}
                            {hasInsuredMultipleIds() && getOtherInsuredIds().map(insuredId => (
                                <NavigationItem
                                    className={`${baseClassName}__lastNavItem`}
                                    direction={ChevronDirection.Right}
                                    href={`/_common/policy/changePolicy?insuredId=${insuredId}`}
                                    key={insuredId}
                                    target="_self"
                                    text={window.sv_resource.get('multiple_insured_id_change_to').replace('{{insuredId}}', insuredId)}
                                    type={NavigationType.Sub}
                                />
                            ))}
                        </Navigation>
                    )}
                </NavigationItem>
                <NavigationItem icon={IconName.Logout} href={rightNavbarItems[3].url} className={`${baseClassName}-logout-item`} />
            </div>
        </Navigation>
    );
};

Navbar.defaultProps = {
    newDocuments: false,
    newMessages: true,
};

export default Navbar;

import React from 'react';
import ReactDOM from 'react-dom';
import { loadWidget } from '@sitevision/react';
import Info from "./templates/organisms/info";

loadWidget('.plf-info-area', info => {
    const texts = {
        topText: info.dataset.topText,
        mainText: info.dataset.mainText,
        bottomText: info.dataset.bottomText,
    }
    const buttonLabel = info.dataset.buttonLabel;
    const buttonLink = info.dataset.buttonLink;

    ReactDOM.render(<Info texts={texts} buttonLabel={buttonLabel} buttonLink={buttonLink} />, info);
});

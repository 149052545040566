import React from 'react';
import './index.scss';
import Home from '../../atoms/icon/icons/Home';
import Phone from '../../atoms/icon/icons/Phone';
import Mail from '../../atoms/icon/icons/Mail';
import Icon, { IconName } from '../../atoms/icon';

export enum FooterIcon {
    HomeFooter = 'home',
    PhoneFooter = 'phone',
    MailFooter = 'mail',
    None = 'none',
}
interface FooterSectionProps {
    icon: FooterIcon;
}

const FooterSection: React.FC<FooterSectionProps> = ({ children, icon }) => {
    const baseClassName = 'm-footer-section';

    const chooseIcon = () => {
        switch (icon) {
        case FooterIcon.HomeFooter:
            return <Home />;
        case FooterIcon.PhoneFooter:
            return <Phone />;
        case FooterIcon.MailFooter:
            return <Mail />;
        default:
            return <Icon className={`${baseClassName}__icon-placeholder`} name={IconName.Home} />;
        }
    };

    return (
        <div className={`${baseClassName}`}>
            {chooseIcon()}
            <div className={`${baseClassName}__text-wrapper`}>
                {children}
            </div>
        </div>
    );
};

export default FooterSection;

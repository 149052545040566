import React from 'react';
import './index.scss';
import ChevronDown from './icons/ChevronDown';
import ChevronLeft from './icons/ChevronLeft';
import ChevronRight from './icons/ChevronRight';
import Close from './icons/Close';
import Home from './icons/Home';
import InfoCircle from './icons/InfoCircle';
import Mail from './icons/Mail';
import Menu from './icons/Menu';
import Phone from './icons/Phone';
import Check from './icons/Check';
import ArrowBack from './icons/ArrowBack';
import ArrowForward from './icons/ArrowForward';
import Password from './icons/Password';
import ErrorCircle from './icons/ErrorCircle';
import SuccessCircle from './icons/SuccessCircle';
import Download from './icons/Download';
import Document from './icons/Document';
import Print from './icons/Print';
import Calendar from './icons/Calendar';
import Cashed from './icons/Cashed';
import CHF from './icons/CHF';
import Logout from './icons/Logout';
import PDF from './icons/PDF';

export enum IconName {
    'ArrowBack',
    'ArrowForward',
    'Cashed',
    'Calendar',
    'Check',
    'ChevronRight',
    'ChevronLeft',
    'ChevronDown',
    'Chf',
    'Close',
    'Document',
    'Download',
    'ErrorCircle',
    'Home',
    'InfoCircle',
    'Mail',
    'Menu',
    'Password',
    'Phone',
    'Print',
    'SuccessCircle',
    'Logout',
    'PDF',
}

export enum IconPosition {
    'Left',
    'Right',
}

const icons: { [key in IconName]: React.FC } = {
    [IconName.ArrowBack]: ArrowBack,
    [IconName.ArrowForward]: ArrowForward,
    [IconName.Cashed]: Cashed,
    [IconName.Calendar]: Calendar,
    [IconName.Check]: Check,
    [IconName.ChevronRight]: ChevronRight,
    [IconName.ChevronLeft]: ChevronLeft,
    [IconName.ChevronDown]: ChevronDown,
    [IconName.Chf]: CHF,
    [IconName.Close]: Close,
    [IconName.Document]: Document,
    [IconName.Download]: Download,
    [IconName.ErrorCircle]: ErrorCircle,
    [IconName.Home]: Home,
    [IconName.InfoCircle]: InfoCircle,
    [IconName.Mail]: Mail,
    [IconName.Menu]: Menu,
    [IconName.Password]: Password,
    [IconName.Phone]: Phone,
    [IconName.Print]: Print,
    [IconName.SuccessCircle]: SuccessCircle,
    [IconName.Logout]: Logout,
    [IconName.PDF]: PDF,
} as const;

interface Props {
    className?: string;
    name: IconName;
    onClick?: () => void;
}

const Icon = ({ className, name, onClick }: Props) => {
    const Ico = icons[name];
    const handleClick = () => {
        if (onClick !== undefined) {
            onClick();
        }
    }

    return (
        <div className={`a-icon ${className}`} onClick={handleClick}>
            <Ico />
        </div>
    );
};

Icon.defaultProps = {
    className: '',
};

export default Icon;

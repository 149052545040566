import React from 'react';
import Icon, { IconName } from '../../atoms/icon';
import './index.scss';
import RichText from '../../atoms/richtext';
import Section from '../../atoms/section';
import StatusMessageVariation from './lib/StatusMessageVariation';
import { RichContextLink } from "../../../lib/entity/response/RichContext";
import Button, { ButtonType } from "../../atoms/button";


interface Props {
    additionalText?: string;
    className?: string;
    customMessageColor?: MessageType,
    description: string;
    inline?: boolean;
    messageType: MessageType,
    traceId?: string;
    title?: string;
    variation?: StatusMessageVariation;
    link?: RichContextLink;
}

export enum MessageType {
    Success = 'success',
    Info = 'info',
    Error = 'error',
}

const icons: { [key in MessageType]: IconName } = {
    [MessageType.Success]: IconName.SuccessCircle,
    [MessageType.Info]: IconName.InfoCircle,
    [MessageType.Error]: IconName.ErrorCircle,
};

const StatusMessage = ({
    link, additionalText, className, customMessageColor, description, inline, messageType, traceId, title, variation,
}: Props) => {
    const baseClassName = 'm-statusMessage';
    const ParentComponent = inline ? React.Fragment : Section;
    return (
        <ParentComponent>
            <div className={`${baseClassName} ${className} -${variation}`}>
                {title.length > 0 && (
                    variation === StatusMessageVariation.Subpage
                        ? <h2>{title}</h2> : <h2 className="headline">{title}</h2>
                )}
                <div className={`${baseClassName}__container -icon-${customMessageColor || messageType} -color-${customMessageColor ? customMessageColor : 'default'}`}>
                    <div className={`${baseClassName}__icon`}>
                        <Icon name={icons[messageType]} />
                    </div>
                    <div className={`${baseClassName}__content`}>
                        <p>
                            <RichText text={description} />
                            <br />
                            {
                                traceId && variation !== StatusMessageVariation.Short && (
                                    <span className="small-text">
                                        (
                                        {window.sv_resource.get('plf_error_reference_id')}
                                        :
                                        {' '}
                                        {traceId}
                                        )
                                    </span>
                                )
                            }
                        </p>
                        {additionalText?.length > 0 && (
                            <RichText text={additionalText} />
                        )}
                        {link?.url.length > 0 && (
                            <div className="d-inline-block">
                                <Button href={link.url} target="_self" text={link.label} type={ButtonType.Orange} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </ParentComponent>
    );
};

StatusMessage.defaultProps = {
    additionalText: null,
    className: '',
    inline: false,
    traceId: null,
    title: '',
    variation: StatusMessageVariation.Standard,
    link: null,
};

export default StatusMessage;

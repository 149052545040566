import React from 'react';
import ReactDOM from 'react-dom';
import { loadWidget } from '@sitevision/react';
import FormsOverview from './templates/organisms/services-overview';
import ServiceType from './lib/ServiceType';

loadWidget('.plf-lifesituation-services', lifeSituationServicesWidget => {
    const service: ServiceType = JSON.parse(lifeSituationServicesWidget.dataset.service);
    const id: string = lifeSituationServicesWidget.dataset.headerId;
    ReactDOM.render(<FormsOverview id={id} service={service} />, lifeSituationServicesWidget);
});

import React from 'react';
import './index.scss';
import BtnChevron, { ChevronDirection } from '../btn-chevron';
import { NavigationItemProps } from '../navigation-item';

export enum MobileNavigationType {
    Back = 'back',
    MainLink = 'mainLink',
    SubLink = 'subLink',
    Submenu = 'submenu',
}

interface MobileNavigationItemProps extends NavigationItemProps {
    mobileType?: MobileNavigationType;
}

const MobileNavigationItem = ({
    children,
    className,
    direction,
    href,
    news,
    text,
    mobileType,
}: MobileNavigationItemProps) => {
    const baseClassName = 'a-mobile-navigation-item';

    const renderItem = () => {
        switch (mobileType) {
        case MobileNavigationType.Back:
            return (
                <li className={`${baseClassName} nav-item -${mobileType}  `}>
                    <div className={`${baseClassName}__link ${className}`}>
                        <BtnChevron direction={direction} standalone />
                        <div className={`${baseClassName}__title display2`}>{text}</div>
                    </div>
                </li>
            );
        case MobileNavigationType.MainLink:
        case MobileNavigationType.SubLink:
            return (
                <li className={`${baseClassName} nav-item -${mobileType} `}>
                    <a href={href} className={`${baseClassName}__link ${className}`}>
                        {children}
                        <div className={`${baseClassName}__title display2`}>
                            {text}
                            {news ? <span className={`${baseClassName}__news`} /> : ''}
                        </div>
                        <BtnChevron direction={direction} standalone />
                    </a>
                </li>
            );
        case MobileNavigationType.Submenu:
            return (
                <li className={`${baseClassName} nav-item -${mobileType}  `}>
                    <div className={`${baseClassName}__link ${className}`}>
                        {children}
                    </div>
                </li>
            );
        default:
            return (
                <li className={`${baseClassName} nav-item -${mobileType}  `}>
                    <div className={`${baseClassName}__link ${className}`}>
                        <BtnChevron direction={direction} standalone />
                        <div className={`${baseClassName}__title display2`}>{text}</div>
                    </div>
                </li>
            );
        }
    };

    return (renderItem());
};

MobileNavigationItem.defaultProps = {
    className: '',
    direction: ChevronDirection.Down,
    href: '/',
    mobileType: MobileNavigationType.SubLink,
};

export default MobileNavigationItem;

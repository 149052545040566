import React from 'react';
import './index.scss';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';

const MoreQuestions = () => {
    const baseClassName = 'a-more-questions';
    return (
        <div className={`${baseClassName} display1`}>
            <Paragraph>{window.sv_resource.get('plf_contact_title_question')}</Paragraph>
            <Paragraph>{window.sv_resource.get('plf_contact_title_answer')}</Paragraph>
        </div>
    );
};

export default MoreQuestions;

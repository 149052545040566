export const formatCurrency = (amount: number) => (
    new Intl.NumberFormat('de-CH', { maximumFractionDigits: 0 }).format(Math.floor(amount))
);

export const formatPercentage = (amount: number) => (
    new Intl.NumberFormat('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount)
);

export const formatDate = (date: Date) => (
    new Intl.DateTimeFormat('de-CH', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(date)
);

export const formatTime = (date: Date) => (
    new Intl.DateTimeFormat('de-CH', { hour: '2-digit', minute: '2-digit' }).format(date)
);

/**
 * Because JavaScript doesn't know timezones, this can lead to weird errors.
 * E.g. You receive the date `2022-12-21T00:00:00Z` (or even just `2022-12-21`).
 * If you're in the timezone `GMT-0800 Pacific Standard Time`, this is converted to `2022-12-20T16:00:00 GMT-0800`.
 * While both represent the same instant in time, the date differs (20th december vs. 21st december).
 * This can lead, for example, to the wrong birthdate being shown.
 * Thus, in such cases, we must offset the difference back.
 */
export const offsetDate = (date: Date): Date => {
    const timezoneOffset = date.getTimezoneOffset();
    const fixedDate = new Date(date);
    fixedDate.setMinutes(date.getMinutes() + timezoneOffset);

    return fixedDate;
}

// We concatenate the formats instead of using Intl.DateTimeFormat directly
// because it adds a comma between date and time, and we don't want that
export const formatDateAndTime = (date: Date) => (
    `${formatDate(date)} ${formatTime(date)}`
);

export const formatSocialSecurityNumber = (socialSecurityNumber: string) => (
    `${socialSecurityNumber.substr(0, 3)}.${socialSecurityNumber.substr(3, 4)}.${socialSecurityNumber.substr(7, 4)}.${socialSecurityNumber.substr(11, 2)}`
);

export const formatIBAN = (ibanToValidate: string | null) => {
    if (ibanToValidate === null) {
        return '';
    }

    const iban = ibanToValidate.replace(/\s/g, '');

    let result = '';
    for (let i = 0; i < iban.length; i += 4) {
        result += `${iban.substring(i, i + 4)} `;
    }

    return result.trim();
};

export const getIsoDate = (date: Date) => (
    `${date.getFullYear()}-${zeroPad(date.getMonth() + 1)}-${zeroPad(date.getDate())}`
);

const zeroPad = (number: number) => (
    `${number < 10 ? '0' : ''}${number}`
);

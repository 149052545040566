import { loadWidget } from '@sitevision/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { ButtonType } from './atoms/button';
import ButtonInfos from './atoms/button/lib/ButtonInfos';
import FrontPageBox, { BoxType } from './molecules/frontpage-box';

export default function initializeFrontPage() {
    initializeLoginBox();
    initializeRegisterBox();
}

function initializeLoginBox() {
    loadWidget('.plf-login-box', loginBox => {
        const btnLeft: ButtonInfos = {
            label: window.sv_resource.get('frontpage_login_btn_left_text'),
            type: ButtonType.SwissID,
            url: window.sv_resource.get('frontpage_login_btn_left_link'),
        };
        const btnRight = {
            label: window.sv_resource.get('frontpage_login_btn_right_text'),
            type: ButtonType.White,
            url: window.sv_resource.get('frontpage_login_btn_right_link'),
        };
        ReactDOM.render(<FrontPageBox btnLeft={btnLeft} btnRight={btnRight} intro={window.sv_resource.get('frontpage_login_intro')} title={window.sv_resource.get('frontpage_login_title')} type={BoxType.Login} modal={false} />, loginBox);
    });
}

function initializeRegisterBox() {
    loadWidget('.plf-register-box', registerBox => {
        const btnLeft: ButtonInfos = {
            label: window.sv_resource.get('frontpage_register_btn_left_text'),
            type: ButtonType.SwissID,
            url: 'openDialog register',
        };
        const btnRight = {
            label: window.sv_resource.get('frontpage_register_btn_right_text'),
            type: ButtonType.White,
            url: registerBox.dataset.urlAccess,
        };

        ReactDOM.render(<FrontPageBox btnLeft={btnLeft} btnRight={btnRight} intro={window.sv_resource.get('frontpage_register_intro')} title={window.sv_resource.get('frontpage_register_title')} type={BoxType.Register} oneColumn={false} modal />, registerBox);
    });
}

import React from 'react';
import ReactDOM from 'react-dom';
import { loadWidget } from '@sitevision/react';
import YourProfileArea from './lib/YourProfileArea';
import ContactData from './templates/organisms/contact-data';
import MasterData from './templates/organisms/master-data';
import SalaryData from './templates/organisms/salary-data';
import DocumentDelivery from './templates/organisms/document-delivery';
import Notifications from './templates/organisms/notifications';
import NotificationActionType from './lib/entity/NotificationActionType';
import AccountData from './templates/organisms/account-data';
import DocumentDeliveryActionType from './lib/entity/DocumentDeliveryActionType';

loadWidget(`.plf-your-profile-area[data-area="${YourProfileArea.ContactData}"]`, contactDataArea => {
    const actionEmail = contactDataArea.dataset.actionEmail;
    const actionPhone = contactDataArea.dataset.actionPhone;
    const action = contactDataArea.dataset.action;
    ReactDOM.render(<ContactData actionEmail={actionEmail} actionPhone={actionPhone} action={action} />, contactDataArea);
});

loadWidget(`.plf-your-profile-area[data-area="${YourProfileArea.MasterData}"]`, masterDataArea => {
    const action = masterDataArea.dataset.action;
    ReactDOM.render(<MasterData action={action} />, masterDataArea);
});

loadWidget(`.plf-your-profile-area[data-area="${YourProfileArea.SalaryData}"]`, salaryDataArea => {
    const action = salaryDataArea.dataset.action;
    ReactDOM.render(<SalaryData action={action} />, salaryDataArea);
});

loadWidget(`.plf-your-profile-area[data-area="${YourProfileArea.DocumentDelivery}"]`, documentDeliveryArea => {
    const action = documentDeliveryArea.dataset.action;
    const actions: DocumentDeliveryActionType = JSON.parse(documentDeliveryArea.dataset.actions);
    ReactDOM.render(<DocumentDelivery action={action} documentDeliveryActions={actions} />, documentDeliveryArea);
});

loadWidget(`.plf-your-profile-area[data-area="${YourProfileArea.Notifications}"]`, notificationsArea => {
    const action = notificationsArea.dataset.action;
    const actions: NotificationActionType = JSON.parse(notificationsArea.dataset.actions);
    ReactDOM.render(<Notifications action={action} notificationActions={actions} />, notificationsArea);
});

loadWidget(`.plf-your-profile-area[data-area="${YourProfileArea.AccountData}"]`, accountDataArea => {
    const action = accountDataArea.dataset.action;
    ReactDOM.render(<AccountData action={action} />, accountDataArea);
});

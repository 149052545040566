import {MessageToolView} from "../enum/MessageToolView";

const evaluateView = (): MessageToolView => {
    const hash = document.location.hash;

    if (hash === '#' + MessageToolView.CONVERSATION) {
        return MessageToolView.CONVERSATION;
    }

    if (hash === '#' + MessageToolView.NEW_MESSAGE) {
        return MessageToolView.NEW_MESSAGE;
    }

    return MessageToolView.INBOX
}

export default evaluateView;

import React from 'react';
import ReactDOM from 'react-dom';
import { loadWidget } from '@sitevision/react';
import Benefits from './templates/organisms/benefits';

loadWidget('.plf-life-situation-benefits', lifeSituationBenefitsWidget => {
    const action: string = lifeSituationBenefitsWidget.dataset.action;
    const id: string = lifeSituationBenefitsWidget.dataset.headerId;

    ReactDOM.render(<Benefits action={action} id={id} />, lifeSituationBenefitsWidget);
});

import React from 'react';
import './index.scss';
import TableHead from '../../../../../../../../design/1/js/templates/atoms/table-head';
import TableBody from '../../../../../../../../design/1/js/templates/atoms/table-body';
import Table from '../../../../../../../../design/1/js/templates/molecules/table';
import { formatCurrency, formatDate, formatPercentage } from '../../../../../../../../design/1/js/lib/formatData';
import FormButton, {
    FormButtonVariation
} from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import PensionCalculationSimulationCalculatedData
    from '../../../lib/pensionCalculator/PensionCalculationSimulationCalculatedData';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import Link from '../../../../../../../../design/1/js/templates/atoms/link';
import RichText from '../../../../../../../../design/1/js/templates/atoms/richtext';
import { FormattingType } from '../../../lib/FormattingType';

export interface PensionCalculationSimulationCalculatedProps {
    handleCreatePdf: () => void;
    handleReset: () => void;
    linkToCapitalPayments: string;
    simulationData: PensionCalculationSimulationCalculatedData;
}

const PensionCalculationSimulationCalculated = ({
    handleCreatePdf, handleReset, linkToCapitalPayments, simulationData,
}: PensionCalculationSimulationCalculatedProps) => {
    const baseClassName = 'w-pensionCalculationSimulationCalculated';

    return (
        <div className={baseClassName}>
            <Table>
                <TableHead>
                    <div className="col-8 col-lg-9 text-start">{window.sv_resource.get('plf_simulation_calculated_table_head_input_data')}</div>
                    <div className="col-4 col-lg-3" />
                </TableHead>
                <TableBody>
                    {simulationData.inputData.map((inputData, rowIndex) => (
                        <div className="row" key={`inputDataRow-${rowIndex.toString()}`}>
                            <div className={`${baseClassName}__column col-8 col-lg-9`}>
                                {inputData.title}
                            </div>
                            <div className={`${baseClassName}__column text-center col-4 col-lg-3`}>
                                {getFormattedValue(inputData.value, inputData.formattingType)}
                                { inputData.currency &&
                                    <span className={`${baseClassName}__currency small-text`}>{inputData.currency}</span>
                                }
                            </div>
                        </div>
                    ))}
                </TableBody>
            </Table>
            <div className={`${baseClassName}__simulationDescriptionBox`}>
                <Paragraph>
                    <RichText text={window.sv_resource.get('plf_simulation_calculated_description')} />
                </Paragraph>
            </div>
            {
                simulationData.baseData.map((chapter, index) => (
                    <Table key={`baseData-${index.toString()}`}>
                        { chapter.title &&
                            <TableHead className={`${baseClassName}__tableHead`}>
                                <h3 className={`${baseClassName}__sectionSubtitle`}>{chapter.title}</h3>
                            </TableHead>
                        }
                        <TableBody>
                            {
                                chapter.data.map((row, chapterIndex) => (
                                    <div className="row" key={`chapter-${chapterIndex.toString()}`}>
                                        <div className={`col-8 col-lg-9`}>
                                            {row.title}
                                        </div>
                                        <div className="text-center col-4 col-lg-3">
                                            {getFormattedValue(row.value, row.formattingType)}
                                            { row.currency &&
                                                <span className={`${baseClassName}__currency small-text`}>{row.currency}</span>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </TableBody>
                    </Table>
                ))
            }
            <Table className={`${baseClassName}__pensionDataTable`} scrollable>
                <TableHead className={`${baseClassName}__tableHead`}>
                    <h3 className={`${baseClassName}__sectionSubtitle`}>{simulationData.pensionData.title}</h3>
                </TableHead>
                <TableBody className={`${baseClassName}__pensionDataTableBody`}>
                    {
                        simulationData.pensionData.data.map((row, rowIndex) => (
                            <div className="row" key={`pensionData-row-${rowIndex}`}>
                                {
                                    row.map((rowItem, itemIndex) => {
                                        const valueCols = row.length -1;
                                        let valueColWidth =
                                            valueCols === 3 ? 2 : 3;
                                        let textColWidth = (valueCols === 1 && itemIndex === 0) ? 9 : 6;
                                        return (
                                        <div className={`col-${itemIndex === 0 ? textColWidth : valueColWidth}`} key={`pensionData-rowItem-${itemIndex}`}>
                                            <span className={rowItem.bold ? '-bold' : ''}>
                                                {getFormattedValue(rowItem.value, rowItem.formattingType)}
                                            </span>
                                        </div>
                                    )})
                                }
                            </div>
                        ))
                    }
                    <div className={`${baseClassName}__smallText ${baseClassName}__pensionBenefitsInfo`}>
                        {window.sv_resource.get('plf_simulation_pension_calculation_pension_benefits_info')}
                    </div>
                </TableBody>
            </Table>

            <Table>
                <TableBody>
                    <div className="row">
                        <div className={`col-12`}>
                            <strong>{simulationData.capitalData.title}</strong>
                        </div>
                    </div>
                    {
                        simulationData.capitalData.data.map((row, chapterIndex) => (
                            <div className="row" key={`chapter-${chapterIndex.toString()}`}>
                                <div className={`col-8 col-lg-9`}>
                                    {row.title}
                                </div>
                                <div className="text-center col-4 col-lg-3">
                                    {getFormattedValue(row.value, row.formattingType)}
                                    { row.currency &&
                                        <span className={`${baseClassName}__currency small-text`}>{row.currency}</span>
                                    }
                                </div>
                            </div>
                        ))
                    }
                </TableBody>
            </Table>
            {
                simulationData.inputData.some(data => data.title === window.sv_resource.get('plf_simulation_pension_calculation_label_requested_capital_withdrawal') && data.value !== null)
                && (
                    <Paragraph className={`${baseClassName}__requestLink d-print-none`}>
                        {`${window.sv_resource.get('plf_simulation_pension_calculated_request_link')} `}
                        <Link title={`${window.sv_resource.get('plf_simulation_pension_calculated_request_link')} ${window.sv_resource.get('plf_simulation_pension_calculated_capital_payments')}`} href={linkToCapitalPayments} target='_blank'>
                            {window.sv_resource.get('plf_simulation_pension_calculated_capital_payments')}
                        </Link>
                    </Paragraph>
                )
            }
            <div className={`${baseClassName}__buttonWrapper d-print-none`}>
                <div>
                    <FormButton text={window.sv_resource.get('plf_simulation_calculated_btn_reset_label')} variation={FormButtonVariation.Secondary} type="button" hasIcon={false} handleClick={handleReset} />
                </div>
                <div className="d-flex">
                    <FormButton className={`${baseClassName}__requestButton`} text={window.sv_resource.get('plf_simulation_calculated_btn_pdf_save_label')} variation={FormButtonVariation.Primary} type="button" hasIcon={false} handleClick={handleCreatePdf} />
                </div>
            </div>
        </div>
    );
};

const getFormattedValue = (value: string, type: FormattingType|null): string => {
    switch (type) {
        case FormattingType.Money: {
            return formatCurrency(+value);
        }
        case FormattingType.Date: {
            return formatDate(new Date(value));
        }
        case FormattingType.Percent: {
            return formatPercentage(+value) + '%';
        }
    }

    return value;
}

export default PensionCalculationSimulationCalculated;

import React from 'react';
import './index.scss';
import { Dialog } from '@headlessui/react';
import Close from '../../atoms/icon/icons/Close';

interface ModalProps {
    children: React.ReactNode;
    isOpen: boolean;
    isUserLoggedIn?: boolean;
    setIsOpen: (open: boolean) => void;
    title: string;
}

const Modal = ({
    children,
    isOpen,
    setIsOpen,
    title,
    isUserLoggedIn,
}: ModalProps) => {
    const baseClassName = 'm-modal';

    return (
        <Dialog className={`${baseClassName} ${isUserLoggedIn ? '-userLoggedIn' : ''} container-lg`} open={isOpen} onClose={() => setIsOpen(false)} as="div">
            <div className={`${baseClassName}__wrapper`}>
                <Dialog.Overlay className={`${baseClassName}__overlay`} />
                <div className={`${baseClassName}__content`}>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button className={`${baseClassName}__closeButton`} onClick={() => setIsOpen(false)} type="button"><Close /></button>
                    <Dialog.Title as="h1">{title}</Dialog.Title>
                    {children}
                </div>
            </div>
        </Dialog>
    );
};

Modal.defaultProps = {
    isUserLoggedIn: false,
};

export default Modal;

import React from 'react';

interface RichTextProps {
    className?: string;
    inline?: boolean;
    text: string;
}

const RichText = ({ className, inline, text }: RichTextProps) => {
    const richText = { __html: text };

    const CustomTag = inline ? 'span' : 'div';
    return (
        <CustomTag className={`${className}`} dangerouslySetInnerHTML={richText} />
    );
};

RichText.defaultProps = {
    className: '',
    inline: true,
};

export default RichText;

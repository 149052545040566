import { useFormik } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import JsonResponse from '../../../../../../../../design/1/js/lib/entity/response/JsonResponse';
import FileHelper from '../../../../../../../../design/1/js/lib/FileHelper';
import formatErrorToastMessage from '../../../../../../../../design/1/js/lib/formatErrorToastMessage';
import FormButton, {
    FormButtonVariation,
} from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import FileUploadField from '../../../../../../../../design/1/js/templates/molecules/file-upload-field';
import TextareaField from '../../../../../../../../design/1/js/templates/molecules/textarea-field';
import AnswerMessageJsonResponseType from '../../../lib/AnswerMessageJsonResponseType';
import AnswerType from '../../../lib/AnswerType';
import './index.scss';
import ConsultantType from "../../../lib/consultant/ConsultantType";
import getAnswerValidationSchema from "../../../lib/utils/getAnswerValidationSchema";

export interface ClientAnswerFormProps {
    addAnswer: (answer: AnswerType) => void;
    answerAction: string;
    messageId: number;
    consultant: ConsultantType;
}

const ClientAnswerForm = ({
    addAnswer, answerAction, messageId, consultant,
}: ClientAnswerFormProps) => {
    const baseClassName = 'm-clientAnswerForm';

    const maxFileSize = 8 * 1024 * 1024;
    const allowedFileTypes = ['jpg', 'jpeg', 'png', 'jpe', 'pdf'];
    const fileHelper = new FileHelper(maxFileSize, allowedFileTypes);
    const [fileUploadError, setFileUploadError] = useState(null);
    const validationSchema = getAnswerValidationSchema();
    const initialValues = Object({
        answer: '',
        files: [],
    });

    const onSubmit = async values => {
        const response: JsonResponse<AnswerMessageJsonResponseType> = await fetch(answerAction, {
            method: 'POST',
            body: JSON.stringify({ inboxId: messageId, ...values }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        }).then(res => res.json());

        if (!response.success) {
            toast.error(formatErrorToastMessage(window.sv_resource.get('plf_messagetool_answer_error'), response.traceId), {
                className: 'filledErrorToast',
            });
            return;
        }

        addAnswer(response.context.message);

        toast.success(window.sv_resource.get('plf_messagetool_client_answer_success').replace('{{pensionConsultantName}}', consultant.consultantName), {
            className: 'filledSuccessToast',
            duration: 4000,
        });

        formik.resetForm();
    };

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });

    const handleFileChange = event => {
        fileHelper.getFileContent(event.target)
            .then(files => {
                formik.setFieldValue('files', files);
                setFileUploadError(null);
            })
            .catch((error: Error) => {
                setFileUploadError(error.message);
            })
            .finally(() => formik.setFieldTouched('files'));
    };

    return (
        <form className={`${baseClassName} row`} onSubmit={formik.handleSubmit}>
            <TextareaField
                id="answer"
                label={window.sv_resource.get('plf_messagetool_answer_label')}
                name="answer"
                className={`${baseClassName}__textarea col-12`}
                placeholder=""
                touched={!!formik.touched.answer}
                errors={formik.errors.answer}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                onFocus={formik.handleBlur}
                value={formik.values.answer}
            />
            <div className={`${baseClassName}__answerWrapper row`}>
                <FileUploadField
                    accept="application/pdf, image/png, image/jpeg"
                    className={`${baseClassName}__fileUpload col-12 col-xl-10`}
                    label={window.sv_resource.get('plf_messagetool_answer_upload_label')}
                    onChange={handleFileChange}
                    id="files"
                    placeholder=""
                    multiple
                    name="files"
                    errors={formik.touched.files ? fileUploadError ?? formik.errors.files : ''}
                    onBlur={formik.handleBlur}
                    touched={!!formik.touched.files}
                    value={formik.values.files}
                />
                <FormButton
                    className={`${baseClassName}__answerButton col-2`}
                    disabled={Object.keys(formik.errors).length > 0 || Object.keys(formik.touched).length === 0 || formik.isSubmitting}
                    text={window.sv_resource.get('plf_messagetool_answer_button')}
                    variation={FormButtonVariation.Primary}
                    hasIcon={false}
                    type="submit"
                />
            </div>
        </form>
    );
};

export default ClientAnswerForm;

import React from 'react';

interface ParagraphProps {
    children: React.ReactNode;
    className?: string;
}

const Paragraph = ({ children, className }: ParagraphProps) => {
    const baseClassName = 'a-paragraph';

    return (
        <p className={`${baseClassName} ${className}`}>{children}</p>
    );
};

Paragraph.defaultProps = {
    className: '',
};

export default Paragraph;

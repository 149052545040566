import React, { useEffect, useState } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import BankTransferData from '../../../lib/type/BankTransferData';
import { MasterDataType } from '../../molecules/master-data';
import TransferVeryLowWageStep, {
    TransferVeryLowWageType,
    validationSchemaTransferStep,
} from '../../molecules/transfer-very-low-wage';
import RegistrationStep, { RegistrationType, validationSchemaRegistrationStep } from '../../molecules/registration';
import ExitDateStep, { ExitDateType, validationSchemaExitDateStep } from '../../molecules/exit-date';
import FormWithAnswerText from '../../../../../../../../design/1/js/templates/organisms/form-with-answer-text';
import CheckStep, { CheckType, validationSchemaCheckStep } from '../../molecules/check';
import FileHelper from '../../../../../../../../design/1/js/lib/FileHelper';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import StatusMessageVariation
    from '../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation';
import FormSteps from '../form-steps';
import MasterData from "../../molecules/master-data";

type ExitNoticeInsignificanceFormType = MasterDataType & ExitDateType & TransferVeryLowWageType & RegistrationType & CheckType;

interface ExitNoticeVeryLowWageFormProps {
    bankTransferDataAction: string;
    formAction: string;
    handleSubmit: (status: number) => void,
    masterDataAction: string;
}

const ExitNoticeVeryLowWageForm = ({
    bankTransferDataAction, formAction, handleSubmit, masterDataAction,
}: ExitNoticeVeryLowWageFormProps) => {
    const baseClassName = 'w-exitNoticeVeryLowWageForm';
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['exitDate', 'transfer', 'registration', 'check'];
    const isLastStep = activeStep === steps.length - 1;

    const maxFileSize = 4 * 1024 * 1024;
    const allowedFileTypes = ['jpg', 'png', 'pdf'];
    const fileHelper = new FileHelper(maxFileSize, allowedFileTypes);

    const initialValues = Object({
        firstname: '',
        lastname: '',
        birthdate: '',
        socialSecurityNumber: '',
        resignationDate: '',
        domestic: 'domestic-yes',
        accountType: 'iban',
        iban: '',
        accountNr: '',
        bic: '',
        currency: '',
        bankName: '',
        street1: '',
        street2: '',
        zip: '',
        city: '',
        country: '',
        files: [],
        disabilityInsurance: '',
        disabilityInsuranceRegistrationDate: '',
        disabilityInsuranceCanton: '',
        confirmation: false,
    });

    const validationSchema = [
        validationSchemaExitDateStep,
        validationSchemaTransferStep,
        validationSchemaRegistrationStep,
        validationSchemaCheckStep,
    ];

    const currentValidationSchema = validationSchema[activeStep];
    const renderStepContent = (
        step,
        errors: FormikErrors<ExitNoticeInsignificanceFormType>,
        touched: FormikTouched<ExitNoticeInsignificanceFormType>,
        onChange,
        onBlur,
        values: ExitNoticeInsignificanceFormType,
        handleBack,
        handleFileChange?,
    ) => (
        <FormSteps step={step}>
            <ExitDateStep isFirstStep={true} errors={errors} disabled={step > 1} id={steps[1]} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} />
            <AsyncLoader
                action={bankTransferDataAction}
                renderContent={(lazyChildren, isLoading) => (
                    <>
                        {isLoading && (
                            // We use an empty span with an ID to allow the page
                            // to scroll correctly before the data is loaded
                            <span id={steps[2]} />
                        )}
                        {lazyChildren}
                    </>
                )}
            >
                {(bankTransferData: BankTransferData) => (
                    <TransferVeryLowWageStep bankTransferData={bankTransferData} disabled={step > 2} errors={errors} id={steps[2]} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} handleFileChange={handleFileChange} />
                )}
            </AsyncLoader>
            <RegistrationStep errors={errors} disabled={step > 3} id={steps[3]} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} />
            <CheckStep errors={errors} disabled={step > 4} id={steps[4]} label={window.sv_resource.get('plf_onlineform_exit_notice_very_low_wage_check_description')} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} />
        </FormSteps>
    );

    const customOnSubmit = (values, actions) => {
        actions.setSubmitting(false);
        actions.setTouched({});
        setActiveStep(activeStep + 1);
    };

    useEffect(() => {
        scrollTo(steps[activeStep]);
    }, [activeStep]);

    const scrollTo = (id: string) => {
        document.getElementById(id)?.scrollIntoView();
    };

    return (
        activeStep !== steps.length && (
            <FormWithAnswerText
                actionUrl={formAction}
                className={`${baseClassName}`}
                customOnSubmit={isLastStep ? undefined : customOnSubmit}
                handleSubmit={handleSubmit}
                handleValidate={currentValidationSchema}
                initialValues={initialValues}
                name="exitNoticeVeryLowWageForm"
            >
                {formik => {
                    const handleBack = () => {
                        formik.setErrors({});
                        setActiveStep(activeStep - 1);
                    };

                    const handleFileChange = event => {
                        fileHelper.getFileContent(event.target)
                            .then(files => {
                                formik.setFieldValue('files', files);
                            })
                            .catch((error: Error) => {
                                formik.setFieldError('files', error.message);
                            })
                            .finally(() => formik.setFieldTouched('files'));
                    };

                    return (
                        <>
                            <AsyncLoader
                                action={masterDataAction}
                                renderContent={lazyChildren => (
                                    <>
                                        {lazyChildren}
                                    </>
                                )}
                                variation={StatusMessageVariation.Subpage}
                            >
                                {(masterData: MasterDataType) => (
                                    <>
                                        <MasterData data={masterData} />
                                        { renderStepContent(activeStep, formik.errors, formik.touched, formik.handleChange, formik.handleBlur, formik.values, handleBack, handleFileChange) }
                                    </>
                                )}
                            </AsyncLoader>
                        </>
                    );
                }}
            </FormWithAnswerText>
        )
    );
};

export default ExitNoticeVeryLowWageForm;

import React from 'react';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import Simulation from '../simulation';
import PurchaseSimulationCalculated from '../../molecules/purchase-simulation-calculated';
import RequestForm from '../../molecules/request-form';
import PurchaseSimulationForm from '../purchase-simulation-form';
import { formatDate } from '../../../../../../../../design/1/js/lib/formatData';
import PurchaseResponseContext from '../../../lib/purchase/PurchaseResponseContext';
import PurchaseSimulationData from '../../../lib/purchase/PurchaseSimulationData';
import PurchasePossibilityCheck from '../../../lib/purchase/PurchasePossibilityCheck';
import PurchasePossibilityCheckEvaluationList from '../../molecules/purchase-possibility-check-evaluation-list';

interface PurchaseSimulationProps {
    action: string;
    headerIdSimulation: string;
    linkToMessageTool: string;
    linkToRegulationsDocument: string;
    maxPurchaseAction: string;
    requestAction: string;
}

const PurchaseSimulation = ({
    action, headerIdSimulation, linkToMessageTool, linkToRegulationsDocument, maxPurchaseAction, requestAction,
}: PurchaseSimulationProps) => {
    const baseClassName = 'w-purchaseSimulation';

    const getPurchasePossibilityWarnings = (checks: PurchasePossibilityCheck) => {
        const warnings: string[] = [];

        const warningChecks: { check: boolean, message: string }[] = [
            {
                check: checks.vestedBenefitCheck.includes('no'),
                message: window.sv_resource.get('plf_simulation_purchase_calculated_errormsg_vested_benefit'),
            },
            {
                check: checks.wefCheck.includes('yes'),
                message: window.sv_resource.get('plf_simulation_purchase_calculated_errormsg_wef'),
            },
            {
                check: checks.selfEmployedCheck.includes('yes'),
                message: window.sv_resource.get('plf_simulation_purchase_calculated_errormsg_self_employed'),
            },
            {
                check: checks.aboardCheck.includes('yes'),
                message: window.sv_resource.get('plf_simulation_purchase_calculated_errormsg_aboard'),
            },
            {
                check: checks.pillar2Check.includes('yes'),
                message: window.sv_resource.get('plf_simulation_purchase_calculated_errormsg_pillar_2'),
            }
        ];

        for (const warningCheck of warningChecks) {
            if (warningCheck.check) {
                warnings.push(warningCheck.message);
            }
        }

        return warnings;
    };

    return (
        <div className={`${baseClassName}`}>
            <Simulation
                calculatingAction={action}
                simulationForm={(handleFormSubmit: (response: PurchaseResponseContext) => void) => <PurchaseSimulationForm handleSubmit={handleFormSubmit} headerId={headerIdSimulation} maxPurchaseAction={maxPurchaseAction} />}
                simulationAction={
                    (calculatingAction, handleReset, handleCreatePdf) => (
                        <AsyncLoader
                            action={calculatingAction}
                            autoscroll
                            renderContent={lazyChildren => (
                                <>
                                    {lazyChildren}
                                </>
                            )}
                            requestJob={true}
                        >
                            {(simulationData: PurchaseSimulationData) => {
                                const purchaseWarnings: string[] = getPurchasePossibilityWarnings(simulationData.possibilityChecks);
                                return (
                                    <>
                                        <h2 id={headerIdSimulation}>
                                            <span className="d-print-inline d-none">
                                                {simulationData.insuredData.name}
                                                {window.sv_resource.get('plf_simulation_print_title_link')}
                                            </span>
                                            {window.sv_resource.get('plf_simulation_title')}
                                            <span className="d-print-inline d-none">{` ${window.sv_resource.get('plf_simulation_print_title_date_link')} ${formatDate(new Date())} `}</span>
                                        </h2>
                                        <Paragraph>{window.sv_resource.get('plf_simulation_purchase_calculated_description')}</Paragraph>
                                        <PurchaseSimulationCalculated simulationData={simulationData} handleCreatePdf={handleCreatePdf} handleReset={handleReset} />
                                        {
                                            purchaseWarnings.length > 0
                                                ? (
                                                    <PurchasePossibilityCheckEvaluationList linkToMessageTool={linkToMessageTool} warnings={purchaseWarnings} />
                                                ) : (
                                                    <RequestForm actionUrl={requestAction} checkboxLabel={window.sv_resource.get('plf_simulation_purchase_calculated_request_checkbox_label').replace('{{link_regulationsdocument}}', linkToRegulationsDocument)} description={window.sv_resource.get('plf_simulation_purchase_calculated_request_description')} inputData={simulationData.inputData} name="purchaseRequestForm" requestButtonLabel={window.sv_resource.get('plf_simulation_purchase_calculated_btn_request_label')} />
                                                )
                                        }
                                    </>
                                );
                            }}
                        </AsyncLoader>
                    )
                }
            />
        </div>
    );
};

export default PurchaseSimulation;

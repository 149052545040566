import React, { useEffect, useState } from "react";
import LoadSpinner, { SpinnerSize } from "../../atoms/load-spinner";
import JsonResponse from "../../../lib/entity/response/JsonResponse";
import MessageContext from "../../../lib/entity/response/MessageContext";
import StatusMessage, { MessageType } from "../status-message";
import ErrorContext from "../../../lib/entity/response/ErrorContext";

interface Props {
    description: string;
    action: string;
    title: string;
}

interface RedirectContext extends MessageContext {
    redirect: string;
}

interface RedirectErrorContext extends ErrorContext {
    backLinkUrl?: string,
    backLinkText?: string,
}

const RedirectPage = ({
    title, action, description,
}: Props) => {
    const baseClassName = 'm-pageLoading';
    const [response, setResponse] = useState<JsonResponse<RedirectContext | RedirectErrorContext>>(null);

    useEffect(() => {
        fetch(action).then(r =>  r.json()).then((res) => {
            setResponse(res);
        });
    }, []);

    if (response !== null && response.success) {
        window.location.replace((response.context as RedirectContext).redirect);
    }

    if (response === null || response.success) {
        return (
            <div className={`${baseClassName}`}>
                <h2 className="headline">{title}</h2>
                <div className={`${baseClassName}__container`}>
                    <div className={`${baseClassName}__content`}>
                        <p>{description}</p>
                        <LoadSpinner loadingImages={true} size={SpinnerSize.Large}></LoadSpinner>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <StatusMessage
                additionalText={(response.context as MessageContext).message}
                description={(`${((response.context as ErrorContext).description ?? window.sv_resource.get("plf_error_try_again_later"))} ${response.context.hasOwnProperty('backLinkUrl') ? `<br> <a href="${(response.context as RedirectErrorContext).backLinkUrl}">${(response.context as RedirectErrorContext).backLinkText}</a>` : ''}`)}
                inline
                messageType={(response.context as ErrorContext).informational ? MessageType.Info : MessageType.Error}
                traceId={response.traceId}
                title={(response.context as ErrorContext).title ?? window.sv_resource.get("plf_error_unknown")}
            />
        </div>
    );

};

RedirectPage.defaultProps = {
    description: null,
    title: '',
};

export default RedirectPage;

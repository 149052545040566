import { loadWidget } from '@sitevision/react';
import React from 'react';
import ReactDOM from 'react-dom';
import ServicesSection from './templates/molecules/services-section';
import ServiceCard from './lib/ServiceCard';

loadWidget('.plf-services-area', provisionsOverviewWidget => {
    const cards: ServiceCard[] = JSON.parse(provisionsOverviewWidget.dataset.services);
    ReactDOM.render(<ServicesSection cards={cards} />, provisionsOverviewWidget);
});

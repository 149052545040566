import ReCAPTCHA from 'react-google-recaptcha';
import { FormikProps, useFormik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import React, { MutableRefObject, ReactNode } from 'react';
import { SchemaOf } from 'yup';

interface Props<T, U> {
    actionUrl: string,
    className?: string;
    children: (bag: FormikProps<T>) => ReactNode;
    customOnSubmit?: (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<any>;
    beforeSubmit?: () => void;
    initialValues: T,
    handleResponse?: (jsonResponse: U) => void,
    name: string,
    recaptchaRef?: MutableRefObject<ReCAPTCHA>,
    validationSchema: SchemaOf<T>,
}

const Form = <T extends any, U extends any = {}>({
    actionUrl, className = '', children, customOnSubmit, beforeSubmit, initialValues, handleResponse, name, recaptchaRef, validationSchema,
}: Props<T, U>) => {
    const baseClassName = 'o-form';

    const useRecaptcha = recaptchaRef !== undefined;

    let onSubmit;
    if (customOnSubmit !== undefined) {
        onSubmit = customOnSubmit;
    } else {
        onSubmit = async values => {
            if (beforeSubmit) {
                beforeSubmit();
            }

            let formData = { ...values };
            if (useRecaptcha) {
                if (recaptchaRef.current === null) {
                    return;
                }

                const token = await recaptchaRef.current.executeAsync();
                recaptchaRef.current.reset();

                formData = {
                    captchaToken: token,
                    ...formData,
                };
            }
            const response = await fetch(actionUrl, {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'X-Csrf-Token': document.body.dataset.csrfToken,
                },
            });

            if (handleResponse !== undefined) {
                const jsonResponse = await response.json();
                handleResponse(jsonResponse);
            }
        };
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });

    return (
        <form className={`${baseClassName} ${className}`} name={name} onSubmit={formik.handleSubmit}>
            {children !== undefined && children(formik)}
        </form>
    );
};

Form.defaultProps = {
    className: '',
    customOnSubmit: undefined,
    handleResponse: undefined,
    recaptchaRef: undefined,
};

export default Form;

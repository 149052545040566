import React from 'react';
import './index.scss';

export enum LangNavPosition {
    Header = 'header',
    Mobile_Nav = 'mobile-nav',
}

interface LangNavProps {
    position?: LangNavPosition;
}

const LangNav: React.FC<LangNavProps> = ({ position = LangNavPosition.Header }) => {
    const baseClassName = 'm-langnav';

    const activeLangId = window.sv_resource.getLanguage();

    const langChange = (element: HTMLElement) => {
        const langId = parseInt(element.dataset.langId, 10);

        /* eslint-disable-next-line no-undef */
        // @ts-ignore
        postToUrl('/_common/common/langChange', {
            sv_lang: langId,
            url: document.location.pathname,
            menuId: document.body.dataset.menuId,
        });
    };

    return (
        <ul className={`${baseClassName} col-lg-9`}>
            <li className={position === LangNavPosition.Header ? 'small-text active' : `display3 -${position}`}>
                <button className={activeLangId === 0 ? '-active' : ''} onClick={event => langChange(event.currentTarget)} type="button" data-lang-id="0" title="Deutsch">
                    D
                </button>
            </li>
            <li className={position === LangNavPosition.Header ? 'small-text' : `display3 -${position}`}>
                <button className={activeLangId === 1 ? '-active' : ''} onClick={event => langChange(event.currentTarget)} type="button" data-lang-id="1" title="Français">
                    F
                </button>
            </li>
            <li className={position === LangNavPosition.Header ? 'small-text' : `display3 -${position}`}>
                <button className={activeLangId === 2 ? '-active' : ''} onClick={event => langChange(event.currentTarget)} type="button" data-lang-id="2" title="Italiano">
                    I
                </button>
            </li>
        </ul>
    );
};

export default LangNav;

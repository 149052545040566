import React, { useEffect, useMemo, useState } from 'react';
import './index.scss';
import NavigationItem from '../../atoms/navigation-item';
import { NavigationType } from '../navigation';
import useIntersectionObserver from '../../../lib/hooks/useIntersectionObserver';
import useMutationObserver from '../../../lib/hooks/useMutationObserver';
import AnchorPoint from './lib/AnchorPoint';

interface SideNavProps {
    parent: HTMLElement;
}

const Sidenav = ({ parent }: SideNavProps) => {
    const baseClassName = 'm-sidenav';

    const [anchorHeadings, setAnchorHeadings] = useState<HTMLElement[]>(() => getHeadings(parent));
    const [anchorPoints, setAnchorPoints] = useState<AnchorPoint[]>([]);

    const headerHeight = useMemo(() => document.querySelector('.m-header-bottom').clientHeight, []);

    useEffect(() => {
        const points = anchorHeadings.map(element => ({ id: element.id, visible: false }));
        setAnchorPoints(points);
    }, [anchorHeadings.map(element => element.id).join(',')]);

    const handleObserverIntersection: IntersectionObserverCallback = elements => {
        const points = anchorPoints.slice();

        elements.forEach(element => {
            const index = anchorPoints.findIndex(point => point.id === element.target.id);
            if (index === -1) {
                return;
            }

            points[index].visible = element.isIntersecting;
        });

        setAnchorPoints(points);
    };

    useMutationObserver(parent, () => {
        setAnchorHeadings(getHeadings(parent));
    });

    useIntersectionObserver(anchorHeadings, {
        root: null,
        rootMargin: `${-headerHeight}px 0px 0px 0px`,
        threshold: [1],
    }, handleObserverIntersection);

    return (
        <>
            <nav className={`${baseClassName}`}>
                <ul>
                    {anchorHeadings.map(element => (
                        <NavigationItem
                            active={anchorPoints.find(point => point.visible)?.id === element.id}
                            key={element.id}
                            href={`#${element.id}`}
                            text={element.id}
                            type={NavigationType.Side}
                        />
                    ))}
                </ul>
            </nav>
        </>
    );
};

function getHeadings(parent: HTMLElement): HTMLElement[] {
    return Array.from(parent.querySelectorAll<HTMLElement>('h2[id], h2 > a[id]')).filter(heading => heading.id !== '');
}

export default Sidenav;

import React from 'react';
import './index.scss';
import User from '../../../lib/User';
import { formatDate, offsetDate } from '../../../../../../../../design/1/js/lib/formatData';
import {getCurrentInsuredId} from "../../../../../../../../design/1/js/lib/util/InsuredUtils";

export interface UserProps {
    user: User;
}

const UserInfos = ({ user }: UserProps) => {
    const baseClassName = 'a-user-infos';

    let formattedBirthdate = '';
    if (user !== null) {
        const birthdate = new Date(user.birthdate);
        formattedBirthdate = formatDate(offsetDate(birthdate));
    }

    return (
        <div className={`${baseClassName}`}>
            {user?.insuranceNr && getCurrentInsuredId() && (
                <>
                    <div>{window.sv_resource.get('plf_welcome_user_insurance_nr')}</div>
                    <div>{user.insuranceNr}</div>
                </>
            )}
            {formattedBirthdate && (
                <>
                    <div>{window.sv_resource.get('plf_welcome_user_birthdate')}</div>
                    <div>{formattedBirthdate}</div>
                </>
            )}
        </div>
    );
};

export default UserInfos;

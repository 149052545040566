import React, { forwardRef } from 'react';
import './index.scss';
import { FormikErrors, FormikValues } from 'formik';
import HandleChangeType from '../../../../lib/types/HandleChangeType';

interface SelectProps {
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
    errors?: string | string[] | FormikErrors<FormikValues> | FormikErrors<FormikValues>[];
    id: string;
    label: string;
    name: string;
    onBlur?: HandleChangeType;
    onChange: HandleChangeType;
    touched?: boolean;
    value: string;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(({
    children, className, disabled, errors, id, label, name, onBlur, onChange, touched, value,
}: SelectProps, ref) => {
    const baseClassName = 'a-select';

    const onClickItem = (itemValue: string) => {
        // We cannot create a real React.ChangeEvent, so we must simulate the properties Formik uses
        // @ts-ignore
        onChange({ target: { id, value: itemValue } });

        if (onBlur !== undefined) {
            // @ts-ignore
            setTimeout(() => onBlur({ target: { id } }), 0);
        }
    };

    return (
        <div className={`${baseClassName} ${className}`}>
            <label className={`${baseClassName}__label light-text`} htmlFor={id}>{label}</label>
            <select id={id} name={name} onChange={event => onClickItem(event.target.value)} value={value} disabled={disabled} ref={ref}>
                {children}
            </select>
            {errors && touched && <div className={`${baseClassName}__msg small-text`}>{errors}</div>}
        </div>
    );
});

Select.defaultProps = {
    className: '',
    disabled: false,
    errors: null,
    onBlur: undefined,
    touched: null,
};

export default Select;

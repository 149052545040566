import React from 'react';
import './index.scss';
import Avatar from '../../atoms/avatar';
import ContactInfos, { ConsultantProps } from '../../atoms/contact-infos';

const ContactCard: React.FC<ConsultantProps> = ({ consultant }) => {
    const baseClassName = 'm-contact-card';
    return (
        <div className={`${baseClassName}`}>
            { consultant?.image !== null && <Avatar image={consultant?.image} /> }
            <ContactInfos consultant={consultant} />
        </div>
    );
};

export default ContactCard;

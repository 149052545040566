import React from 'react';
import './index.scss';
import Table from '../../../../../../../../design/1/js/templates/molecules/table';
import TableHead from '../../../../../../../../design/1/js/templates/atoms/table-head';
import TableBody from '../../../../../../../../design/1/js/templates/atoms/table-body';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import YourBenefitsType from '../../../lib/YourBenefitsType';
import TableHeightRow from '../../../../../../../../design/1/js/templates/atoms/table-height-row';
import { AsyncWidgetProps } from '../../../../../base/ts/lib/AsyncWidgetProps';
import {
    formatCurrency,
    formatDate,
    formatSocialSecurityNumber, offsetDate,
} from '../../../../../../../../design/1/js/lib/formatData';

const YourBenefits = ({ action }: AsyncWidgetProps) => {
    const baseClassName = 'w-yourBenefits';
    return (
        <div className={`${baseClassName}`}>
            <AsyncLoader
                action={action}
                renderContent={lazyChildren => (
                    <>
                        {lazyChildren}
                    </>
                )}
            >
                {({ benefits, userInfos }: YourBenefitsType) => (
                    <>
                        <div className={`${baseClassName}__userInfos`}>
                            <Table>
                                <TableBody>
                                    <TableHeightRow>
                                        <div className="col-6">
                                            {userInfos[0].label}
                                        </div>
                                        <div className="col-6">
                                            {userInfos[0].value}
                                        </div>
                                    </TableHeightRow>
                                    <TableHeightRow>
                                        <div className="col-6">
                                            {userInfos[1].label}
                                        </div>
                                        <div className="col-6">
                                            {formatDate(offsetDate(new Date(userInfos[1].value)))}
                                        </div>
                                    </TableHeightRow>
                                    <TableHeightRow>
                                        <div className="col-6">
                                            {userInfos[2].label}
                                        </div>
                                        <div className="col-6">
                                            {formatSocialSecurityNumber(userInfos[2].value)}
                                        </div>
                                    </TableHeightRow>
                                </TableBody>
                            </Table>
                        </div>
                        <h2>{window.sv_resource.get('plf_your_benefits_detail')}</h2>
                        {benefits.map((benefit, index) => (
                            <div className={`${baseClassName}__benefitSection`} key={`section-${index.toString()}`}>
                                <h3>{benefit.sectionTitle}</h3>
                                <Table scrollable>
                                    <TableHead slim>
                                        <div className="col-6 col-lg-8" />
                                        <div className="col-3 col-lg-2">{window.sv_resource.get('plf_your_benefits_table_head_amount')}</div>
                                        <div className="col-3 col-lg-2">{window.sv_resource.get('plf_your_benefits_table_head_date_to')}</div>
                                    </TableHead>
                                    <TableBody>
                                        {benefit.infos.map((info, infoIndex) => (
                                            <div className="row" key={`benefitInfo-${infoIndex.toString()}`}>
                                                <div className="col-6 col-lg-8">
                                                    {info.name}
                                                </div>
                                                <div className="col-3 col-lg-2">
                                                    {formatCurrency(+info.amount)}
                                                    <span className="small-text">{info.currency}</span>
                                                </div>
                                                <div className="col-3 col-lg-2">
                                                    {info.dateTo}
                                                </div>
                                            </div>
                                        ))}
                                        <div className="row">
                                            <div className="col-6 col-lg-8"><strong>{benefit.total.name}</strong></div>
                                            <div className="col-3 col-lg-2">
                                                <strong>{formatCurrency(+benefit.total.amount)}</strong>
                                                <span className="small-text">{benefit.total.currency}</span>
                                            </div>
                                            <div className="col-3 col-lg-2">{benefit.total.dateTo}</div>
                                        </div>
                                    </TableBody>
                                </Table>
                            </div>
                        ))}
                    </>
                )}
            </AsyncLoader>
        </div>
    );
};

export default YourBenefits;

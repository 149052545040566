import React, { useCallback } from 'react';
import './index.scss';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import { AsyncWidgetProps } from '../../../../../base/ts/lib/AsyncWidgetProps';
import Table from '../../../../../../../../design/1/js/templates/molecules/table';
import TableBody from '../../../../../../../../design/1/js/templates/atoms/table-body';
import TableHeightRow from '../../../../../../../../design/1/js/templates/atoms/table-height-row';
import {
    formatCurrency,
    formatDate,
    formatPercentage,
    formatSocialSecurityNumber, offsetDate,
} from '../../../../../../../../design/1/js/lib/formatData';
import YourProvisionsType from '../../../lib/YourProvisionsType';
import ProvisionType from '../../../lib/ProvisionType';

const YourProvisions = ({ action }: AsyncWidgetProps) => {
    const baseClassName = 'w-yourProvisions';

    const formatProvisionData = useCallback((data: ProvisionType) => {
        if (data.unit === '%') {
            return formatPercentage(+data.amount);
        }
        if (data.unit === 'string') {
            return data.amount;
        }
        return (
            <>
                {formatCurrency(+data.amount)}
                <span className="small-text">{data.unit}</span>
            </>
        );
    }, []);

    return (
        <div className={`${baseClassName}`}>
            <AsyncLoader
                action={action}
                renderContent={lazyChildren => (
                    <>
                        {lazyChildren}
                    </>
                )}
            >
                {({ provisions, userInfos }: YourProvisionsType) => (
                    <>
                        <div className={`${baseClassName}__userInfos`}>
                            <Table>
                                <TableBody>
                                    <TableHeightRow>
                                        <div className="col-6">
                                            {userInfos[0].label}
                                        </div>
                                        <div className="col-6">
                                            {userInfos[0].value}
                                        </div>
                                    </TableHeightRow>
                                    <TableHeightRow>
                                        <div className="col-6">
                                            {userInfos[1].label}
                                        </div>
                                        <div className="col-6">
                                            {formatDate(offsetDate(new Date(userInfos[1].value)))}
                                        </div>
                                    </TableHeightRow>
                                    <TableHeightRow>
                                        <div className="col-6">
                                            {userInfos[2].label}
                                        </div>
                                        <div className="col-6">
                                            {formatSocialSecurityNumber(userInfos[2].value)}
                                        </div>
                                    </TableHeightRow>
                                </TableBody>
                            </Table>
                        </div>
                        <h2>{window.sv_resource.get('plf_your_provisions_section_title').replace('{{date}}', formatDate(new Date()))}</h2>
                        {provisions.map((provision, index) => (
                            <div className={`${baseClassName}__provisionsSection`} key={`section-${index.toString()}`}>
                                <h3 className={`${baseClassName}__sectionTitle`}>{provision.label}</h3>
                                {
                                    provision.children[0].children === undefined
                                        ? (
                                            <Table>
                                                <TableBody>
                                                    {
                                                        provision.children.map((data, dataIndex) => (
                                                            <div className={`${data.bold ? '-bold' : ''} row`} key={`row-${dataIndex.toString()}`}>
                                                                <div className="col-9">
                                                                    {data.label}
                                                                </div>
                                                                <div className="col-3 text-end">
                                                                    {formatProvisionData(data)}
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        ) : (
                                            <>
                                                {provision.children.map(subSection => (
                                                    <div key={`row-${subSection.label}`}>
                                                        <div className={`${baseClassName}__sectionSubtitle`}>{subSection.label}</div>
                                                        <Table>
                                                            <TableBody>
                                                                {subSection.children.map((data, dataIndex) => (
                                                                    <div className={`${data.bold ? '-bold' : ''} row`} key={`row-${dataIndex.toString()}`}>
                                                                        <div className="col-9">
                                                                            {data.label}
                                                                        </div>
                                                                        <div className="col-3 text-end">
                                                                            {formatProvisionData(data)}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </div>
                                                ))}
                                            </>
                                        )
                                }
                            </div>
                        ))}
                    </>
                )}
            </AsyncLoader>
        </div>
    );
};

export default YourProvisions;

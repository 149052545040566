import React from 'react';
import PensionCertificateSimulationForm from '../pension-certificate-simulation-form';
import Simulation from '../simulation';

interface PensionCertificateSimulationProps {
    calculatingAction: string;
    leavingDateAction: string;
    headerIdSimulation: string;
    isLeavingPending: boolean;
}

const PensionCertificateSimulation = ({
    calculatingAction, leavingDateAction, headerIdSimulation, isLeavingPending,
}: PensionCertificateSimulationProps) => {
    const baseClassName = 'w-pensionCertificateSimulation';

    return (
        <div className={`${baseClassName}`}>
            <Simulation
                simulationForm={() => <PensionCertificateSimulationForm actionUrl={calculatingAction} leavingDateAction={leavingDateAction} headerId={headerIdSimulation} isLeavingPending={isLeavingPending} />}
            />
        </div>
    );
};

export default PensionCertificateSimulation;

import React, { useState } from 'react';
import IntroductionText from '../../../../../../../../design/1/js/templates/molecules/introduction-text';
import ChangeOfAccountForm from '../../molecules/change-of-account-form';

interface ChangeOfAccountProps {
    actionUrl: string;
    initialDataAction: string,
}

const ChangeOfAccount = ({
    actionUrl, initialDataAction,
}: ChangeOfAccountProps) => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const baseClassName = 'w-changeOfAccount';

    const handleFormSubmit = () => {
        setFormSubmitted(!formSubmitted);
    };

    return (
        <div className={`${baseClassName}`}>
            { !formSubmitted && (
                <IntroductionText
                    description={window.sv_resource.get('plf_servicesform_changeofaccount_introduction_description')}
                    title={window.sv_resource.get('plf_servicesform_changeofaccount_introduction_title')}
                />
            )}
            <div className={`${baseClassName}-wrapper`}>
                <ChangeOfAccountForm actionUrl={actionUrl} handleSubmit={handleFormSubmit} initialDataAction={initialDataAction} />
            </div>
        </div>
    );
};

export default ChangeOfAccount;

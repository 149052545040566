import React from 'react';
import './index.scss';
import Section from '../../../../../../../../design/1/js/templates/atoms/section';
import SectionTitle from '../../../../../../../../design/1/js/templates/atoms/section-title';
import Card from '../../../../../../../../design/1/js/templates/molecules/card';
import InfoStats from '../../../../../../../../design/1/js/templates/atoms/info-stats';
import BenefitsCard from '../../../lib/BenefitsCard';
import CardImage from '../../../../../../../../design/1/js/templates/atoms/card-image';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import { AsyncWidgetProps } from '../../../../../base/ts/lib/AsyncWidgetProps';
import { formatCurrency } from '../../../../../../../../design/1/js/lib/formatData';
import StatusMessageVariation
    from '../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation';

const BenefitsOverviewSection = ({ action }: AsyncWidgetProps) => {
    const baseClassName = 'm-benefits-section';

    return (
        <Section className={`${baseClassName}`}>
            <AsyncLoader
                action={action}
                renderContent={lazyChildren => (
                    <>
                        <SectionTitle frontpage headline level={2} title={window.sv_resource.get('plf_benefits_overview_title')} />
                        {lazyChildren}
                    </>
                )}
                variation={StatusMessageVariation.Homepage}
            >
                {(cards: BenefitsCard[]) => (
                    <div className={`${baseClassName}__wrapper row`}>
                        {cards.map((card, index) => (
                            <div className="col-12 col-sm-6 col-lg-4" key={`card-wrapper-${index.toString()}`}>
                                <Card title={card.title} type={card.type} url={card.url}>
                                    <CardImage image={card.image ? card.image : '/design/img/illustrations/fallback/retirement.svg'} cardName={card.title} />
                                    {
                                        card.infos.map((cardInfo, infoIndex) => (
                                            <InfoStats amount={formatCurrency(+cardInfo.amount)} currency={cardInfo.currency} name={cardInfo.name} key={`cardInfo-${infoIndex.toString()}`} />
                                        ))
                                    }
                                </Card>
                            </div>
                        ))}
                    </div>
                )}
            </AsyncLoader>
        </Section>
    );
};

export default BenefitsOverviewSection;

import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import JsonResponse from '../../../../../../../design/1/js/lib/entity/response/JsonResponse';
import formatErrorToastMessage from '../../../../../../../design/1/js/lib/formatErrorToastMessage';
import FilterType from '../consultant/FilterType';
import MessageType from '../MessageType';

type InboxesResponse = {
    inboxes: MessageType[],
    hasMore: boolean,
};

export default function useMessageLoad(action: string, pageNumber: number, filter: FilterType) {
    const [loading, setLoading] = useState(true);
    const [messageList, setMessages] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        setMessages([]);
    }, [filter]);

    useEffect(() => {
        setLoading(true);
        const params = {
            pageNumber,
            filter,
        };
        fetch(action, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        }).then(res => res.json())
            .then((res: JsonResponse<InboxesResponse>) => {
                if (!res.success) {
                    toast.error(formatErrorToastMessage(window.sv_resource.get('plf_error_unknown'), res.traceId));
                    return;
                }

                setMessages(messages => [...messages, ...res.context.inboxes]);
                setHasMore(res.context.hasMore);
                setLoading(false);
            });
    }, [pageNumber, filter]);

    return {
        loading, messageList, hasMore, setMessages,
    };
}

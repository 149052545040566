import React, {forwardRef, useState} from 'react';
import {formatDateAndTime} from '../../../../../../../../design/1/js/lib/formatData';
import BtnChevron, {ChevronDirection} from '../../../../../../../../design/1/js/templates/atoms/btn-chevron';
import AnswerType from '../../../lib/AnswerType';
import MessageType from '../../../lib/MessageType';
import './index.scss';
import {MessageToolView} from "../../../lib/enum/MessageToolView";

interface ClientMessageListEntryProps {
    switchView: (view: MessageToolView) => void;
    setSelectedMessage: (message: MessageType) => void;
    message: MessageType;
}

const ClientMessageListEntry = forwardRef<HTMLDivElement, ClientMessageListEntryProps>(({
    switchView, setSelectedMessage, message,
}: ClientMessageListEntryProps, ref) => {
    const baseClassName = 'm-clientMessageListEntry';

    const openConversation = () => {
        setSelectedMessage(message);
        switchView(MessageToolView.CONVERSATION);
    }

    const messageIsUnread = () => message.answers.some(answer => !answer.read && answer.fromMPK);

    return (
        <div className={`${baseClassName} row`} ref={ref} onClick={openConversation}>
            <div className={`${baseClassName}__button ${messageIsUnread() ? '-unread' : ''}`}>
                <div className={`${baseClassName}__infoWrapper col-11`}>
                    <div className={`${baseClassName}__infos col-9`}>
                        <span className={`${baseClassName}__title`}>{message.title}</span>
                        {message.subject !== null && (
                            <span className={`${baseClassName}__subject`}>{window.sv_resource.get(`plf_messagetool_theme_${message.subject}`)}</span>
                        )}
                    </div>
                    <div className={`${baseClassName}__date col-3`}>{formatDateAndTime(new Date(message.date))}</div>
                </div>
                <div className={`${baseClassName}__chevronWrapper col-1`}>
                    <BtnChevron className={`${baseClassName}__chevron`} direction={ChevronDirection.Right} standalone />
                </div>
            </div>
        </div>
    );
});

export default ClientMessageListEntry;

import React from 'react';
import './index.scss';
import ToastContainer from '../../molecules/toast-container';
import BrandLogo from '../../atoms/brand-logo';
import Link from '../../atoms/link';

interface HeaderConsultantProps {
    loggedIn: boolean;
}

const HeaderSlim = ({ loggedIn }: HeaderConsultantProps) => {
    const baseClassName = 'o-headerSlim';
    return (
        <div className={`${baseClassName}`}>
            <div className={`${baseClassName}__borderTop`} />
            <div className={`${baseClassName}__headerTop`} />
            <div className="container-lg">
                <div className={`${baseClassName}__wrapper row`}>
                    <div className={`${baseClassName}__logo col-2`}>
                        <a href="/">
                            <BrandLogo />
                        </a>
                    </div>
                    {loggedIn && (
                        <div className={`${baseClassName}__navigation col-10`}>
                            <Link className={`${baseClassName}__logout`} href="/_logout" title={window.sv_resource.get('logout')}>{window.sv_resource.get('logout')}</Link>
                        </div>
                    )}
                </div>
                <ToastContainer />
            </div>
        </div>
    );
};

export default HeaderSlim;

import React from 'react';
import './index.scss';

interface InfoStatsProps {
    amount: string,
    currency: string,
    name: string,
}

const InfoStats: React.FC<InfoStatsProps> = ({
    amount,
    currency,
    name,
}) => {
    const baseClassName = 'a-info-stats';

    return (
        <div className={`${baseClassName}`}>
            <div className={`${baseClassName}__name display3`}>{name}</div>
            <div className={`${baseClassName}__amount display2`}>{amount}</div>
            <div className={`${baseClassName}__currency`}>{currency}</div>
        </div>
    );
};

export default InfoStats;

import { loadWidget } from '@sitevision/react';
import React from 'react';
import ReactDOM from 'react-dom';
import WelcomeSection from './templates/molecules/welcome-section';

loadWidget('.plf-welcome-area', welcomeWidget => {
    const action = welcomeWidget.dataset.action;
    const loggedIn: boolean = Boolean(welcomeWidget.dataset.loggedIn);
    ReactDOM.render(<WelcomeSection action={action} loggedIn={loggedIn} />, welcomeWidget);
});

import React from 'react';

export default function nl2br(content: string): React.ReactNode {
    return content.trim().split('\n').reduce((prev, curr, i) => {
        if (i === 0) {
            return <>{curr}</>;
        }

        return (
            <>
                {prev}
                <br />
                {curr}
            </>
        );
    }, <></>);
}

import React, {useMemo} from 'react';
import './index.scss';
import { Popover, Transition } from '@headlessui/react';
import Menu from '../../atoms/icon/icons/Menu';
import BtnChevron, { ChevronDirection } from '../../atoms/btn-chevron';
import Close from '../../atoms/icon/icons/Close';
import LangNav, { LangNavPosition } from '../lang-nav';
import MobileNavigationItem, { MobileNavigationType } from '../../atoms/mobile-navigation-item';
import Navigation from '../navigation';
import { NavbarProps } from '../navbar';
import LogoutButtonMobile from "../logout-button-mobile";
import {
    getCurrentInsuredId,
    getOtherInsuredIds,
    hasInsuredMultipleIds
} from "../../../lib/util/InsuredUtils";

const MenuIndex = {
    documents: 3,
    messages: 4,
    myMPK: 5,
}

const MobileNavPopover = ({ navigationItems, newDocuments, newMessages }: NavbarProps) => {
    const baseClassName = 'm-mobile-nav-popover';

    const [normalNavItems, logoutItem] = useMemo(() => {
        return [navigationItems.slice(0, -1), navigationItems.slice(-1)[0]];
    }, [navigationItems]);

    const items = normalNavItems.map((item, index) => (
        item.children.length > 0
            ? (
                <MobileNavigationItem
                    text=""
                    direction={ChevronDirection.Right}
                    mobileType={MobileNavigationType.Submenu}
                    key={`mobile-navigation-item-${index.toString()}`}
                >
                    <Popover className={`${baseClassName}__popover -sublevel`}>
                        {({ open: openSubPopover }) => (
                            <>
                                <Popover.Button className={`${baseClassName}__toggle display2`}>
                                    <div className={`${baseClassName}__title`}>{`${item.title}${index === MenuIndex.myMPK ? ` ${getCurrentInsuredId()}` : ''}`}</div>
                                    <BtnChevron direction={ChevronDirection.Right} standalone />
                                </Popover.Button>
                                <Transition
                                    show={openSubPopover}
                                    enter="transition-sub"
                                    enterFrom="-close"
                                    enterTo="-open"
                                    leave="transition-sub"
                                    leaveFrom="-open"
                                    leaveTo="-close"
                                    className={`${baseClassName}__transition-wrapper`}
                                >
                                    <Popover.Panel className={`${baseClassName}__panel`}>
                                        <Navigation>
                                            <Popover.Button className={`${baseClassName}__toggle display2`}>
                                                <MobileNavigationItem
                                                    text={`${item.title}${index === MenuIndex.myMPK ? ` ${getCurrentInsuredId()}` : ''}`}
                                                    direction={ChevronDirection.Left}
                                                    mobileType={MobileNavigationType.Back}
                                                />
                                            </Popover.Button>
                                            {item.children.map((navLink, linkIndex) => (
                                                <MobileNavigationItem
                                                    text={navLink.title}
                                                    href={navLink.url}
                                                    target={navLink.target}
                                                    direction={ChevronDirection.Right}
                                                    mobileType={MobileNavigationType.SubLink}
                                                    key={`mobile-sub-navigation-item-${linkIndex.toString()}`}
                                                />
                                            ))}
                                            {index === MenuIndex.myMPK && hasInsuredMultipleIds() && getOtherInsuredIds().map(insuredId => (
                                                <MobileNavigationItem
                                                    text={window.sv_resource.get('multiple_insured_id_change_to').replace('{{insuredId}}', insuredId)}
                                                    href={`/_common/policy/changePolicy?insuredId=${insuredId}`}
                                                    target="_self"
                                                    direction={ChevronDirection.Right}
                                                    mobileType={MobileNavigationType.SubLink}
                                                    key={insuredId}
                                                />
                                            ))}
                                        </Navigation>
                                    </Popover.Panel>
                                </Transition>
                            </>
                        )}
                    </Popover>
                </MobileNavigationItem>
            ) : (
                <MobileNavigationItem
                    text={item.title}
                    href={item.url}
                    target={item.target}
                    direction={ChevronDirection.Right}
                    mobileType={MobileNavigationType.MainLink}
                    key={`mobile-link-navigation-item-${index.toString()}`}
                    news={(index === MenuIndex.documents && newDocuments) || (index === MenuIndex.messages && newMessages)}
                />
            )
    ));

    return (
        <Popover className={`${baseClassName}`}>
            {({ open: openPopover }) => (
                <>
                    <Popover.Button className={`${baseClassName}__toggle`}>
                        {openPopover ? <Close /> : <Menu />}
                    </Popover.Button>
                    <Transition
                        show={openPopover}
                        enter="transition"
                        enterFrom="-close"
                        enterTo="-open"
                        leave="transition"
                        leaveFrom="-open"
                        leaveTo="-close"
                        className={`${openPopover ? `${baseClassName}__transition-wrapper` : `${baseClassName}__transition-wrapper closePopover`}`}
                    >
                        <Popover.Panel className={`${baseClassName}__panel`}>
                            <Navigation>
                                {items}
                            </Navigation>
                            <LangNav position={LangNavPosition.Mobile_Nav} />
                            <LogoutButtonMobile label={logoutItem.title} />
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
};

export default MobileNavPopover;

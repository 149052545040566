import React from 'react';
import ReactDOM from 'react-dom';
import { loadWidget } from '@sitevision/react';
import InsuranceCertificate from './lib/InsuranceCertificate';
import DocumentHelper from './templates/organisms/document-helper';

loadWidget('.plf-document-helper', documentHelperWidget => {
    const insuranceCertificates: InsuranceCertificate[] = JSON.parse(documentHelperWidget.dataset.insuranceCertificates);
    const title: string = documentHelperWidget.dataset.title;

    ReactDOM.render(<DocumentHelper insuranceCertificates={insuranceCertificates} title={title} />, documentHelperWidget);
});

import { useCallback } from 'react';
import { toast } from 'react-hot-toast';
import formatErrorToastMessage from '../formatErrorToastMessage';
import { fetchRequestJob } from "../util/requestJobUtils";
import * as contentDisposition from 'content-disposition-header';

export default function usePdfDownload(actionUrl: string, setLoading: (isLoading: boolean) => void, customInputData: any = undefined, requestJob: boolean = false) {
    return useCallback(async (inputData: any) => {
        setLoading(true);
        (requestJob ? fetchRequestJob : fetch)(actionUrl, {
            method: 'POST',
            body: JSON.stringify(customInputData ?? inputData),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        }).then(async res => {
            if (res.status === 200) {
                const blob = await res.blob();
                const anchor = document.createElement('a');
                anchor.href = URL.createObjectURL(blob);
                anchor.download = contentDisposition.parse(res.headers.get('Content-Disposition')).parameters.filename;
                anchor.click();
                setLoading(false);
            } else {
                res.json().then(response => {
                    toast.error(formatErrorToastMessage(response.context.title, response.traceId));
                });
                setLoading(false);
            }
        });
    }, [actionUrl, setLoading, customInputData]);
}

import React from 'react';
import './index.scss';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {toast} from 'react-hot-toast';
import TextareaField from '../../../../../../../../design/1/js/templates/molecules/textarea-field';
import FileUploadField from '../../../../../../../../design/1/js/templates/molecules/file-upload-field';
import FormButton, {
    FormButtonVariation
} from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import FileHelper from '../../../../../../../../design/1/js/lib/FileHelper';
import JsonResponse from '../../../../../../../../design/1/js/lib/entity/response/JsonResponse';
import AnswerMessageJsonResponseType from '../../../lib/AnswerMessageJsonResponseType';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import formatErrorToastMessage from '../../../../../../../../design/1/js/lib/formatErrorToastMessage';

interface NewMessageFormProps {
    backToInbox: () => void;
    pensionConsultantName: string;
    sendMessageAction: string;
}

const ClientNewMessageForm = ({
    backToInbox, pensionConsultantName, sendMessageAction,
}: NewMessageFormProps) => {
    const baseClassName = 'w-clientNewMessageForm';

    const maxFileSize = 8 * 1024 * 1024;
    const allowedFileTypes = ['jpg', 'jpeg', 'png', 'jpe', 'pdf'];
    const fileHelper = new FileHelper(maxFileSize, allowedFileTypes);

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .max(255, () => window.sv_resource.get('form_errormsg_number_max').replace('{{max}}', '255'))
            .required(window.sv_resource.get('form_errormsg_string')),
        message: Yup.string()
            .max(2000, () => window.sv_resource.get('form_errormsg_number_max').replace('{{max}}', '2000'))
            .required(window.sv_resource.get('form_errormsg_string')),
        files: Yup
            .array().of(
                Yup.object().shape({
                    file: Yup.string(),
                    fileName: Yup.string(),
                    fileType: Yup.string(),
                }),
            ),
    });
    const initialValues = Object({
        title: '',
        message: '',
        files: [],
    });

    const onSubmit = async values => {
        const response: JsonResponse<AnswerMessageJsonResponseType> = await fetch(sendMessageAction, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        }).then(res => res.json());

        backToInbox();

        if (response.success) {
            toast.success(window.sv_resource.get('plf_messagetool_client_answer_success').replace('{{pensionConsultantName}}', pensionConsultantName));
        } else {
            toast.error(formatErrorToastMessage(window.sv_resource.get('plf_messagetool_answer_error'), response.traceId));
        }
    };

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });

    const handleFileChange = event => {
        fileHelper.getFileContent(event.target)
            .then(files => {
                formik.setFieldValue('files', files);
            })
            .catch((error: Error) => {
                formik.setFieldError('files', error.message);
            })
            .finally(() => formik.setFieldTouched('files'));
    };

    return (
        <div className={`${baseClassName}`}>
            <h1>{window.sv_resource.get('plf_messagetool_new_message_title')}</h1>
            <form className={`${baseClassName} row`} onSubmit={formik.handleSubmit}>
                <TextField
                    id="title"
                    label={window.sv_resource.get('plf_messagetool_new_message_title_label')}
                    name="title"
                    type="text"
                    className="col-12"
                    placeholder=""
                    touched={!!formik.touched.title}
                    errors={formik.errors.title}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                />
                <TextareaField
                    id="message"
                    label={window.sv_resource.get('plf_messagetool_new_message_label')}
                    name="message"
                    className={`${baseClassName}__textarea col-12`}
                    placeholder=""
                    touched={!!formik.touched.message}
                    errors={formik.errors.message}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    onFocus={formik.handleBlur}
                />
                <FileUploadField
                    accept="application/pdf, image/png, image/jpeg"
                    className={`${baseClassName}__fileUpload col-10`}
                    label={window.sv_resource.get('plf_messagetool_client_answer_upload_label')}
                    onChange={handleFileChange}
                    id="files"
                    placeholder=""
                    multiple
                    name="files"
                    errors={formik.touched.files ? formik.errors.files as string : ''}
                    onBlur={formik.handleBlur}
                    touched={!!formik.touched.files}
                    value={formik.values.files}
                />
                <div className={`${baseClassName}__buttonWrapper`}>
                    <FormButton
                        handleClick={backToInbox}
                        hasIcon={false}
                        text={window.sv_resource.get('plf_messagetool_new_message_cancel')}
                        type="button"
                        variation={FormButtonVariation.Secondary}
                    />
                    <FormButton
                        className={`${baseClassName}__sendButton`}
                        disabled={Object.keys(formik.errors).length > 0 || Object.keys(formik.touched).length === 0}
                        hasIcon={false}
                        text={window.sv_resource.get('plf_messagetool_new_message_send')}
                        type="submit"
                        variation={FormButtonVariation.Primary}
                    />
                </div>
            </form>
            <Paragraph>{window.sv_resource.get('plf_messagetool_new_message_required_fields')}</Paragraph>
        </div>
    );
};

export default ClientNewMessageForm;

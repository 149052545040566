import React, {ReactNode} from 'react';
import './index.scss';
import * as Yup from 'yup';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import Checkbox from '../../../../../../../../design/1/js/templates/atoms/form-fields/checkbox';
import { StepProps } from '../../../lib/StepProps';
import RichText from '../../../../../../../../design/1/js/templates/atoms/richtext';
import StatusMessage, {MessageType} from "../../../../../../../../design/1/js/templates/molecules/status-message";
import StatusMessageVariation
    from "../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation";

export interface CheckType {
    confirmation: boolean;
}

interface CheckStepProps extends StepProps<CheckType> {
    customContent?: ReactNode;
    description?: string;
    label?: string;
}

export const validationSchemaCheckStep = Yup.object().shape({
    confirmation: Yup.boolean()
        .oneOf([true], () => window.sv_resource.get('form_errormsg_radio'))
        .required(() => window.sv_resource.get('form_errormsg_radio')),
});

const CheckStep = ({
    customContent, description, disabled, errors, handleBack, id, label, onBlur, onChange, touched, values,
}: CheckStepProps) => {
    const baseClassName = 'w-checkStep';

    return (
        <div className={`${baseClassName}`}>
            <h2 className={`${baseClassName}__title`} id={id}>{window.sv_resource.get('plf_onlineform_check_title')}</h2>
            {description !== '' && <RichText className={`${baseClassName}__description`} text={description} />}
            <div className={`${baseClassName}__checkbox row gap-lg-s`}>
                <Checkbox
                    checked={values.confirmation}
                    errors={errors.confirmation}
                    touched={touched.confirmation}
                    id="confirmation"
                    name="confirmation"
                    onBlur={onBlur}
                    onChange={onChange}
                    label={label !== '' ? label : window.sv_resource.get('plf_onlineform_check_description')}
                />
            </div>
            {customContent}
            <div className={`${baseClassName}__buttons d-flex justify-content-between`}>
                <FormButton disabled={disabled} handleClick={handleBack} text={window.sv_resource.get('plf_onlineform_previous_label')} variation={FormButtonVariation.Previous} type="submit" />
                <FormButton
                    text={window.sv_resource.get('plf_onlineform_submit_label')}
                    disabled={Object.keys(errors).length > 0 || values.confirmation === false}
                    type="submit"
                    variation={FormButtonVariation.Primary}
                />
            </div>
        </div>
    );
};

CheckStep.defaultProps = {
    description: '',
    label: '',
};

export default CheckStep;

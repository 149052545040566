import FileContent from './FileContent';

export default class FileHelper {
    private readonly maxFileSize: number;

    private readonly allowedFileTypes: string[];

    constructor(maxFileSize: number, allowedFileTypes: string[]) {
        this.maxFileSize = maxFileSize;
        this.allowedFileTypes = allowedFileTypes;
    }

    public getFileContent(element: HTMLInputElement): Promise<FileContent[]> {
        const filesPromise = Array.from(element.files).map(file => this.getFilePromise(file));
        return Promise.all(filesPromise);
    }

    private getFilePromise(file: File): Promise<FileContent> {
        return new Promise<FileContent>((resolve, reject) => {
            const extension = file.name.split('.').pop().toLowerCase();
            const isValidFileType = this.allowedFileTypes.indexOf(extension) > -1;

            if (file.size > this.maxFileSize) {
                reject(new Error(window.sv_resource.get('form_errormsg_file_size')));
                return;
            }

            if (!isValidFileType) {
                reject(new Error(window.sv_resource.get('form_errormsg_file_type')));
                return;
            }

            const reader = new FileReader();

            reader.addEventListener('load', () => {
                resolve({
                    file: reader.result as string,
                    fileName: file.name,
                    fileType: file.type,
                });
            });

            reader.readAsDataURL(file);
        });
    }
}

import React from 'react';
import { StringSchema } from 'yup';
import * as Yup from 'yup';
import './index.scss';
import FormWithAnswerText from '../../../../../../../../design/1/js/templates/organisms/form-with-answer-text';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import Checkbox from '../../../../../../../../design/1/js/templates/atoms/form-fields/checkbox';
import DatePickerField from '../../../../../../../../design/1/js/templates/molecules/date-picker-field';
import { IconName } from '../../../../../../../../design/1/js/templates/atoms/icon';
import { getIsoDate } from '../../../../../../../../design/1/js/lib/formatData';
import {hasInsuredMultipleIds} from "../../../../../../../../design/1/js/lib/util/InsuredUtils";
import StatusMessage, {MessageType} from "../../../../../../../../design/1/js/templates/molecules/status-message";
import StatusMessageVariation
    from "../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation";

export interface CivilStateDeceasedPartnerFormProps {
    actionUrl: string,
    handleSubmit: (status: number) => void,
}

const CivilStateDeceasedPartnerForm = ({
    actionUrl, handleSubmit,
}:CivilStateDeceasedPartnerFormProps) => {
    const baseClassName = 'w-changeCivilStateDeceasedPartnerForm';

    const validationSchema = Yup.object().shape({
        // @ts-ignore -> Yup is actually able to handle strings as date; it casts strings with new Date(string)
        date: Yup.date()
            .required(window.sv_resource.get('form_errormsg_date')) as StringSchema,
        withPensionCertificate: Yup.boolean(),
    });

    const initialValues = Object.freeze({
        date: getIsoDate(new Date()),
        withPensionCertificate: false,
    });

    return (
        <FormWithAnswerText
            actionUrl={actionUrl}
            className={`${baseClassName}`}
            handleSubmit={handleSubmit}
            handleValidate={validationSchema}
            initialValues={initialValues}
            name="ChangeCivilStateDecreasedPartner"
        >
            {formik => (
                <div className={`${baseClassName}`}>
                    <DatePickerField
                        className="col-6"
                        errors={formik.errors.date}
                        icon={IconName.Calendar}
                        id="date"
                        label={window.sv_resource.get('plf_servicesform_civil_state_deceased_partner_date_to_change_label')}
                        maxDate={new Date()}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        touched={formik.touched.date}
                        value={formik.values.date}
                    />
                    <Checkbox
                        className={`${baseClassName}__checkbox col-12`}
                        errors={formik.errors.withPensionCertificate}
                        touched={formik.touched.withPensionCertificate}
                        id="withPensionCertificate"
                        name="withPensionCertificate"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        label={window.sv_resource.get('plf_servicesform_civil_state_deceased_partner_agree_label')}
                    />
                    {hasInsuredMultipleIds() && (
                        <div className="row my-4">
                            <StatusMessage
                                customMessageColor={MessageType.Info}
                                messageType={MessageType.Info}
                                description={window.sv_resource.get('plf_yourprofile_master_data_civil_state_description_multiple_insured_ids')}
                                variation={StatusMessageVariation.WithBackground}
                            />
                        </div>
                    )}
                    <FormButton
                        className={`${baseClassName}__submit`}
                        disabled={Object.keys(formik.errors).length > 0 || Object.keys(formik.touched).length === 0}
                        text={window.sv_resource.get('plf_servicesform_civil_state_deceased_partner_submit_btn')}
                        type="submit"
                        variation={FormButtonVariation.Primary}
                    />
                </div>
            )}
        </FormWithAnswerText>
    );
};

export default CivilStateDeceasedPartnerForm;

import React from 'react';
import ConsultantMessageListEntry from '../../molecules/consultant-message-list-entry';
import FilterOptionsType from '../../../lib/consultant/FilterOptionsType';
import BaseMessagetoolInbox from '../base-messagetool-inbox';
import ConsultantMessageListTitleRow from '../../molecules/consultant-message-list-title-row';
import FilterType from '../../../lib/consultant/FilterType';
import ConsultantMessageFilter from '../../molecules/consultant-message-filter';
import {MessageToolView} from "../../../lib/enum/MessageToolView";
import MessageType from "../../../lib/MessageType";

interface MessagetoolInboxConsultantProps {
    switchView: (view: MessageToolView) => void;
    filter: FilterType;
    filterOptions: FilterOptionsType;
    messagesAction: string;
    saveMessageUpdateAction: string;
    setFilter: (filter: FilterType) => void;
    setSelectedMessage: (message: MessageType) => void;
}

const MessagetoolInboxConsultant = ({
    switchView, filter, filterOptions, messagesAction, saveMessageUpdateAction, setFilter, setSelectedMessage,
}: MessagetoolInboxConsultantProps) => (
    <BaseMessagetoolInbox
        messagesAction={messagesAction}
        messageListTitleRow={() => <ConsultantMessageListTitleRow />}
        messageListEntry={(
            updateMessage,
            message,
            ref,
            key,
        ) => (
            <ConsultantMessageListEntry
                filterOptions={filterOptions}
                message={message}
                ref={ref}
                saveMessageUpdateAction={saveMessageUpdateAction}
                updateMessage={updateMessage}
                setSelectedMessage={setSelectedMessage}
                switchView={switchView}
                key={key}
            />
        )}
        filterComponent={() => (
            <ConsultantMessageFilter filterOptions={filterOptions} updateFilter={setFilter} filter={filter} />
        )}
        switchView={switchView}
        filter={filter}
        notFoundMessage={getNotFoundMessage(filter.search)}
    />
);

const getNotFoundMessage = (search: string) => {
    if (search.length === 0) {
        return window.sv_resource.get('plf_messagetool_no_message');
    }

    return window.sv_resource.get('plf_messagetool_consultant_search_no_results')
};

export default MessagetoolInboxConsultant;

import React, { useEffect, useState } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import MasterData, { MasterDataType } from '../../molecules/master-data';
import RegistrationStep, { RegistrationType, validationSchemaRegistrationStep } from '../../molecules/registration';
import ExitDateStep, { ExitDateType, validationSchemaExitDateStep } from '../../molecules/exit-date';
import FormWithAnswerText from '../../../../../../../../design/1/js/templates/organisms/form-with-answer-text';
import CheckStep, { CheckType, validationSchemaCheckStep } from '../../molecules/check';
import FileHelper from '../../../../../../../../design/1/js/lib/FileHelper';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import JsonResponse from '../../../../../../../../design/1/js/lib/entity/response/JsonResponse';
import TransferVestedBenefitsInstitutionStep, {
    TransferVestedBenefitsInstitutionType,
    validationSchemaTransferVestedBenefitsInstitutionStep,
} from '../../molecules/transfer-vested-benefits-institution';
import { formatSocialSecurityNumber } from '../../../../../../../../design/1/js/lib/formatData';
import StatusMessageVariation
    from '../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation';

type ExitNoticeVestedBenefitsInstitutionFormType = MasterDataType & ExitDateType & TransferVestedBenefitsInstitutionType & RegistrationType & CheckType;

interface ExitNoticeVestedBenefitsInstitutionFormProps {
    formAction: string;
    handleSubmit: (status: number) => void,
    masterDataAction: string;
}

const ExitNoticeVestedBenefitsInstitutionForm = (({ formAction, handleSubmit, masterDataAction }: ExitNoticeVestedBenefitsInstitutionFormProps) => {
    const baseClassName = 'w-exitNoticeVestedBenefitsInstitutionForm';
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['exitDate', 'transferVestedBenefitsInstitution', 'registration', 'check'];
    const isLastStep = activeStep === steps.length - 1;

    const maxFileSize = 4 * 1024 * 1024;
    const allowedFileTypes = ['jpg', 'png', 'pdf'];
    const fileHelper = new FileHelper(maxFileSize, allowedFileTypes);

    const initialValues = Object({
        firstname: '',
        lastname: '',
        birthdate: '',
        socialSecurityNumber: '',
        resignationDate: '',
        institution: '',
        newEmployer: '',
        pensionFundNewEmployer: '',
        addressPensionFundNewEmployer: '',
        ibanPensionFundNewEmployer: '',
        vestedBenefitsInstitution: '',
        nameOfOtherVestedBenefitsInstitution: '',
        ibanOfOtherVestedBenefitsInstitution: '',
        files: [],
        disabilityInsurance: '',
        disabilityInsuranceRegistrationDate: '',
        disabilityInsuranceCanton: '',
        confirmation: false,
    });

    const validationSchema = [
        validationSchemaExitDateStep,
        validationSchemaTransferVestedBenefitsInstitutionStep,
        validationSchemaRegistrationStep,
        validationSchemaCheckStep,
    ];

    const currentValidationSchema = validationSchema[activeStep];

    const renderStepContent = (
        step,
        errors: FormikErrors<ExitNoticeVestedBenefitsInstitutionFormType>,
        touched: FormikTouched<ExitNoticeVestedBenefitsInstitutionFormType>,
        onChange,
        onBlur,
        values: ExitNoticeVestedBenefitsInstitutionFormType,
        handleBack,
        handleFileChange?,
    ) => {
        switch (step) {
        case 0:
            return (
                <ExitDateStep isFirstStep={true} errors={errors} id={steps[0]} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} />
            );
        case 1:
            return (
                <>
                    <ExitDateStep isFirstStep={true} errors={errors} disabled id={steps[0]} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} />
                    <TransferVestedBenefitsInstitutionStep errors={errors} id={steps[1]} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} handleFileChange={handleFileChange} />
                </>
            );
        case 2:
            return (
                <>
                    <ExitDateStep isFirstStep={true} errors={errors} disabled id={steps[0]} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} />
                    <TransferVestedBenefitsInstitutionStep errors={errors} disabled id={steps[1]} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} handleFileChange={handleFileChange} />
                    <RegistrationStep errors={errors} id={steps[2]} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} />
                </>
            );
        case 3:
            return (
                <>
                    <ExitDateStep isFirstStep={true} errors={errors} disabled id={steps[0]} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} />
                    <TransferVestedBenefitsInstitutionStep errors={errors} disabled touched={touched} id={steps[1]} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} handleFileChange={handleFileChange} />
                    <RegistrationStep errors={errors} disabled id={steps[2]} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} />
                    <CheckStep errors={errors} id={steps[3]} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} />
                </>
            );
        default:
            throw new Error();
        }
    };

    const customOnSubmit = (values, actions) => {
        actions.setSubmitting(false);
        actions.setTouched({});
        setActiveStep(activeStep + 1);
    };

    useEffect(() => {
        scrollTo(`#${steps[activeStep]}`);
    }, [activeStep]);

    const scrollTo = (id: string) => {
        window.location.href = id;
    };

    return (
        activeStep !== steps.length && (
            <FormWithAnswerText
                actionUrl={formAction}
                className={`${baseClassName}`}
                customOnSubmit={isLastStep ? undefined : customOnSubmit}
                handleSubmit={handleSubmit}
                handleValidate={currentValidationSchema}
                initialValues={initialValues}
                name="ExitNoticeVestedBenefitsInstitutionForm"
            >
                {formik => {
                    const handleBack = () => {
                        formik.setErrors({});
                        setActiveStep(activeStep - 1);
                    };

                    const handleFileChange = event => {
                        fileHelper.getFileContent(event.target)
                            .then(files => {
                                formik.setFieldValue('files', files);
                            })
                            .catch((error: Error) => {
                                formik.setFieldError('files', error.message);
                            })
                            .finally(() => formik.setFieldTouched('files'));
                    };

                    return (
                        <>
                            <AsyncLoader
                                action={masterDataAction}
                                renderContent={lazyChildren => (
                                    <>
                                        {lazyChildren}
                                    </>
                                )}
                                variation={StatusMessageVariation.Subpage}
                            >
                                {(masterData: MasterDataType) => (
                                    <>
                                        <MasterData data={masterData} />
                                        { renderStepContent(activeStep, formik.errors, formik.touched, formik.handleChange, formik.handleBlur, formik.values, handleBack, handleFileChange) }
                                    </>
                                )}

                            </AsyncLoader>
                        </>
                    );
                }}
            </FormWithAnswerText>
        )
    );
});

export default ExitNoticeVestedBenefitsInstitutionForm;

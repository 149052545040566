import React from 'react';
import ReactDOM from 'react-dom';
import { loadWidget } from '@sitevision/react';
import LinkType from '../../yourdocuments/ts/lib/LinkType';
import MutationsAndServicesOverview from './templates/organism/mutationsandrequests-overview';

loadWidget('.plf-mutationsandrequests', mutationsandrequestsWidget => {
    const data: DOMStringMap = mutationsandrequestsWidget.dataset;
    if (data.links === '') {
        return;
    }
    const links: LinkType[] = JSON.parse(mutationsandrequestsWidget.dataset.links);
    const id: string = mutationsandrequestsWidget.dataset.headerId;
    const title: string = mutationsandrequestsWidget.dataset.title;
    ReactDOM.render(<MutationsAndServicesOverview id={id} links={links} title={title} />, mutationsandrequestsWidget);
});

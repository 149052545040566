import React, { useRef, useState } from 'react';
import './index.scss';
import * as Yup from 'yup';
import { StringSchema } from 'yup';
import { useFormik } from 'formik';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import DatePickerField from '../../../../../../../../design/1/js/templates/molecules/date-picker-field';
import { IconName } from '../../../../../../../../design/1/js/templates/atoms/icon';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import WefResponseContext from '../../../lib/wef/WefResponseContext';
import { getIsoDate } from '../../../../../../../../design/1/js/lib/formatData';
import { baseIntegerSchema, cleanIntegerValue } from '../../../lib/yupFields/baseIntegerSchema';

export interface WefSimulationFormProps {
    handleSubmit: (response: WefResponseContext) => void;
    headerId: string;
    hidden: boolean;
    maxWef: number;
}

const WefSimulationForm = ({
    handleSubmit, headerId, hidden, maxWef,
}: WefSimulationFormProps) => {
    const baseClassName = 'w-wefSimulationForm';
    const [datePickerError, setDatePickerError] = useState(null);
    const datePickerRef = useRef<HTMLDivElement>(null);

    const validationSchema = Yup.object().shape({
        wef: baseIntegerSchema()
            .max(maxWef, window.sv_resource.get('plf_simulation_errormsg_max_wef')),
        // @ts-ignore -> Yup is actually able to handle strings as date; it casts strings with new Date(string)
        deadline: Yup.date()
            .typeError(window.sv_resource.get('form_errormsg_date'))
            .test('isThisYear', window.sv_resource.get('form_errormsg_date_this_year'), (val: Date) => val.getFullYear() === (new Date().getFullYear()))
            .test('isNotPast', window.sv_resource.get('form_errormsg_date_not_past'), (val: Date) => {
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0);
                return val >= currentDate;
            })
            .required(window.sv_resource.get('form_errormsg_date')) as StringSchema,
    });

    const initialValues = Object.freeze({
        wef: '',
        deadline: getIsoDate(new Date()),
    });

    const onSubmit = ({ deadline, wef }: typeof initialValues) => {
        const numericWef = String(cleanIntegerValue(wef));

        handleSubmit({ deadline, wef: numericWef });
    };

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });

    const handleIsValid = (isValid: boolean) => {
        setDatePickerError(isValid ? null : window.sv_resource.get('form_errormsg_date_between_now_and_end_of_year'));
    };

    return (
        <div className={`${baseClassName} ${hidden ? 'd-none' : ''} `}>
            <h2 id={headerId}>{window.sv_resource.get('plf_simulation_title')}</h2>
            <Paragraph>{window.sv_resource.get('plf_simulation_wef_description')}</Paragraph>
            <form name="wefSimulation" onSubmit={formik.handleSubmit}>
                <>
                    <div className="row gap-xxs">
                        <TextField
                            className={`${baseClassName}__field col-12 col-lg-6`}
                            errors={formik.errors.wef}
                            icon={IconName.Chf}
                            id="wef"
                            label={window.sv_resource.get('plf_simulation_wef_label_advance')}
                            name="wef"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            onFocus={formik.handleBlur}
                            placeholder={formik.values.wef}
                            touched={!!formik.touched.wef}
                            type="text"
                            value={formik.values.wef}
                        />
                        <DatePickerField
                            className="col-12 col-lg-6"
                            errors={datePickerError ?? formik.errors.deadline}
                            icon={IconName.Calendar}
                            id="deadline"
                            label={window.sv_resource.get('plf_simulation_wef_label_date')}
                            maxDate={new Date(new Date().getFullYear(), 11, 31)}
                            minDate={new Date()}
                            ref={datePickerRef}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            onIsValid={handleIsValid}
                            touched
                            value={formik.values.deadline}
                        />
                    </div>
                    <div className={`${baseClassName}__submitWrapper row`}>
                        <FormButton
                            disabled={Object.keys(formik.errors).length > 0 || Object.keys(formik.touched).length === 0 || datePickerError !== null}
                            hasIcon={false}
                            text={window.sv_resource.get('plf_simulation_calc_button_label')}
                            type="submit"
                            variation={FormButtonVariation.Primary}
                        />
                    </div>
                </>
            </form>
        </div>
    );
};

export default WefSimulationForm;

import { useEffect, useState } from 'react';
import FilterOptionsType from '../consultant/FilterOptionsType';

export default function useSortedFilterOptions(filterOptions: FilterOptionsType): FilterOptionsType {
    const [sortedFilterOptions, setSortedFilterOptions] = useState<FilterOptionsType>(() => getSortedFilterOptions(filterOptions));

    useEffect(() => {
        setSortedFilterOptions(getSortedFilterOptions(filterOptions));
    }, [filterOptions.subjectList, filterOptions.consultantList]);

    return sortedFilterOptions;
}

function getSortedFilterOptions(filterOptions: FilterOptionsType): FilterOptionsType {
    const iso = window.sv_resource.getIsoLanguage();

    const sortedConsultantList = [...filterOptions.consultantList];
    sortedConsultantList.sort((a, b) => a.consultantName.localeCompare(b.consultantName, iso));

    const sortedSubjectList = [...filterOptions.subjectList];
    sortedSubjectList.sort((a, b) => {
        const translatedSubjectA = window.sv_resource.get(`plf_messagetool_theme_${a}`);
        const translatedSubjectB = window.sv_resource.get(`plf_messagetool_theme_${b}`);

        return translatedSubjectA.localeCompare(translatedSubjectB, iso);
    });

    return {
        consultantList: sortedConsultantList,
        subjectList: sortedSubjectList,
        statusList: filterOptions.statusList,
    };
}

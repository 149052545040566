import React from 'react';
import './index.scss';
import ContactCard from '../../../../../../../../design/1/js/templates/molecules/contact-card';
import UserInfos from '../../atoms/user-infos';
import WelcomeUserPanel from '../../atoms/welcome-user-panel';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import User from '../../../lib/User';
import { AsyncWidgetProps } from '../../../../../base/ts/lib/AsyncWidgetProps';
import {getCurrentInsuredId} from "../../../../../../../../design/1/js/lib/util/InsuredUtils";

interface WelcomeSectionProps extends AsyncWidgetProps {
    loggedIn: boolean;
}

const WelcomeSection = ({ action, loggedIn }: WelcomeSectionProps) => {
    const baseClassName = 'm-banner-section';

    const getContent = (user: User) => (
        <>
            <div className={`${baseClassName}__text-layer`}>
                <WelcomeUserPanel user={user} />
                <UserInfos user={user} />
            </div>
            {getCurrentInsuredId() && (
                <div className={`${baseClassName}__contact-card`}>
                    <ContactCard consultant={user?.pensionConsultant} />
                </div>
            )}
        </>
    );

    return (
        <div className={`${baseClassName} ${loggedIn ? '' : '-short'}`}>
            <div className="container-lg">
                <div className={`${baseClassName}__wrapper`}>
                    { loggedIn ? (
                        <AsyncLoader
                            action={action}
                            renderContent={lazyChildren => (
                                <>
                                    {lazyChildren}
                                </>
                            )}
                        >
                            {(user: User) => getContent(user)}
                        </AsyncLoader>
                    ) : (
                        getContent(null)
                    )}
                </div>
            </div>
        </div>
    );
};

export default WelcomeSection;

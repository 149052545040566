import React from 'react';
import './index.scss';

interface TableProps {
    children: React.ReactNode;
    className?: string;
}

const TableBody = ({ children, className }: TableProps) => {
    const baseClassName = 'a-tableBody';

    return (
        <div className={`${baseClassName} ${className}`}>
            {children}
        </div>
    );
};

TableBody.defaultProps = {
    className: '',
}

export default TableBody;

import React from 'react';
import RichText from '../../../../../../../../design/1/js/templates/atoms/richtext';
import WefSimulationForm from '../wef-simulation-form';
import WefSimulationCalculated from '../../molecules/wef-simulation-calculated';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import WefSimulationData from '../../../lib/WefSimulationData';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import DataTableMaxWef from '../../molecules/data-table-max-wef';
import WefResponseContext from '../../../lib/wef/WefResponseContext';
import Simulation from '../simulation';
import { formatDate } from '../../../../../../../../design/1/js/lib/formatData';
import RequestForm from '../../molecules/request-form';

interface WefSimulationProps {
    action: string;
    headerIdSimulation: string;
    linkToRegulationsDocument: string;
    maxWefAction: string;
    requestAction: string;
}

const WefSimulation = ({
    action, headerIdSimulation, linkToRegulationsDocument, maxWefAction, requestAction,
}: WefSimulationProps) => {
    const baseClassName = 'w-wefSimulation';

    return (
        <div className={`${baseClassName}`}>
            <Simulation
                calculatingAction={action}
                simulationDataTable={setMaxValue => <DataTableMaxWef action={maxWefAction} setMax={setMaxValue} />}
                simulationAction={
                    (calculatingAction, handleReset, handleCreatePdf) => (
                        <AsyncLoader
                            action={calculatingAction}
                            autoscroll
                            renderContent={lazyChildren => (
                                <>
                                    {lazyChildren}
                                </>
                            )}
                            requestJob={true}
                        >
                            {(simulationData: WefSimulationData) => (
                                <>
                                    <h2 id={headerIdSimulation}>
                                        <span className="d-print-inline d-none">
                                            {simulationData.insuredData.name}
                                            {window.sv_resource.get('plf_simulation_print_title_link')}
                                        </span>
                                        {window.sv_resource.get('plf_simulation_title')}
                                        <span className="d-print-inline d-none">{` ${window.sv_resource.get('plf_simulation_print_title_date_link')} ${formatDate(new Date())} `}</span>
                                    </h2>
                                    <Paragraph>{window.sv_resource.get('plf_simulation_wef_calculated_description')}</Paragraph>
                                    <WefSimulationCalculated simulationData={simulationData} handleCreatePdf={handleCreatePdf} handleReset={handleReset} />
                                    <RichText
                                        text={window.sv_resource.get('plf_simulation_calculated_request_document')
                                            .replace('{{link_regulationsdocument}}', linkToRegulationsDocument)}
                                    />
                                    <RequestForm
                                        actionUrl={requestAction}
                                        checkboxLabel={window.sv_resource.get('plf_simulation_purchase_wef_request_checkbox_label')}
                                        description={window.sv_resource.get('plf_simulation_calculated_request_description')}
                                        inputData={simulationData.inputData}
                                        name="wefRequestForm" />
                                </>
                            )}
                        </AsyncLoader>
                    )
                }
                simulationForm={(handleFormSubmit: (response: WefResponseContext) => void, maxValue) => <WefSimulationForm handleSubmit={handleFormSubmit} headerId={headerIdSimulation} hidden={maxValue === 0} maxWef={maxValue} />}
            />
        </div>
    );
};

export default WefSimulation;

import React from 'react';
import './index.scss';
import { UserProps } from '../user-infos';

const WelcomeUserPanel = ({ user }: UserProps) => {
    const baseClassName = 'a-welcome-panel';
    if (user === null) {
        return (
            <div className={`${baseClassName}`}>
                <div className={`${baseClassName}__text headline display1`}><strong>{window.sv_resource.get('plf_welcome_title')}</strong></div>
            </div>
        );
    }
    return (
        <div className={`${baseClassName}`}>
            <div className={`${baseClassName}__text display1`}>{window.sv_resource.get('plf_welcome_title_loggedin')}</div>
            <div className={`${baseClassName}__name headline display1`}>{user.name}</div>
        </div>
    );
};

export default WelcomeUserPanel;

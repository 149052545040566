import React from 'react';
import './index.scss';
import DataTableData from '../../../lib/DataTableData';
import Table from '../../../../../../../../design/1/js/templates/molecules/table';
import TableBody from '../../../../../../../../design/1/js/templates/atoms/table-body';
import TableHeightRow from '../../../../../../../../design/1/js/templates/atoms/table-height-row';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import TableHead from '../../../../../../../../design/1/js/templates/atoms/table-head';
import StatusMessageVariation
    from '../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation';

interface DataTablePensionCalculatorProps {
    action: string;
    fetchingFinished: (finished: boolean) => void;
}

const InfoTableSavingPlan = ({
    action, fetchingFinished,
}: DataTablePensionCalculatorProps) => {
    const baseClassName = 'w-infoTableSavingPlan';
    const handleSuccess = () => {
        fetchingFinished(true);
    }

    return (
        <div className={`${baseClassName}`}>
            <AsyncLoader
                action={action}
                renderContent={lazyChildren => (
                    <>
                        {lazyChildren}
                    </>
                )}
                variation={StatusMessageVariation.Subpage}
                onSuccess={handleSuccess}
            >
                {(infoData: DataTableData[]) => (
                    <Table>
                        <TableHead>
                            <div className="col-8 col-lg-10" />
                            <div className="col-4 col-lg-2" />
                        </TableHead>
                        <TableBody>
                            {infoData.map((data, index) => (
                                <TableHeightRow key={`row-${index.toString()}`}>
                                    <div className={`${baseClassName}__column col-8 col-lg-10`}>
                                        {data.title}
                                    </div>
                                    <div className={`${baseClassName}__column col-4 col-lg-2`}>
                                        {data.value}
                                    </div>
                                </TableHeightRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </AsyncLoader>
        </div>
    );
};

export default InfoTableSavingPlan;

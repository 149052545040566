import React from 'react';
import './index.scss';

interface LinkProps {
    children: React.ReactNode;
    className?: string;
    href: string,
    target?: string;
    title: string,
}

const Link = ({
    children,
    className,
    href,
    title,
    target,
}:LinkProps) => {
    const baseClassName = 'a-link';

    return (
        <a className={`${baseClassName} ${className}`} href={href} target={target} title={title}>{children}</a>
    );
};

Link.defaultProps = {
    className: '',
    target: '_self',
};

export default Link;

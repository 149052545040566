enum YourProfileArea {
    AccountData = 'accountdata',
    ContactData = 'contactdata',
    DocumentDelivery = 'documentdelivery',
    MasterData = 'masterdata',
    Notifications = 'notifications',
    SalaryData = 'salarydata',
}

export default YourProfileArea;

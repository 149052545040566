import React, { useState } from 'react';
import './index.scss';
import IntroductionText from '../../../../../../../../design/1/js/templates/molecules/introduction-text';
import CivilStateChangeForm from '../civil-state-change-form';

interface CivilStateChangeProps {
    formAction: string;
    masterDataAction: string;
}

const CivilStateChange = ({ formAction, masterDataAction }: CivilStateChangeProps) => {
    const [formSubmit, setFormSubmit] = useState(false);
    const baseClassName = 'w-civilStateChange';

    const handleFormSubmit = () => {
        setFormSubmit(!formSubmit);
    };

    return (
        <div className={`${baseClassName}`}>
            { !formSubmit && <IntroductionText description={window.sv_resource.get('plf_onlineform_civil_state_description')} title={window.sv_resource.get('plf_onlineform_civil_state_title')} /> }
            <div className={`${baseClassName}__wrapper`}>
                <CivilStateChangeForm formAction={formAction} handleSubmit={handleFormSubmit} masterDataAction={masterDataAction} />
            </div>
        </div>
    );
};

export default CivilStateChange;

import React from 'react';
import './index.scss';

const FooterTitle: React.FC = ({ children }) => {
    const baseClassName = 'a-footer-title';

    return (
        <p className={`${baseClassName} title`}>{children}</p>
    );
};

export default FooterTitle;

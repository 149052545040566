import React, { useState } from 'react';
import JsonResponse from '../../../../../../../../design/1/js/lib/entity/response/JsonResponse';
import RichContext from '../../../../../../../../design/1/js/lib/entity/response/RichContext';
import Button, { ButtonType } from '../../../../../../../../design/1/js/templates/atoms/button';
import StatusMessage, { MessageType } from '../../../../../../../../design/1/js/templates/molecules/status-message';
import StatusMessageVariation
    from '../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation';
import FormListItemType from '../../../lib/FormListItemType';
import FormType from '../../../lib/FormType';
import './index.scss';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import * as contentDisposition from 'content-disposition-header';

interface FormsOverviewProps {
    id: string;
    description: string;
    formList: FormListItemType[];
}

const FormsOverview = ({
    id, formList, description,
}: FormsOverviewProps) => {
    const baseClassName = 'w-formsOverview';

    const [currentlyLoadingPdfs, setCurrentlyLoadingPdfs] = useState([]);
    const [errorResponse, setErrorResponse] = useState<JsonResponse<RichContext>>(null);

    if (errorResponse !== null) {
        return (
            <StatusMessage
                additionalText={errorResponse.context.additionalText}
                description={errorResponse.context.description}
                inline
                messageType={MessageType.Error}
                traceId={errorResponse.traceId}
                title={errorResponse.context.title}
                variation={StatusMessageVariation.Subpage}
            />
        );
    }

    const getPdf = (getPdfAction: string, pdfId: number) => {
        addPdfToLoadingState(pdfId);
        fetch(getPdfAction)
            .then(async res => {
                if (res.status === 200) {
                    const blob = await res.blob();
                    const anchor = document.createElement('a');
                    anchor.href = URL.createObjectURL(blob);
                    anchor.download = contentDisposition.parse(res.headers.get('Content-Disposition')).parameters.filename;
                    anchor.click();
                } else {
                    res.json().then(response => {
                        setErrorResponse(response);
                    });
                }
                removePdfFromLoadingState(pdfId);
            });
    };

    const addPdfToLoadingState = (idToAdd: number) => {
        setCurrentlyLoadingPdfs(prevState => [...prevState, idToAdd]);
    }

    const removePdfFromLoadingState = (idToDelete: number) => {
        setCurrentlyLoadingPdfs(prevState => prevState.filter((pdfId) => pdfId !== idToDelete));
    }

    return (
        <div className={`${baseClassName}`}>
            <h2 id={id}>{window.sv_resource.get('plf_lifesituation_forms_title')}</h2>
            <Paragraph>{description}</Paragraph>
            {
                formList.map((formItem, index) => {
                    switch (formItem.type) {
                    case FormType.Spacer:
                        return <div className="-space" key={`formItem-${index.toString()}`} />;
                    case FormType.Document:
                        const pdfIsLoading = currentlyLoadingPdfs.indexOf(index) > -1;
                        return (
                            <Button
                                className={`${baseClassName}__button`}
                                disabled={pdfIsLoading}
                                text={formItem.name}
                                handleClick={() => getPdf(formItem.url, index)}
                                hasArrow
                                key={`formItem-${index.toString()}`}
                                type={ButtonType.Default}
                            />
                        );
                    case FormType.Link:
                    default:
                        return (
                            <Button
                                className={`${baseClassName}__button`}
                                text={formItem.name}
                                href={formItem.url}
                                hasArrow
                                key={`formItem-${index.toString()}`}
                                type={ButtonType.Default}
                            />
                        );
                    }
                })
            }
        </div>
    );
};

export default FormsOverview;

import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import "./index.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { StringSchema } from "yup";
import DatePickerField from "../../../../../../../../design/1/js/templates/molecules/date-picker-field";
import { IconName } from "../../../../../../../../design/1/js/templates/atoms/icon";
import FormButton, {
    FormButtonVariation
} from "../../../../../../../../design/1/js/templates/atoms/form-fields/form-button";
import Paragraph from "../../../../../../../../design/1/js/templates/atoms/paragraph";
import TextField from "../../../../../../../../design/1/js/templates/molecules/text-field";
import PensionCalculatorResponseContext from "../../../lib/pensionCalculator/PensionCalculatorResponseContext";
import RadioButton from "../../../../../../../../design/1/js/templates/atoms/form-fields/radiobutton";
import Label from "../../../../../../../../design/1/js/templates/atoms/form-fields/label";
import AsyncLoader from "../../../../../../../../design/1/js/templates/molecules/async-loader";
import LoadSpinner from "../../../../../../../../design/1/js/templates/atoms/load-spinner";
import AnswerText from "../../../../../../../../design/1/js/templates/molecules/answer-text";
import { formatCurrency, getIsoDate, offsetDate } from "../../../../../../../../design/1/js/lib/formatData";
import JsonResponse from "../../../../../../../../design/1/js/lib/entity/response/JsonResponse";
import InitialDataType from "../../../lib/pensionCalculator/InitialDataType";
import { CalenderStartView } from "../../../../../../../../design/1/js/templates/atoms/form-fields/date-picker";
import PensionCalculatorFormRequestType from "../../../lib/pensionCalculator/PensionCalculatorFormRequestType";
import StatusMessageVariation
    from "../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation";
import RichText from "../../../../../../../../design/1/js/templates/atoms/richtext";
import nl2br from "../../../../../messagetool/ts/lib/nl2br";
import Link from "../../../../../../../../design/1/js/templates/atoms/link";
import { baseIntegerSchema, cleanIntegerValue } from "../../../lib/yupFields/baseIntegerSchema";
import { basePercentageSchema, cleanPercentageValue } from "../../../lib/yupFields/basePercentageSchema";
import { fetchRequestJob } from "../../../../../../../../design/1/js/lib/util/requestJobUtils";

interface PensionCalculatorSimulationFormProps {
    handleSubmit: (response: PensionCalculatorResponseContext) => void;
    headerId: string;
    getOrdinaryRetirementDateAction: string;
    getBirthdateAction: string;
    getRetirementPercentageAction: string
    getInfosOnRetirementDateAction: string;
    getGeneralInfosAction: string;
}

const PensionCalculatorSimulationForm = ({
    getOrdinaryRetirementDateAction, getBirthdateAction, getRetirementPercentageAction, getInfosOnRetirementDateAction, getGeneralInfosAction, handleSubmit, headerId,
}: PensionCalculatorSimulationFormProps) => {
    const baseClassName = 'w-pensionCalculatorSimulationForm';
    const [datePickerError, setDatePickerError] = useState(null);
    const datePickerRef = useRef<HTMLDivElement>(null);
    const [birthdate, setBirthdate] = useState<Date>(null);
    const [retirementDate, setRetirementDate] = useState<Date>(null);
    const [minRetirementDate, setMinRetirementDate] = useState<Date>(new Date());
    const [isLoading, setLoading] = useState(false);
    const [failingResponse, setFailingResponse] = useState(null);
    const [maxCapitalWithdrawal, setMaxCapitalWithdrawal] = useState(0); // use getMaxCapitalWithdrawal
    const [retirementCapital, setRetirementCapital] = useState('');
    const [balanceAdditionalAccount, setBalanceAdditionalAccount] = useState('');
    const [maxBridgingPension, setMaxBridgingPension] = useState(0);
    const [ordinaryRetirementDate, setOrdinaryRetirementDate] = useState<Date>(null);
    const [age58, setAge58] = useState<Date>(null);
    const [age70, setAge70] = useState<Date>(null);
    const [yearAtRetirement, setYearAtRetirement] = useState(0);
    const [monthsAtRetirement, setMonthsAtRetirement] = useState(0);
    const [diffMonthsAtRetirement, setDiffMonthsAtRetirement] = useState(0);
    const [lifeSituationRetirementLink, setLifeSituationRetirementLink] = useState('');
    const [currentIncome, setCurrentIncome] = useState(0);
    const [currentEmploymentLevel, setCurrentEmploymentLevel	] = useState(null);
    const [isRetirementPercentageLoading, setIsRetirementPercentageLoading] = useState<boolean>(false);
    const [isRetirementInfoLoading, setIsRetirementInfoLoading] = useState<boolean>(false);
    const [retirementPercentage, setRetirementPercentage] = useState(0);
    const retirementPercentageAbortController  = useRef<AbortController>(new AbortController());
    const infosOnRetirementAbortController= useRef<AbortController>(new AbortController());
    const today = new Date();

    useEffect(() => {
        if (birthdate === null) {
            return;
        }
        setAge58(new Date(birthdate.getFullYear() + 58, birthdate.getMonth(), birthdate.getDate()));
        setAge70(new Date(birthdate.getFullYear() + 70, birthdate.getMonth(), birthdate.getDate()));
    }, [birthdate]);

    useEffect(() => {
        if (age58 === null) {
            return;
        }

        setMinRetirementDate(today < age58 ? age58 : new Date(today.getFullYear(), today.getMonth() + 1, today.getDate()));
    }, [age58]);

    useEffect(() => {
        if (birthdate === null || retirementDate === null) {
            return;
        }
        setDiffMonthsAtRetirement((retirementDate.getMonth() + 12 * retirementDate.getFullYear()) - (birthdate.getMonth() + 12 * birthdate.getFullYear()));
    }, [birthdate, retirementDate]);

    useEffect(() => {
        setYearAtRetirement(Math.floor(diffMonthsAtRetirement / 12));
    }, [diffMonthsAtRetirement]);

    useEffect(() => {
        setMonthsAtRetirement(diffMonthsAtRetirement - (12 * yearAtRetirement));
    }, [yearAtRetirement, diffMonthsAtRetirement]);

    useEffect(() => {
        if (ordinaryRetirementDate === null) {
            return;
        }
        const retirementDateToday = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        setRetirementDate(today < ordinaryRetirementDate ? ordinaryRetirementDate : retirementDateToday);
    }, [ordinaryRetirementDate]);

    const validationSchema = Yup.object().shape({
        // @ts-ignore -> Yup is actually able to handle strings as date; it casts strings with new Date(string)
        retirementDate: Yup.date()
            .typeError(window.sv_resource.get('form_errormsg_date'))
            .min(minRetirementDate)
            .required(window.sv_resource.get('form_errormsg_date')) as StringSchema,
        partialRetirement: Yup.string().required(window.sv_resource.get('form_errormsg_string')),
        newIncome: Yup.number()
            .when([], {
                is: () => insuredWantsPartialRetirement(),
                then: baseIntegerSchema()
                    // New income need to be 20% less than the current income
                    .max(currentIncome * 0.8, window.sv_resource.get('plf_simulation_errormsg_new_income_max'))
                    .required(window.sv_resource.get('form_errormsg_integer'))
            }),
        newDegreeOfEmployment: Yup.number()
            .when([], {
                is: () => insuredWantsPartialRetirement(),
                then: basePercentageSchema()
                    .min(1, window.sv_resource.get('form_errormsg_percentage'))
                    .max(currentEmploymentLevel, window.sv_resource.get('plf_simulation_errormsg_new_degree_of_employment_max'))
            }),
        requestedRetirementPercentage: Yup.number()
            .when([], {
                is: () => insuredWantsPartialRetirement(),
                then: basePercentageSchema()
                    .min(20, window.sv_resource.get('plf_simulation_errormsg_requested_retirement_percentage_min'))
                    .max(retirementPercentage, window.sv_resource.get('plf_simulation_errormsg_requested_retirement_percentage_max'))
            }),
        liquidateAdditionalAccount: Yup.string()
            .when([], {
                is: () => insuredWantsPartialRetirement() && +balanceAdditionalAccount > 0 && false /* see https://intersim.atlassian.net/issues/MPKVP2-430 */,
                then: Yup.string()
                    .required(window.sv_resource.get('form_errormsg_string')),
            }),
        capitalWithdrawal: Yup.string()
            .test('required if visible', window.sv_resource.get('form_errormsg_string'), (value) => {
                return value === 'capitalWithdrawal-yes' || value === 'capitalWithdrawal-no';

            }),
        requestedWithdrawal: Yup.number()
            .when([], {
                is: () => insuredWantsCapitalWithdrawal() && (getMaxCapitalWithdrawal() > 0),
                then: baseIntegerSchema()
                    .test('isValid', window.sv_resource.get('plf_simulation_errormsg_max_capital_withdrawal'), val => val <= getMaxCapitalWithdrawal()),
            }),
        bridgingPension: Yup.string()
            .test('valid bridgingPension', window.sv_resource.get('form_errormsg_string'), (value) => {
                return value === 'bridgingPension-yes' || value === 'bridgingPension-no';
            }),
        requestedBridgingPension: Yup.number()
            .when([], {
                is: () => insuredWantsBridgingPension() && getMaxBridgingPension() > 0,
                then: baseIntegerSchema()
                    .test('isValid', window.sv_resource.get('plf_simulation_errormsg_max_bridging_pension'), val => val <= getMaxBridgingPension()),
            }),
    });

    const initialValues = Object({
        retirementDate: null,
        partialRetirement: '',
        liquidateAdditionalAccount: '',
        currentIncome: '',
        newIncome: '',
        currentDegreeOfEmployment: '',
        newDegreeOfEmployment: '',
        retirementPercentage: '',
        requestedRetirementPercentage: '',
        capitalWithdrawal: '',
        requestedWithdrawal: '',
        bridgingPension: '',
        requestedBridgingPension: '',
    });

    const onSubmit = (values) => {
        values.requestedWithdrawal = String(cleanIntegerValue(values.requestedWithdrawal));
        values.requestedBridgingPension = String(cleanIntegerValue(values.requestedBridgingPension));
        values.retirementPercentage = String(cleanPercentageValue(values.retirementPercentage));
        values.requestedRetirementPercentage = String(cleanPercentageValue(values.requestedRetirementPercentage));
        values.newIncome = String(cleanIntegerValue(values.newIncome));
        values.newDegreeOfEmployment = String(cleanPercentageValue(values.newDegreeOfEmployment))

        handleSubmit(values);
    };

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });

    const insuredWantsPartialRetirement = (): boolean => {
        return formik.values.partialRetirement === 'partialRetirement-yes';
    }

    const insuredWantsCapitalWithdrawal = (): boolean => {
        return formik.values.capitalWithdrawal === 'capitalWithdrawal-yes';
    }

    const insuredWantsBridgingPension = (): boolean => {
        return formik.values.bridgingPension === 'bridgingPension-yes';
    }

    // Returns true if the user clicked partial retirement and liquidateAdditionalAccount, false in all other cases
    const usesAdditionalAccount = (): boolean => {
        return !insuredWantsPartialRetirement() || formik.values.liquidateAdditionalAccount.toString() !== 'liquidateAdditionalAccount-no';
    }

    const getMaxCapitalWithdrawal = (): number => {
        return usesAdditionalAccount() ? +maxCapitalWithdrawal : +retirementCapital;
    }

    const getMaxBridgingPension = (): number => {
        if (insuredWantsCapitalWithdrawal() && getMaxCapitalWithdrawal() === cleanIntegerValue(formik.values.requestedWithdrawal)) {
            return 0;
        }

        return +maxBridgingPension;
    }

    const handleFetchAbortCatch = (error: any) => {
        if (error instanceof DOMException && error.name === 'AbortError') {
           return;
        }
        console.error(error);
    }

    useEffect(() => {
        fetch(getOrdinaryRetirementDateAction, {
            method: 'GET',
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    setOrdinaryRetirementDate(offsetDate(new Date(res.context.ordinaryRetirementDate)));
                } else {
                    setFailingResponse(res);
                }
            });
    }, []);

    const fetchRetirementInfo = () => {
        const isRequestedRetirementPercentageValid = validationSchema.fields.requestedRetirementPercentage.isValidSync(formik.values.requestedRetirementPercentage);

        if (
            !validationSchema.fields.retirementDate.isValidSync(formik.values.retirementDate)
            || (insuredWantsPartialRetirement() && formik.values.requestedRetirementPercentage !== '' && !isRequestedRetirementPercentageValid)
        ) {
            return;
        }

        infosOnRetirementAbortController.current.abort();
        infosOnRetirementAbortController.current = new AbortController();
        setIsRetirementInfoLoading(true);
        let params: PensionCalculatorFormRequestType = {
            retirementDate: formik.values.retirementDate,
        };

        if (
            insuredWantsPartialRetirement()
            && isRequestedRetirementPercentageValid
            && validationSchema.fields.newIncome.isValidSync(formik.values.newIncome)
            && validationSchema.fields.newDegreeOfEmployment.isValidSync(formik.values.newDegreeOfEmployment)
        ) {
            params.requestedRetirementPercentage = String(cleanPercentageValue(formik.values.requestedRetirementPercentage));
            params.retirementPercentage = String(retirementPercentage);
            params.newIncome = String(cleanIntegerValue(formik.values.newIncome));
            params.newDegreeOfEmployment = String(cleanPercentageValue(formik.values.newDegreeOfEmployment));
        }

        fetchRequestJob(getInfosOnRetirementDateAction, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
            signal: infosOnRetirementAbortController.current.signal,
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    setMaxCapitalWithdrawal(res.context.maxCapitalWithdrawal);
                    setRetirementCapital(res.context.retirementCapital);
                    setBalanceAdditionalAccount(res.context.balanceAdditionalAccount);
                    setMaxBridgingPension(res.context.maxBridgingPension);
                    setFailingResponse(null);
                    formik.validateForm();
                } else if (!res.success) {
                    setFailingResponse(res);
                }
                setIsRetirementInfoLoading(false);
            }).catch(error => {
            handleFetchAbortCatch(error);
        });
    };

    useEffect(() => {
        // Debouncing to prevent unnecessary requests.
        const timer = setTimeout(() => fetchRetirementInfo(),  400)

        return () => {
            clearTimeout(timer)
        }
    }, [formik.values.retirementDate, formik.values.requestedRetirementPercentage, formik.values.partialRetirement, formik.values.newDegreeOfEmployment, retirementPercentage]);


    useEffect(() => {
        fetch(getGeneralInfosAction, {
           method: 'POST',
           headers: {
               'Content-type': 'application/json; charset=UTF-8',
               'X-Csrf-Token': document.body.dataset.csrfToken,
           },
       }).then(res => res.json())
           .then(res => {
               if (res.success) {
                   setCurrentEmploymentLevel(res.context.currentEmploymentLevel);
                   setLifeSituationRetirementLink(res.context.linkToLifeSituationPension);
                   formik.values.currentIncome = formatCurrency(res.context.grossSalary);
                   formik.values.currentDegreeOfEmployment = (res.context.currentEmploymentLevel / 100).toLocaleString('en', {
                       maximumFractionDigits: 5,
                       style: 'percent',
                   });
                   setCurrentIncome(Number(res.context.grossSalary));
               } else if (!res.success) {
                   setFailingResponse(res);
               }
           });
    }, []);


    const fetchRetirementPercentage = () => {
        if (
            !validationSchema.fields.retirementDate.isValidSync(formik.values.retirementDate)
            || !validationSchema.fields.newDegreeOfEmployment.isValidSync(formik.values.newDegreeOfEmployment)
            || !validationSchema.fields.newIncome.isValidSync(formik.values.newIncome))
        {
            return;
        }
        retirementPercentageAbortController.current.abort();
        retirementPercentageAbortController.current = new AbortController();
        setIsRetirementPercentageLoading(true);

        const params = new URLSearchParams({
            retirementDate: formik.values.retirementDate,
            newIncome: String(cleanIntegerValue(formik.values.newIncome)),
            newDegreeOfEmployment: String(cleanPercentageValue(formik.values.newDegreeOfEmployment)),
        });

        fetch(
            `${getRetirementPercentageAction}?${params}`,
            { signal: retirementPercentageAbortController.current.signal }
        ).then(
            res => res.json()
        ).then(res => {
            if (res.success) {
                const retirementInPercentage = res.context.retirementPercentage.toLocaleString('en', {
                    maximumFractionDigits: 5,
                    style: 'percent',
                });
                setRetirementPercentage(Number(retirementInPercentage.replace('%', '')));
                formik.setFieldValue('retirementPercentage', retirementInPercentage).then(() => {
                    setIsRetirementPercentageLoading(false);
                    formik.validateForm();
                });
            } else if (!res.success) {
                setFailingResponse(res);
            }
        }).catch(error => {
            handleFetchAbortCatch(error);
        });
    };

    useEffect(() => {
        // Debouncing to prevent unnecessary requests.
        const timer = setTimeout(() => fetchRetirementPercentage(),  400)

        return () => {
            clearTimeout(timer)
        }
    }, [formik.values.retirementDate, formik.values.newIncome, formik.values.newDegreeOfEmployment]);

    const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        setRetirementDate(offsetDate(new Date(e.target.value)));
        formik.handleChange(e);
    };

    useEffect(() => {
        if (retirementDate === null) {
            return;
        }
        formik.values.retirementDate = getIsoDate(retirementDate);
    }, [retirementDate]);

    const handleIsValid = (isValid: boolean) => {
        setDatePickerError(isValid ? null : window.sv_resource.get('plf_simulation_errormsg_date_between_now_and_70'));
    };

    return (
        <div className={`${baseClassName}`}>
            <AsyncLoader
                action={getBirthdateAction}
                onSuccess={(res: JsonResponse<InitialDataType>) => {
                    setBirthdate(offsetDate(new Date(res.context.birthdate)));
                }}
                renderContent={lazyChildren => (
                    <>
                        <h2 id={headerId}>{window.sv_resource.get('plf_simulation_title')}</h2>
                        <div className={`${baseClassName}__lifeSituationRetirementLink`}>
                            <Link href={lifeSituationRetirementLink} title={window.sv_resource.get('plf_simulation_pension_calculation_lifesituation_link_label')}>
                                {window.sv_resource.get('plf_simulation_pension_calculation_lifesituation_link_label')}
                            </Link>
                        </div>
                        <Paragraph>{window.sv_resource.get('plf_simulation_pension_calculation_description')}</Paragraph>
                        {lazyChildren}
                    </>
                )}
                variation={StatusMessageVariation.Subpage}
            >
                {() => {
                    if (isLoading || formik.values.retirementDate === null) {
                        return (<LoadSpinner />);
                    }
                    if (failingResponse !== null && failingResponse.statusCode !== 409) {
                        return (<AnswerText response={failingResponse} />);
                    }
                    return (
                        <form name="pensionCalculationSimulation" onSubmit={formik.handleSubmit}>
                            <>
                                <div className="row gap-xxs">
                                    <DatePickerField
                                        className="col-12 col-lg-6"
                                        endOfMonth
                                        errors={datePickerError ?? formik.errors.retirementDate}
                                        icon={IconName.Calendar}
                                        id="retirementDate"
                                        label={window.sv_resource.get('plf_simulation_pension_calculation_label_retirement_date')}
                                        maxDate={age70}
                                        minDate={minRetirementDate}
                                        ref={datePickerRef}
                                        onChange={handleDateChange}
                                        onBlur={formik.handleBlur}
                                        onIsValid={handleIsValid}
                                        startView={CalenderStartView.Month}
                                        touched
                                        value={formik.values.retirementDate}
                                    />
                                    <div className="col-12 col-lg-6">
                                        <div className="row gap-xxs">
                                            <Label
                                                className={`${baseClassName}__label col-12`}
                                                htmlFor="retirementAgeYears"
                                            >
                                                {window.sv_resource.get('plf_simulation_pension_calculation_label_retirement_age')}
                                            </Label>
                                        </div>
                                        <div className="row gap-xxs">
                                            <TextField
                                                className="col-12 col-lg-6"
                                                disabled
                                                errors={null}
                                                id="retirementAgeYears"
                                                label=""
                                                name="retirementAgeYears"
                                                onBlur={null}
                                                onChange={null}
                                                touched={null}
                                                type="text"
                                                value={`${yearAtRetirement.toString()} ${window.sv_resource.get('plf_simulation_years')}`}
                                            />
                                            <TextField
                                                className="col-12 col-lg-6"
                                                disabled
                                                errors={null}
                                                id="retirementAgeMonth"
                                                label=""
                                                name="retirementAgeMonth"
                                                onBlur={null}
                                                onChange={null}
                                                touched={null}
                                                type="text"
                                                value={`${monthsAtRetirement.toString()} ${window.sv_resource.get('plf_simulation_month')}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {
                                    failingResponse !== null && failingResponse.statusCode === 409
                                        ? (
                                            <RichText className={`${baseClassName}__infoBirthdateAndRetirement`} text={failingResponse.context.description} />
                                        ) : (
                                            <>
                                                <div className={`${baseClassName}__radioRow row gap-xxs`}>
                                                    <Paragraph className="col-12 col-lg-6">{window.sv_resource.get('plf_simulation_pension_calculation_label_partial_retirement')}</Paragraph>
                                                    <div className={`${baseClassName}__radioWrapper col-12 col-lg-6`}>
                                                        <RadioButton
                                                            checked={formik.values.partialRetirement.toString() === 'partialRetirement-no'}
                                                            errors={formik.errors.partialRetirement}
                                                            id="partialRetirement-no"
                                                            name="partialRetirement"
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                            touched={!!formik.touched.partialRetirement}
                                                            label={window.sv_resource.get('plf_simulation_pension_calculation_label_partial_retirement_option_no')}
                                                        />
                                                        <RadioButton
                                                            checked={formik.values.partialRetirement.toString() === 'partialRetirement-yes'}
                                                            errors={formik.errors.partialRetirement}
                                                            id="partialRetirement-yes"
                                                            name="partialRetirement"
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                            touched={!!formik.touched.partialRetirement}
                                                            label={window.sv_resource.get('plf_simulation_pension_calculation_label_partial_retirement_option_yes')}
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    insuredWantsPartialRetirement() && (
                                                        <>
                                                            <div className="row gap-xxs">
                                                                <TextField
                                                                    className="col-12 col-lg-6"
                                                                    errors={formik.errors.currentIncome}
                                                                    icon={IconName.Chf}
                                                                    id="currentIncome"
                                                                    label={window.sv_resource.get('plf_simulation_pension_calculation_label_current_income')}
                                                                    name="currentIncome"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    touched={!!formik.touched.currentIncome}
                                                                    type="text"
                                                                    value={formik.values.currentIncome}
                                                                    disabled={true}
                                                                />
                                                                <TextField
                                                                    className="col-12 col-lg-6"
                                                                    errors={formik.errors.newIncome}
                                                                    icon={IconName.Chf}
                                                                    id="newIncome"
                                                                    label={window.sv_resource.get('plf_simulation_pension_calculation_label_new_income')}
                                                                    name="newIncome"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    touched={!!formik.touched.newIncome}
                                                                    type="text"
                                                                    value={formik.values.newIncome}
                                                                />
                                                                <TextField
                                                                    className="col-12 col-lg-6"
                                                                    errors={formik.errors.currentDegreeOfEmployment}
                                                                    id="currentDegreeOfEmployment"
                                                                    label={window.sv_resource.get('plf_simulation_pension_calculation_label_current_degree_of_employment')}
                                                                    name="currentDegreeOfEmployment"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    touched={!!formik.touched.currentDegreeOfEmployment}
                                                                    type="text"
                                                                    value={formik.values.currentDegreeOfEmployment}
                                                                    disabled={true}
                                                                />
                                                                <TextField
                                                                    className="col-12 col-lg-6"
                                                                    errors={formik.errors.newDegreeOfEmployment}
                                                                    id="newDegreeOfEmployment"
                                                                    label={window.sv_resource.get('plf_simulation_pension_calculation_label_new_degree_of_employment')}
                                                                    name="newDegreeOfEmployment"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    touched={!!formik.touched.newDegreeOfEmployment}
                                                                    type="text"
                                                                    value={formik.values.newDegreeOfEmployment}
                                                                />
                                                                <TextField
                                                                    className="col-12 col-lg-6"
                                                                    errors={formik.errors.retirementPercentage}
                                                                    id="retirementPercentage"
                                                                    label={window.sv_resource.get('plf_simulation_pension_calculation_label_retirement_percentage')}
                                                                    name="retirementPercentage"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    touched={!!formik.touched.retirementPercentage}
                                                                    type="text"
                                                                    value={isRetirementPercentageLoading ? '' : formik.values.retirementPercentage}
                                                                    disabled={true}
                                                                    isLoading={isRetirementPercentageLoading}
                                                                />
                                                                <TextField
                                                                    className="col-12 col-lg-6"
                                                                    errors={formik.errors.requestedRetirementPercentage}
                                                                    id="requestedRetirementPercentage"
                                                                    label={window.sv_resource.get('plf_simulation_pension_calculation_label_requested_retirement_percentage')}
                                                                    name="requestedRetirementPercentage"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    touched={!!formik.touched.requestedRetirementPercentage}
                                                                    type="text"
                                                                    value={formik.values.requestedRetirementPercentage}
                                                                />
                                                            </div>

                                                            { +balanceAdditionalAccount > 0 && false /* see https://intersim.atlassian.net/issues/MPKVP2-430 */ && (
                                                                <div className="row gap-xxs">
                                                                    <div
                                                                        className={`${baseClassName}__radioRow row gap-xxs`}>
                                                                        <Paragraph
                                                                            className="col-12 col-lg-6">{nl2br(window.sv_resource.get('plf_simulation_pension_calculation_label_liquidate_additional_account'))}</Paragraph>
                                                                        <div
                                                                            className={`${baseClassName}__radioWrapper col-12 col-lg-6 align-items-center`}>
                                                                            <RadioButton
                                                                                checked={formik.values.liquidateAdditionalAccount.toString() === 'liquidateAdditionalAccount-no' && false /* see https://intersim.atlassian.net/issues/MPKVP2-430 */}
                                                                                errors={formik.errors.liquidateAdditionalAccount}
                                                                                id="liquidateAdditionalAccount-no"
                                                                                name="liquidateAdditionalAccount"
                                                                                onBlur={formik.handleBlur}
                                                                                onChange={formik.handleChange}
                                                                                touched={!!formik.touched.liquidateAdditionalAccount}
                                                                                label={window.sv_resource.get('plf_simulation_pension_calculation_liquidate_additional_account_option_no')}
                                                                            />
                                                                            <RadioButton
                                                                                checked={formik.values.liquidateAdditionalAccount.toString() === 'liquidateAdditionalAccount-yes' || true /* see https://intersim.atlassian.net/issues/MPKVP2-430 */}
                                                                                errors={formik.errors.liquidateAdditionalAccount}
                                                                                id="liquidateAdditionalAccount-yes"
                                                                                name="liquidateAdditionalAccount"
                                                                                onBlur={formik.handleBlur}
                                                                                onChange={formik.handleChange}
                                                                                touched={!!formik.touched.liquidateAdditionalAccount}
                                                                                label={window.sv_resource.get('plf_simulation_pension_calculation_liquidate_additional_account_option_yes')}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                            }
                                                        </>
                                                    )
                                                }
                                                <div className={`${baseClassName}__radioRow row gap-xxs`}>
                                                    <Paragraph
                                                        className="strong col-12 col-lg-6">{window.sv_resource.get('plf_simulation_pension_calculation_label_capital_withdrawal')}</Paragraph>
                                                    <div
                                                        className={`${baseClassName}__radioWrapper col-12 col-lg-6`}>
                                                        <RadioButton
                                                            checked={formik.values.capitalWithdrawal.toString() === 'capitalWithdrawal-no'}
                                                            errors={formik.errors.capitalWithdrawal}
                                                            id="capitalWithdrawal-no"
                                                            name="capitalWithdrawal"
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                            touched={!!formik.touched.capitalWithdrawal}
                                                            label={window.sv_resource.get('plf_simulation_pension_calculation_label_partial_retirement_option_no')}
                                                        />
                                                        <RadioButton
                                                            checked={formik.values.capitalWithdrawal.toString() === 'capitalWithdrawal-yes'}
                                                            errors={formik.errors.capitalWithdrawal}
                                                            id="capitalWithdrawal-yes"
                                                            name="capitalWithdrawal"
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                            touched={!!formik.touched.capitalWithdrawal}
                                                            label={window.sv_resource.get('plf_simulation_pension_calculation_label_partial_retirement_option_yes')}
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    insuredWantsCapitalWithdrawal() && (
                                                        <div className="row gap-xxs">
                                                            <TextField
                                                                className="col-12 col-lg-6"
                                                                disabled
                                                                errors={null}
                                                                icon={IconName.Chf}
                                                                id="maxCapitalWithdrawal"
                                                                label={window.sv_resource.get('plf_simulation_pension_calculation_label_max_capital_withdrawal')}
                                                                name="maxCapitalWithdrawal"
                                                                onBlur={null}
                                                                onChange={null}
                                                                touched={null}
                                                                type="text"
                                                                value={isRetirementInfoLoading ? '' : formatCurrency(getMaxCapitalWithdrawal())}
                                                                isLoading={isRetirementInfoLoading}
                                                            />
                                                            { usesAdditionalAccount() && +balanceAdditionalAccount > 0 &&
                                                                <TextField
                                                                    className="col-12 col-lg-6"
                                                                    disabled
                                                                    errors={null}
                                                                    icon={IconName.Chf}
                                                                    id="capitalWithdrawalAdditionalAccountPart"
                                                                    label={window.sv_resource.get('plf_simulation_pension_calculation_label_capital_withdrawal_additional_account_part')}
                                                                    name="capitalWithdrawalAdditionalAccountPart"
                                                                    onBlur={null}
                                                                    onChange={null}
                                                                    touched={null}
                                                                    type="text"
                                                                    value={isRetirementInfoLoading ? '' : formatCurrency(+balanceAdditionalAccount)}
                                                                    isLoading={isRetirementInfoLoading}
                                                                />
                                                            }
                                                            <TextField
                                                                className="col-12 col-lg-6"
                                                                errors={formik.errors.requestedWithdrawal}
                                                                icon={IconName.Chf}
                                                                id="requestedWithdrawal"
                                                                label={window.sv_resource.get('plf_simulation_pension_calculation_label_requested_capital_withdrawal')}
                                                                name="requestedWithdrawal"
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                                onFocus={formik.handleBlur}
                                                                touched={!!formik.touched.requestedWithdrawal}
                                                                type="text"
                                                                value={formik.values.requestedWithdrawal}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    <div className={`${baseClassName}__radioRow row gap-xxs`}>
                                                        <div className="col-12 col-lg-6">
                                                            <Paragraph className="strong">{window.sv_resource.get('plf_simulation_pension_calculation_label_bridging_pension')}</Paragraph>
                                                        </div>
                                                        <div
                                                            className={`${baseClassName}__radioWrapper col-12 col-lg-6`}>
                                                            <RadioButton
                                                                checked={formik.values.bridgingPension.toString() === 'bridgingPension-no'}
                                                                errors={formik.errors.bridgingPension}
                                                                id="bridgingPension-no"
                                                                name="bridgingPension"
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                                touched={!!formik.touched.bridgingPension}
                                                                label={window.sv_resource.get('plf_simulation_pension_calculation_bridging_pension_option_no')}
                                                            />
                                                            <RadioButton
                                                                checked={formik.values.bridgingPension.toString() === 'bridgingPension-yes'}
                                                                errors={formik.errors.bridgingPension}
                                                                id="bridgingPension-yes"
                                                                name="bridgingPension"
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                                touched={!!formik.touched.bridgingPension}
                                                                label={window.sv_resource.get('plf_simulation_pension_calculation_bridging_pension_option_yes')}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                     insuredWantsBridgingPension() && (
                                                        <div className="row gap-xxs">
                                                            <TextField
                                                                className="col-12 col-lg-6"
                                                                disabled
                                                                errors={null}
                                                                icon={IconName.Chf}
                                                                id="maxBridgingPension"
                                                                label={window.sv_resource.get('plf_simulation_pension_calculation_label_max_bridging_pension')}
                                                                name="maxBridgingPension"
                                                                onBlur={null}
                                                                onChange={null}
                                                                touched={null}
                                                                type="text"
                                                                value={isRetirementInfoLoading ? '' : formatCurrency(getMaxBridgingPension())}
                                                                isLoading={isRetirementInfoLoading}
                                                            />
                                                            { getMaxBridgingPension() > 0 &&
                                                                <TextField
                                                                    className="col-12 col-lg-6"
                                                                    errors={formik.errors.requestedBridgingPension}
                                                                    icon={IconName.Chf}
                                                                    id="requestedBridgingPension"
                                                                    label={window.sv_resource.get('plf_simulation_pension_calculation_label_requested_bridging_pension')}
                                                                    name="requestedBridgingPension"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    onFocus={formik.handleBlur}
                                                                    touched={!!formik.touched.requestedBridgingPension}
                                                                    type="text"
                                                                    value={formik.values.requestedBridgingPension}
                                                                />
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                }

                                <div className={`${baseClassName}__submitWrapper row`}>
                                    <FormButton
                                        disabled={
                                            Object.keys(formik.errors).length > 0
                                            || Object.keys(formik.touched).length === 0
                                            || datePickerError !== null
                                            || (isRetirementInfoLoading && (insuredWantsCapitalWithdrawal() || insuredWantsBridgingPension()))
                                            || (isRetirementPercentageLoading && insuredWantsPartialRetirement())
                                        }
                                        hasIcon={false}
                                        text={window.sv_resource.get('plf_simulation_calc_button_label')}
                                        type="submit"
                                        variation={FormButtonVariation.Primary}
                                    />
                                </div>
                            </>
                        </form>
                    );
                }}
            </AsyncLoader>
        </div>
    );
};

export default PensionCalculatorSimulationForm;

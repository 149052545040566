import { loadWidget } from '@sitevision/react';
import React from 'react';
import ReactDOM from 'react-dom';
import PolicySelector from "./templates/organisms/policy-selector";

loadWidget('.plf-policyselector', policySelectorWidget => {
    const policyListAction = policySelectorWidget.dataset.policyListAction;
    const selectAction = policySelectorWidget.dataset.selectAction;
    ReactDOM.render(<PolicySelector policyListAction={policyListAction} selectAction={selectAction} />, policySelectorWidget);
});

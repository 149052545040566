import React, {useState} from 'react';
import * as Yup from 'yup';
import Checkbox from '../../../../../../../../design/1/js/templates/atoms/form-fields/checkbox';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import { IconName } from '../../../../../../../../design/1/js/templates/atoms/icon';
import RichText from '../../../../../../../../design/1/js/templates/atoms/richtext';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import './index.scss';
import FormWithAnswerText from '../../../../../../../../design/1/js/templates/organisms/form-with-answer-text';

interface OnboardingFormProps {
    actionUrl: string;
    agbText: string;
    swissIdEmail: string;
    handleSubmit: (status: number) => void,
    infoboxText: string;
}

const OnboardingForm = ({
    actionUrl, agbText, handleSubmit, infoboxText, swissIdEmail,
}:OnboardingFormProps) => {
    const baseClassName = 'w-onboardingForm';

    const [isLoading, setLoading] = useState(false);
    const int32MaxPositiveValue = 2147483647;

    const validationSchema = Yup.object().shape({
        id: Yup.number()
            .test('integer_check', window.sv_resource.get('form_errormsg_integer'), value => !value?.toString()?.includes('.'))
            .min(0, window.sv_resource.get('form_errormsg_integer'))
            .max(int32MaxPositiveValue, window.sv_resource.get('plf_onboarding_error_provide_id_message'))
            .required(window.sv_resource.get('form_errormsg_integer'))
            .typeError(window.sv_resource.get('form_errormsg_integer')),
        activationcode: Yup.string()
            .required(window.sv_resource.get('form_errormsg_string')),
        email: Yup.string()
            .email(window.sv_resource.get('form_errormsg_email'))
            .required(window.sv_resource.get('form_errormsg_email')),
        acceptedTerms: Yup.boolean()
            .oneOf([true], window.sv_resource.get('form_errormsg_terms'))
            .required(window.sv_resource.get('form_errormsg_terms')),
    });

    const initialValues = Object.freeze({
        id: null as number,
        activationcode: '',
        email: swissIdEmail,
        acceptedTerms: false,
    });

    return (
        <FormWithAnswerText
            actionUrl={actionUrl}
            className={`${baseClassName}`}
            handleSubmit={handleSubmit}
            beforeSubmit={() => {
                setLoading(true);
            }}
            handleValidate={validationSchema}
            initialValues={initialValues}
            name="Onboarding"
        >
            {formik => (
                <>
                    <div className="row gap-lg-s">
                        <div className={`${baseClassName}__form col-12 col-lg-6`}>
                            <TextField
                                id="id"
                                label={window.sv_resource.get('plf_onboarding_form_id_label')}
                                name="id"
                                type="number"
                                placeholder={window.sv_resource.get('plf_onboarding_form_id_placeholder')}
                                touched={formik.touched.id}
                                errors={formik.errors.id}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                disabled={isLoading}
                            />
                            <TextField
                                id="activationcode"
                                icon={IconName.Password}
                                label={window.sv_resource.get('plf_onboarding_form_activationcode_label')}
                                name="activationcode"
                                type="password"
                                placeholder={window.sv_resource.get('plf_onboarding_form_activationcode_placeholder')}
                                touched={formik.touched.activationcode}
                                errors={formik.errors.activationcode}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                disabled={isLoading}
                            />
                            <TextField
                                id="email"
                                icon={IconName.Mail}
                                label={window.sv_resource.get('plf_onboarding_form_email_label')}
                                name="email"
                                type="email"
                                placeholder={window.sv_resource.get('plf_onboarding_form_email_placeholder')}
                                touched={true}
                                errors={formik.errors.email}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                disabled={isLoading}
                                defaultValue={swissIdEmail}
                            />
                            <Checkbox
                                className={`${baseClassName}__checkbox`}
                                errors={formik.errors.acceptedTerms}
                                touched={formik.touched.acceptedTerms}
                                id="acceptedTerms"
                                name="acceptedTerms"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                label={agbText}
                                disabled={isLoading}
                            />
                            <FormButton
                                className={`${baseClassName}__formButton`}
                                disabled={Object.keys(formik.errors).length > 0 || Object.keys(formik.touched).length === 0 || isLoading}
                                text={window.sv_resource.get('plf_onboarding_submit_btn')}
                                type="submit"
                                variation={FormButtonVariation.Primary}
                                isLoading={isLoading}
                            />
                        </div>
                        <div className={`${baseClassName}__infobox-wrapper col-12 col-lg-6`}>
                            <div className={`${baseClassName}__infobox`}>
                                <RichText text={infoboxText} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </FormWithAnswerText>
    );
};

export default OnboardingForm;

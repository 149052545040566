import React from 'react';
import ReactDOM from 'react-dom';
import { loadWidget } from '@sitevision/react';
import Contactform from './templates/organisms/contactform';
import { ContactformType } from './templates/molecules/contactform-form';

loadWidget('.plf-contactform-area', contactform => {
    const actionURL = contactform.dataset.url;
    const formType: ContactformType = contactform.dataset.formType as ContactformType;
    const recaptchaKey: string = contactform.dataset.recaptchaKey;
    ReactDOM.render(<Contactform actionUrl={actionURL} formType={formType} recaptchaKey={recaptchaKey} />, contactform);
});

import React from 'react';
import './index.scss';
import BtnChevron, { ChevronDirection } from '../btn-chevron';
import { NavigationType } from '../../molecules/navigation';
import { IconName } from '../icon';
import IconButton from '../icon-button';
import NewsIndicatorOverlay from "../news-indicator-overlay";

export interface NavigationItemProps {
    active?: boolean;
    children?: React.ReactNode;
    className?: string;
    direction?: ChevronDirection,
    href?: string;
    icon?: IconName;
    news?: boolean;
    target?: string;
    text?: string,
    type?: NavigationType,
}

const NavigationItem = ({
    active,
    children,
    className,
    direction,
    href,
    icon,
    news,
    target,
    text,
    type,
}: NavigationItemProps) => {
    const baseClassName = 'a-navigation-item';
    const CustomTag = type === NavigationType.Main && children !== null ? 'div' : 'a';

    return (
        <li className={`${baseClassName} nav-item -${type} ${icon ? '-icon' : ''} ${className}`}>
            {children}
            <CustomTag href={href} target={target} className={`${baseClassName}__link ${active ? '-active' : ''}`}>
                {icon
                    ? (
                        <>
                            {news ? <NewsIndicatorOverlay />: ''}
                            <IconButton icon={icon} />
                        </>
                    )
                    : (
                        <>
                            <div className={`${baseClassName}__title`}>{text}</div>
                            <BtnChevron direction={direction} />
                        </>
                    )}
            </CustomTag>
        </li>
    );
};

NavigationItem.defaultProps = {
    active: false,
    children: null,
    className: '',
    direction: ChevronDirection.Down,
    icon: null,
    news: null,
    target: null,
    text: null,
    href: null,
    type: NavigationType.Main,
};

export default NavigationItem;

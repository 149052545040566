import React from 'react';
import './index.scss';

export enum BorderType {
    Solid = 'solid',
    Dotted = 'dotted',
}

interface InfoCircleProps {
    amount: string,
    border?: BorderType,
    currency: string,

}

const InfoCircle: React.FC<InfoCircleProps> = ({
    amount,
    border = BorderType.Solid,
    currency,
}) => {
    const baseClassName = 'a-info-circle';

    return (
        <div className={`${baseClassName} -${border}`}>
            <div className={`${baseClassName}__amount display2`}>{amount}</div>
            <div className={`${baseClassName}__currency`}>{currency}</div>
        </div>
    );
};

export default InfoCircle;

export default function makeTablesScrollable() {
    document.addEventListener('DOMContentLoaded', () => {
        const tables = document.querySelectorAll<HTMLElement>('.sv-widget-content table');
        for (const table of tables) {
            const scollableTableWrapper: HTMLElement = document.createElement('div');
            scollableTableWrapper.classList.add('scrollable');
            scollableTableWrapper.appendChild(table.cloneNode(true));
            table.replaceWith(scollableTableWrapper);
        }
    });
}

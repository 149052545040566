import React from 'react';
import './index.scss';
import { Disclosure } from '@headlessui/react';
import BtnChevron, { ChevronDirection } from '../../atoms/btn-chevron';
import AccordionData from '../accordion/lib/AccordionData';

interface AccordionItemProps {
    id?: string;
    item: AccordionData;
}

const AccordionItem = ({ id, item }: AccordionItemProps) => {
    const baseClassName = 'm-accordionItem';

    return (
        <div className={`${baseClassName}`} id={id}>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className={`${baseClassName}__button display3 ${open ? '-open' : ''}`}>
                            <span className={`${baseClassName}__title`}>{item.title}</span>
                            <span className={`${baseClassName}__chevronWrapper`}>
                                <BtnChevron
                                    className={`${baseClassName}__chevron ${open ? '-rotate' : ''}`}
                                    direction={ChevronDirection.Down}
                                    standalone
                                />
                            </span>
                        </Disclosure.Button>
                        <Disclosure.Panel className={`${baseClassName}__panel`} dangerouslySetInnerHTML={{ __html: item.description }} />
                    </>
                )}
            </Disclosure>
        </div>
    );
};

AccordionItem.defaultProps = {
    id: null,
};

export default AccordionItem;

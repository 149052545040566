import React from 'react';
import './index.scss';
import { Tab } from '@headlessui/react';
import InsuranceCertificate from '../../../lib/InsuranceCertificate';
import AccordionData from '../../../../../../../../design/1/js/templates/molecules/accordion/lib/AccordionData';
import Accordion from '../../../../../../../../design/1/js/templates/molecules/accordion';
import DocumentDot from '../document-dot';

interface TabPanelProps{
    certificate: InsuranceCertificate;
    onClick: (id: string) => void;
}

const TabPanel = ({ certificate, onClick }: TabPanelProps) => {
    const baseClassName = 'w-tabPanel';

    const accordions = certificate.dots.map((dot, index) => (
        {
            id: `${dot.title}-${index.toString()}`,
            title: `${(index + 1).toString()}: ${dot.title}`,
            description: dot.text,
        } as AccordionData
    ));

    return (
        <Tab.Panel className={`${baseClassName}`} unmount={false}>
            <div className={`${baseClassName}__imageBox`}>
                <img src={certificate.image} alt={certificate.title} />
                <div className={`${baseClassName}__dotBox`}>
                    {certificate.dots.map((dot, dotIndex) => (
                        <DocumentDot dot={dot} dotIndex={dotIndex} onClick={onClick} key={`dotWrapper-${dotIndex.toString()}`} />
                    ))}
                </div>
            </div>
            <div className={`${baseClassName}__accordions`}>
                <Accordion data={accordions} />
            </div>
        </Tab.Panel>
    );
};

export default TabPanel;

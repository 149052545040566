import React from 'react';
import SectionTitle from '../../../../../../../../design/1/js/templates/atoms/section-title';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import Card from '../../../../../../../../design/1/js/templates/molecules/card';
import InfoCircle from '../../../../../../../../design/1/js/templates/atoms/info-circle';
import Section from '../../../../../../../../design/1/js/templates/atoms/section';
import CardImage from '../../../../../../../../design/1/js/templates/atoms/card-image';
import { AsyncWidgetProps } from '../../../../../base/ts/lib/AsyncWidgetProps';
import { formatCurrency } from '../../../../../../../../design/1/js/lib/formatData';
import StatusMessageVariation
    from '../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation';
import ProvisionData from '../../../lib/ProvisionData';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import './index.scss';
import { getNextYear } from '../../../../../../../../design/1/js/lib/util/DateUtils';

const ProvisionsoverviewSection = ({ action }: AsyncWidgetProps) => {
    const baseClassName = 'w-provisionoverviewSection';
    const currentPlanText = window.sv_resource.get('plf_provisions_overview_saving_plan_insurance');
    const nextPlanText = window.sv_resource.get('plf_provisions_overview_saving_plan_next_year');

    return (
        <Section className={`${baseClassName}`}>
            <AsyncLoader
                action={action}
                renderContent={lazyChildren => (
                    <>
                        <SectionTitle frontpage headline level={2} title={window.sv_resource.get('plf_provisions_overview_title')} />
                        {lazyChildren}
                    </>
                )}
                variation={StatusMessageVariation.Homepage}
            >
                {(provisionData: ProvisionData) => (
                    <>
                        <div className={`${baseClassName}__savingPlanContainer`}>
                            <Paragraph>{currentPlanText.replace('{{savingplan}}', provisionData.savingPlans.current)}</Paragraph>
                            { provisionData.savingPlans.nextYear &&
                                <Paragraph>
                                    {nextPlanText
                                        .replace('{{savingplan}}', provisionData.savingPlans.nextYear)
                                        .replace('{{nextyear}}', String(getNextYear()))
                                    }
                                </Paragraph>
                            }
                        </div>
                        <div className={`${baseClassName}__wrapper row`}>
                            {provisionData.cards.map((card, index) => (
                                <div className="col-12 col-sm-6 col-lg-3" key={`card-wrapper-${index.toString()}`}>
                                    <Card title={card.title} type={card.type} url={card.url} btnTxt={card.btnText}>
                                        {card.circle ? (
                                            <InfoCircle amount={formatCurrency(+card.circle.amount)} currency={card.circle.currency} border={card.circle.border} />
                                        ) : (
                                            <CardImage image={card.image !== '' ? card.image : '/design/img/illustrations/fallback/vorsorgedokumente.svg'} cardName={card.title} />
                                        )}
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </AsyncLoader>
        </Section>
    );
};

export default ProvisionsoverviewSection;

import React from 'react';
import Category from '../../../lib/Category';
import Document from '../../../lib/Document';
import DocumentItem from '../../atoms/documentItem';

interface Props {
    className?: string;
    documents: Document[];
    selectedCategory: Category | null;
}

const DocumentList = ({ className, documents, selectedCategory }: Props) => {
    const baseClassName = 'w-document-documentList';

    return (
        <div className={`${baseClassName} ${className}`}>
            {documents
                .filter(document => selectedCategory === null || document.category.id === selectedCategory.id)
                .map(document => <DocumentItem key={document.id} document={document} />)}
        </div>
    );
};

DocumentList.defaultProps = {
    className: '',
};

export default DocumentList;

import { loadWidget } from '@sitevision/react';
import React from 'react';
import ReactDOM from 'react-dom';
import ServicesFormArea from './lib/ServicesFormArea';
import ChangeOfAddress from './templates/organisms/change-of-address';
import CivilStateDeceasedPartner from './templates/organisms/civil-state-deceased-partner';
import CivilStateMarriagePartnership from './templates/organisms/civil-state-marriage-partnership';
import ChangeOfAccount from './templates/organisms/change-of-account';

loadWidget(`.plf-services-form-area[data-area="${ServicesFormArea.CivilStateDeceasedPartner}"]`, civilStateChangeWidget => {
    const actionUrl: string = civilStateChangeWidget.dataset.actionUrl;
    ReactDOM.render(<CivilStateDeceasedPartner actionUrl={actionUrl} />, civilStateChangeWidget);
});

loadWidget(`.plf-services-form-area[data-area="${ServicesFormArea.CivilStateMarriagePartnership}"]`, civilStateChangeMarriagePartnershipWidget => {
    const actionUrl: string = civilStateChangeMarriagePartnershipWidget.dataset.actionUrl;
    const unavailableAction: string = civilStateChangeMarriagePartnershipWidget.dataset.unavailableAction;
    ReactDOM.render(<CivilStateMarriagePartnership actionUrl={actionUrl} unavailableAction={unavailableAction} />, civilStateChangeMarriagePartnershipWidget);
});

loadWidget(`.plf-services-form-area[data-area="${ServicesFormArea.ChangeOfAddress}"]`, changeOfAddressWidget => {
    const actionUrl: string = changeOfAddressWidget.dataset.actionUrl;
    const zipByZipAction: string = changeOfAddressWidget.dataset.zipByZipAction;
    const zipByCityAction: string = changeOfAddressWidget.dataset.zipByCityAction;
    const cityByCityAction: string = changeOfAddressWidget.dataset.cityByCityAction;
    const initialDataAction = changeOfAddressWidget.dataset.initialDataAction;
    const cityByZipAction: string = changeOfAddressWidget.dataset.cityByZipAction;
    ReactDOM.render(<ChangeOfAddress actionUrl={actionUrl} cityByCityAction={cityByCityAction} cityByZipAction={cityByZipAction} initialDataAction={initialDataAction} zipByCityAction={zipByCityAction} zipByZipAction={zipByZipAction} />, changeOfAddressWidget);
});

loadWidget(`.plf-services-form-area[data-area="${ServicesFormArea.ChangeOfAccount}"]`, changeOfAccountWidget => {
    const actionUrl: string = changeOfAccountWidget.dataset.actionUrl;
    const initialDataAction = changeOfAccountWidget.dataset.initialDataAction;
    ReactDOM.render(<ChangeOfAccount actionUrl={actionUrl} initialDataAction={initialDataAction} />, changeOfAccountWidget);
});

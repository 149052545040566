import React from "react";
import SectionTitle from "../../../../../../../../design/1/js/templates/atoms/section-title";
import PolicyCardData from "../../../lib/PolicyCardData";
import Section from "../../../../../../../../design/1/js/templates/atoms/section";
import AsyncLoader from "../../../../../../../../design/1/js/templates/molecules/async-loader";
import PolicyCard from "../../molecules/policy-card";


interface Props {
    policyListAction: string,
    selectAction: string,
}

const PolicySelector = ({policyListAction, selectAction}: Props) => {
    const baseClassName = 'w-policySelector-policySelector';

    return (
        <Section className={baseClassName}>
            <AsyncLoader
                action={policyListAction}
                renderContent={(lazyChildren) => (
                    <>
                        <SectionTitle title={window.sv_resource.get('plf_policyselector_header')} />
                        {lazyChildren}
                    </>
                )}
            >
                {(policyCards: PolicyCardData[]) => (
                    <div className={`${baseClassName}__wrapper row justify-content-around`}>
                        {policyCards.map((card) => (
                            <div className="col-12 col-sm-6 col-lg-3" key={card.insuredId}>
                                <PolicyCard  card={card} selectAction={selectAction} />
                            </div>
                        ))}
                    </div>
                )}
            </AsyncLoader>

        </Section>
    )
}

export default PolicySelector;

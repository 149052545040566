import React, { ReactNode, useEffect, useState } from 'react';
import ErrorContext from '../../../lib/entity/response/ErrorContext';
import JsonResponse from '../../../lib/entity/response/JsonResponse';
import MessageContext from '../../../lib/entity/response/MessageContext';
import LoadSpinner from '../../atoms/load-spinner';
import StatusMessage, { MessageType } from '../status-message';
import StatusMessageVariation from '../status-message/lib/StatusMessageVariation';
import { fetchRequestJob } from "../../../lib/util/requestJobUtils";

interface AsyncLoaderProps<T> {
    action: string;
    autoscroll?: boolean;
    children: (data: T) => ReactNode;
    onFailure?: (response: JsonResponse<MessageContext>) => void;
    onSuccess?: (response: JsonResponse<T>) => void;
    renderContent?: (lazyChildren: ReactNode, isLoading: boolean) => ReactNode;
    variation?: StatusMessageVariation;
    requestJob: boolean,
}

const AsyncLoader = <T extends any>({
    action, autoscroll, children, onFailure, onSuccess, renderContent = (lazyChildren) => lazyChildren, variation, requestJob
}: AsyncLoaderProps<T>) => {
    const [response, setResponse] = useState<JsonResponse<T | MessageContext>>(null);

    useEffect(() => {
        (requestJob ? fetchRequestJob : fetch)(action, {
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        }).then(res => res.json())
            .then(res => {
                if (res.success && onSuccess !== undefined) {
                    onSuccess(res);
                } else if (!res.success && onFailure !== undefined) {
                    onFailure(res);
                }

                setResponse(res);
            });
    }, []);

    if (response === null) {
        return (
            <>
                {renderContent(<LoadSpinner autoscroll={autoscroll} />, true)}
            </>
        );
    }

    if (response.success) {
        return (
            <>
                {renderContent(children !== undefined && children((response.context as T)), false)}
            </>
        );
    }

    // An error has occurred
    return (
        <StatusMessage
            additionalText={(response.context as MessageContext).message}
            description={(response.context as ErrorContext).description ?? window.sv_resource.get('plf_error_try_again_later')}
            inline
            messageType={(response.context as ErrorContext).informational ? MessageType.Info : (!response.success ? MessageType.Error : MessageType.Success)}
            traceId={!response.success ? response.traceId : undefined}
            title={(response.context as ErrorContext).title ?? window.sv_resource.get('plf_error_unknown')}
            variation={variation}
        />
    );
};

AsyncLoader.defaultProps = {
    autoscroll: false,
    onFailure: undefined,
    onSuccess: undefined,
    variation: StatusMessageVariation.Standard,
    requestJob: false,
};

export default AsyncLoader;

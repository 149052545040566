import React from 'react';
import SectionTitle from '../../../../../../../../design/1/js/templates/atoms/section-title';
import Section from '../../../../../../../../design/1/js/templates/atoms/section';
import Card from '../../../../../../../../design/1/js/templates/molecules/card';
import ServiceCard from '../../../lib/ServiceCard';
import CardImage from '../../../../../../../../design/1/js/templates/atoms/card-image';

interface ServiceProps {
    cards: ServiceCard[];
}

const ServicesSection = ({ cards }: ServiceProps) => {
    const baseClassName = 'm-service-section';

    const cardElements = cards.map((card, index) => (
        <div className="col-12 col-sm-6 col-lg-3" key={`card-wrapper-${index.toString()}`}>
            <Card title={card.title} type={card.type} url={card.url} video={card.video}>
                <CardImage image={card.image ? card.image : '/design/img/illustrations/fallback/kontoaenderung.svg'} cardName={card.title} />
            </Card>
        </div>
    ));

    return (
        <Section className={`${baseClassName}`}>
            <SectionTitle frontpage headline level={2} title={window.sv_resource.get('plf_services_title')} />
            <div className={`${baseClassName}__wrapper row`}>
                {cardElements}
            </div>
        </Section>
    );
};

export default ServicesSection;

import React from 'react';
import './index.scss';
import { FormikErrors, FormikValues } from 'formik';
import RichText from '../../richtext';

interface CheckboxProps {
    checked?: boolean;
    className?: string;
    disabled?: boolean;
    errors: string | string[] | FormikErrors<FormikValues> | FormikErrors<FormikValues>[];
    id: string;
    label?: string;
    name: string;
    onBlur: (e) => void;
    onChange: (e) => void;
    touched: boolean;
}

const Checkbox = (props: CheckboxProps) => {
    const baseClassName = 'a-checkbox';

    const handleChange = e => {
        const name = props.name;
        props.onChange(e);
        setTimeout(() => props.onBlur({ target: { name } }), 0);
    };

    return (
        <>
            <div className={`${baseClassName} ${props.className}`}>
                <input
                    checked={props.checked}
                    disabled={props.disabled}
                    id={props.id}
                    name={props.name}
                    type="checkbox"
                    onChange={handleChange}
                />
                { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                <label htmlFor={props.id} />
                <RichText className={`${baseClassName}__text light-text`} text={props.label} />
            </div>
            {props.errors && props.touched && <div className={`${baseClassName}__msg small-text`}>{props.errors}</div>}
        </>
    );
};

Checkbox.defaultProps = {
    checked: undefined,
    className: '',
    disabled: false,
    label: '',
};

export default Checkbox;

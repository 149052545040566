import React, { useState } from 'react';
import _ from 'lodash';
import './index.scss';
import Section from '../../../../../../../../design/1/js/templates/atoms/section';
import SectionTitle from '../../../../../../../../design/1/js/templates/atoms/section-title';
import Variation from '../../../lib/Variation';
import Accordion from '../../../../../../../../design/1/js/templates/molecules/accordion';
import Category from '../../../../../../../../design/1/js/templates/molecules/accordion/lib/Category';
import CategorySelector from '../../molecules/category-selector';
import AccordionData from '../../../../../../../../design/1/js/templates/molecules/accordion/lib/AccordionData';

interface Props {
    hasFilter: boolean;
    headerId: string;
    data: AccordionData[];
    title: string;
    categories: Category[];
    variation: Variation;
}

const FaqSection = ({
    hasFilter, headerId, data, title, categories, variation,
}: Props) => {
    const baseClassName = 'w-faqSection';

    const [selectedCategory, setSelectedCategory] = useState<Category>(null);

    const hasTitle = title.trim().length > 0;

    const getStyledTitle = () => {
        switch (variation) {
        case Variation.Subpage:
            return <SectionTitle id={headerId} level={2} title={title} />;
        case Variation.Login:
        case Variation.Homepage:
            return <SectionTitle frontpage id={headerId} headline level={2} title={title} />;
        default:
            return <SectionTitle id={headerId} title={title} />;
        }
    };

    return (
        <Section className={`${baseClassName} ${hasTitle ? '-hasTitle' : ''} -variation${_.capitalize(variation)}`}>
            {hasTitle && getStyledTitle()}
            {hasFilter && (
                <CategorySelector
                    className={`${baseClassName}__categorySelector`}
                    onSelectCategory={setSelectedCategory}
                    data={data}
                    selectedCategory={selectedCategory}
                    categories={categories}
                />
            )}
            <Accordion className={`${baseClassName}__accordion`} data={data} selectedCategory={selectedCategory} />
        </Section>
    );
};

export default FaqSection;

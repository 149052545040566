import React, { useState } from 'react';
import IntroductionText from '../../../../../../../../design/1/js/templates/molecules/introduction-text';
import VoluntaryContinuationForm from '../voluntary-continuation-form';

interface VoluntaryContinuationProps {
    formAction: string;
    masterDataAction: string;
}

const VoluntaryContinuation = ({ formAction, masterDataAction }: VoluntaryContinuationProps) => {
    const [formSubmit, setFormSubmit] = useState(false);
    const baseClassName = 'w-voluntaryContinuation';

    const handleFormSubmit = () => {
        setFormSubmit(!formSubmit);
    };

    return (
        <div className={`${baseClassName}`}>
            { !formSubmit && <IntroductionText description={window.sv_resource.get('plf_onlineform_voluntary_continuation_description')} title={window.sv_resource.get('plf_onlineform_voluntary_continuation_title')} /> }
            <div className={`${baseClassName}__wrapper`}>
                <VoluntaryContinuationForm formAction={formAction} handleSubmit={handleFormSubmit} masterDataAction={masterDataAction} />
            </div>
        </div>
    );
};

export default VoluntaryContinuation;

import React, { ComponentProps } from 'react';

export interface LabelProps extends ComponentProps<'label'> {
    className?: string;
    htmlFor: string;
}

const Label = ({ children, className, htmlFor }: LabelProps) => (
    <label
        htmlFor={htmlFor}
        className={`a-label light-text ${className}`}
    >
        {children}
    </label>
);

Label.defaultProps = {
    className: '',
};

export default Label;

import React from 'react';
import './index.scss';
import * as Yup from 'yup';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import RadioButton from '../../../../../../../../design/1/js/templates/atoms/form-fields/radiobutton';
import FileUploadField from '../../../../../../../../design/1/js/templates/molecules/file-upload-field';
import { StepProps } from '../../../lib/StepProps';
import FileContent from '../../../../../../../../design/1/js/lib/FileContent';
import { validateIBAN } from '../../../../../../../../design/1/js/lib/validateData';
import StepNavigation from "../step-navigation";

export interface TransferVestedBenefitsInstitutionType {
    institution: string;
    newEmployer: string;
    pensionFundNewEmployer: string;
    addressPensionFundNewEmployer: string;
    ibanPensionFundNewEmployer: string;
    vestedBenefitsInstitution: string;
    nameOfOtherVestedBenefitsInstitution: string;
    ibanOfOtherVestedBenefitsInstitution: string;
    files: FileContent[];
}

export const validationSchemaTransferVestedBenefitsInstitutionStep = Yup.object().shape({
    institution: Yup.string().required(() => window.sv_resource.get('form_errormsg_radio')),
    newEmployer: Yup.string()
        .when('institution', {
            is: 'pensionFundNewEmployer',
            then: Yup.string().required(() => window.sv_resource.get('form_errormsg_string')),
        }),
    pensionFundNewEmployer: Yup.string()
        .when('institution', {
            is: 'pensionFundNewEmployer',
            then: Yup.string().required(() => window.sv_resource.get('form_errormsg_string')),
        }),
    addressPensionFundNewEmployer: Yup.string()
        .when('institution', {
            is: 'pensionFundNewEmployer',
            then: Yup.string().required(() => window.sv_resource.get('form_errormsg_string')),
        }),
    ibanPensionFundNewEmployer: Yup.string()
        .when('institution', {
            is: 'pensionFundNewEmployer',
            then: Yup.string()
                .test(
                    'valid iban',
                    () => window.sv_resource.get('form_errormsg_iban'),
                    value => (validateIBAN(value)),
                )
                .required(() => () => window.sv_resource.get('form_errormsg_iban')),
        }),
    vestedBenefitsInstitution: Yup.string()
        .when('institution', {
            is: 'vestedBenefitsInstitution',
            then: Yup.string().required(() => window.sv_resource.get('form_errormsg_string')),
        }),
    nameOfOtherVestedBenefitsInstitution: Yup.string()
        .when(['institution', 'vestedBenefitsInstitution'], {
            is: (institution, vestedBenefitsInstitution) => institution === 'vestedBenefitsInstitution' && vestedBenefitsInstitution === 'vestedBenefitsInstitutionOther',
            then: Yup.string().required(() => window.sv_resource.get('form_errormsg_string')),
        }),
    ibanOfOtherVestedBenefitsInstitution: Yup.string()
        .when(['institution', 'vestedBenefitsInstitution'], {
            is: (institution, vestedBenefitsInstitution) => institution === 'vestedBenefitsInstitution' && vestedBenefitsInstitution === 'vestedBenefitsInstitutionOther',
            then: Yup.string().test(
                'valid iban',
                () => window.sv_resource.get('form_errormsg_iban'),
                value => (validateIBAN(value)),
            )
                .required(() => window.sv_resource.get('form_errormsg_iban')),
        }),
    files: Yup.array()
        .when(['institution', 'vestedBenefitsInstitution'], {
            is: (institution, vestedBenefitsInstitution) => institution === 'pensionFundNewEmployer' || vestedBenefitsInstitution === 'vestedBenefitsInstitutionOther',
            then: Yup.array().of(
                Yup.object().shape({
                    file: Yup.string(),
                    fileName: Yup.string(),
                    fileType: Yup.string(),
                }),
            ).min(1, () => window.sv_resource.get('form_errormsg_upload'))
                .required(() => window.sv_resource.get('form_errormsg_upload')),
        }),
});

const TransferVestedBenefitsInstitutionStep = (({
    disabled, errors, handleBack, handleFileChange, id, touched, onChange, onBlur, values, isFirstStep
}: StepProps<TransferVestedBenefitsInstitutionType>) => {
    const baseClassName = 'w-transferVestedBenefitsInstitutionStep';

    const valuesAreSet = values.institution === 'pensionFundNewEmployer'
        ? (values.newEmployer !== ''
            && values.pensionFundNewEmployer !== ''
            && values.addressPensionFundNewEmployer !== ''
            && values.ibanPensionFundNewEmployer !== ''
            && values.files.length > 0
        ) : (
            (
                values.vestedBenefitsInstitution === 'vestedBenefitsInstitutionOther'
                && values.nameOfOtherVestedBenefitsInstitution !== ''
                && values.ibanOfOtherVestedBenefitsInstitution !== ''
                && values.files.length > 0
            ) || (
                values.vestedBenefitsInstitution === 'foundationBVG'
            )
        );

    const renderContent = () => {
        if (values.institution.toString() === 'pensionFundNewEmployer') {
            return (
                <>
                    <div className="row gap-xxs">
                        <TextField
                            className="col-12 col-lg-6"
                            disabled={disabled}
                            errors={errors.newEmployer}
                            id="newEmployer"
                            label={window.sv_resource.get('plf_onlineform_transfer_new_company')}
                            name="newEmployer"
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder=""
                            touched={touched.newEmployer}
                            type="text"
                            value={values.newEmployer}
                        />
                    </div>
                    <div className="row gap-xxs">
                        <TextField
                            className="col-12 col-lg-6"
                            disabled={disabled}
                            errors={errors.pensionFundNewEmployer}
                            id="pensionFundNewEmployer"
                            label={window.sv_resource.get('plf_onlineform_transfer_pension_funds')}
                            name="pensionFundNewEmployer"
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder=""
                            touched={touched.pensionFundNewEmployer}
                            type="text"
                            value={values.pensionFundNewEmployer}
                        />
                        <TextField
                            className="col-12 col-lg-6"
                            disabled={disabled}
                            errors={errors.addressPensionFundNewEmployer}
                            id="addressPensionFundNewEmployer"
                            label={window.sv_resource.get('plf_onlineform_transfer_pension_funds_address')}
                            name="addressPensionFundNewEmployer"
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder=""
                            touched={touched.addressPensionFundNewEmployer}
                            type="text"
                            value={values.addressPensionFundNewEmployer}
                        />
                        <TextField
                            className="col-12 col-lg-6"
                            disabled={disabled}
                            errors={errors.ibanPensionFundNewEmployer}
                            id="ibanPensionFundNewEmployer"
                            label={window.sv_resource.get('plf_onlineform_transfer_iban')}
                            name="ibanPensionFundNewEmployer"
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder=""
                            touched={touched.ibanPensionFundNewEmployer}
                            type="text"
                            value={values.ibanPensionFundNewEmployer}
                        />
                    </div>
                    <div className="row gap-xxs">
                        <Paragraph className="strong">{window.sv_resource.get('plf_onlineform_documents_description')}</Paragraph>
                        <FileUploadField
                            accept="application/pdf, image/jpeg, image/png"
                            disabled={disabled}
                            label={window.sv_resource.get('plf_onlineform_transfer_document')}
                            onChange={handleFileChange}
                            id="files"
                            placeholder=""
                            multiple
                            name="files"
                            errors={touched.files ? errors.files as string : ''}
                            onBlur={onBlur}
                            touched={!!touched.files}
                            value={values.files}
                        />
                    </div>
                </>
            );
        }
        if (values.institution.toString() === 'vestedBenefitsInstitution') {
            return (
                <>
                    <div className={`${baseClassName}__radioSubWrapper`}>
                        <RadioButton
                            checked={values.vestedBenefitsInstitution.toString() === 'vestedBenefitsInstitutionOther'}
                            disabled={disabled}
                            errors={errors.institution}
                            id="vestedBenefitsInstitutionOther"
                            name="vestedBenefitsInstitution"
                            onBlur={onBlur}
                            onChange={onChange}
                            touched={touched.institution}
                            label={window.sv_resource.get('plf_onlineform_transfer_institution_vested_benefits_institution_option_other')}
                        />
                        <RadioButton
                            checked={values.vestedBenefitsInstitution.toString() === 'foundationBVG'}
                            disabled={disabled}
                            errors={errors.institution}
                            id="foundationBVG"
                            name="vestedBenefitsInstitution"
                            onBlur={onBlur}
                            onChange={onChange}
                            touched={touched.institution}
                            label={window.sv_resource.get('plf_onlineform_transfer_institution_option_foundation_bvg')}
                        />
                    </div>

                    {
                        values.vestedBenefitsInstitution.toString() === 'vestedBenefitsInstitutionOther'
                        && (
                            <div className={`${baseClassName}__vestedBenefitsInstitutionOther`}>
                                <div className="row gap-xxs">
                                    <TextField
                                        className="col-12 col-lg-6"
                                        disabled={disabled}
                                        errors={errors.nameOfOtherVestedBenefitsInstitution}
                                        id="nameOfOtherVestedBenefitsInstitution"
                                        label={window.sv_resource.get('plf_onlineform_transfer_institution_vested_benefits_institution_name')}
                                        name="nameOfOtherVestedBenefitsInstitution"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        placeholder=""
                                        touched={touched.nameOfOtherVestedBenefitsInstitution}
                                        type="text"
                                        value={values.nameOfOtherVestedBenefitsInstitution}
                                    />
                                    <TextField
                                        className="col-12 col-lg-6"
                                        disabled={disabled}
                                        errors={errors.ibanOfOtherVestedBenefitsInstitution}
                                        id="ibanOfOtherVestedBenefitsInstitution"
                                        label={window.sv_resource.get('plf_onlineform_transfer_institution_vested_benefits_institution_iban')}
                                        name="ibanOfOtherVestedBenefitsInstitution"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        placeholder=""
                                        touched={touched.ibanOfOtherVestedBenefitsInstitution}
                                        type="text"
                                        value={values.ibanOfOtherVestedBenefitsInstitution}
                                    />
                                </div>
                                <div className="row gap-xxs">
                                    <Paragraph className="strong">{window.sv_resource.get('plf_onlineform_documents_description')}</Paragraph>
                                    <FileUploadField
                                        accept="application/pdf, image/jpeg, image/png"
                                        disabled={disabled}
                                        label={window.sv_resource.get('plf_onlineform_transfer_institution_vested_benefits_institution_document')}
                                        onChange={handleFileChange}
                                        id="files"
                                        placeholder=""
                                        multiple
                                        name="files"
                                        errors={touched.files ? errors.files as string : ''}
                                        onBlur={onBlur}
                                        touched={!!touched.files}
                                        value={values.files}
                                    />
                                </div>
                            </div>
                        )
                    }
                </>
            );
        }
        return '';
    };

    return (
        <div className={`${baseClassName}`}>
            <h2 className={`${baseClassName}__title`} id={id}>{window.sv_resource.get('plf_onlineform_transfer_cash_title')}</h2>
            <div className="row gap-xxs">
                <Paragraph className="strong">{window.sv_resource.get('plf_onlineform_transfer_description')}</Paragraph>
            </div>
            <div className="row gap-xxs">
                <div className={`${baseClassName}__radioWrapper`}>
                    <RadioButton
                        checked={values.institution.toString() === 'pensionFundNewEmployer'}
                        disabled={disabled}
                        errors={errors.institution}
                        id="pensionFundNewEmployer"
                        name="institution"
                        onBlur={onBlur}
                        onChange={onChange}
                        touched={touched.institution}
                        label={window.sv_resource.get('plf_onlineform_transfer_institution_option_new_company')}
                    />
                    <RadioButton
                        checked={values.institution.toString() === 'vestedBenefitsInstitution'}
                        disabled={disabled}
                        errors={errors.institution}
                        id="vestedBenefitsInstitution"
                        name="institution"
                        onBlur={onBlur}
                        onChange={onChange}
                        touched={touched.institution}
                        label={window.sv_resource.get('plf_onlineform_transfer_institution_option_vested_benefits_institution')}
                    />
                </div>
            </div>
            { renderContent() }
            <StepNavigation valuesAreSet={valuesAreSet} disabled={disabled} isFirstStep={isFirstStep} hasErrors={Object.keys(errors).length > 0} handleBack={handleBack} />
        </div>
    );
});

export default TransferVestedBenefitsInstitutionStep;

import React, {useState} from 'react';
import './index.scss';
import {toast} from 'react-hot-toast';
import JsonResponse from '../../../../../../../../design/1/js/lib/entity/response/JsonResponse';
import formatErrorToastMessage from '../../../../../../../../design/1/js/lib/formatErrorToastMessage';
import RichText from '../../../../../../../../design/1/js/templates/atoms/richtext';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import ToggleSwitch from '../../../../../../../../design/1/js/templates/atoms/form-fields/toggle-switch';
import NotificationType from '../../../lib/entity/NotificationType';
import NotificationActionType from '../../../lib/entity/NotificationActionType';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import {AsyncWidgetProps} from '../../../../../base/ts/lib/AsyncWidgetProps';
import StatusMessage, {MessageType} from "../../../../../../../../design/1/js/templates/molecules/status-message";
import StatusMessageVariation
    from "../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation";
import {hasInsuredMultipleIds} from "../../../../../../../../design/1/js/lib/util/InsuredUtils";

interface NotificationsProps extends AsyncWidgetProps {
    notificationActions: NotificationActionType;
    className?: string;
}

const Notifications = ({
    action,
    notificationActions,
    className,
}: NotificationsProps) => {
    const baseClassName = 'w-yourProfile-notifications';

    const [isNewDocumentsEnabled, setNewDocumentsEnabled] = useState(false);
    const [isNewMessagesEnabled, setNewMessagesEnabled] = useState(false);

    const sendRequest = (enabled: boolean, actionTo: string): Promise<JsonResponse> => (
        fetch(actionTo, {
            method: 'POST',
            body: JSON.stringify(enabled),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        }).then(res => res.json())
    );

    const handleChangeNewDocuments = async (enabled: boolean) => {
        setNewDocumentsEnabled(enabled);
        const response = await sendRequest(enabled, notificationActions.newDocuments);

        if (response.success) {
            toast.success(window.sv_resource.get('plf_yourprofile_notifications_new_documents_request_success'));
        } else {
            toast.error(formatErrorToastMessage(window.sv_resource.get('plf_yourprofile_notifications_new_documents_request_error'), response.traceId));
            setNewDocumentsEnabled(!enabled);
        }
    };

    const handleChangeNewMessages = async (enabled: boolean) => {
        setNewMessagesEnabled(enabled);
        const response = await sendRequest(enabled, notificationActions.newMessages);

        if (response.success) {
            toast.success(window.sv_resource.get('plf_yourprofile_notifications_new_messages_request_success'));
        } else {
            toast.error(formatErrorToastMessage(window.sv_resource.get('plf_yourprofile_notifications_new_messages_request_error'), response.traceId));
            setNewMessagesEnabled(!enabled);
        }
    };

    return (
        <div className={`${baseClassName} ${className}`}>
            <AsyncLoader
                action={action}
                onSuccess={res => {
                    setNewDocumentsEnabled(res.context.newDocuments.state === 'on');
                    setNewMessagesEnabled(res.context.newMessages.state === 'on');
                }}
                renderContent={lazyChildren => (
                    <>
                        <h2 id={window.sv_resource.get('plf_yourprofile_notifications_title')}>{window.sv_resource.get('plf_yourprofile_notifications_title')}</h2>
                        <RichText className={`${baseClassName}__description`} inline={false} text={window.sv_resource.get('plf_yourprofile_notifications_description')} />
                        {lazyChildren}
                    </>
                )}
            >
                {(notifications: NotificationType) => (
                    <>
                        <div className="row gap-xxs">
                            <Paragraph className="light-text col-5 col-sm-6">{notifications.newDocuments.label}</Paragraph>
                            <ToggleSwitch
                                checked={isNewDocumentsEnabled}
                                className="col-7 col-sm-6"
                                onChange={handleChangeNewDocuments}
                                states={notifications.newDocuments.options}
                            />
                            <Paragraph className="light-text col-5 col-sm-6">{notifications.newMessages.label}</Paragraph>
                            <ToggleSwitch
                                checked={isNewMessagesEnabled}
                                className="col-7 col-sm-6"
                                onChange={handleChangeNewMessages}
                                states={notifications.newMessages.options}
                            />
                        </div>
                        {hasInsuredMultipleIds() && (
                            <div className="row gap-xxs">
                                <StatusMessage
                                    customMessageColor={MessageType.Success}
                                    messageType={MessageType.Info}
                                    description={window.sv_resource.get('plf_yourprofile_notifications_description_multiple_insured_ids')}
                                    variation={StatusMessageVariation.WithBackground}
                                />
                            </div>
                        )}
                    </>
                )}
            </AsyncLoader>
        </div>
    );
};

Notifications.defaultProps = {
    className: '',
};

export default Notifications;

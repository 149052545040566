import React, {useState} from 'react';
import './index.scss';
import OnboardingForm from '../../molecules/onboarding-form';
import IntroductionText from '../../../../../../../../design/1/js/templates/molecules/introduction-text';
import JsonResponse from '../../../../../../../../design/1/js/lib/entity/response/JsonResponse';
import MessageContext from '../../../../../../../../design/1/js/lib/entity/response/MessageContext';
import RichText from "../../../../../../../../design/1/js/templates/atoms/richtext";

interface OnboardingProps {
    actionUrl: string;
    agbText: string;
    infoboxText: string;
    swissIdEmail: string;
    onboardingLink: string;
    swissIdEmailText: string;
    homepageLinkAction: string;
}

const Onboarding = ({
    actionUrl, agbText, infoboxText, swissIdEmail, onboardingLink, swissIdEmailText, homepageLinkAction,
}: OnboardingProps) => {
    const [formSubmit, setFormSubmit] = useState(false);
    const baseClassName = 'w-onboarding';

    const handleFormSubmit = (status: number) => {
        setFormSubmit(!formSubmit);

        // Don't redirect after error, otherwise the user can't read the message.
        if (status !== 200) {
            return;
        }

        setTimeout(async () => {
            const response: JsonResponse<MessageContext> = await fetch(homepageLinkAction, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'X-Csrf-Token': document.body.dataset.csrfToken,
                },
            }).then(res => res.json());

            window.location.href = response.context.message;
        }, 5000);
    };

    return (
        <div className={`${baseClassName}`}>
            { !formSubmit ? <IntroductionText description={window.sv_resource.get('plf_onboarding_introduction_description')} title={window.sv_resource.get('plf_onboarding_introduction_title')} /> : <></> }
            { !formSubmit ? <RichText text={swissIdEmailText} className={`${baseClassName}__swissid-email-warning`} /> : <></> }
            <div className={`${baseClassName}-wrapper`}>
                <OnboardingForm actionUrl={actionUrl} agbText={agbText} handleSubmit={handleFormSubmit} infoboxText={infoboxText} swissIdEmail={swissIdEmail} />
            </div>
        </div>
    );
};

export default Onboarding;

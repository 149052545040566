import React from 'react';
import './index.scss';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import Form from '../../../../../../../../design/1/js/templates/organisms/form';
import Button, { ButtonType } from '../../../../../../../../design/1/js/templates/atoms/button';
import AccountDataType from '../../../lib/entity/AccountDataType';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import Select from '../../../../../../../../design/1/js/templates/atoms/form-fields/select';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import { AsyncWidgetProps } from '../../../../../base/ts/lib/AsyncWidgetProps';
import { formatIBAN } from '../../../../../../../../design/1/js/lib/formatData';
import StatusMessage, {MessageType} from "../../../../../../../../design/1/js/templates/molecules/status-message";
import StatusMessageVariation
    from "../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation";
import {hasInsuredMultipleIds} from "../../../../../../../../design/1/js/lib/util/InsuredUtils";

interface AccountDataProps extends AsyncWidgetProps{
    className?: string;
}

const AccountData = ({
    action,
    className,
}: AccountDataProps) => {
    const baseClassName = 'w-yourProfile-accountData';

    return (
        <div className={`${baseClassName} ${className}`}>
            <AsyncLoader
                action={action}
                renderContent={lazyChildren => (
                    <>
                        <h2 id={window.sv_resource.get('plf_yourprofile_account_title')}>{window.sv_resource.get('plf_yourprofile_account_title')}</h2>
                        <Paragraph>{window.sv_resource.get('plf_yourprofile_account_data_description')}</Paragraph>
                        {lazyChildren}
                    </>
                )}
            >
                {(accountData: AccountDataType) => {
                    const initialValues = Object.freeze({
                        iban: formatIBAN(accountData.iban?.value),
                        bicSwift: accountData.bicSwift?.value,
                        currency: accountData.currency?.value,
                        financialInstitution: accountData.financialInstitution?.value,
                        street1: accountData.street1?.value,
                        street2: accountData.street2?.value,
                        zip: accountData.zip?.value,
                        city: accountData.city?.value,
                        country: accountData.country?.value,
                    });

                    return (
                        <Form actionUrl="/" name="accountForm" initialValues={initialValues} validationSchema={null}>
                            {formik => (
                                <>
                                    <div className="row gap-xxs">
                                        {accountData.iban && (
                                            <TextField
                                                className={`${baseClassName}__field col-12 col-lg-6`}
                                                disabled
                                                errors={formik.errors.iban}
                                                id="iban"
                                                label={accountData.iban.label}
                                                name="iban"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                placeholder={formik.values.iban}
                                                touched={formik.touched.iban}
                                                type="text"
                                                value={formik.values.iban}
                                            />
                                        )}
                                        {accountData.bicSwift && (
                                            <TextField
                                                className={`${baseClassName}__field col-12 col-lg-6`}
                                                disabled
                                                errors={formik.errors.bicSwift}
                                                id="bic"
                                                label={accountData.bicSwift?.label}
                                                name="bic"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                placeholder={formik.values.bicSwift}
                                                touched={formik.touched.bicSwift}
                                                type="text"
                                                value={formik.values.bicSwift}
                                            />
                                        )}
                                    </div>
                                    <div className="row gap-xxs ">
                                        {accountData.currency && (
                                            <Select
                                                className={`${baseClassName}__field col-12 col-lg-6`}
                                                disabled
                                                id={accountData.currency?.id}
                                                label={accountData.currency?.label}
                                                name={accountData.currency?.id}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.currency}
                                            >
                                                <option value={accountData.currency?.value}>{accountData.currency?.value}</option>
                                            </Select>
                                        )}
                                    </div>
                                    <div className="row gap-xxs">
                                        {accountData.financialInstitution && (
                                            <TextField
                                                className={`${baseClassName}__field col-12 col-lg-6`}
                                                disabled
                                                errors={formik.errors.financialInstitution}
                                                id="financialInstitution"
                                                label={accountData.financialInstitution?.label}
                                                name="financialInstitution"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                placeholder={formik.values.financialInstitution}
                                                touched={formik.touched.financialInstitution}
                                                type="text"
                                                value={formik.values.financialInstitution}
                                            />
                                        )}
                                    </div>
                                    <div className={`${baseClassName}__addressRow row gap-xxs`}>
                                        {accountData.street1 && (
                                            <TextField
                                                className={`${baseClassName}__field col-12 col-lg-6`}
                                                disabled
                                                errors={formik.errors.street1}
                                                id="street1"
                                                label={accountData.street1?.label}
                                                name="street1"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                placeholder={formik.values.street1}
                                                touched={formik.touched.street1}
                                                type="text"
                                                value={formik.values.street1}
                                            />
                                        )}
                                        {accountData.street2 && (
                                            <TextField
                                                className={`${baseClassName}__field col-12 col-lg-6`}
                                                disabled
                                                errors={formik.errors.street2}
                                                id="street2"
                                                label={accountData.street2?.label}
                                                name="street2"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                placeholder={formik.values.street2}
                                                touched={formik.touched.street2}
                                                type="text"
                                                value={formik.values.street2}
                                            />
                                        )}
                                        {accountData.zip && (
                                            <TextField
                                                className={`${baseClassName}__field col-4 col-lg-2`}
                                                disabled
                                                errors={formik.errors.zip}
                                                id="zip"
                                                label={accountData.zip?.label}
                                                name="zip"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                placeholder={formik.values.zip}
                                                touched={formik.touched.zip}
                                                type="text"
                                                value={formik.values.zip}
                                            />
                                        )}
                                        {accountData.city && (
                                            <TextField
                                                className={`${baseClassName}__field col-8 col-lg-4`}
                                                disabled
                                                errors={formik.errors.city}
                                                id="city"
                                                label={accountData.city?.label}
                                                name="city"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                placeholder={formik.values.city}
                                                touched={formik.touched.city}
                                                type="text"
                                                value={formik.values.city}
                                            />
                                        )}
                                        {accountData.country && (
                                            <Select
                                                className={`${baseClassName}__field col-12 col-lg-6`}
                                                disabled
                                                id={accountData.country?.id}
                                                label={accountData.country?.label}
                                                name={accountData.country?.id}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.country}
                                            >
                                                <option value={accountData.country?.value}>{accountData.country?.value}</option>
                                            </Select>
                                        )}
                                        <div className="col-12 col-lg-6">
                                            <Button
                                                className={`${baseClassName}__button`}
                                                href={accountData.accountChange.link}
                                                text={accountData.accountChange.label}
                                                type={ButtonType.Orange}
                                            />
                                        </div>
                                    </div>
                                    {hasInsuredMultipleIds() && (
                                        <div className="col-12 mb-5">
                                            <StatusMessage
                                                customMessageColor={MessageType.Info}
                                                messageType={MessageType.Info}
                                                description={window.sv_resource.get('plf_yourprofile_account_data_description_multiple_insured_ids')}
                                                variation={StatusMessageVariation.WithBackground}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </Form>
                    );
                }}
            </AsyncLoader>
        </div>
    );
};

AccountData.defaultProps = {
    className: '',
};

export default AccountData;

import React from 'react';
import './index.scss';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import Card from '../../../../../../../../design/1/js/templates/molecules/card';
import InfoCircle from '../../../../../../../../design/1/js/templates/atoms/info-circle';
import SectionTitle from '../../../../../../../../design/1/js/templates/atoms/section-title';
import Section from '../../../../../../../../design/1/js/templates/atoms/section';
import CardImage from '../../../../../../../../design/1/js/templates/atoms/card-image';
import BenefitsAtAGlanceType from '../../../lib/BenefitsAtAGlanceType';
import { AsyncWidgetProps } from '../../../../../base/ts/lib/AsyncWidgetProps';
import { formatCurrency } from '../../../../../../../../design/1/js/lib/formatData';
import StatusMessageVariation
    from '../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation';

const BenefitsAtAGlanceSection = ({ action }: AsyncWidgetProps) => {
    const baseClassName = 'w-benefits-at-a-glance-section';

    return (
        <Section className={`${baseClassName}`}>
            <AsyncLoader
                action={action}
                renderContent={lazyChildren => (
                    <>
                        <SectionTitle frontpage headline level={2} title={window.sv_resource.get('plf_benefits_at_a_glance_title')} />
                        {lazyChildren}
                    </>
                )}
                variation={StatusMessageVariation.Homepage}
            >
                {({ cards, explanation }: BenefitsAtAGlanceType) => (
                    <div className={`${baseClassName}__wrapper row`}>
                        {cards.map((card, index) => (
                            <div className="col-12 col-sm-6 col-lg-3" key={`card-wrapper-${index.toString()}`}>
                                <Card title={card.title} type={card.type} url={card.url}>
                                    {card.circle
                                        ? <InfoCircle amount={formatCurrency(+card.circle.amount)} currency={card.circle.currency} border={card.circle.border} />
                                        : <CardImage image={card.image !== '' ? card.image : '/design/img/illustrations/fallback/retirement.svg'} cardName={card.title} />}
                                </Card>
                            </div>
                        ))}
                        <div className={`${baseClassName}__explanation col-12 col-sm-12 col-lg-6`}>
                            {explanation}
                        </div>
                    </div>
                )}
            </AsyncLoader>
        </Section>
    );
};

export default BenefitsAtAGlanceSection;

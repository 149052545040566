import React, { useState } from 'react';
import './index.scss';
import Paragraph from '../../atoms/paragraph';
import Button, { ButtonType } from '../../atoms/button';
import SectionTitle from '../../atoms/section-title';
import Section from '../../atoms/section';
import ButtonInfos from '../../atoms/button/lib/ButtonInfos';
import Modal from '../modal';
import RichText from '../../atoms/richtext';

export enum BoxType {
    Login = 'login',
    Register = 'register',
}

interface BoxProps {
    title: string;
    intro: string;
    btnLeft: ButtonInfos;
    btnRight: ButtonInfos;
    modal: boolean;
    oneColumn?: boolean;
    type: BoxType;
}

const FrontPageBox = ({
    btnLeft,
    btnRight,
    intro,
    modal,
    oneColumn,
    title,
    type,
} : BoxProps) => {
    const baseClassName = 'm-frontpage-box';
    const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
    const [isVideoModalOpen, setVideoModalOpen] = useState(false);

    const [videoLink, setVideoLink] = useState('');

    const openLoginWithSwissIDModal = () => {
        setVideoLink(window.sv_resource.get('video_popup_link_with_swissid'));
        displayVideoModal();
    };

    const openLoginWithoutSwissIDModal = () => {
        setVideoLink(window.sv_resource.get('video_popup_link_without_swissid'));
        displayVideoModal();
    };

    const displayVideoModal = () => {
        setRegisterModalOpen(false);
        setVideoModalOpen(true);
    };

    return (
        <Section className={`${baseClassName} -${type}`} oneColumn={oneColumn}>
            <SectionTitle frontpage headline level={2} title={title} />
            <div className={`${baseClassName}__wrapper`}>
                <Paragraph className={`${baseClassName}__text`}>{intro}</Paragraph>
                <div className={`${baseClassName}__buttons`}>
                    { modal
                        ? <Button handleClick={() => setRegisterModalOpen(true)} text={btnLeft.label} type={btnLeft.type} />
                        : <Button href={btnLeft.url} target="_self" text={btnLeft.label} type={btnLeft.type} />
                    }
                    {
                        type === BoxType.Register && <Button handleClick={() => setRegisterModalOpen(true)} text={window.sv_resource.get('frontpage_register_btn_video_text')} type={ButtonType.White} />
                    }
                    <Button href={btnRight.url} target="_self" text={btnRight.label} type={btnRight.type} />
                </div>
            </div>
            {modal && (
                <Modal isOpen={isRegisterModalOpen} setIsOpen={setRegisterModalOpen} title={window.sv_resource.get('register_popup_title')}>
                    <p>
                        {window.sv_resource.get('register_popup_intro')}
                    </p>
                    <div className="row gap-lg-s m-frontpage-box__outerWrapper">
                        <div className="col-12 col-lg-6">
                            <div className="m-frontpage-box__wrapper">
                                <h2>{window.sv_resource.get('register_popup_sid_lead')}</h2>
                                <div className="m-frontpage-box__buttons">
                                    <Button type={ButtonType.SwissID} text={window.sv_resource.get('register_popup_sid_btn_text')} href={window.sv_resource.get('register_popup_sid_btn_link')} />
                                    <Button handleClick={() => openLoginWithSwissIDModal()} text={window.sv_resource.get('register_popup_video_btn_text')} type={ButtonType.White} />
                                    <Button type={ButtonType.White} text={window.sv_resource.get('register_popup_sid_pdf_text')} href={window.sv_resource.get('register_popup_sid_pdf_link')} target="_blank" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="m-frontpage-box__wrapper">
                                <h2>{window.sv_resource.get('register_popup_n_sid_lead')}</h2>
                                <div className="m-frontpage-box__buttons">
                                    <Button type={ButtonType.SwissID} text={window.sv_resource.get('register_popup_n_sid_btn_text')} href={window.sv_resource.get('register_popup_n_sid_btn_link')} />
                                    <Button handleClick={() => openLoginWithoutSwissIDModal()} text={window.sv_resource.get('register_popup_video_btn_text')} type={ButtonType.White} />
                                    <Button type={ButtonType.White} text={window.sv_resource.get('register_popup_n_sid_pdf_text')} href={window.sv_resource.get('register_popup_n_sid_pdf_link')} target="_blank" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>
                        <strong>{window.sv_resource.get('register_popup_help_lead')}</strong>
                        <br />
                        <RichText text={window.sv_resource.get('register_popup_help_text')} />
                    </p>
                </Modal>
            )}

            {modal && (
                <Modal isOpen={isVideoModalOpen} setIsOpen={setVideoModalOpen} title={window.sv_resource.get('video_popup_title')}>
                    <div className="row gap-lg-s m-frontpage-box__outerWrapper">
                        <iframe className={`${baseClassName}__videoFrame`} title={window.sv_resource.get('video_popup_title')} src={videoLink} frameBorder="0" allowFullScreen />
                    </div>
                </Modal>
            )}
        </Section>
    );
};

FrontPageBox.defaultProps = {
    oneColumn: true,
};

export default FrontPageBox;

import React, { useState } from 'react';
import ChangeOfAddressForm from '../../molecules/change-of-address-form';
import IntroductionText from '../../../../../../../../design/1/js/templates/molecules/introduction-text';

interface ChangeOfAddressProps {
    actionUrl: string;
    cityByCityAction: string;
    cityByZipAction: string;
    initialDataAction: string;
    zipByCityAction: string;
    zipByZipAction: string;
}

const ChangeOfAddress = ({
    actionUrl, cityByCityAction, cityByZipAction, initialDataAction, zipByCityAction, zipByZipAction,
}: ChangeOfAddressProps) => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const baseClassName = 'w-changeOfAddress';

    const handleFormSubmit = () => {
        setFormSubmitted(!formSubmitted);
    };

    return (
        <div className={`${baseClassName}`}>
            { !formSubmitted && (
                <IntroductionText
                    description={window.sv_resource.get('plf_servicesform_changeofaddress_introduction_description')}
                    title={window.sv_resource.get('plf_servicesform_changeofaddress_introduction_title')}
                />
            )}
            <div className={`${baseClassName}-wrapper`}>
                <ChangeOfAddressForm actionUrl={actionUrl} cityByCityAction={cityByCityAction} cityByZipAction={cityByZipAction} initialDataAction={initialDataAction} handleSubmit={handleFormSubmit} zipByCityAction={zipByCityAction} zipByZipAction={zipByZipAction} />
            </div>
        </div>
    );
};

export default ChangeOfAddress;

import React from 'react';
import './index.scss';
import ChevronRight from '../icon/icons/ChevronRight';
import ChevronDown from '../icon/icons/ChevronDown';
import ChevronLeft from '../icon/icons/ChevronLeft';

export enum ChevronDirection {
    Right = 'right',
    Down = 'down',
    Left = 'left',
}

interface Props {
    className?: string;
    direction: ChevronDirection,
    standalone?: boolean,
}

const BtnChevron = ({ className, direction, standalone }: Props) => {
    const baseClassName = 'a-btn-chevron';

    const standaloneClass = standalone ? '-standalone' : '';

    const chooseChevron = () => {
        switch (direction) {
        case ChevronDirection.Right:
            return <ChevronRight />;
        case ChevronDirection.Down:
            return <ChevronDown />;
        case ChevronDirection.Left:
            return <ChevronLeft />;
        default:
            return <ChevronRight />;
        }
    };

    return (
        <div className={`${baseClassName} ${standaloneClass}`}>
            <div className={`${baseClassName}__wrapper`}>
                <div className={`${baseClassName}__icon-up ${className}`}>
                    {chooseChevron()}
                </div>
                <div className={`${baseClassName}__icon-down ${className}`}>
                    {chooseChevron()}
                </div>
            </div>
        </div>
    );
};

BtnChevron.defaultProps = {
    className: '',
    standalone: false,
};

export default BtnChevron;

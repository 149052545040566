import React, { forwardRef, useMemo } from 'react';
import Label from '../../atoms/form-fields/label';
import './index.scss';
import MpkDatePicker, { DatePickerProps } from '../../atoms/form-fields/date-picker';

interface DatePickerFieldProps extends DatePickerProps {
    label: string;
}

const DatePickerField = forwardRef<HTMLDivElement, DatePickerFieldProps>((props: DatePickerFieldProps, ref) => {
    const baseClassName = 'm-datePickerField';

    return (
        <div className={`${baseClassName} ${props.className}`}>
            {useMemo(() => (
                <Label
                    className={`${baseClassName}__label`}
                    htmlFor={props.id}
                >
                    {props.label}
                </Label>
            ), [baseClassName, props.id, props.label])}
            {useMemo(() => (
                <MpkDatePicker
                    className="col-6"
                    disabled={props.disabled}
                    endOfMonth={props.endOfMonth}
                    errors={props.errors}
                    icon={props.icon}
                    id={props.id}
                    maxDate={props.maxDate}
                    minDate={props.minDate}
                    ref={ref}
                    onBlur={props.onBlur}
                    onCalendarClose={props.onCalendarClose}
                    onChange={props.onChange}
                    onIsValid={props.onIsValid}
                    startView={props.startView}
                    touched={props.touched}
                    value={props.value}
                />
            ), [
                props.disabled,
                props.endOfMonth,
                props.errors,
                props.icon,
                props.id,
                props.maxDate,
                props.minDate,
                ref,
                props.onBlur,
                props.onCalendarClose,
                props.onChange,
                props.onIsValid,
                props.startView,
                props.touched,
                props.value,
            ])}
        </div>
    );
});

export default DatePickerField;

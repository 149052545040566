export const formatRetirementAge = (age: number) => {
    const months = (age % 1) * 12;
    const years = age - (age % 1);

    if (months === 0) {
       return years.toString();
    }

    return years.toString() + '/' + (months < 10 ? '0' : '') + months;
}

import React from 'react';
import './index.scss';
import TableHead from '../../../../../../../../design/1/js/templates/atoms/table-head';
import TableBody from '../../../../../../../../design/1/js/templates/atoms/table-body';
import Table from '../../../../../../../../design/1/js/templates/molecules/table';
import { formatCurrency } from '../../../../../../../../design/1/js/lib/formatData';
import RichText from '../../../../../../../../design/1/js/templates/atoms/richtext';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import PurchaseSimulationData from '../../../lib/purchase/PurchaseSimulationData';

export interface PurchaseSimulationCalculatedProps {
    handleCreatePdf: () => void;
    handleReset: () => void;
    simulationData: PurchaseSimulationData;
}

const PurchaseSimulationCalculated = ({ handleCreatePdf, handleReset, simulationData }: PurchaseSimulationCalculatedProps) => {
    const baseClassName = 'w-purchaseSimulationCalculated';

    return (
        <div className={baseClassName}>
            <>
                <Table>
                    <TableHead>
                        <div className="col-6 col-lg-8 text-start">{window.sv_resource.get('plf_simulation_table_head_input_requested_purchase')}</div>
                        <div className="col-3 col-lg-2">{window.sv_resource.get('plf_simulation_table_head_deadline')}</div>
                        <div className="col-3 col-lg-2">{window.sv_resource.get('plf_simulation_table_head_amount')}</div>
                    </TableHead>
                    <TableBody>
                        <div className="row">
                            <div className={`${baseClassName}__column col-6 col-lg-8`}>
                                {window.sv_resource.get('plf_simulation_purchase_label_requested_purchase')}
                            </div>
                            <div className={`${baseClassName}__column text-center col-3 col-lg-2`}>
                                {simulationData.inputData.deadline}
                            </div>
                            <div className={`${baseClassName}__column text-center col-3 col-lg-2`}>
                                {formatCurrency(simulationData.inputData.value)}
                                <span className={`${baseClassName}__currency small-text`}>{simulationData.inputData.currency}</span>
                            </div>
                        </div>
                    </TableBody>
                </Table>
                <Table scrollable>
                    <TableHead className={`${baseClassName}__tableHead`}>
                        <div className="col-3 col-lg-2 offset-4 offset-lg-6 justify-content-center"><RichText text={window.sv_resource.get('plf_simulation_purchase_calculated_table_head_before_calculation')} /></div>
                        <div className="col-3 col-lg-2"><RichText text={window.sv_resource.get('plf_simulation_purchase_calculated_table_head_after_calculation')} /></div>
                        <div className="col-2">{window.sv_resource.get('plf_simulation_purchase_calculated_table_head_dif')}</div>
                    </TableHead>
                    <TableBody>
                        {
                            simulationData.calculatedData.map((service, index) => (
                                <div className="row" key={`serviceRow-${index.toString()}`}>
                                    <div className="-alignleft col-4 col-lg-6">
                                        {service.title}
                                    </div>
                                    <div className="text-center col-3 col-lg-2">
                                        {formatCurrency(service.beforeValue)}
                                        <span className={`${baseClassName}__currency small-text`}>{service.currency}</span>
                                    </div>
                                    <div className="text-center col-3 col-lg-2">
                                        {formatCurrency(service.afterValue)}
                                        <span className={`${baseClassName}__currency small-text`}>{service.currency}</span>
                                    </div>
                                    <div className={`${baseClassName}__columnDiff text-center col-2`}>
                                        {service.difference}
                                    </div>
                                </div>
                            ))
                        }
                    </TableBody>
                </Table>
            </>
            <Paragraph className={`${baseClassName}__terms d-print-inline d-none`}>
                {window.sv_resource.get('plf_simulation_print_terms')}
            </Paragraph>
            <div className={`${baseClassName}__buttonWrapper d-print-none`}>
                <div>
                    <FormButton text={window.sv_resource.get('plf_simulation_calculated_btn_reset_label')} variation={FormButtonVariation.Secondary} type="button" hasIcon={false} handleClick={handleReset} />
                </div>
                <div className="d-flex">
                    <FormButton className={`${baseClassName}__requestButton`} text={window.sv_resource.get('plf_simulation_calculated_btn_pdf_save_label')} variation={FormButtonVariation.Primary} type="button" hasIcon={false} handleClick={handleCreatePdf} />
                </div>
            </div>
        </div>
    );
};

export default PurchaseSimulationCalculated;

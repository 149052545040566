import React, { ChangeEvent, forwardRef } from 'react';
import './index.scss';
import Icon, { IconName, IconPosition } from '../../atoms/icon';
import Label from '../../atoms/form-fields/label';

export interface SearchInputProps {
    id: string;
    name: string;
    placeholder: string;
    label: string;
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onSubmit?: () => void;
    onIconClick?: () => void;
    autoComplete?: string;
    className?: string;
    disabled?: boolean;
    icon?: IconName;
    iconPosition?: IconPosition,
    type?: string;
}

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>((props: SearchInputProps, ref) => {
    const baseClassName = 'm-searchInput';

    const handleSubmit = event => {
        if (event.key === 'Enter') {
            props.onSubmit();
        }
    }

    return (
        <>
            <div className={`${baseClassName} ${props.className} ${props.icon ? '-icon' : ''} ${props.icon && props.iconPosition === IconPosition.Right ? 'icon-right' : ''} `}>
                <Label
                    className={`${baseClassName}__label`}
                    htmlFor={props.id}
                >
                    {props.label}
                </Label>
                <input
                    autoComplete={props.autoComplete}
                    ref={ref}
                    disabled={props.disabled}
                    id={props.id}
                    name={props.name}
                    placeholder={props.placeholder}
                    tabIndex={0}
                    type={props.type}
                    value={props.value}
                    onChange={props.onChange}
                    onKeyDown={handleSubmit}
                />
                {props.icon && (
                    <Icon
                        name={props.icon}
                        className={`${baseClassName}__input-icon ${props.disabled && '-disabled'}`}
                        onClick={props.onIconClick}
                    />
                )}
            </div>
        </>
    );
});

SearchInput.defaultProps = {
    autoComplete: 'on',
    className: '',
    disabled: false,
    value: undefined,
    type: 'text',
    icon: IconName.Close,
    iconPosition: IconPosition.Right,
};

export default SearchInput;

import React from 'react';
import './index.scss';

interface TableProps {
    children: React.ReactNode;
    className?: string;
    scrollable?: boolean;
}

const Table = ({ children, className, scrollable }: TableProps) => {
    const baseClassName = 'a-table';

    return (
        <div className={`${baseClassName} ${className} ${scrollable ? '-scrollable' : ''}`}>
            {children}
        </div>
    );
};

Table.defaultProps = {
    className: '',
    scrollable: false,
};

export default Table;

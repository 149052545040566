import React from 'react';
import Simulation from '../simulation';
import PurchaseSimulationAdditionalAccountForm from '../purchase-simulation-additional-account-form';

interface PurchaseAdditionalAccountSimulationProps {
    action: string;
    getBirthdateAction: string;
    headerIdSimulation: string;
    linkToMessageTool: string;
    linkToRegulationsDocument: string;
    maxPurchaseAdditionalAccountAction: string;
}

const PurchaseSimulationAdditionalAccount = ({
    action, getBirthdateAction, headerIdSimulation, linkToMessageTool, linkToRegulationsDocument, maxPurchaseAdditionalAccountAction,
}: PurchaseAdditionalAccountSimulationProps) => {
    const baseClassName = 'w-purchaseSimulationAdditionalAccount';

    return (
        <div className={`${baseClassName}`}>
            <Simulation
                simulationForm={() => (
                    <PurchaseSimulationAdditionalAccountForm
                        actionUrl={action}
                        headerId={headerIdSimulation}
                        hidden={false}
                        getMaxPurchaseAction={maxPurchaseAdditionalAccountAction}
                        getBirthdateAction={getBirthdateAction}
                        linkToMessageTool={linkToMessageTool}
                        linkToRegulationsDocument={linkToRegulationsDocument}
                    />
                )}
            />
        </div>
    );
};

export default PurchaseSimulationAdditionalAccount;

import React, { useEffect, useRef, useState } from "react";
import './index.scss';
import * as Yup from 'yup';
import { StringSchema } from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import DatePickerField from '../../../../../../../../design/1/js/templates/molecules/date-picker-field';
import { IconName } from '../../../../../../../../design/1/js/templates/atoms/icon';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import { getIsoDate } from '../../../../../../../../design/1/js/lib/formatData';
import usePdfDownload from '../../../../../../../../design/1/js/lib/hooks/usePdfDownload';
import LoadSpinner from "../../../../../../../../design/1/js/templates/atoms/load-spinner";
import JsonResponse from "../../../../../../../../design/1/js/lib/entity/response/JsonResponse";

interface PensionCertificateSimulationFormProps {
    headerId: string;
    actionUrl: string;
    leavingDateAction: string;
    isLeavingPending: boolean;
}

const PensionCertificateSimulationForm = ({
    actionUrl, leavingDateAction, headerId, isLeavingPending,
}: PensionCertificateSimulationFormProps) => {
    const baseClassName = 'w-pensionCertificateSimulationForm';
    const [isLoading, setLoading] = useState(true);
    const [datePickerError, setDatePickerError] = useState(null);
    const datePickerRef = useRef<HTMLDivElement>(null);
    const [maxDatePickerDate, setMaxDatePickerDate] = useState<Date>(null);

    const validationSchema = Yup.object().shape({
        // @ts-ignore -> Yup is actually able to handle strings as date; it casts strings with new Date(string)
        simulationDate: Yup.date()
            .test('isFutureOrLeavingPending', window.sv_resource.get('form_errormsg_date_isNotInFuture'),
                (val: Date) =>
                    val.getTime() >= (new Date()).setHours(0, 0, 0, 0)
                    || isLeavingPending
            )
            .required(window.sv_resource.get('form_errormsg_date')) as StringSchema,
    });

    const initialValues = Object({
        simulationDate: getIsoDate(new Date()),
    });

    const onSubmit = usePdfDownload(actionUrl, setLoading, undefined, true);

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });
    useEffect(() => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 100);
        setMaxDatePickerDate(date);

        if (isLeavingPending) {
            fetch(leavingDateAction).then(res => res.json())
                .then(response => {
                    const date = new Date(response.context.leavingDate);
                    date.setHours(0, 0, 0, 0);
                    formik.values.simulationDate = getIsoDate(date);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, []);


    const handleIsValid = (isValid: boolean) => {
        setDatePickerError(isValid ? null : window.sv_resource.get('form_errormsg_date_between_now_and_date').replace('{{max}}', maxDatePickerDate.toLocaleDateString('de-CH')));
    };

    return (
        <div className={`${baseClassName}`}>
            <h2 id={headerId} className={`${isLoading ? 'd-none' : ''}`}>{window.sv_resource.get('plf_simulation_title')}</h2>
            { isLoading &&
                <LoadSpinner />
            }
            { !isLoading && !isLeavingPending &&
                <Paragraph>{window.sv_resource.get('plf_simulation_pension_certificate_description')}</Paragraph>
            }
            { !isLoading &&
                <form name="pensionCertificateSimulation" onSubmit={formik.handleSubmit}>
                    <>
                        <div className="row gap-xxs">
                            <DatePickerField
                                className="col-12 col-lg-6"
                                minDate={isLeavingPending ? null : new Date()}
                                maxDate={isLeavingPending ? null : maxDatePickerDate}
                                errors={datePickerError ?? formik.errors.simulationDate}
                                icon={IconName.Calendar}
                                id="simulationDate"
                                label={window.sv_resource.get('plf_simulation_pension_certificate_label_advance')}
                                ref={datePickerRef}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                onIsValid={handleIsValid}
                                touched
                                value={formik.values.simulationDate}
                                disabled={isLeavingPending}
                            />
                        </div>
                        <div className={`${baseClassName}__submitWrapper row`}>
                            <FormButton
                                disabled={Object.keys(formik.errors).length > 0 || datePickerError !== null || isLoading}
                                hasIcon={false}
                                text={window.sv_resource.get('plf_simulation_generate_button_label')}
                                type="submit"
                                variation={FormButtonVariation.Primary}
                            />
                        </div>
                    </>
                </form>
            }
        </div>
    );
};

export default PensionCertificateSimulationForm;

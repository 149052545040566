import {useCallback} from "react";
import {getBrowser} from "../util/BrowserUtils";
import {Browser} from "../entity/enum/Browser";

/**
 * When called, this function shows the MPK logo at the top and applies some styles.
 * The additional handling of displaying components is supposed to be done via d-print-none and d-print-inline/block.
 * For page-breaks, there is the PrintBreakPage component
 */
export default function usePrettyPrint(additionalCSS: string = '') {
    const browser = getBrowser();

    return useCallback(() => {
        const sheet = document.createElement('style');
        // language=css
        let prettyPrintCss = `
            @page { 
                margin: 2cm;
            } 
            
            @page:first { 
                margin: 0 2cm 0 2cm;
            }
            
            html { 
                font-size: 60%;
            }
            
            .d-pretty-print { 
                display: block !important;   
            }
            
            .print-brand-logo {
                height: 1.1cm;
            }
            
            .plf-header-print-pdf {
                margin-bottom: 1.1cm !important;
            }
            
            .plf-print-detail-section-safari .plf-footer-print-pdf {
                display: block !important;
                /* Fix hidden footer on Firefox: https://bugzilla.mozilla.org/show_bug.cgi?id=1695475 */
                margin-bottom: 60px;
            }

            .plf-print-detail-section .plf-footer-print-pdf {
                display: none !important;
            }
        `;
        // language=css
        const firefoxCss = `
            /* Minimize top-margin in our code since Firefox has default top-margin */
            .plf-header-print-pdf {
                margin-top: 0 !important;
            }

            /* Fix Firefox creating an extra blank end page (https://stackoverflow.com/a/50277075)  */
            .sv-view-wrapper {
                display: block !important;
                min-height: 100% !important;
            }
        `
        if (browser === Browser.Firefox) {
            prettyPrintCss += ' ' + firefoxCss;
        }
        prettyPrintCss += ' ' + additionalCSS;
        sheet.innerHTML = prettyPrintCss;
        
        document.body.appendChild(sheet);
        window.print();
        document.body.removeChild(sheet);
    }, [additionalCSS, browser]);
}

import React, { forwardRef, useMemo } from 'react';
import Label from '../../atoms/form-fields/label';
import TextArea, { TextAreaProps } from '../../atoms/form-fields/text-area';
import './index.scss';

interface TextareaFieldProps extends TextAreaProps {
    label: string;
}

const TextareaField = forwardRef<HTMLTextAreaElement, TextareaFieldProps>(({ label, ...props }: TextareaFieldProps, ref) => {
    const baseClassName = 'm-textArea';

    return (
        <div className={`${baseClassName} ${props.className}`}>
            {useMemo(() => (
                <Label
                    className={`${baseClassName}__label`}
                    htmlFor={props.id}
                >
                    {label}
                </Label>
            ), [baseClassName, props.id, label])}
            {useMemo(() => (
                <TextArea
                    className={`${baseClassName}__input`}
                    ref={ref}
                    disabled={props.disabled}
                    errors={props.errors}
                    id={props.id}
                    name={props.name}
                    onBlur={props.onBlur}
                    onChange={props.onChange}
                    onFocus={props.onFocus}
                    placeholder={props.placeholder}
                    touched={props.touched}
                    value={props.value}
                    rows={props.rows}
                />
            ), [
                baseClassName,
                ref,
                props.disabled,
                props.errors,
                props.id,
                props.name,
                props.onBlur,
                props.onChange,
                props.onFocus,
                props.placeholder,
                props.touched,
                props.value,
                props.rows,
            ])}
        </div>
    );
});

TextareaField.defaultProps = TextArea.defaultProps;

export default TextareaField;

import $ from 'jquery';

declare global {
    interface Window {
        jQuery: typeof $,
        $: typeof $
    }
}

export default function useJqueryGlobal() {
    window.jQuery = $;
    window.$ = $;
}

// @ts-ignore
$.migrateMute = true;

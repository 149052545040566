import React from 'react';
import './index.scss';
import Table from '../../../../../../../../design/1/js/templates/molecules/table';
import TableBody from '../../../../../../../../design/1/js/templates/atoms/table-body';
import TableHeightRow from '../../../../../../../../design/1/js/templates/atoms/table-height-row';
import { formatDate, formatSocialSecurityNumber } from "../../../../../../../../design/1/js/lib/formatData";

export interface MasterDataType  {
    firstname: string;
    lastname: string;
    birthdate: string;
    socialSecurityNumber: string;
}

interface MasterDataProps {
    customTitle?: string
    data: MasterDataType;
}

interface Rows {
    label: string;
    value: string;
}

const MasterData = ({
    customTitle, data
}: MasterDataProps) => {
    const baseClassName = 'w-masterData';

    const rows: Rows[] = [
        {
            label: window.sv_resource.get('plf_onlineform_masterdata_lastname'),
            value: data.firstname + ' ' + data.lastname,
        },
        {
            label: window.sv_resource.get('plf_onlineform_masterdata_birthdate'),
            value: formatDate(new Date(data.birthdate)),
        },
        {
            label: window.sv_resource.get('plf_onlineform_masterdata_ahv'),
            value: formatSocialSecurityNumber(data.socialSecurityNumber),
        },
    ];

    return (
        <div className={`${baseClassName}`}>
            <h2 className={`${baseClassName}__title`}>{customTitle ?? window.sv_resource.get('plf_onlineform_masterdata_title')}</h2>
            <div className="row gap-xxs">
                <Table>
                    <TableBody>
                        {rows.map((row, i) => (
                            <TableHeightRow  key={`row-${i.toString()}`}>
                                <div className={`${baseClassName}__column col-6`}>
                                    {row.label}
                                </div>
                                <div className={`${baseClassName}__column col-6`}>
                                    {row.value}
                                </div>
                            </TableHeightRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

export default MasterData;

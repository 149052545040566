import React, { useEffect, useState } from 'react';
import './index.scss';
import JsonResponse from '../../../lib/entity/response/JsonResponse';
import BrandLogo from '../../atoms/brand-logo';
import Navbar from '../navbar';
import MobileNav from '../mobile-nav';
import NavItem from '../navbar/lib/NavItem';

export interface HeaderBottomProps {
    authenticated: string,
    navigationItems: NavItem[];
    newMessagesAction: string;
    newDocumentsAction: string;
}

const HeaderBottom = ({
    authenticated, navigationItems, newDocumentsAction, newMessagesAction,
}: HeaderBottomProps) => {
    const baseClassName = 'm-header-bottom';

    const [newMessages, setNewMessages] = useState(false);
    const [newDocuments, setNewDocuments] = useState(false);

    useEffect(() => {
        if (!authenticated) {
            return;
        }

        fetch(newMessagesAction, {
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        }).then(res => res.json())
            .then((response: JsonResponse<{ unread: number }>) => {
                const hasUnreadMessages = response.context.unread > 0;
                setNewMessages(hasUnreadMessages);
            });
    }, [newMessagesAction, authenticated]);

    useEffect(() => {
        if (!authenticated) {
            return;
        }

        fetch(newDocumentsAction, {
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        }).then(res => res.json())
            .then((response: JsonResponse<{ unseen: number }>) => {
                const hasUnseenDocuments = response.context.unseen > 0;
                setNewDocuments(hasUnseenDocuments);
            });
    }, [newDocumentsAction, authenticated]);

    if (!authenticated) {
        return (
            <div className={`${baseClassName} -logocentered`}>
                <div className="container-lg">
                    <a href="/">
                        <BrandLogo />
                    </a>
                </div>
            </div>
        );
    }

    return (
        <div className={`${baseClassName}`}>
            <div className="container-lg">
                <div className="row">
                    <div className={`${baseClassName}__logo d-none d-lg-flex col-lg-2`}>
                        <a href="/">
                            <BrandLogo />
                        </a>
                    </div>
                    <div className={`${baseClassName}__navigation-wrapper col-12 col-lg-10  d-none d-lg-flex`}>
                        <Navbar navigationItems={navigationItems.slice(0, 7)} newDocuments={newDocuments} newMessages={newMessages} />
                    </div>
                </div>
            </div>
            <MobileNav navigationItems={navigationItems} newDocuments={newDocuments} newMessages={newMessages} />
        </div>
    );
};

export default HeaderBottom;

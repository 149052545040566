import React from 'react';
import { resolveValue, toast, Toaster } from 'react-hot-toast';
import Icon, { IconName } from '../../atoms/icon';
import './index.scss';

interface Props {
    className?: string;
}

const ToastContainer = ({ className }: Props) => {
    const baseClassName = 'm-toastContainer';

    return (
        <Toaster
            containerClassName={`${baseClassName} ${className}`}
            toastOptions={{
                success: {
                    duration: 3000,
                },
                error: {
                    duration: Infinity,
                },
            }}
        >
            {t => (
                <div
                    className={`${baseClassName}__toast -${t.type} ${t.className ?? ''} container-lg`}
                    style={{
                        opacity: t.visible ? 1 : 0,
                    }}
                >
                    <div className={`${baseClassName}__toastMessage`}>
                        { t.type === 'success' && <Icon name={IconName.SuccessCircle} /> }
                        { t.type === 'error' && <Icon name={IconName.ErrorCircle} /> }

                        {resolveValue(t.message, t)}
                    </div>
                    {t.type === 'error' && (
                        <button
                            className={`${baseClassName}__toastClose`}
                            onClick={() => toast.dismiss(t.id)}
                            type="button"
                        >
                            <Icon
                                name={IconName.Close}
                            />
                        </button>
                    )}
                </div>
            )}
        </Toaster>
    );
};

ToastContainer.defaultProps = {
    className: '',
};

export default ToastContainer;

import React, { useEffect, useState } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import FormWithAnswerText from '../../../../../../../../design/1/js/templates/organisms/form-with-answer-text';
import CheckStep, { CheckType, validationSchemaCheckStep } from '../../molecules/check';
import FileHelper from '../../../../../../../../design/1/js/lib/FileHelper';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import ContinuationReasonsStep, {
    ContinuationReasonsType,
    validationSchemaContinuationReasonsStep,
} from '../../molecules/continuation-reasons';
import TypeOfContinuationStep, {
    TypeOfContinuationType,
    validationSchemaTypeOfContinuationStep,
} from '../../molecules/type-of-continuation';
import StatusMessageVariation
    from '../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation';
import MasterData, { MasterDataType } from "../../molecules/master-data";

type VoluntaryContinuationFormType = MasterDataType & ContinuationReasonsType & TypeOfContinuationType & CheckType;

interface VoluntaryContinuationFormProps {
    formAction: string;
    handleSubmit: (status: number) => void,
    masterDataAction: string;
}

const VoluntaryContinuationForm = (({ formAction, handleSubmit, masterDataAction }: VoluntaryContinuationFormProps) => {
    const baseClassName = 'w-voluntaryContinuationForm';
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['continuationReasons', 'typeOfContinuation', 'check'];
    const isLastStep = activeStep === steps.length - 1;

    const maxFileSize = 4 * 1024 * 1024;
    const allowedFileTypes = ['jpg', 'png', 'pdf'];
    const fileHelper = new FileHelper(maxFileSize, allowedFileTypes);

    const initialValues = Object({
        firstname: '',
        lastname: '',
        birthdate: '',
        socialSecurityNumber: '',
        terminationReason: '',
        noPensionFundSwitch: false,
        files: [],
        typeOfInsurance: '',
        confirmation: false,
    });

    const validationSchema = [
        validationSchemaContinuationReasonsStep,
        validationSchemaTypeOfContinuationStep,
        validationSchemaCheckStep,
    ];
    const currentValidationSchema = validationSchema[activeStep];

    const renderStepContent = (
        step,
        errors: FormikErrors<VoluntaryContinuationFormType>,
        touched: FormikTouched<VoluntaryContinuationFormType>,
        onChange,
        onBlur,
        values: VoluntaryContinuationFormType,
        handleBack,
        handleFileChange?,
    ) => {
        switch (step) {
        case 0:
            return (
                <>
                    <ContinuationReasonsStep isFirstStep={true} errors={errors} id={steps[0]} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} handleFileChange={handleFileChange} />
                </>
            );
        case 1:
            return (
                <>
                    <ContinuationReasonsStep isFirstStep={true} errors={errors} disabled id={steps[0]} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} handleFileChange={handleFileChange} />
                    <TypeOfContinuationStep errors={errors} id={steps[1]} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} />
                </>
            );
        case 2:
            return (
                <>
                    <ContinuationReasonsStep isFirstStep={true} errors={errors} disabled id={steps[0]} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} handleFileChange={handleFileChange} />
                    <TypeOfContinuationStep errors={errors} disabled id={steps[1]} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} />
                    <CheckStep errors={errors} id={steps[2]} touched={touched} onChange={onChange} onBlur={onBlur} values={values} handleBack={handleBack} label={window.sv_resource.get('plf_onlineform_voluntary_continuation_check_description')} />
                </>
            );
        default:
            throw new Error();
        }
    };

    const customOnSubmit = (values, actions) => {
        actions.setSubmitting(false);
        actions.setTouched({});
        setActiveStep(activeStep + 1);
    };

    useEffect(() => {
        scrollTo(`#${steps[activeStep]}`);
    }, [activeStep]);

    const scrollTo = (id: string) => {
        window.location.href = id;
    };

    return (
        activeStep !== steps.length && (
            <FormWithAnswerText
                actionUrl={formAction}
                className={`${baseClassName}`}
                customOnSubmit={isLastStep ? undefined : customOnSubmit}
                handleSubmit={handleSubmit}
                handleValidate={currentValidationSchema}
                initialValues={initialValues}
                name="VoluntaryContinuationForm"
            >
                {formik => {
                    const handleBack = () => {
                        formik.setErrors({});
                        setActiveStep(activeStep - 1);
                    };

                    const handleFileChange = event => {
                        fileHelper.getFileContent(event.target)
                            .then(files => {
                                formik.setFieldValue('files', files);
                            })
                            .catch((error: Error) => {
                                formik.setFieldError('files', error.message);
                            })
                            .finally(() => formik.setFieldTouched('files'));
                    };

                    return (
                        <>
                            <AsyncLoader
                                action={masterDataAction}
                                renderContent={lazyChildren => (
                                    <>
                                        {lazyChildren}
                                    </>
                                )}
                                variation={StatusMessageVariation.Subpage}
                            >
                                {(masterData: MasterDataType) => (
                                    <>
                                        <MasterData data={masterData} />
                                        { renderStepContent(activeStep, formik.errors, formik.touched, formik.handleChange, formik.handleBlur, formik.values, handleBack, handleFileChange) }
                                    </>
                                )}
                            </AsyncLoader>
                        </>
                    );
                }}
            </FormWithAnswerText>
        )
    );
});

export default VoluntaryContinuationForm;

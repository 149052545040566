import React from 'react';
import './index.scss';
import IconButton from '../../../../../../../../design/1/js/templates/atoms/icon-button';
import { IconName } from '../../../../../../../../design/1/js/templates/atoms/icon';

interface MutationsAndRequestLinkProps {
    title: string;
    url: string
    target?: string;
}
const MutationsAndRequestLink = ({
    title, url, target,
}: MutationsAndRequestLinkProps) => {
    const baseClassName = 'w-mutationsAndRequestLink';

    return (
        <a className={`${baseClassName} row`} href={url} target={target}>
            <span className={`${baseClassName}__title col-10`}>{title}</span>
            <IconButton className={`${baseClassName}__icon col-10`} icon={IconName.ChevronRight} />
        </a>
    );
};

export default MutationsAndRequestLink;

import React from 'react';
import Category from '../../../lib/Category';
import Document from '../../../lib/Document';
import Button, { ButtonType } from '../../../../../../../../design/1/js/templates/atoms/button';
import './index.scss';

interface Props {
    categories: Category[];
    className?: string;
    documents: Document[];
    onSelectCategory: (category: Category) => void;
    selectedCategory: Category | null;
}

const CategoryFilter = ({
    categories, className, documents, onSelectCategory, selectedCategory,
}: Props) => {
    const baseClassName = 'w-document-categoryFilter';

    return (
        <div className={`${baseClassName} ${className}`}>
            <Button
                active={selectedCategory === null}
                handleClick={() => onSelectCategory(null)}
                hasArrow={false}
                text={`${window.sv_resource.get('plf_document_all')} (${documents.length})`}
                type={ButtonType.Action}
            />
            {categories.map(category => {
                const active = category.id === selectedCategory?.id;
                const documentCount = documents.filter(document => document.category.id === category.id).length;

                return (
                    <Button
                        active={active}
                        key={category.id}
                        handleClick={() => onSelectCategory(category)}
                        hasArrow={false}
                        text={`${category.title} (${documentCount})`}
                        type={ButtonType.Action}
                    />
                );
            })}
        </div>
    );
};

CategoryFilter.defaultProps = {
    className: '',
};

export default CategoryFilter;

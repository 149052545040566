import React from 'react';
import './index.scss';
import BtnChevron, { ChevronDirection } from '../btn-chevron';

export enum ButtonType {
    Default = 'default',
    White = 'white',
    Orange = 'orange',
    Action = 'action',
    SwissID = 'swissID',
}

export interface ButtonProps {
    active?: boolean;
    className?: string;
    disabled?: boolean;
    handleClick?: () => void;
    hasArrow?: boolean;
    href?: string;
    target?: string;
    text: string;
    type?: ButtonType;
}
const Button = ({
    active,
    className,
    disabled,
    handleClick,
    hasArrow,
    href,
    target,
    text,
    type,
}: ButtonProps) => {
    const baseClassName = 'a-button';

    const CustomTag = href ? 'a' : 'button';
    const btnType = href ? null : 'button';

    return (
        <CustomTag
            className={`${baseClassName} -${type} ${active ? '-active' : ''} ${className}`}
            disabled={disabled}
            href={href}
            onClick={handleClick}
            target={href ? target : null}
            type={btnType}
        >
            { type === ButtonType.SwissID ? (
                <>
                    <span className="connectIcon" aria-hidden="true" />
                    {text}
                </>
            ) : (
                <div className={`${baseClassName}__wrapper`}>
                    <div className={`${baseClassName}__text`}>
                        {text}
                    </div>
                    {hasArrow && (
                        <BtnChevron direction={ChevronDirection.Right} />
                    )}
                </div>
            )}
        </CustomTag>
    );
};

Button.defaultProps = {
    active: false,
    className: '',
    disabled: false,
    handleClick: null,
    hasArrow: true,
    href: null,
    target: '_self',
    type: ButtonType.Default,
};

export default Button;

import React, {ReactNode} from 'react';
import Table from "../../../../../../../../design/1/js/templates/molecules/table";
import TableBody from "../../../../../../../../design/1/js/templates/atoms/table-body";
import TableHeightRow from "../../../../../../../../design/1/js/templates/atoms/table-height-row";
import {SavingPlanBaseData} from "../../../lib/savingPlan/SavingPlanData";
import {formatCurrency} from "../../../../../../../../design/1/js/lib/formatData";

interface SavingPlanTableBaseDataProps {
    className?: string;
    baseData: SavingPlanBaseData[];
}

const SavingPlanTableBaseData = ({
    className, baseData,
}: SavingPlanTableBaseDataProps) => {
    const baseClassName = 'w-simulationSavingPlanBaseDataTable';

    return (
        <div className={`${baseClassName} ${className}`}>
            <Table>
                <TableBody>
                    {baseData.map((data, index) => (
                        <TableHeightRow key={`row-${index.toString()}`}>
                            <div className={`${baseClassName}__column col-8 col-lg-10`}>
                                {data.label}
                            </div>
                            <div className={`${baseClassName}__column col-4 col-lg-2`}>
                                {formatCurrencyValue(data.value)}
                            </div>
                        </TableHeightRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

const formatCurrencyValue = (value: number): ReactNode => {
    const currencyValue = formatCurrency(value);
    const currency = window.sv_resource.get('plf_simulation_saving_plan_currency_chf');

    return (
        <>
        {currencyValue}
        <span className='small-text'>{currency}</span>
        </>
    );
}

SavingPlanTableBaseData.defaultProps = {
  className: '',
};

export default SavingPlanTableBaseData;

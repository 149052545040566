import React, {ReactNode, useEffect, useState} from 'react';
import ConsultantType from '../../../lib/consultant/ConsultantType';
import ClientNewMessageForm from '../../molecules/client-new-message-form';
import MessagetoolInboxClient from '../messagetool-inbox-client';
import {MessageToolView} from "../../../lib/enum/MessageToolView";
import MessagetoolConversation from "../messagetool-conversation";
import MessageType from "../../../lib/MessageType";
import ClientAnswerForm from "../../molecules/client-answer-form";
import evaluateView from "../../../lib/utils/evaluateView";

interface MessageToolClientProps {
    answerAction: string;
    consultant: ConsultantType;
    messagesAction: string;
    sendMessageAction: string;
    updateReadStatusAction: string;
}

const MessagetoolClient = ({
    answerAction, consultant, messagesAction, sendMessageAction, updateReadStatusAction,
}: MessageToolClientProps) => {
    const baseClassName = 'w-messagetoolClient';
    const [selectedMessage, setSelectedMessage] = useState<MessageType>(null);
    const [view, setView] = useState<MessageToolView>((() => evaluateView()));

    useEffect(() => {
        if (view === MessageToolView.NEW_MESSAGE) {
            window.history.replaceState(null, '', '#' + MessageToolView.NEW_MESSAGE);
            return;
        }
        if (view === MessageToolView.CONVERSATION) {
            window.history.replaceState(null, '', '#' + MessageToolView.CONVERSATION);
            return;
        }

        // Redirect to default view
        window.history.replaceState(null, '', '#' + MessageToolView.INBOX);
    }, [view]);

    const backToInbox = () => {
        setView(MessageToolView.INBOX);
    };

    const getViewComponent = (): ReactNode | undefined => {
        switch (view) {
            case MessageToolView.INBOX:
                return <MessagetoolInboxClient
                    switchView={setView}
                    setSelectedMessage={setSelectedMessage}
                    messagesAction={messagesAction}
                />
            case MessageToolView.NEW_MESSAGE:
                return <ClientNewMessageForm
                    backToInbox={backToInbox}
                    pensionConsultantName={consultant.consultantName}
                    sendMessageAction={sendMessageAction}
                />
            case MessageToolView.CONVERSATION:
                if (null === selectedMessage) {
                    setView(MessageToolView.INBOX);
                    return;
                }
                return <MessagetoolConversation
                    message={selectedMessage}
                    switchView={setView}
                    updateReadStatusAction={updateReadStatusAction}
                    answerForm={
                        (addAnswer) => <ClientAnswerForm
                            addAnswer={addAnswer}
                            answerAction={answerAction}
                            messageId={selectedMessage.id}
                            consultant={consultant}
                        />
                    }
                    isClient
                />
        }
    }

    return (
        <div className={`${baseClassName}`}>
            {getViewComponent()}
        </div>
    );
};

export default MessagetoolClient;

import React from 'react';
import './index.scss';
import Button, {ButtonType} from "../../../../../../../../design/1/js/templates/atoms/button";

interface InfoProps {
    texts: {
        topText: string,
        mainText: string,
        bottomText: string,
    };
    buttonLabel?: string;
    buttonLink?: string;
}
const Info = ({
    texts, buttonLabel, buttonLink,
}: InfoProps) => {
    const baseClassName = 'w-info';

    return (
        <div className={`${baseClassName}`}>
            <div className='container-lg'>
                <div className={`${baseClassName}__wrapper`}>
                    <div className={`${baseClassName}__infoText`}>{texts.topText}</div>
                    <div className={`${baseClassName}__infoText display3 -bold`}>{texts.mainText}</div>
                    <div className={`${baseClassName}__infoText`}>{texts.bottomText}</div>
                    { buttonLabel && buttonLink &&
                        <Button text={buttonLabel} href={buttonLink} target={'_blank'} type={ButtonType.Orange} className={`${baseClassName}__infoButton`} />
                    }
                </div>
            </div>
        </div>
    );
};

export default Info;

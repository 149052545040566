import { loadWidget } from '@sitevision/react';
import React from 'react';
import ReactDOM from 'react-dom';
import LinkType from './lib/LinkType';
import YourDocumentType from './lib/YourDocumentType';
import YourDocuments from './templates/organisms/your-documents';
import YourDocumentTextType from './lib/YourDocumentTextType';

loadWidget('.plf-your-documents', yourDocumentsWidget => {
    const yourDocuments: YourDocumentType[] = JSON.parse(yourDocumentsWidget.dataset.yourdocuments);
    const links: LinkType[] = JSON.parse(yourDocumentsWidget.dataset.links);
    const text: YourDocumentTextType = JSON.parse(yourDocumentsWidget.dataset.text);
    const showSelfGenerationInfo: boolean = JSON.parse(yourDocumentsWidget.dataset.showSelfGenerationInfo) ?? false;
    ReactDOM.render(<YourDocuments links={links} text={text} yourDocuments={yourDocuments} showSelfGenerationInfo={showSelfGenerationInfo} />, yourDocumentsWidget);
});

import {Browser} from "../entity/enum/Browser";

export const getBrowser = (): Browser|null => {
    const userAgent = navigator.userAgent;

    if (userAgent.match(/chrome|chromium|crios/i)) {
        return Browser.Chrome;
    }
    if (userAgent.match(/firefox|fxios/i)) {
        return Browser.Firefox;
    }
    if (userAgent.match(/safari/i)) {
        return Browser.Safari;
    }
    if (userAgent.match(/edg/i)) {
        return Browser.Edge;
    }

    return null;
}

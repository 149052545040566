export const fetchRequestJob = async (url: string, options?: object) => {
    const response = await fetch(url, options);
    if (response.status === 201) {
        return response.json().then(data => {
            return fetchJobContent(url, data.id);
        });
    }

    return response;
}

const fetchJobContent = async (url: string, jobId: string) => {
     const params = new URLSearchParams();
     params.append('jobId', jobId);
     url = url.split('?')[0];
     url = url.split('#')[0];
     url = `${url}?${params.toString()}`;

     let sleep = 500;
     let response: Response;

     do {
         await new Promise(resolve => setTimeout(resolve, sleep));
         response = await fetch(url);
         sleep = Math.min(sleep + 250, 8000);
     } while (response.status === 202);

    return response;
}

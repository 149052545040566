import React from 'react';
import './index.scss';

interface TableProps {
    children: React.ReactNode;
    className?: string;
    slim: boolean;
}

const TableHead = ({ children, className, slim }: TableProps) => {
    const baseClassName = 'a-tableHead';

    return (
        <div className={`${baseClassName} ${className} ${slim && '-slim'} row`}>
            {children}
        </div>
    );
};

TableHead.defaultProps = {
    className: '',
    slim: false,
};

export default TableHead;

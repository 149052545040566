import React, { useState } from 'react';
import './index.scss';
import IntroductionText from '../../../../../../../../design/1/js/templates/molecules/introduction-text';
import ExitNoticeVeryLowWageForm from '../exit-notice-very-low-wage-form';

interface ExitNoticeVeryLowWageProps {
    bankTransferDataAction: string;
    formAction: string;
    masterDataAction: string;
}

const ExitNoticeVeryLowWage = ({ bankTransferDataAction, formAction, masterDataAction }: ExitNoticeVeryLowWageProps) => {
    const [formSubmit, setFormSubmit] = useState(false);
    const baseClassName = 'w-exitNoticeVeryLowWage';

    const handleFormSubmit = () => {
        setFormSubmit(!formSubmit);
    };

    return (
        <div className={`${baseClassName}`}>
            { !formSubmit && <IntroductionText description={window.sv_resource.get('plf_onlineform_exit_notice_very_low_wage_description')} title={window.sv_resource.get('plf_onlineform_exit_notice_very_low_wage_title')} /> }
            <div className={`${baseClassName}__wrapper`}>
                <ExitNoticeVeryLowWageForm bankTransferDataAction={bankTransferDataAction} formAction={formAction} handleSubmit={handleFormSubmit} masterDataAction={masterDataAction} />
            </div>
        </div>
    );
};

export default ExitNoticeVeryLowWage;

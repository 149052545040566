import React from 'react';
import AccordionItem from '../accordion-item';
import Category from './lib/Category';
import AccordionData from './lib/AccordionData';

interface Props {
    className?: string;
    data: AccordionData[];
    selectedCategory?: Category,
}

const Accordion = ({
    className = '', data, selectedCategory,
}: Props) => {
    const baseClassName = 'm-accordion';

    return (
        <div className={`${baseClassName} ${className}`}>
            {data
                .filter(item => selectedCategory === null || item.categories.map(t => t.id).includes(selectedCategory.id))
                .map(item => (<AccordionItem item={item} id={item.id} key={item.id.toString()} />))}
        </div>
    );
};

Accordion.defaultProps = {
    className: '',
    selectedCategory: null,
};

export default Accordion;

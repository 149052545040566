export function hasInsuredMultipleIds() {
    return getAllInsuredIds()?.length > 1;
}

export function getCurrentInsuredId(): string|null {
    return document.body.dataset.insuredCurrentId ?? null;
}

export function getAllInsuredIds(): string[]|null {
    return document.body.dataset.insuredAllIds?.split(',') ?? null;
}

export function getOtherInsuredIds(): string[]|null {
    const currentInsuredId = getCurrentInsuredId()
    return getAllInsuredIds()?.filter(insuredId => insuredId !== currentInsuredId);
}

import AsyncLoader from "../../../../../../../../design/1/js/templates/molecules/async-loader";
import React from "react";
import StatusMessageVariation
    from "../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation";
import SavingPlanData from "../../../lib/savingPlan/SavingPlanData";
import SavingPlanTableBaseData from "../../molecules/saving-plan-table-base-data";
import './index.scss';
import SavingPlanTableSimulationData from "../../molecules/saving-plan-table-simulation-data";
import TableColumnSize from "../../../lib/savingPlan/TableColumnSize";
import FormButton, {
    FormButtonVariation
} from "../../../../../../../../design/1/js/templates/atoms/form-fields/form-button";
import usePrettyPrint from "../../../../../../../../design/1/js/lib/hooks/usePrettyPrint";
import {getNextYear} from "../../../../../../../../design/1/js/lib/util/DateUtils";
import Paragraph from "../../../../../../../../design/1/js/templates/atoms/paragraph";
import { formatRetirementAge } from "../../../../../../../../design/1/js/lib/util/RetirementDateUtils";

interface SavingPlanSimulationDataTableProps {
    action: string;
    className?: string;
    visible: boolean;
    fetchingFinished: (finished: boolean) => void;
}

const SavingPlanSimulationDataTable = ({
    action, className, visible, fetchingFinished,
}: SavingPlanSimulationDataTableProps) => {
    const baseClassName = 'w-simulationSavingPlanData';
    // language=css
    const printCss = `
        .w-simulationSavingPlanDataTable_sectionSubTitle {
            margin-bottom: var(--spacer-xxs);
        }
        
        html {
            font-size: 72%;
        }
    `;
    const print = usePrettyPrint(printCss);
    const handlePrint = () => {
        print();
    }
    const handleSuccess = () => {
        fetchingFinished(true);
    }

    return (
        <div className={`${baseClassName} ${className} ${visible ? '' : 'd-none'}`}>
            <AsyncLoader
                action={action}
                renderContent={lazyChildren => (
                    <>
                        {lazyChildren}
                    </>
                )}
                variation={StatusMessageVariation.Subpage}
                onSuccess={handleSuccess}
                requestJob={true}
            >
                {(savingPlanData: SavingPlanData) => (
                    <div className={`${baseClassName}_data`}>
                        <div className={`${baseClassName}_dataBox`}>
                            <h3 className={`${baseClassName}_sectionSubTitle`}>{window.sv_resource.get('plf_simulation_saving_plan_section_base_data')}</h3>
                            <SavingPlanTableBaseData baseData={savingPlanData.baseData} />
                        </div>

                        <div className={`${baseClassName}_dataBox`}>
                            <h3 className={`${baseClassName}_sectionSubTitle`}>{window.sv_resource.get('plf_simulation_saving_plan_section_yearly_payments')}</h3>
                            <SavingPlanTableSimulationData simulationData={savingPlanData.yearlyPaymentsInsured} columnSize={TableColumnSize.EQUAL} shortBottomSpace={true} />
                            <SavingPlanTableSimulationData simulationData={savingPlanData.yearlyPaymentsCompany} columnSize={TableColumnSize.EQUAL} />
                        </div>

                        <div className={`${baseClassName}_dataBox`}>
                            <h3 className={`${baseClassName}_sectionSubTitle`}>
                                {window.sv_resource.get('plf_simulation_saving_plan_section_retirement_benefits').replace('{{age}}', formatRetirementAge(savingPlanData.calculationAge))}
                            </h3>
                            <SavingPlanTableSimulationData simulationData={savingPlanData.retirementBenefits} columnSize={TableColumnSize.WIDE_FIRST_COLUMN} />
                        </div>

                        <div className={`${baseClassName}_dataBox`}>
                            <h3 className={`${baseClassName}_sectionSubTitle`}>{window.sv_resource.get('plf_simulation_saving_plan_section_disability_benefits')}</h3>
                            <SavingPlanTableSimulationData simulationData={savingPlanData.disabilityBenefits} columnSize={TableColumnSize.WIDE_FIRST_COLUMN} />
                        </div>

                        <div className={`${baseClassName}_dataBox`}>
                            <h3 className={`${baseClassName}_sectionSubTitle`}>{window.sv_resource.get('plf_simulation_saving_plan_section_death_benefits')}</h3>
                            <SavingPlanTableSimulationData simulationData={savingPlanData.deathBenefits} columnSize={TableColumnSize.WIDE_FIRST_COLUMN} />
                        </div>

                        <div className={`${baseClassName}_dataBox`}>
                            <h3 className={`${baseClassName}_sectionSubTitle`}>{window.sv_resource.get('plf_simulation_saving_plan_section_max_purchase')}</h3>
                            <SavingPlanTableSimulationData simulationData={savingPlanData.maxPurchase} columnSize={TableColumnSize.WIDE_FIRST_COLUMN} className={`${baseClassName}_print_spacer_bottom`} />
                        </div>

                        <div className={`${baseClassName}_printButtonWrapper`}>
                            <FormButton
                                className='d-print-none'
                                text={window.sv_resource.get('plf_simulation_saving_plan_generate_pdf')}
                                type={'button'}
                                variation={FormButtonVariation.Primary}
                                hasIcon={false}
                                handleClick={handlePrint}
                            />
                        </div>
                        <div className={`${baseClassName}_afterInfoWrapper d-print-none`}>
                            <Paragraph>
                                {window.sv_resource.get('plf_simulation_saving_plan_legal_info')}
                            </Paragraph>
                            <Paragraph>
                                {window.sv_resource.get('plf_simulation_saving_plan_after_table_info').replace('{{nextYear}}', String(getNextYear()))}
                            </Paragraph>
                        </div>
                    </div>
                )}
            </AsyncLoader>
        </div>
    );
}

export default SavingPlanSimulationDataTable;

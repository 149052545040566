import React, { useState } from 'react';
import Category from '../../../lib/Category';
import DocumentObject from '../../../lib/Document';
import CategoryFilter from '../../molecules/categoryFilter';
import DocumentList from '../../molecules/documentList';

interface Props {
    categories: Category[];
    documents: DocumentObject[];
}

const Document = ({ categories, documents }: Props) => {
    const baseClassName = 'w-document-document';

    const [selectedCategory, setSelectedCategory] = useState<Category>(null);

    return (
        <div className={`${baseClassName}`}>
            <CategoryFilter categories={categories} documents={documents} onSelectCategory={setSelectedCategory} selectedCategory={selectedCategory} />
            <DocumentList documents={documents} selectedCategory={selectedCategory} />
        </div>
    );
};

export default Document;

import React, { MouseEventHandler } from 'react';
import Icon, { IconName } from '../../icon';
import './index.scss';
import LoadSpinner from '../../load-spinner';

export enum FormButtonVariation {
    Primary = 'primary',
    Secondary = 'secondary',
    Next = 'next',
    Previous = 'previous',
}

interface Props {
    className?: string;
    disabled?: boolean;
    handleClick?: MouseEventHandler;
    hasIcon?: boolean;
    text: string;
    type: 'submit' | 'reset' | 'button';
    variation: FormButtonVariation,
    isLoading: boolean,
    hidden: boolean,
}

const FormButton = ({
    className, disabled, handleClick, hasIcon, text, type, variation, isLoading, hidden,
}: Props) => {
    const baseClassName = 'a-formButton';

    const iconName = getIconName(variation);
    const isLight = variation !== FormButtonVariation.Primary;

    return (
        // eslint-disable-next-line react/button-has-type
        <button hidden={hidden} disabled={disabled} className={`${baseClassName} ${className} -${variation} ${isLight ? '-light' : ''} ${hasIcon ? '-hasIcon' : ''}`} onClick={handleClick} type={type}>
            <div className={`${baseClassName}__wrapper`}>
                {hasIcon && !isLoading && (
                    <Icon className={`${baseClassName}__icon`} name={iconName} />
                )}
                {isLoading &&
                    <LoadSpinner inline />
                }
                <div className={`${baseClassName}__text`}>
                    {text}
                </div>
            </div>
        </button>
    );
};

function getIconName(variation: FormButtonVariation): IconName {
    switch (variation) {
    default:
    case FormButtonVariation.Primary:
    case FormButtonVariation.Secondary:
        return IconName.Check;
    case FormButtonVariation.Next:
        return IconName.ArrowForward;
    case FormButtonVariation.Previous:
        return IconName.ArrowBack;
    }
}

FormButton.defaultProps = {
    className: '',
    disabled: false,
    handleClick: undefined,
    hasIcon: true,
    isLoading: false,
    hidden: false,
};

export default FormButton;

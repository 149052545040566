import React, { useState } from 'react';
import IntroductionText from '../../../../../../../../design/1/js/templates/molecules/introduction-text';
import CivilStateMarriagePartnershipForm from '../../molecules/civil-state-marriage-partnership-form';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';

interface CivilStateMarriagePartnershipProps {
    actionUrl: string;
    unavailableAction: string;
}

const CivilStateMarriagePartnership = ({
    actionUrl, unavailableAction,
}: CivilStateMarriagePartnershipProps) => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const baseClassName = 'w-changeCivilStateMarriagePartnership';

    const handleFormSubmit = () => {
        setFormSubmitted(!formSubmitted);
    };

    return (
        <div className={`${baseClassName}`}>
            <AsyncLoader
                action={unavailableAction}
                renderContent={lazyChildren => lazyChildren}
            >
                {() => (
                    <>
                        {!formSubmitted && (
                            <IntroductionText
                                description={window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_description')}
                                title={window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_title')}
                            />
                        )}
                        <CivilStateMarriagePartnershipForm actionUrl={actionUrl} handleSubmit={handleFormSubmit} />
                    </>
                )}
            </AsyncLoader>
        </div>
    );
};

export default CivilStateMarriagePartnership;

import React, { forwardRef } from 'react';
import TextInput, { TextInputProps } from '../../atoms/form-fields/text-input';
import './index.scss';

const HoneyPot = forwardRef<HTMLInputElement, TextInputProps>(({ ...props }: TextInputProps, ref) => {
    const baseClassName = 'm-contactMe';

    return (
        <div className={`${baseClassName} ${props.className}`}>
            <TextInput
                autoComplete="off"
                className={`${baseClassName}__input`}
                ref={ref}
                disabled={props.disabled}
                errors={props.errors}
                icon={props.icon}
                id={props.id}
                name={props.name}
                onBlur={props.onBlur}
                onChange={props.onChange}
                onFocus={props.onFocus}
                placeholder={props.placeholder}
                tabbable={false}
                touched={props.touched}
                type={props.type}
                value={props.value}
            />
        </div>
    );
});

HoneyPot.defaultProps = TextInput.defaultProps;

export default HoneyPot;

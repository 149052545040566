import React from 'react';
import './index.scss';
import FooterSection, { FooterIcon } from '../../molecules/footer-section';
import Link from '../../atoms/link';
import FooterColumn from '../../molecules/footer-column';
import FooterTitle from '../../atoms/footer-title';
import FooterLinkList from '../../molecules/footer-link-list';
import Paragraph from '../../atoms/paragraph';

interface Props {
    dataPrivacy: string;
    imprint: string;
    terms: string;
}

const Footer = ({
    dataPrivacy, imprint, terms,
}: Props) => {
    const baseClassName = 'o-footer';

    return (
        <div className={`${baseClassName}`}>
            <div className="container-lg">
                <div className={`${baseClassName}__wrapper row`}>
                    <FooterColumn centerMobile>
                        <FooterTitle>{window.sv_resource.get('plf_footer_title')}</FooterTitle>
                        <FooterSection icon={FooterIcon.None}>
                            <Paragraph>{window.sv_resource.get('plf_footer_line1')}</Paragraph>
                        </FooterSection>
                        <FooterSection icon={FooterIcon.HomeFooter}>
                            <Paragraph>{window.sv_resource.get('plf_footer_line2')}</Paragraph>
                            <Paragraph>{window.sv_resource.get('plf_footer_line3')}</Paragraph>
                            <Paragraph>{window.sv_resource.get('plf_footer_line4')}</Paragraph>
                            <Paragraph>{window.sv_resource.get('plf_footer_line5')}</Paragraph>
                        </FooterSection>
                    </FooterColumn>
                    <FooterColumn centerMedium>
                        <FooterLinkList>
                            <Link href={imprint} title={window.sv_resource.get('footer_imprint')}>{window.sv_resource.get('footer_imprint')}</Link>
                            <Link href={terms} title={window.sv_resource.get('footer_terms')}>{window.sv_resource.get('footer_terms')}</Link>
                            <Link href={dataPrivacy} title={window.sv_resource.get('footer_data_protection')}>{window.sv_resource.get('footer_data_protection')}</Link>
                        </FooterLinkList>
                    </FooterColumn>
                </div>
            </div>
        </div>
    );
};

export default Footer;

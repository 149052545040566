import React from 'react';
import Table from "../../../../../../../../design/1/js/templates/molecules/table";
import TableBody from "../../../../../../../../design/1/js/templates/atoms/table-body";
import TableHeightRow from "../../../../../../../../design/1/js/templates/atoms/table-height-row";
import SavingPlanInfoTable from "../../../lib/savingPlan/SavingPlanInfoTable";
import {formatCurrency} from "../../../../../../../../design/1/js/lib/formatData";
import TableHead from "../../../../../../../../design/1/js/templates/atoms/table-head";
import TableColumnSize from "../../../lib/savingPlan/TableColumnSize";
import './index.scss';

interface SavingPlanTableSimulationDataProps {
    className?: string;
    simulationData: SavingPlanInfoTable;
    columnSize: TableColumnSize;
    shortBottomSpace?: boolean;
}

const SavingPlanTableSimulationData = ({
    className, simulationData, columnSize, shortBottomSpace = false,
}: SavingPlanTableSimulationDataProps) => {
    const baseClassName = 'w-simulationSavingPlanDataTable';
    const labelCol = getLabelCol(columnSize);
    const valueCol = getValueCol(columnSize);
    const hasFirstColLabel = (undefined !== simulationData.header?.labelFirstColumn);

    return (
        <div className={`${baseClassName} ${className}`}>
            <Table className={`${baseClassName}_simulationDataTable`} scrollable>
                <TableHead className={`${baseClassName}_simulationDataTableHead ${!hasFirstColLabel ? '-no-first-col-label' : ''}`}>
                    <div className={`col-${labelCol} text-start`}>{simulationData.header?.labelFirstColumn}</div>
                    <div className={`col-${valueCol} text-end`}>{simulationData.header.labelBasic}</div>
                    <div className={`col-${valueCol} text-end`}>{simulationData.header.labelDefault}</div>
                    <div className={`col-${valueCol} text-end`}>{simulationData.header.labelPlus}</div>
                </TableHead>
                <TableBody className={`${baseClassName}_tableBody`}>
                    {simulationData.value.map((data, index) => (
                        <TableHeightRow key={`row-${index.toString()}`} className={`${shortBottomSpace ? '-short-bottom-space' : ''}`}>
                            <div className={`${baseClassName}__column col-${labelCol}`}>
                                {data.label}
                            </div>
                            <div className={`${baseClassName}__column text-end col-${valueCol}`}>
                                {formatCurrency(data.valueBasic)}
                            </div>
                            <div className={`${baseClassName}__column text-end col-${valueCol}`}>
                                {formatCurrency(data.valueDefault)}
                            </div>
                            <div className={`${baseClassName}__column text-end col-${valueCol}`}>
                                {formatCurrency(data.valuePlus)}
                            </div>
                        </TableHeightRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

const getLabelCol = (tableMode: TableColumnSize) => {
    return tableMode === TableColumnSize.EQUAL ? 3 : 6;
}

const getValueCol = (tableMode: TableColumnSize) => {
    return tableMode === TableColumnSize.EQUAL ? 3 : 2;
}

SavingPlanTableSimulationData.defaultProps = {
    className: '',
}

export default SavingPlanTableSimulationData;

import React from "react";
import './index.scss';
import SimulationType from '../../../lib/SimulationType';
import Card, { CardType } from '../../../../../../../../design/1/js/templates/molecules/card';
import CardImage from '../../../../../../../../design/1/js/templates/atoms/card-image';

interface SimulationsOverviewProps {
    id: string;
    simulations: SimulationType[];
    showPensionCalculatorDescription: boolean;
}

const SimulationsOverview = ({
    id, simulations, showPensionCalculatorDescription
}: SimulationsOverviewProps) => {
    const baseClassName = 'w-simulationsOverview';
    return (
        <div className={`${baseClassName}`}>
            <h2 id={id}>{window.sv_resource.get('plf_lifesituation_simulations_title')}</h2>
            <p>
                {window.sv_resource.get('plf_lifesituation_simulations_description')}
                {showPensionCalculatorDescription && ' ' + window.sv_resource.get('plf_lifesituation_simulations_description_pension_calculator')}
            </p>
            <div className="row">
                {simulations.map((simulation, index) => (
                    <div className="col-12 col-sm-6 col-lg-4" key={`card-wrapper-${index.toString()}`}>
                        <Card title={simulation.title} type={CardType.More} url={simulation.url}>
                            <CardImage image={simulation.image ? simulation.image : '/design/img/illustrations/services/pensionsrechner.svg'} cardName={simulation.title} />
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SimulationsOverview;

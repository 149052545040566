import React from 'react';
import Icon, {IconName} from "../../../../../../../../design/1/js/templates/atoms/icon";
import './index.scss';

interface SavingPlanButtonProps {
    planId: number;
    text: string;
    active: boolean;
    handleClick: (number) => void;
    disabled: boolean;
}

const SavingPlanButton = ({
    planId, text, active, handleClick, disabled,
}: SavingPlanButtonProps) => {
    const baseClassName = 'w-savingPlanButton';

    return (
        <button className={`${baseClassName} ${active ? '-active' : ''}`} onClick={() => handleClick(planId)} type='button' disabled={disabled}>
            <div className={`${baseClassName}_text`}>
                {text}
            </div>
            <div className={`${baseClassName}_icon`}>
                <Icon name={active ? IconName.Check : IconName.Close} />
            </div>
        </button>
    );
}

export default SavingPlanButton;

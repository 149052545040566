import React from 'react';
import ReactDOM from 'react-dom';
import { loadWidget } from '@sitevision/react';
import FormListItemType from './lib/FormListItemType';
import FormsOverview from './templates/organisms/forms-overview';

loadWidget('.plf-lifesituation-forms', lifeSituationFormsWidget => {
    const id: string = lifeSituationFormsWidget.dataset.headerId;
    const description: string = lifeSituationFormsWidget.dataset.description;
    const formList: FormListItemType[] = JSON.parse(lifeSituationFormsWidget.dataset.formList);
    ReactDOM.render(<FormsOverview formList={formList} id={id} description={description} />, lifeSituationFormsWidget);
});

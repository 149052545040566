import React from 'react';
import {formatDateAndTime} from '../../../../../../../../design/1/js/lib/formatData';
import AnswerType from '../../../lib/AnswerType';
import './index.scss';
import nl2br from '../../../lib/nl2br';
import Icon, {IconName} from "../../../../../../../../design/1/js/templates/atoms/icon";

interface ConversationAnswerProps {
    answer: AnswerType;
    isClient?: boolean;
}

const ConversationAnswer = ({
    answer, isClient,
}: ConversationAnswerProps) => {
    const baseClassName = 'm-conversation-answer';

    const hasOnBehalfOf = answer.onBehalfOf !== null;

    let messageAuthor: string;
    let messageByDeputySender: string = null;

    if (hasOnBehalfOf) {
        messageAuthor = answer.onBehalfOf.consultantName;
        messageByDeputySender = answer.sender;
    } else {
        messageAuthor = answer.sender;
    }

    const messageIsFromOther = (isClient === answer.fromMPK);
    const isUnread = !answer.read && messageIsFromOther;
    const hasAttachments = answer.attachments.length > 0;

    return (
        <div className={`${baseClassName} ${messageIsFromOther && '-fromOther'} ${isUnread && '-unread'}`} role="presentation">
            <div className={`${baseClassName}__content`}>
                <div className={`${baseClassName}__infoWrapper col-12`}>
                    <div className={`${baseClassName}__info`}>
                        {formatDateAndTime(new Date(answer.date))}
                    </div>
                    <div className={`${baseClassName}__info`}>
                        {
                            messageByDeputySender !== null ? (
                                <span className={`${baseClassName}__onBehalfOf`}>
                                    {`${messageByDeputySender} ${window.sv_resource.get('plf_messagetool_by_deputy')} ${messageAuthor} `}
                                </span>
                            ) : (
                                <>{messageAuthor}</>
                            )
                        }
                    </div>
                </div>
                <div className={`${baseClassName}__panel`}>
                    <p>{nl2br(answer.text)}</p>
                </div>
            </div>
            { hasAttachments && (
                <div className={`${baseClassName}__attachments`}>
                    {
                        answer.attachments.map((attachment, index) => {
                            const isPDF = attachment.name.toLowerCase().endsWith('.pdf');
                            return (
                                <a className={`${baseClassName}__attachment ${isPDF && '-isPDF'}`} href={attachment.link} download key={`attachment-${index.toString()}`}>
                                    {isPDF && (
                                        <div className={`${baseClassName}__pdfAttachment`}>
                                            <Icon name={IconName.PDF} className={`${baseClassName}__pdfIcon`} />
                                            <span className={`${baseClassName}__pdfName`}>{attachment.name}</span>
                                        </div>
                                    )}
                                    {!isPDF && (
                                        <img src={attachment.link} alt={attachment.name}/>
                                    )}
                                </a>
                            );
                        })
                    }
                </div>
            )}
        </div>
    );
};

ConversationAnswer.defaultProps = {
    isClient: false,
    markRead: undefined,
};

export default ConversationAnswer;

import React, {useState} from 'react';
import AsyncLoader from "../../../../../../../../design/1/js/templates/molecules/async-loader";
import StatusMessageVariation
    from "../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation";
import SavingPlanInfoData from "../../../lib/savingPlan/SavingPlanInfoData";
import SavingPlanButton from "../../molecules/saving-plan-button";
import Checkbox from "../../../../../../../../design/1/js/templates/atoms/form-fields/checkbox";
import Form from '../../../../../../../../design/1/js/templates/organisms/form';
import FormButton, {
    FormButtonVariation
} from "../../../../../../../../design/1/js/templates/atoms/form-fields/form-button";
import * as Yup from "yup";
import TextField from "../../../../../../../../design/1/js/templates/molecules/text-field";
import JsonResponse from "../../../../../../../../design/1/js/lib/entity/response/JsonResponse";
import './index.scss';
import StatusMessage, {MessageType} from "../../../../../../../../design/1/js/templates/molecules/status-message";
import LoadSpinner from "../../../../../../../../design/1/js/templates/atoms/load-spinner";
import Paragraph from "../../../../../../../../design/1/js/templates/atoms/paragraph";

interface SavingPlanChangeFormProps {
    visible: boolean;
    dataAction: string;
    sendAction: string;
}

const SavingPlanChangeForm = ({
    visible, dataAction, sendAction,
}: SavingPlanChangeFormProps) => {
    const baseClassName = 'w-savingPlanChangeForm';
    const [preSelectedPlanId, setPreSelectedPlanId] = useState(null);
    const [selectedPlanId, setSelectedPlanId] = useState(null);
    const [changeYear, setChangeYear] = useState(null);
    const [success, setSuccess] = useState(false);
    const [errorOccurred, setErrorOccurred] = useState(false);
    const [errorTraceId, setErrorTraceId] = useState(null);
    const [isSending, setIsSending] = useState(false);

    if (success) {
        return (
          <StatusMessage
              className='d-print-none'
              messageType={MessageType.Success}
              title={window.sv_resource.get('plf_simulation_saving_plan_change_success_title')}
              description={window.sv_resource.get('plf_simulation_saving_plan_change_success_description')}
          />
        );
    }
    if (errorOccurred) {
        return (
            <StatusMessage
                className='d-print-none'
                messageType={MessageType.Error}
                title={window.sv_resource.get('plf_simulation_saving_plan_change_error_title')}
                description={window.sv_resource.get('plf_simulation_saving_plan_change_error_description')}
                traceId={errorTraceId}
            />
        );
    }

    const handleUpdateClick = (planId: number, setFieldValue: (field: string, value: any) => void) => {
        setSelectedPlanId(planId);
        setFieldValue('selectedPlan', String(planId));
    }

    const handleDataResponse = (res: JsonResponse<SavingPlanInfoData>) => {
        const preSelected = res.context.currentlySelectedPlanId;
        const date = new Date(res.context.changeDate);
        const year = date.getFullYear();

        setPreSelectedPlanId(preSelected);
        setSelectedPlanId(preSelected);
        setChangeYear(year);
    }

    const handleSendResponse = (res: JsonResponse) => {
        setIsSending(false);

        if (res.success) {
            setSuccess(true);
            return;
        }

        setErrorTraceId(res.traceId);
        setErrorOccurred(true);
    }

    const validatePlanId = (): boolean => {
        return selectedPlanId !== preSelectedPlanId;
    }

    const validationSchema = Yup.object().shape({
        confirmation: Yup.boolean()
            .oneOf([true], window.sv_resource.get('form_errormsg_radio'))
            .required(window.sv_resource.get('form_errormsg_radio')),
        selectedPlan: Yup.string(),
    });

    const initialValues = Object.freeze({
        confirmation: false as boolean,
        selectedPlan: '' as string,
    });

    return (
        <div className={`${baseClassName} ${visible ? '' : 'd-none'} d-print-none`}>
            <AsyncLoader
                action={dataAction}
                renderContent={lazyChildren => (
                    <>
                        {lazyChildren}
                    </>
                )}
                variation={StatusMessageVariation.Subpage}
                onSuccess={handleDataResponse}
            >
                {(infoData: SavingPlanInfoData) => (
                    <Form
                        actionUrl={sendAction}
                        beforeSubmit={() => setIsSending(true)}
                        handleResponse={handleSendResponse}
                        initialValues={initialValues}
                        name='savingPlanChangeForm'
                        validationSchema={validationSchema}
                    >
                        {formik => {
                            if (isSending) {
                                return (<LoadSpinner />);
                            }
                            return (
                                <>
                                    <h2>{window.sv_resource.get('plf_simulation_saving_plan_change_plan_header')}</h2>
                                    <Paragraph>
                                        {
                                            window.sv_resource.get('plf_simulation_saving_plan_change_per_label')
                                            .replace('{{changeYear}}', String(changeYear))
                                        }
                                    </Paragraph>
                                    {infoData.planInfo.map((changeData) => (
                                        <SavingPlanButton
                                            key={`spb-${changeData.id}`}
                                            text={`${changeData.name}: ${window.sv_resource.get('plf_simulation_saving_plan_insured_part_label')} ${changeData.percent}%`}
                                            active={selectedPlanId === changeData.id}
                                            planId={changeData.id}
                                            handleClick={(planId) => handleUpdateClick(planId, formik.setFieldValue)}
                                            disabled={changeData.id === preSelectedPlanId}
                                        />
                                    ))}
                                    <TextField
                                        id="selectedPlan"
                                        label={null}
                                        name="selectedPlan"
                                        type="text"
                                        className="d-none"
                                        placeholder=""
                                        touched={formik.touched.selectedPlan}
                                        errors={formik.errors.selectedPlan}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                    />
                                    <div className={`${baseClassName}_confirmationRow`}>
                                        <Checkbox
                                            checked={formik.values.confirmation}
                                            errors={formik.errors.confirmation}
                                            touched={!!formik.touched.confirmation}
                                            id="confirmation"
                                            name="confirmation"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            label={window.sv_resource.get('plf_simulation_saving_plan_confirmation_label').replace('{{changeYear}}', changeYear)}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <FormButton
                                            className={`${baseClassName}__requestButton`}
                                            disabled={Object.keys(formik.errors).length > 0 || Object.keys(formik.touched).length === 0 || !validatePlanId()}
                                            text={window.sv_resource.get('plf_simulation_saving_plan_send_request_button')}
                                            variation={FormButtonVariation.Primary}
                                            type="submit"
                                            hasIcon={false}
                                        />
                                    </div>
                                </>
                            )}}
                    </Form>
            )}
            </AsyncLoader>
        </div>
    );
}

export default SavingPlanChangeForm;

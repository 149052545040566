import React from 'react';
import './index.scss';

interface AvatarProps {
    image: string;
}

const Avatar: React.FC<AvatarProps> = ({ image }) => {
    const baseClassName = 'a-avatar';

    return (
        <div className={`${baseClassName}`}>
            <img src={image} alt="contact person" className="circle" />
        </div>
    );
};

export default Avatar;

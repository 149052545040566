import React from 'react';
import './index.scss';
import * as Yup from 'yup';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import RadioButton from '../../../../../../../../design/1/js/templates/atoms/form-fields/radiobutton';
import { StepProps } from '../../../lib/StepProps';
import StepNavigation from "../step-navigation";

export interface TypeOfContinuationType {
    typeOfInsurance: string;
}

export const validationSchemaTypeOfContinuationStep = Yup.object().shape({
    typeOfInsurance: Yup.string().required(() => window.sv_resource.get('form_errormsg_radio')),
});

const TypeOfContinuationStep = (({
    disabled, errors, handleBack, id, touched, onChange, onBlur, values, isFirstStep
}: StepProps<TypeOfContinuationType>) => {
    const baseClassName = 'w-typeOfContinuationStep';

    const valuesAreSet = values.typeOfInsurance !== '';

    return (
        <div className={`${baseClassName}`}>
            <h2 className={`${baseClassName}__title`} id={id}>{window.sv_resource.get('plf_onlineform_voluntary_continuation_type_title')}</h2>
            <div className="row gap-xxs">
                <div className={`${baseClassName}__radioWrapper`}>
                    <RadioButton
                        checked={values.typeOfInsurance.toString() === 'fullInsurance'}
                        disabled={disabled}
                        errors={errors.typeOfInsurance}
                        id="fullInsurance"
                        name="typeOfInsurance"
                        onBlur={onBlur}
                        onChange={onChange}
                        touched={touched.typeOfInsurance}
                        label={window.sv_resource.get('plf_onlineform_voluntary_continuation_type_full')}
                    />
                    <RadioButton
                        checked={values.typeOfInsurance.toString() === 'riskInsurance'}
                        disabled={disabled}
                        errors={errors.typeOfInsurance}
                        id="riskInsurance"
                        name="typeOfInsurance"
                        onBlur={onBlur}
                        onChange={onChange}
                        touched={touched.typeOfInsurance}
                        label={window.sv_resource.get('plf_onlineform_voluntary_continuation_type_risk')}
                    />
                    <RadioButton
                        checked={values.typeOfInsurance.toString() === 'premiumInsurance'}
                        disabled={disabled}
                        errors={errors.typeOfInsurance}
                        id="premiumInsurance"
                        name="typeOfInsurance"
                        onBlur={onBlur}
                        onChange={onChange}
                        touched={touched.typeOfInsurance}
                        label={window.sv_resource.get('plf_onlineform_voluntary_continuation_type_free')}
                    />
                </div>
            </div>
            <StepNavigation valuesAreSet={valuesAreSet} disabled={disabled} isFirstStep={isFirstStep} hasErrors={Object.keys(errors).length > 0} handleBack={handleBack} />
        </div>
    );
});

export default TypeOfContinuationStep;

import * as Yup from 'yup';
import { NumberSchema } from 'yup';

export function baseIntegerSchema(): NumberSchema
{
    return Yup.number()
        .integer(window.sv_resource.get('form_errormsg_integer'))
        .transform((_value, originalValue) => Number(cleanIntegerValue(originalValue)))
        .min(1, window.sv_resource.get('form_errormsg_integer'))
        .typeError(window.sv_resource.get('form_errormsg_integer'))
        .required(() => window.sv_resource.get('form_errormsg_integer'));
}

export function cleanIntegerValue(value: string): number
{
    const cleanValue = value
        // Removes spaces
        .replace(/ /g, '')
        // Removes thousands separators
        // typewriter apostrophe (U+0027), punctuation apostrophe (U+2019) and backtick / grave accent (U+060).
        .replace(/['’`]/g, '')
        // Decimal separators `,` and `.` are purposely kept,
        // as the yup validation catches them and shows the correct message.
        .replace(/[.,]/g, '.')
    ;

    return Number(cleanValue);
}

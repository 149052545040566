import React from "react";

const PDF: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
        <path d="M 17.75 4.96875 C 16.679688 4.96875 15.816406 4.078125 15.816406 3.03125 L 15.816406 0 L 3.921875 0 C 3.554688 0 3.242188 0.3125 3.242188 0.679688 L 3.242188 23.320312 C 3.242188 23.6875 3.554688 24 3.921875 24 L 20.105469 24 C 20.46875 24 20.757812 23.6875 20.757812 23.320312 L 20.757812 4.96875 Z M 8.890625 12.105469 C 8.601562 12.367188 8.183594 12.46875 7.582031 12.46875 L 6.71875 12.46875 L 6.71875 13.804688 C 6.71875 14.589844 5.777344 14.589844 5.777344 13.804688 L 5.777344 10.195312 C 5.777344 9.777344 5.960938 9.621094 6.378906 9.621094 C 6.902344 9.621094 8 9.59375 8.417969 9.699219 C 9.570312 10.011719 9.570312 11.554688 8.890625 12.105469 Z M 14.066406 12.707031 C 13.910156 13.515625 13.386719 14.117188 12.550781 14.25 L 10.746094 14.300781 C 10.25 14.300781 10.144531 14.089844 10.144531 13.621094 L 10.144531 10.195312 C 10.144531 9.777344 10.328125 9.621094 10.71875 9.621094 C 11.292969 9.621094 12.289062 9.570312 12.785156 9.699219 C 13.988281 10.011719 14.328125 11.53125 14.066406 12.707031 Z M 18.089844 10.25 C 18.011719 10.328125 17.910156 10.351562 17.75 10.351562 L 15.894531 10.351562 L 15.894531 11.554688 L 17.464844 11.554688 C 18.039062 11.554688 18.039062 12.261719 17.464844 12.261719 L 15.894531 12.261719 L 15.894531 13.804688 C 15.894531 14.484375 15.292969 14.484375 15.085938 14.25 C 15.007812 14.144531 14.953125 13.988281 14.953125 13.804688 L 14.953125 10.195312 C 14.953125 9.777344 15.136719 9.621094 15.53125 9.621094 L 17.75 9.621094 C 18.273438 9.621094 18.273438 10.089844 18.089844 10.25 Z M 18.089844 10.25 "/>
        <path d="M 7.371094 10.328125 L 6.746094 10.328125 L 6.746094 11.765625 L 6.71875 11.765625 C 7.398438 11.765625 8.390625 11.894531 8.390625 11.03125 C 8.390625 10.457031 8 10.328125 7.371094 10.328125 Z M 11.710938 10.40625 L 11.085938 10.40625 L 11.085938 13.570312 C 11.476562 13.570312 12.128906 13.59375 12.445312 13.488281 C 13.332031 13.175781 13.28125 11.449219 12.96875 10.929688 C 12.679688 10.457031 12.261719 10.40625 11.710938 10.40625 Z M 17.75 3.816406 L 19.949219 3.816406 L 16.941406 0.808594 L 16.941406 3.007812 C 16.941406 3.476562 17.308594 3.816406 17.75 3.816406 Z M 17.75 3.816406 "/>
    </svg>

);

export default PDF;

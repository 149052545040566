import React from 'react';
import './index.scss';
import Icon, {IconName} from "../../atoms/icon";

interface LogoutButtonMobileProps {
    label: string;
}

const LogoutButtonMobile = ({
    label,
}: LogoutButtonMobileProps) => {
    const baseClassName = 'm-logout-button-mobile';

    return (
        <div className={baseClassName}>
            <a href='/_logout' title={label} className={`${baseClassName}_link`}>
                <Icon name={IconName.Logout} className={`${baseClassName}_linkIcon`} />
                <span className={`${baseClassName}_linkLabel`}>{label}</span>
            </a>
        </div>
    )
};

export default LogoutButtonMobile;

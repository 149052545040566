import React from 'react';
import './index.scss';

interface SectionProps {
    children: React.ReactNode;
    className?: string;
    oneColumn?: boolean
}

const Section = ({ children, className, oneColumn }: SectionProps) => {
    const baseClassName = 'a-section';

    return (
        <div className={oneColumn ? 'container-lg' : ''}>
            <div className={`${baseClassName} ${className}`}>
                {children}
            </div>
        </div>
    );
};

Section.defaultProps = {
    className: '',
    oneColumn: true,
};

export default Section;

import React from 'react';
import * as Yup from 'yup';
import './index.scss';
import { StringSchema } from 'yup';
import FormWithAnswerText from '../../../../../../../../design/1/js/templates/organisms/form-with-answer-text';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import DatePickerField from '../../../../../../../../design/1/js/templates/molecules/date-picker-field';
import { IconName } from '../../../../../../../../design/1/js/templates/atoms/icon';
import Select from '../../../../../../../../design/1/js/templates/atoms/form-fields/select';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import { validateSocialSecurityNumber } from '../../../../../../../../design/1/js/lib/validateData';
import CivilStateMarriagePartnershipChangeType from '../../../lib/CivilStateMarriagePartnershipChangeType';
import {hasInsuredMultipleIds} from "../../../../../../../../design/1/js/lib/util/InsuredUtils";
import StatusMessage, {MessageType} from "../../../../../../../../design/1/js/templates/molecules/status-message";
import StatusMessageVariation
    from "../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation";

export interface CivilStateFormProps {
    actionUrl: string,
    handleSubmit: (status: number) => void,
}

const CivilStateMarriagePartnershipForm = ({
    actionUrl, handleSubmit,
}:CivilStateFormProps) => {
    const baseClassName = 'w-changeCivilStateMarriagePartnershipForm';
    const validationSchema = Yup.object().shape({
        changeType: Yup.string()
            .typeError(window.sv_resource.get('form_errormsg_date'))
            .required(window.sv_resource.get('form_errormsg_dropdown')),
        // @ts-ignore -> Yup is actually able to handle strings as date; it casts strings with new Date(string)
        date: Yup.date()
            .required(window.sv_resource.get('form_errormsg_date')) as StringSchema,
        nameAfterChange: Yup.string()
            .required(window.sv_resource.get('form_errormsg_string')),
        partnerFirstname: Yup.string()
            .required(window.sv_resource.get('form_errormsg_string')),
        partnerLastname: Yup.string()
            .required(window.sv_resource.get('form_errormsg_string')),
        // @ts-ignore -> Yup is actually able to handle strings as date; it casts strings with new Date(string)
        partnerBirthdate: Yup.date()
            .test('isPast', window.sv_resource.get('form_errormsg_date_isNotInFuture'), (val: Date) => val.getTime() <= (new Date()).setHours(0, 0, 0, 0))
            .typeError(window.sv_resource.get('form_errormsg_date'))
            .required(window.sv_resource.get('form_errormsg_date')) as StringSchema,
        gender: Yup.string()
            .required(window.sv_resource.get('form_errormsg_dropdown')),
        partnerSocialSecurityNumber: Yup.string()
            .test(
                'valid socialSecurityNumber',
                () => window.sv_resource.get('form_errormsg_socialSecurityNumber'),
                value => (value === undefined || validateSocialSecurityNumber(value)),
            ),
    });

    const initialValues = Object.freeze({
        changeType: '',
        date: null,
        nameAfterChange: '',
        partnerFirstname: '',
        partnerLastname: '',
        partnerBirthdate: null,
        gender: '',
        partnerSocialSecurityNumber: '',
    });

    return (
        <FormWithAnswerText
            actionUrl={actionUrl}
            className={`${baseClassName}`}
            handleSubmit={handleSubmit}
            handleValidate={validationSchema}
            initialValues={initialValues}
            name="ChangeCivilStateDecreasedPartner"
        >
            {formik => (
                <div className={`${baseClassName}`}>
                    <h2 className={`${baseClassName}__title`}>{window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_masterdata_title')}</h2>
                    <div className="row gap-xxs">
                        <Select
                            className="col-12 col-lg-6"
                            errors={formik.errors.changeType}
                            id="changeType"
                            label={window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_option_label')}
                            name="changeType"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            touched={formik.touched.changeType}
                            value={formik.values.changeType}
                        >
                            <option value="" disabled>{window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_option_default')}</option>
                            <option value={CivilStateMarriagePartnershipChangeType.Married}>{window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_option_marriage')}</option>
                            <option value={CivilStateMarriagePartnershipChangeType.RegisteredPartnership}>{window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_option_partnership')}</option>
                        </Select>
                    </div>
                    <div className="row gap-xxs">
                        <DatePickerField
                            className="col-12 col-lg-6"
                            errors={formik.errors.date}
                            icon={IconName.Calendar}
                            id="date"
                            label={window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_date_to_change_label')}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            touched={!!formik.touched.date}
                            value={formik.values.date}
                        />
                        <TextField
                            className="col-12 col-lg-6"
                            errors={formik.errors.nameAfterChange}
                            id="nameAfterChange"
                            label={window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_name_after_change_label')}
                            name="nameAfterChange"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder=""
                            touched={formik.touched.nameAfterChange}
                            type="text"
                        />
                    </div>
                    <h2 className={`${baseClassName}__title`}>{window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_partnerdata_title')}</h2>
                    <div className="row gap-xxs">
                        <TextField
                            className="col-12 col-lg-6"
                            errors={formik.errors.partnerFirstname}
                            id="partnerFirstname"
                            label={window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_partner_firstname_label')}
                            name="partnerFirstname"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder=""
                            touched={formik.touched.partnerFirstname}
                            type="text"
                        />
                        <TextField
                            className="col-12 col-lg-6"
                            errors={formik.errors.partnerLastname}
                            id="partnerLastname"
                            label={window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_partner_lastname_label')}
                            name="partnerLastname"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder=""
                            touched={formik.touched.partnerLastname}
                            type="text"
                        />
                        <DatePickerField
                            className="col-12 col-lg-6"
                            errors={formik.errors.partnerBirthdate}
                            icon={IconName.Calendar}
                            id="partnerBirthdate"
                            label={window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_partner_birthday_label')}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            maxDate={new Date()}
                            touched={!!formik.touched.partnerBirthdate}
                            value={formik.values.partnerBirthdate}
                        />
                        <Select
                            className="col-12 col-lg-6"
                            errors={formik.errors.gender}
                            id="gender"
                            label={window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_partner_gender_label')}
                            name="gender"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            touched={formik.touched.gender}
                            value={formik.values.gender}
                        >
                            <option value="" disabled>{window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_partner_gender_option_default')}</option>
                            <option value="male">{window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_partner_gender_option_male')}</option>
                            <option value="female">{window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_partner_gender_option_female')}</option>
                        </Select>
                        <TextField
                            className="col-12 col-lg-6"
                            errors={formik.errors.partnerSocialSecurityNumber}
                            id="partnerSocialSecurityNumber"
                            label={window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_partner_social_number_label')}
                            name="partnerSocialSecurityNumber"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={window.sv_resource.get('plf_servicesform_civil_state_marragie_partnership_partner_social_number_placeholder')}
                            touched={formik.touched.partnerSocialSecurityNumber}
                            type="text"
                        />
                    </div>
                    {hasInsuredMultipleIds() && (
                        <div className="row my-4">
                            <StatusMessage
                                customMessageColor={MessageType.Info}
                                messageType={MessageType.Info}
                                description={window.sv_resource.get('plf_yourprofile_master_data_civil_state_description_multiple_insured_ids')}
                                variation={StatusMessageVariation.WithBackground}
                            />
                        </div>
                    )}
                    <FormButton
                        className={`${baseClassName}__submit`}
                        disabled={Object.keys(formik.errors).length > 0 || Object.keys(formik.touched).length === 0}
                        text={window.sv_resource.get('plf_servicesform_civil_state_marriage_partnership_submit_btn')}
                        type="submit"
                        variation={FormButtonVariation.Primary}
                    />
                </div>
            )}
        </FormWithAnswerText>
    );
};

export default CivilStateMarriagePartnershipForm;

import { loadWidget } from '@sitevision/react';
import React from 'react';
import ReactDOM from 'react-dom';
import Document from './templates/organisms/document';
import Category from './lib/Category';
import DocumentObject from './lib/Document';

loadWidget('.plf-document', documentWidget => {
    const categories: Category[] = JSON.parse(documentWidget.dataset.downloadCategories);
    const documents: DocumentObject[] = JSON.parse(documentWidget.dataset.downloadElements);

    ReactDOM.render(<Document categories={categories} documents={documents} />, documentWidget);
});

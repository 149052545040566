import React from 'react';
import './index.scss';
import Button, { ButtonType } from '../button';
import PensionConsultant from '../../../../../../platform/widgets/view/welcome/ts/lib/PensionConsultant';
import Paragraph from '../paragraph';

export interface ConsultantProps {
    consultant: PensionConsultant;
}

const ContactInfos: React.FC<ConsultantProps> = ({ consultant }) => {
    const baseClassName = 'a-contact-infos';
    return (
        <div className={`${baseClassName}`}>
            <Paragraph className={`${baseClassName}__profession`}>{window.sv_resource.get('contactcard_salutation')}</Paragraph>
            <Paragraph className={`${baseClassName}__name display2`}>{`${consultant?.name}`}</Paragraph>
            <Button text={window.sv_resource.get('contactcard_btn_text')} href={consultant?.url} type={ButtonType.Default} />
        </div>
    );
};

export default ContactInfos;

import React, { useState } from 'react';
import './index.scss';
import { Switch } from '@headlessui/react';

export interface SwitchStateValues {
    on: string;
    off: string;
}

interface ToggleSwitchProps {
    checked: boolean;
    className?: string,
    label?: string,
    onChange: (checked: boolean) => void,
    states?: SwitchStateValues,
}

// Uses getters / setters because calling it directly would lead
// to window.sv_resource being called before it being properly defined
const defaultStates: SwitchStateValues = {
    get on() {
        return window.sv_resource.get('sv_base_yes');
    },

    get off() {
        return window.sv_resource.get('sv_base_no');
    },
};

const ToggleSwitch = ({
    checked,
    className,
    label,
    onChange,
    states,
}: ToggleSwitchProps) => {
    const [isLoading, setLoading] = useState(false);

    const baseClassName = 'a-toggleSwitch';

    const handleEnabled = async (isChecked: boolean) => {
        if (!isLoading) {
            setLoading(true);
            await onChange(isChecked);
            setLoading(false);
        }
    };

    return (
        <>
            <Switch.Group>
                <div className={`${baseClassName} ${className} ${isLoading ? '-loading' : ''}`}>
                    <Switch.Label className="mr-4">{label}</Switch.Label>
                    <Switch
                        checked={checked}
                        onChange={handleEnabled}
                        className={`${checked ? '-yes' : '-no'} ${baseClassName}__button`}
                    >
                        <span className={`${baseClassName}__yes-btn`}>{states.on}</span>
                        <span className={`${baseClassName}__no-btn`}>{states.off}</span>
                        <span className={`${baseClassName}__toggle ${checked ? 'translate-x-0' : 'translate-x-end'}`}>
                            {checked ? states.on : states.off}
                        </span>
                    </Switch>
                </div>
            </Switch.Group>
        </>
    );
};

ToggleSwitch.defaultProps = {
    className: '',
    label: '',
    states: defaultStates,
};

export default ToggleSwitch;

import React from 'react';
import './index.scss';
import RichText from '../../../../../../../../design/1/js/templates/atoms/richtext';
import DotData from '../../../lib/DotData';

export interface DocumentDotProps {
    dot: DotData;
    dotIndex: number;
    onClick: (id: string) => void;
}

const DocumentDot = ({ dot, dotIndex, onClick }: DocumentDotProps) => {
    const baseClassName = 'w-documentDot';

    return (
        <span className={`${baseClassName}`} style={{ top: `${dot.y.toString()}%`, left: `${dot.x.toString()}%` }}>
            <a className={`${baseClassName}__dot`} href={`#${dot.title}-${dotIndex.toString()}`} onClick={() => onClick(`${dot.title}-${dotIndex.toString()}`)}> </a>
            <span className={`${baseClassName}__inner`}>
                <h4>{dot.title}</h4>
                <RichText text={dot.text} />
                {dot.link !== '' ? <a href={dot.link}>{dot.linkText}</a> : ''}
            </span>
        </span>
    );
};

export default DocumentDot;

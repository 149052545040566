import React from 'react';

const ConsultantMessageListTitleRow = () => (
    <>
        <div className="row col-11">
            <div className="col-5 text-start">
                {window.sv_resource.get('plf_messagetool_head_title_and_subject')}
            </div>
            <div className="col-2 text-start">
                {window.sv_resource.get('plf_messagetool_consultant_head_insured')}
            </div>
            <div className="col-2 text-start">
                {window.sv_resource.get('plf_messagetool_head_date')}
            </div>
            <div className="col-3 text-start">
                {window.sv_resource.get('plf_messagetool_consultant_head_consultant')}
            </div>
        </div>
        <div className="col-1" />
    </>
);

export default ConsultantMessageListTitleRow;

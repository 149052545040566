import React from 'react';
import './index.scss';

interface TableHeightRowProps {
    children: React.ReactNode;
    className?: string;
    download?: string;
    href?: string;
}

const TableHeightRow = ({
    children, className, download, href,
}: TableHeightRowProps) => {
    const baseClassName = 'a-tableHeightRow';
    const CustomTag = href ? 'a' : 'div';

    return (
        <CustomTag className={`${baseClassName} ${className} row`} download={download} href={href}>
            {children}
        </CustomTag>
    );
};

TableHeightRow.defaultProps = {
    className: '',
    href: null,
    download: null,
};

export default TableHeightRow;

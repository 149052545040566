export default function styleFrontPage() {
    styleSections();
}

function styleSections() {
    document.addEventListener('DOMContentLoaded', () => {
        const sections = document.querySelectorAll('.plf-main:not([data-skip-section-color]) .sv-widget .a-section');

        for (const [index, activeSection] of sections.entries()) {
            const widget = activeSection.parentElement.parentElement.parentElement;
            if ((index % 2) !== 0) {
                widget.classList.add('-white');
            } else {
                widget.classList.add('-gray');
            }
        }
    });
}

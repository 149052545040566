import React from 'react';
import './index.scss';
import LangNav from '../lang-nav';

interface HeaderTopProps {
    authenticated: string;
}

const HeaderTop = ({ authenticated }: HeaderTopProps) => {
    const baseClassName = 'm-header-top';
    return (
        <div className={`${baseClassName} ${!authenticated ? '-anonymous' : 'd-none d-lg-block'}`}>
            <div className="container-lg">
                <div className={`${baseClassName}__langnav offset-2`}>
                    <LangNav />
                </div>
            </div>
        </div>
    );
};

export default HeaderTop;

import React from 'react';
import './index.scss';

export enum NavigationType {
    Main = 'main',
    Sub = 'sub',
    Side = 'side',
}

export enum NavDirection {
    Row = 'row',
    RowReverse = 'rowReverse',
}

export interface NavigationProps {
    type?: NavigationType;
    direction?: NavDirection;
}

const Navigation: React.FC<NavigationProps> = ({ children, type = NavigationType.Main, direction = NavDirection.Row }) => {
    const baseClassName = 'm-navigation';
    return (
        <nav className={`${baseClassName} -${type} navbar-expand-lg`}>
            <div className={type === NavigationType.Main ? 'collapse navbar-collapse' : 'container-lg'}>
                <ul className={`${baseClassName}__list ${type === NavigationType.Main ? `navbar-nav ${direction === NavDirection.RowReverse ? '-reverse' : '' }` : ''}`}>
                    {children}
                </ul>
            </div>
        </nav>
    );
};

export default Navigation;

import React, { useEffect, useRef, useState } from "react";
import './index.scss';

export enum SpinnerSize {
    Medium = 'medium',
    Large = 'large',
}

interface LoadSpinnerProps {
    loadingImages?: boolean;
    autoscroll?: boolean;
    inline: boolean;
    size?: SpinnerSize;
}

const LoadSpinner = ({ loadingImages, autoscroll, inline, size }: LoadSpinnerProps) => {
    const baseClassName = 'a-loadSpinner';
    const images = [
        '/design/img/illustrations/insurance/active.svg',
        '/design/img/illustrations/insurance/retirement_pension.svg',
        '/design/img/illustrations/insurance/disability.svg',
        '/design/img/illustrations/insurance/divorce.svg',
        '/design/img/illustrations/insurance/spouse_pension.svg',
    ];
    const ref = useRef<HTMLDivElement>();
    const [activeImage, setActiveImage] = useState(0);


    useEffect(() => {
        if (autoscroll) {
            ref.current.scrollIntoView();
        }
    }, [autoscroll]);

    const nextImage = (e) => {
        setActiveImage((activeImage + 1) % images.length);
    };

    return (
        <div className={`${baseClassName}_container`}>
            <div className={`${baseClassName} ${inline && '-inline'} -size-${size}${loadingImages ? ' -images': ''}`} ref={ref} onAnimationIteration={nextImage}></div>
            { loadingImages &&
                <div className={`${baseClassName}_images -size-${size}`}>
                    <div className={`${baseClassName}_images_inner_container`}>
                        {images.map((url, i) => (<img key={i} className={`${baseClassName}_image ${i === activeImage ? '-active': ''}`} src={url}/>))}
                    </div>
                </div>
            }
        </div>
    );
};

LoadSpinner.defaultProps = {
    loadingImages: false,
    autoscroll: false,
    inline: false,
    size: SpinnerSize.Medium,
};

export default LoadSpinner;

import React from 'react';
import './index.scss';

const FooterLinkList: React.FC = ({ children }) => {
    const baseClassName = 'm-footer-link-list';

    return (
        <div className={`${baseClassName}`}>
            {children}
        </div>
    );
};

export default FooterLinkList;

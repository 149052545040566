import React from 'react';
import './index.scss';
import BrandLogo from '../../atoms/brand-logo';
import MobileNavPopover from '../mobile-nav-popover';
import { NavbarProps } from '../navbar';

const MobileNav = ({ navigationItems, newDocuments, newMessages }: NavbarProps) => {
    const baseClassName = 'm-mobileNav';
    return (
        <div className={`${baseClassName} row d-lg-none`}>
            <div className={`${baseClassName}__mobileLogo col-6`}>
                <a href="/">
                    <BrandLogo />
                </a>
            </div>
            <div className={`${baseClassName}__popover col-6`}>
                <MobileNavPopover navigationItems={navigationItems} newDocuments={newDocuments} newMessages={newMessages} />
            </div>
        </div>
    );
};

export default MobileNav;

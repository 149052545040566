import React from 'react';
import './index.scss';
import * as Yup from 'yup';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import { StepProps } from '../../../lib/StepProps';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import FileUploadField from '../../../../../../../../design/1/js/templates/molecules/file-upload-field';
import FileContent from '../../../../../../../../design/1/js/lib/FileContent';
import StepNavigation from "../step-navigation";

export interface UploadCourtDecisionType {
    files: FileContent[];
}

export const validationSchemaUploadCourtDecisionStep = Yup.object().shape({
    files: Yup
        .array().of(
            Yup.object().shape({
                file: Yup.string(),
                fileName: Yup.string(),
                fileType: Yup.string(),
            }),
        ).min(1, () => window.sv_resource.get('form_errormsg_upload'))
        .required(() => window.sv_resource.get('form_errormsg_upload')),
});

const UploadCourtDecisionStep = (({
    disabled, errors, handleBack, id, handleFileChange, touched, onBlur, values, isFirstStep
}: StepProps<UploadCourtDecisionType>) => {
    const baseClassName = 'w-uploadCourtDecisionStep';
    const valuesAreSet = values.files.length > 0;

    return (
        <div className={`${baseClassName}`}>
            <h2 className={`${baseClassName}__title`} id={id}>{window.sv_resource.get('plf_onlineform_civil_state_document_title')}</h2>
            <Paragraph className="strong">{window.sv_resource.get('plf_onlineform_civil_state_document_description')}</Paragraph>
            <div className="row gap-lg-s">
                <FileUploadField
                    accept="application/pdf, image/jpeg, image/png"
                    disabled={disabled}
                    label={window.sv_resource.get('plf_onlineform_civil_state_document_label')}
                    onChange={handleFileChange}
                    id="files"
                    placeholder=""
                    name="files"
                    errors={touched.files ? errors.files as string : ''}
                    onBlur={onBlur}
                    touched={!!touched.files}
                    value={values.files}
                />
            </div>
            <StepNavigation valuesAreSet={valuesAreSet} disabled={disabled} isFirstStep={isFirstStep} hasErrors={Object.keys(errors).length > 0} handleBack={handleBack} />
        </div>
    );
});

export default UploadCourtDecisionStep;

import React from 'react';
import ClientMessageListEntry from '../../molecules/client-message-list-entry';
import BaseMessagetoolInbox from '../base-messagetool-inbox';
import ClientMessageListTitleRow from '../../molecules/client-message-list-title-row';
import {MessageToolView} from "../../../lib/enum/MessageToolView";
import MessageType from "../../../lib/MessageType";

interface MessagetoolInboxClientProps {
    switchView: (view: MessageToolView) => void;
    setSelectedMessage: (message: MessageType) => void;
    messagesAction: string;
}

const MessagetoolInboxClient = ({
    switchView, setSelectedMessage, messagesAction,
}: MessagetoolInboxClientProps) => (
    <BaseMessagetoolInbox
        messagesAction={messagesAction}
        messageListTitleRow={() => (
            <ClientMessageListTitleRow />
        )}
        messageListEntry={(
            updateMessage,
            message,
            ref,
            key,
        ) => (
            <ClientMessageListEntry
                switchView={switchView}
                setSelectedMessage={setSelectedMessage}
                message={message}
                ref={ref}
                key={key}
            />
        )}
        switchView={switchView}
        notFoundMessage={window.sv_resource.get('plf_messagetool_no_message')}
    />
);

export default MessagetoolInboxClient;

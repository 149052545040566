import React, { useState } from 'react';
import './index.scss';
import * as Yup from 'yup';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import Form from '../../../../../../../../design/1/js/templates/organisms/form';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import Checkbox from '../../../../../../../../design/1/js/templates/atoms/form-fields/checkbox';
import InputData from '../../../lib/InputData';
import LoadSpinner from '../../../../../../../../design/1/js/templates/atoms/load-spinner';
import AnswerText from '../../../../../../../../design/1/js/templates/molecules/answer-text';
import StatusMessageVariation
    from '../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation';
import usePdfDownload from '../../../../../../../../design/1/js/lib/hooks/usePdfDownload';

interface RequestFormProps {
    actionUrl: string;
    checkboxLabel: string;
    description: string;
    inputData: InputData;
    name: string;
    requestButtonLabel?: string;
}

const RequestForm = ({
    actionUrl, checkboxLabel, description, name, inputData, requestButtonLabel,
}: RequestFormProps) => {
    const baseClassName = 'w-requestForm';
    const [isLoading, setLoading] = useState(false);
    const [failingResponse, setFailingResponse] = useState(null);

    const onSubmit = usePdfDownload(actionUrl, setLoading, inputData);

    const validationSchema = Yup.object().shape({
        confirmation: Yup.boolean()
            .oneOf([true], window.sv_resource.get('form_errormsg_radio'))
            .required(window.sv_resource.get('form_errormsg_radio')),
    });

    const initialValues = Object.freeze({
        confirmation: false,
    });

    if (isLoading) {
        return (<LoadSpinner />);
    }

    if (failingResponse !== null) {
        return (<AnswerText response={failingResponse} variation={StatusMessageVariation.Subpage} />);
    }

    return (
        <div className={`${baseClassName} d-print-none`}>
            <Form
                actionUrl={actionUrl}
                initialValues={initialValues}
                customOnSubmit={onSubmit}
                name={name}
                validationSchema={validationSchema}
            >
                {formik => (
                    <>
                        <Paragraph>{description}</Paragraph>
                        <Checkbox
                            checked={formik.values.confirmation}
                            errors={formik.errors.confirmation}
                            touched={!!formik.touched.confirmation}
                            id="confirmation"
                            name="confirmation"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            label={checkboxLabel}
                        />
                        <div className="d-flex justify-content-end">
                            <FormButton className={`${baseClassName}__requestButton`} disabled={Object.keys(formik.errors).length > 0 || Object.keys(formik.touched).length === 0} text={requestButtonLabel} variation={FormButtonVariation.Primary} type="submit" hasIcon={false} />
                        </div>
                    </>
                )}
            </Form>
        </div>
    );
};

RequestForm.defaultProps = {
    get requestButtonLabel() {
        return window.sv_resource.get('plf_simulation_calculated_btn_request_label');
    },
};

export default RequestForm;

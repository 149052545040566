import {loadWidget} from '@sitevision/react';
import React from 'react';
import ReactDOM from 'react-dom';
import Button, {ButtonType} from '../../../../../design/1/js/templates/atoms/button';
import '../scss/index.scss';

loadWidget('.plf-button-area', buttonWidget => {
    const link = buttonWidget.dataset.link;
    const text = buttonWidget.dataset.text;
    const newTab = buttonWidget.dataset.newTab;

    ReactDOM.render(<Button text={text} href={link} target={newTab ? '_blank' : '_self'} type={ButtonType.Orange} />, buttonWidget);
});

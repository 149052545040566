import React from 'react';
import Paragraph from '../../atoms/paragraph';

export interface IntroductionTextProps {
    description: string;
    title: string;
}

const IntroductionText = ({ description, title }: IntroductionTextProps) => {
    const baseClassName = 'm-introductionText';
    return (
        <>
            <h1>{title}</h1>
            <Paragraph className={`${baseClassName}__description`}>
                {description}
            </Paragraph>
        </>
    );
};

export default IntroductionText;

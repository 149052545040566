import { loadWidget } from '@sitevision/react';
import React from 'react';
import ReactDOM from 'react-dom';
import Variation from './lib/Variation';
import FaqSection from './templates/organisms/faq-section';
import Category from '../../../../../design/1/js/templates/molecules/accordion/lib/Category';
import AccordionData from '../../../../../design/1/js/templates/molecules/accordion/lib/AccordionData';

loadWidget('.plf-shared-faq', sharedFaq => {
    const hasFilter: boolean = Boolean(sharedFaq.dataset.hasFilter);
    const data: AccordionData[] = JSON.parse(sharedFaq.dataset.data);
    const title = sharedFaq.dataset.title;
    const headerId = sharedFaq.dataset.headerId;
    const categories: Category[] = JSON.parse(sharedFaq.dataset.categories);
    const variation: Variation = sharedFaq.dataset.variation as Variation;

    ReactDOM.render(<FaqSection hasFilter={hasFilter} headerId={headerId} data={data} title={title} categories={categories} variation={variation} />, sharedFaq);
});

import React, { useState } from 'react';
import './index.scss';
import ContactformForm, { ContactformType } from '../../molecules/contactform-form';
import IntroductionText from '../../../../../../../../design/1/js/templates/molecules/introduction-text';

interface ContactformProps {
    actionUrl: string;
    formType: ContactformType;
    recaptchaKey: string;
}

const Contactform = ({ actionUrl, formType, recaptchaKey }: ContactformProps) => {
    const [formSubmit, setFormSubmit] = useState(false);
    const baseClassName = 'w-contactform';

    const handleFormSubmit = () => {
        setFormSubmit(!formSubmit);
    };

    const description = formType === ContactformType.Support ? window.sv_resource.get('plf_contactform_introduction_description_support') : window.sv_resource.get('plf_contactform_introduction_description_access');
    const title = formType === ContactformType.Support ? window.sv_resource.get('plf_contactform_introduction_title_support') : window.sv_resource.get('plf_contactform_introduction_title_access');

    return (
        <div className={`${baseClassName}`}>
            { !formSubmit ? <IntroductionText description={description} title={title} /> : <></> }
            <div className={`${baseClassName}-wrapper`}>
                <ContactformForm actionUrl={actionUrl} formType={formType} handleSubmit={handleFormSubmit} recaptchaKey={recaptchaKey} />
            </div>
        </div>
    );
};

export default Contactform;

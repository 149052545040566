import React from 'react';
import './index.scss';

const BrandLogo: React.FC = () => {
    const logoUrl = `/design/img/logo/logo-${window.sv_resource.getIsoLanguage()}.png`;
    return (
        <img src={logoUrl} alt="Text" />
    );
};

export default BrandLogo;

import React from 'react';
import './index.scss';
import Icon, { IconName } from '../../atoms/icon';

const PrintButton = () => {
    const baseClassName = 'm-printButton';

    const handleClick = () => {
        window.print();
    };

    return (
        <button
            className={`${baseClassName}`}
            onClick={handleClick}
            type="button"
        >
            <Icon name={IconName.Print} className={`${baseClassName}__icon`} />
            <span>{window.sv_resource.get('print_button_text')}</span>
        </button>
    );
};

export default PrintButton;

import React, { useRef } from 'react';
import './index.scss';
import { FormikErrors, FormikValues } from 'formik';
import Icon, { IconName } from '../../icon';
import FileContent from '../../../../lib/FileContent';

export interface FileUploadProps {
    accept?: string;
    className?: string;
    disabled?: boolean;
    errors: string | string[] | FormikErrors<FormikValues> | FormikErrors<FormikValues>[];
    id: string;
    multiple?: boolean;
    name: string;
    onBlur: (e) => void;
    onChange: (e) => void;
    placeholder: string;
    touched: boolean;
    value: FileContent[]
}

const FileUpload = (props: FileUploadProps) => {
    const baseClassName = 'a-fileUpload';
    const filename = props.value.length > 0 ? Array.from(props.value).map((file: FileContent) => file.fileName).join(', ') : '';

    const inputRef = useRef<HTMLInputElement>();

    const getButtonLabel = () => {
        if (filename === '') {
            if (props.multiple) {
                return window.sv_resource.get('form_field_file_multiple');
            }
            return window.sv_resource.get('form_field_file_upload');
        }
        if (props.multiple) {
            return window.sv_resource.get('form_field_file_multiple_remove');
        }
        return window.sv_resource.get('form_field_file_upload_remove');
    };

    const handleFileUploadButtonClick = () => {
        if (props.value.length !== 0) {
            props.onChange({ target: { [props.id]: [] } });
            inputRef.current!.value = '';
        } else {
            openFileInput();
        }
    };

    const openFileInput = () => {
        inputRef.current?.click();
    };

    return (
        <>
            <div className={`${baseClassName} ${props.className} ${props.touched ? '-touched' : ''} ${props.errors ? '-error' : ''} ${props.disabled ? '-disabled' : ''}`}>
                <div className={`${baseClassName}__preview`}>
                    {filename}
                    {props.errors && props.touched && <Icon name={IconName.ErrorCircle} className={`${baseClassName}__icon`} />}
                    {!props.errors && props.touched && <Icon name={IconName.SuccessCircle} className={`${baseClassName}__icon`} />}
                </div>
                <label className={`${baseClassName}__control`} htmlFor={props.id}>
                    <input
                        accept={props.accept}
                        className="form-control"
                        disabled={props.disabled}
                        id={props.id}
                        multiple={props.multiple}
                        name={props.name}
                        onBlur={props.onBlur}
                        onChange={props.onChange}
                        placeholder={props.placeholder}
                        ref={inputRef}
                        type="file"
                    />
                    <button className={`${baseClassName}__upload`} onClick={handleFileUploadButtonClick} type="button">{getButtonLabel()}</button>
                </label>
            </div>
            {props.errors !== '' && <div className={`${baseClassName}__msg small-text`}>{props.errors}</div>}
        </>
    );
};

FileUpload.defaultProps = {
    accept: '',
    className: '',
    disabled: false,
    multiple: false,
};

export default FileUpload;

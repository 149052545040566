import React from 'react';
import Simulation from '../simulation';
import DataTablePensionCalculator from '../../molecules/data-table-pension-calculator';
import PensionCalculatorResponseContext from '../../../lib/pensionCalculator/PensionCalculatorResponseContext';
import PensionCalculatorSimulationForm from '../pension-calculator-simulation-form/index';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import PensionCalculationSimulationCalculatedData
    from '../../../lib/pensionCalculator/PensionCalculationSimulationCalculatedData';
import PensionCalculationSimulationCalculated from '../../molecules/pension-calculation-calculated';
import { formatDate } from '../../../../../../../../design/1/js/lib/formatData';
import './index.scss';

interface PensionCalculatorSimulationProps {
    action: string;
    dataTableAction: string;
    getOrdinaryRetirementDateAction: string;
    getBirthdateAction: string;
    getInfosOnRetirementDateAction: string;
    getRetirementPercentageAction: string;
    getGeneralInfosAction: string;
    headerIdSimulation: string;
    linkToCapitalPayments: string;
    shouldDisplayDataTable: boolean;
}

const PensionCalculatorSimulation = ({
    action, dataTableAction, getOrdinaryRetirementDateAction, getBirthdateAction, getInfosOnRetirementDateAction, getRetirementPercentageAction, getGeneralInfosAction, headerIdSimulation, linkToCapitalPayments, shouldDisplayDataTable,
}: PensionCalculatorSimulationProps) => {
    const baseClassName = 'w-pensionCalculatorSimulation';
    const dataTable = shouldDisplayDataTable ? <DataTablePensionCalculator action={dataTableAction} /> : <></>

    return (
        <div className={`${baseClassName}`}>
            <Simulation
                calculatingAction={action}
                simulationDataTable={() => dataTable}
                simulationForm={(handleFormSubmit: (response: PensionCalculatorResponseContext) => void) => <PensionCalculatorSimulationForm getOrdinaryRetirementDateAction={getOrdinaryRetirementDateAction} getBirthdateAction={getBirthdateAction} getRetirementPercentageAction={getRetirementPercentageAction} getInfosOnRetirementDateAction={getInfosOnRetirementDateAction} getGeneralInfosAction={getGeneralInfosAction} handleSubmit={handleFormSubmit} headerId={headerIdSimulation} />}
                simulationAction={(calculatingAction, handleReset, handleCreatePdf) => (
                    <AsyncLoader
                        action={calculatingAction}
                        autoscroll
                        renderContent={lazyChildren => (
                            <>
                                {lazyChildren}
                            </>
                        )}
                        requestJob={true}
                    >
                        {(simulationData: PensionCalculationSimulationCalculatedData) => (
                            <>
                                <h2 id={headerIdSimulation} className={`${baseClassName}__simulationHeader`}>
                                    <span className="d-print-inline d-none">
                                        {simulationData.insuredData.name}
                                        {window.sv_resource.get('plf_simulation_print_title_link')}
                                    </span>
                                    {window.sv_resource.get('plf_simulation_title')}
                                    <span className="d-print-inline d-none">{` ${window.sv_resource.get('plf_simulation_print_title_date_link')} ${formatDate(new Date())} `}</span>
                                </h2>
                                <PensionCalculationSimulationCalculated handleCreatePdf={handleCreatePdf} simulationData={simulationData} handleReset={handleReset} linkToCapitalPayments={linkToCapitalPayments} />
                            </>
                        )}
                    </AsyncLoader>
                )}
            />
        </div>
    );
};

export default PensionCalculatorSimulation;

import React, { forwardRef } from 'react';
import './index.scss';
import { FormikErrors, FormikValues } from 'formik';
import Icon, { IconName } from '../../icon';

export interface TextInputProps {
    autoComplete?: string;
    className?: string;
    disabled?: boolean;
    errors: string | string[] | FormikErrors<FormikValues> | FormikErrors<FormikValues>[];
    icon?: IconName;
    id: string;
    name: string;
    onBlur: (e) => void;
    onChange: (e) => void;
    onFocus?: (e) => void;
    placeholder?: string;
    tabbable?: boolean;
    inputMode?: "search" | "text" | "none" | "tel" | "url" | "email" | "numeric" | "decimal"
    touched: boolean;
    type: string;
    value?: string | number;
    isLoading?: boolean,
    defaultValue?: string | number;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props: TextInputProps, ref) => {
    const baseClassName = 'a-textInput';

    return (
        <>
            <div className={`${baseClassName} ${props.className} ${props.icon ? '-icon' : ''} ${props.touched ? '-touched' : ''} ${props.errors ? '-error' : ''}`}>
                <input
                    autoComplete="off"
                    ref={ref}
                    disabled={props.disabled}
                    id={props.id}
                    name={props.name}
                    onBlur={props.onBlur}
                    onChange={props.onChange}
                    onFocus={props.onFocus}
                    placeholder={props.placeholder}
                    tabIndex={props.tabbable ? 0 : -1}
                    type={props.type}
                    inputMode={props.inputMode}
                    value={props.value}
                    defaultValue={props.defaultValue}
                />
                {props.icon && (
                    <Icon name={props.icon} className={`${baseClassName}__input-icon ${props.disabled && '-disabled'}`} />
                )}

                {props.errors && !props.isLoading && props.touched && <Icon name={IconName.ErrorCircle} className={`${baseClassName}__error-icon`} />}
                {!props.errors && !props.isLoading && props.touched && <Icon name={IconName.SuccessCircle} className={`${baseClassName}__success-icon`} />}
            </div>
            {props.errors && props.touched && <div className={`${baseClassName}__msg small-text`}>{props.errors}</div>}
        </>
    );
});

TextInput.defaultProps = {
    autoComplete: 'on',
    className: '',
    disabled: false,
    onFocus: undefined,
    tabbable: true,
    value: undefined,
};

export default TextInput;

import React from 'react';
import './index.scss';
import DataTableData from '../../../lib/DataTableData';
import Table from '../../../../../../../../design/1/js/templates/molecules/table';
import TableBody from '../../../../../../../../design/1/js/templates/atoms/table-body';
import TableHeightRow from '../../../../../../../../design/1/js/templates/atoms/table-height-row';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import TableHead from '../../../../../../../../design/1/js/templates/atoms/table-head';
import { formatCurrency } from '../../../../../../../../design/1/js/lib/formatData';
import StatusMessageVariation
    from '../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation';

interface DataTablePensionCalculatorProps {
    action: string;
}

const DataTablePensionCalculator = ({
    action,
}: DataTablePensionCalculatorProps) => {
    const baseClassName = 'w-dataTablePensionCalculator';

    return (
        <div className={`${baseClassName}`}>
            <AsyncLoader
                action={action}
                renderContent={lazyChildren => (
                    <>
                        {lazyChildren}
                    </>
                )}
                variation={StatusMessageVariation.Subpage}
            >
                {(dataTable: DataTableData[]) => (
                    <Table>
                        <TableHead>
                            <div className="col-7 col-lg-9 text-start">{window.sv_resource.get('plf_simulation_table_head_retirement_age')}</div>
                            <div className="col-5 col-lg-3">{window.sv_resource.get('plf_simulation_table_head_pension')}</div>
                        </TableHead>
                        <TableBody>
                            {dataTable.map((data, index) => (
                                <TableHeightRow key={`row-${index.toString()}`}>
                                    <div className={`${baseClassName}__column col-7 col-lg-9`}>
                                        {data.title}
                                    </div>
                                    <div className={`${baseClassName}__column justify-content-center col-5 col-lg-3`}>
                                        {formatCurrency(+data.value)}
                                        <span className={`${baseClassName}__currency small-text`}>{data.currency}</span>
                                    </div>
                                </TableHeightRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </AsyncLoader>
        </div>
    );
};

export default DataTablePensionCalculator;

import { loadWidget } from '@sitevision/react';
import React from 'react';
import ReactDOM from 'react-dom';
import ConsultantType from './lib/consultant/ConsultantType';
import MessagetoolClient from './templates/organisms/messagetool-client';
import MessagetoolConsultant from './templates/organisms/messagetool-consultant';

loadWidget('.plf-messagetool-client', messageWidget => {
    const answerAction = messageWidget.dataset.answerAction;
    const messagesAction = messageWidget.dataset.messagesAction;
    const sendMessageAction = messageWidget.dataset.sendMessageAction;
    const updateReadStatusAction = messageWidget.dataset.updateReadStatusAction;
    const consultant: ConsultantType = JSON.parse(messageWidget.dataset.consultant);
    ReactDOM.render(<MessagetoolClient answerAction={answerAction} consultant={consultant} messagesAction={messagesAction} sendMessageAction={sendMessageAction} updateReadStatusAction={updateReadStatusAction} />, messageWidget);
});

loadWidget('.plf-messagetool-consultant', messageWidget => {
    const answerAction = messageWidget.dataset.answerAction;
    const messagesAction = messageWidget.dataset.messagesAction;
    const saveMessageUpdateAction = messageWidget.dataset.saveMessageUpdateAction;
    const sendMessageAction = messageWidget.dataset.sendMessageAction;
    const consultantList = JSON.parse(messageWidget.dataset.consultantList);
    const subjectList = JSON.parse(messageWidget.dataset.subjectList);
    const statusList = JSON.parse(messageWidget.dataset.statusList);
    const loggedInConsultantId = messageWidget.dataset.loggedInConsultantId;
    const nameByVersIntIdAction = messageWidget.dataset.nameByVersintidAction;
    const filterOptions = { subjectList, consultantList, statusList };
    const updateReadStatusAction = messageWidget.dataset.updateReadStatusAction;

    ReactDOM.render(<MessagetoolConsultant
        answerAction={answerAction}
        filterOptions={filterOptions}
        loggedInConsultantId={+loggedInConsultantId}
        messagesAction={messagesAction}
        nameByVersIntIdAction={nameByVersIntIdAction}
        saveMessageUpdateAction={saveMessageUpdateAction}
        sendMessageAction={sendMessageAction}
        updateReadStatusAction={updateReadStatusAction}
    />, messageWidget);
});

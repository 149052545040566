import React, { useEffect, useRef } from "react";
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import TextareaField from '../../../../../../../../design/1/js/templates/molecules/textarea-field';
import './index.scss';
import FormWithAnswerText from '../../../../../../../../design/1/js/templates/organisms/form-with-answer-text';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import Select from '../../../../../../../../design/1/js/templates/atoms/form-fields/select';
import HoneyPot from '../../../../../../../../design/1/js/templates/molecules/honeypot';
import { validateBirthdate, validateSocialSecurityNumber } from "../../../../../../../../design/1/js/lib/validateData";
import { FormikProps } from "formik";
import DatePickerField from "../../../../../../../../design/1/js/templates/molecules/date-picker-field";
import { IconName } from "../../../../../../../../design/1/js/templates/atoms/icon";
import { StringSchema } from "yup";

export enum ContactformType {
    AccessData = 'accessData',
    Support = 'support',
}

interface ContactformFormProps {
    actionUrl: string;
    handleSubmit: (status: number) => void,
    formType: ContactformType;
    recaptchaKey: string;
}

const ContactformForm = ({
    actionUrl, formType, handleSubmit, recaptchaKey,
}: ContactformFormProps) => {
    const baseClassName = 'w-contactformForm';
    const recaptchaRef = useRef(null);

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .transform((value) => value.trim())
            .required(window.sv_resource.get('form_errormsg_string')),
        firstname: Yup.string()
            .transform((value) => value.trim())
            .required(window.sv_resource.get('form_errormsg_string')),
        insuranceNr: Yup.string()
            .transform((value) => value.trim())
            .test(
                'valid socialSecurityNumber',
                () => window.sv_resource.get('form_errormsg_socialSecurityNumber'),
                value => validateSocialSecurityNumber(value),
            ),
        birthdate: Yup.string()
            .transform((value) => value.trim().replaceAll(',', '.'))
            .test(
                'valid birthdate',
                () => window.sv_resource.get('form_errormsg_birthdate'),
                value => validateBirthdate(value),
            )
            .required(window.sv_resource.get('form_errormsg_string')),
        company: Yup.string()
            .transform((value) => value.trim()),
        street: Yup.string()
            .transform((value) => value.trim())
            .required(window.sv_resource.get('form_errormsg_string')),
        zip: Yup.string()
            .transform((value) => value.trim())
            .required(window.sv_resource.get('form_errormsg_string')),
        city: Yup.string()
            .transform((value) => value.trim())
            .required(window.sv_resource.get('form_errormsg_string')),
        country: Yup.string()
            .transform((value) => value.trim()),
        phone: Yup.string()
            .transform((value) => value.trim())
            .matches(/^\+?[0-9\s]+$/, window.sv_resource.get('form_errormsg_phone'))
            .max(25, window.sv_resource.get('form_errormsg_number_max').replace('{{max}}', '25'))
            .required(window.sv_resource.get('form_errormsg_phone')),
        email: Yup.string()
            .transform((value) => value.trim())
            .email(window.sv_resource.get('form_errormsg_email'))
            .required(window.sv_resource.get('form_errormsg_email')),
        subject: Yup.string()
            .when('formType', {
                is: ContactformType.Support,
                then: Yup.string().required(window.sv_resource.get('form_errormsg_string'))
            }),
        message: Yup.string()
            .transform((value) => value.trim())
            .when('formType', {
                is: ContactformType.Support,
                then: Yup.string().required(window.sv_resource.get('form_errormsg_string')),
            }),
        formType: Yup.string()
            .oneOf(Object.values(ContactformType)),
        secondName: Yup.string(),
    });

    const initialValues = Object.freeze({
        name: '',
        firstname: '',
        formType: formType.toString(),
        insuranceNr: window.sv_resource.get('plf_contactform_form_insuranceNr_placeholder'),
        birthdate: '',
        company: '',
        street: '',
        zip: '',
        city: '',
        country: '',
        phone: '',
        email: '',
        subject: '',
        message: '',
        secondName: '',
    });

    const castValue = (e: React.FocusEvent, formik: FormikProps<any>) => {
        const field = e.target.getAttribute('name');
        const values = {};
        values[field] = formik.values[field];
        formik.setFieldValue(field, validationSchema.cast(values)[field], true);
        formik.handleBlur(e);
    };

    return (
        <FormWithAnswerText
            actionUrl={actionUrl}
            className={`${baseClassName}`}
            handleSubmit={handleSubmit}
            handleValidate={validationSchema}
            initialValues={initialValues}
            name="Contactform"
            recaptchaRef={recaptchaRef}
        >
            {formik => (
                <>
                    <div className="row gap-xxs">
                        <TextField
                            id="firstname"
                            label={window.sv_resource.get('plf_contactform_form_firstname_label')}
                            name="firstname"
                            type="text"
                            className="col-12 col-lg-6"
                            placeholder=""
                            touched={formik.touched.firstname}
                            errors={formik.errors.firstname}
                            onBlur={(e) => castValue(e, formik)}
                            onChange={formik.handleChange}
                            value={formik.values.firstname}
                        />
                        <TextField
                            id="name"
                            label={window.sv_resource.get('plf_contactform_form_lastname_label')}
                            name="name"
                            type="text"
                            className="col-12 col-lg-6"
                            placeholder=""
                            touched={formik.touched.name}
                            errors={formik.errors.name}
                            onBlur={(e) => castValue(e, formik)}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                        <TextField
                            id="insuranceNr"
                            label={window.sv_resource.get('plf_contactform_form_insuranceNr_label')}
                            name="insuranceNr"
                            type="text"
                            className="col-12 col-lg-6"
                            placeholder={window.sv_resource.get('plf_contactform_form_insuranceNr_placeholder')}
                            touched={formik.touched.insuranceNr}
                            errors={formik.errors.insuranceNr}
                            onBlur={(e) => castValue(e, formik)}
                            onChange={formik.handleChange}
                            value={formik.values.insuranceNr}
                        />
                        <TextField
                            id="birthdate"
                            label={window.sv_resource.get('plf_contactform_form_birthdate_label')}
                            name="birthdate"
                            type="text"
                            inputMode="decimal"
                            className="col-12 col-lg-6"
                            placeholder={window.sv_resource.get('plf_contactform_form_birthdate_placeholder')}
                            touched={formik.touched.birthdate}
                            errors={formik.errors.birthdate}
                            onBlur={(e) => castValue(e, formik)}
                            onChange={formik.handleChange}
                            value={formik.values.birthdate}
                        />
                        <TextField
                            id="street"
                            label={window.sv_resource.get('plf_contactform_form_street_label')}
                            name="street"
                            type="text"
                            className="col-12 col-lg-6"
                            placeholder=""
                            touched={formik.touched.street}
                            errors={formik.errors.street}
                            onBlur={(e) => castValue(e, formik)}
                            onChange={formik.handleChange}
                            value={formik.values.street}

                        />
                        <TextField
                            id="zip"
                            label={window.sv_resource.get('plf_contactform_form_zip_label')}
                            name="zip"
                            type="text"
                            className="col-4 col-lg-2"
                            placeholder=""
                            touched={formik.touched.zip}
                            errors={formik.errors.zip}
                            onBlur={(e) => castValue(e, formik)}
                            onChange={formik.handleChange}
                            value={formik.values.zip}
                        />
                        <TextField
                            id="city"
                            label={window.sv_resource.get('plf_contactform_form_city_label')}
                            name="city"
                            type="text"
                            className="col-8 col-lg-4"
                            placeholder=""
                            touched={formik.touched.city}
                            errors={formik.errors.city}
                            onBlur={(e) => castValue(e, formik)}
                            onChange={formik.handleChange}
                            value={formik.values.city}
                        />
                        <TextField
                            id="country"
                            label={window.sv_resource.get('plf_contactform_form_country_label')}
                            name="country"
                            type="text"
                            className="col-12"
                            placeholder=""
                            touched={formik.touched.country}
                            errors={formik.errors.country}
                            onBlur={(e) => castValue(e, formik)}
                            onChange={formik.handleChange}
                            value={formik.values.country}
                        />
                        <TextField
                            id="phone"
                            label={window.sv_resource.get('plf_contactform_form_phone_label')}
                            name="phone"
                            type="tel"
                            className="col-12 col-lg-6"
                            placeholder={window.sv_resource.get('plf_contactform_form_phone_placeholder')}
                            touched={formik.touched.phone}
                            errors={formik.errors.phone}
                            onBlur={(e) => castValue(e, formik)}
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                        />
                        <TextField
                            id="email"
                            label={window.sv_resource.get('plf_contactform_form_email_label')}
                            inputMode="email"
                            name="email"
                            type="text"
                            className="col-12 col-lg-6"
                            placeholder=""
                            touched={formik.touched.email}
                            errors={formik.errors.email}
                            onBlur={(e) => castValue(e, formik)}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                        />
                        {formType === ContactformType.Support && <Select
                            id="subject"
                            label={window.sv_resource.get('plf_contactform_form_subject_label_support')}
                            name="subject"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.subject}
                            errors={formik.errors.subject}
                            touched={formik.touched.subject}
                        >
                            <option value="" disabled>{window.sv_resource.get('plf_contactform_form_subject_option_default')}</option>
                            <option value="AllgemeinerSupport">{window.sv_resource.get('plf_contactform_form_subject_option_support')}</option>
                            <option value="VersichertenportalLogin">{window.sv_resource.get('plf_contactform_form_subject_option_login')}</option>
                        </Select>}
                        <HoneyPot
                            errors={formik.errors.secondName}
                            id="secondName"
                            name="secondName"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder="secondName"
                            touched={formik.touched.secondName}
                            type="text"
                        />
                        <TextField
                            id="company"
                            label={window.sv_resource.get('plf_contactform_form_company_label')}
                            name="company"
                            type="text"
                            className="col-12 col-lg-6"
                            placeholder=""
                            touched={formik.touched.company}
                            errors={formik.errors.company}
                            onBlur={(e) => castValue(e, formik)}
                            onChange={formik.handleChange}
                            value={formik.values.company}
                        />

                        <TextareaField
                            id="message"
                            label={window.sv_resource.get(formType === ContactformType.Support ? 'plf_contactform_form_message_label_required' : 'plf_contactform_form_message_label')}
                            name="message"
                            className="col-12"
                            placeholder=""
                            touched={formik.touched.message}
                            errors={formik.errors.message}
                            onBlur={(e) => castValue(e, formik)}
                            onChange={formik.handleChange}
                            value={formik.values.message}
                        />
                    </div>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={recaptchaKey}
                        size="invisible"
                    />
                    <Paragraph className={`${baseClassName}__description`}>
                        {window.sv_resource.get('plf_contactform_mandatory')}
                    </Paragraph>
                    <FormButton
                        disabled={Object.keys(formik.errors).length > 0 || Object.keys(formik.touched).length === 0}
                        text={formType === ContactformType.Support ? window.sv_resource.get('plf_contactform_submit_btn_support') : window.sv_resource.get('plf_contactform_submit_btn_access')}
                        type="submit"
                        variation={FormButtonVariation.Primary}
                    />
                </>
            )}
        </FormWithAnswerText>
    );
};

export default ContactformForm;

import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import * as Yup from 'yup';
import { StringSchema } from 'yup';
import { useFormik } from 'formik';
import DatePickerField from '../../../../../../../../design/1/js/templates/molecules/date-picker-field';
import { IconName } from '../../../../../../../../design/1/js/templates/atoms/icon';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import PurchaseResponseContext from '../../../lib/purchase/PurchaseResponseContext';
import { getIsoDate } from '../../../../../../../../design/1/js/lib/formatData';
import RadioButton from '../../../../../../../../design/1/js/templates/atoms/form-fields/radiobutton';
import { baseIntegerSchema, cleanIntegerValue } from '../../../lib/yupFields/baseIntegerSchema';

interface PurchaseSimulationFormProps {
    handleSubmit: (response: PurchaseResponseContext) => void;
    headerId: string;
    maxPurchaseAction: string
}

const PurchaseSimulationForm = ({
    handleSubmit, headerId, maxPurchaseAction
}: PurchaseSimulationFormProps) => {
    const baseClassName = 'w-purchaseSimulationForm';
    const [datePickerError, setDatePickerError] = useState(null);
    const datePickerRef = useRef<HTMLDivElement>(null);
    const [maxPurchase, setMaxPurchase] = useState<number>(0);
    const [isMaxPurchaseLoading, setIsMaxPurchaseLoading] = useState<boolean>(false);
    const [abortController, setAbortController ] = useState<AbortController>(new AbortController());

    const validationSchema = Yup.object().shape({
        // @ts-ignore -> Yup is actually able to handle strings as date; it casts strings with new Date(string)
        purchaseDate: Yup.date()
            .typeError(window.sv_resource.get('form_errormsg_date'))
            .test('isThisYear', window.sv_resource.get('form_errormsg_date_this_year'), (val: Date) => val.getFullYear() === (new Date().getFullYear()))
            .test('isNotPast', window.sv_resource.get('form_errormsg_date_not_past'), (val: Date) => {
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0);
                return val >= currentDate;
            })
            .required(window.sv_resource.get('form_errormsg_date')) as StringSchema,
        requestedPurchase: baseIntegerSchema()
            .max(maxPurchase, window.sv_resource.get('plf_simulation_errormsg_max_purchase')),
        vestedBenefitCheck: Yup.string().required(window.sv_resource.get('form_errormsg_radio')),
        wefCheck: Yup.string().required(window.sv_resource.get('form_errormsg_radio')),
        selfEmployedCheck: Yup.string().required(window.sv_resource.get('form_errormsg_radio')),
        aboardCheck: Yup.string().required(window.sv_resource.get('form_errormsg_radio')),
        pillar2Check: Yup.string().required(window.sv_resource.get('form_errormsg_radio')),
        pillar3aCheck: Yup.string().required(window.sv_resource.get('form_errormsg_radio')),
    });

    const initialValues = Object({
        purchaseDate: getIsoDate(new Date()),
        maxPurchase: '',
        requestedPurchase: '',
        vestedBenefitCheck: '',
        wefCheck: '',
        selfEmployedCheck: '',
        aboardCheck: '',
        pillar2Check: '',
        pillar3aCheck: '',
    });

    const onSubmit = (values) => {
        values.requestedPurchase = String(cleanIntegerValue(values.requestedPurchase));

        handleSubmit(values);
    };

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });

    const loadMaxPurchase = (date: string) => {
        let controller = abortController;
        if(isMaxPurchaseLoading) {
            controller.abort();
            controller = new AbortController();
            setAbortController(controller);
        }
        setIsMaxPurchaseLoading(true);
        formik.setFieldValue('maxPurchase', '');
        fetch(
            maxPurchaseAction + '?' + (new URLSearchParams({date: date}).toString()),
            { signal: controller.signal }
        ).then(
            res => res.json()
        ).then(res => {
            let max = res.context.hasOwnProperty('value') ? Math.floor(res.context.value) : 0;
            setMaxPurchase(max);
            formik.setFieldValue('maxPurchase', max);
            setIsMaxPurchaseLoading(false);
        }).catch(error => {
            if (error instanceof DOMException) {
                console.info('Abort max purchase fetch', error);
            } else {
                console.error(error);
            }
        });
    }

    useEffect(() => {
        loadMaxPurchase(formik.values.purchaseDate);
    }, [formik.values.purchaseDate]);

    const handleIsValid = (isValid: boolean) => {
        setDatePickerError(isValid ? null : window.sv_resource.get('form_errormsg_date_between_now_and_end_of_year'));
    };

    return (
        <div className={`${baseClassName}`}>
            <h2 id={headerId}>{window.sv_resource.get('plf_simulation_title')}</h2>
            <Paragraph>{window.sv_resource.get('plf_simulation_purchase_description')}</Paragraph>
            <form name="purchaseSimulation" onSubmit={formik.handleSubmit}>
                <div className="row gap-xxs">
                    <DatePickerField
                        className="col-12 col-lg-6"
                        errors={datePickerError ?? formik.errors.purchaseDate}
                        icon={IconName.Calendar}
                        id="purchaseDate"
                        label={window.sv_resource.get('plf_simulation_purchase_label_date')}
                        maxDate={new Date(new Date().getFullYear(), 11, 31)}
                        minDate={new Date()}
                        ref={datePickerRef}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onIsValid={handleIsValid}
                        touched
                        value={formik.values.purchaseDate}
                    />
                    <TextField
                        className={`${baseClassName}__field col-12 col-lg-6`}
                        errors={formik.errors.maxPurchase}
                        icon={IconName.Chf}
                        id="maxPurchase"
                        label={window.sv_resource.get('plf_simulation_purchase_label_max_purchase')}
                        name="maxPurchase"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onFocus={formik.handleBlur}
                        placeholder={formik.values.maxPurchase}
                        touched={!!formik.touched.maxPurchase}
                        type="text"
                        value={formik.values.maxPurchase}
                        disabled={true}
                        isLoading={isMaxPurchaseLoading}
                    />
                    <TextField
                        className={`${baseClassName}__field col-12 col-lg-6`}
                        errors={formik.errors.requestedPurchase}
                        icon={IconName.Chf}
                        id="requestedPurchase"
                        label={window.sv_resource.get('plf_simulation_purchase_label_requested_purchase')}
                        name="requestedPurchase"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onFocus={formik.handleBlur}
                        placeholder={formik.values.requestedPurchase}
                        touched={!!formik.touched.requestedPurchase}
                        type="text"
                        value={formik.values.requestedPurchase}
                    />
                </div>
                <div className={`${baseClassName}__radioChecks`}>
                    <div className={`${baseClassName}__radioRow row gap-xxs`}>
                        <Paragraph className="col-12 col-lg-6">{window.sv_resource.get('plf_simulation_purchase_radio_label_vested_benefits')}</Paragraph>
                        <div className={`${baseClassName}__radioWrapper col-12 col-lg-6`}>
                            <RadioButton
                                checked={formik.values.vestedBenefitCheck.toString() === 'vestedBenefitCheck-no'}
                                errors={formik.errors.vestedBenefitCheck}
                                id="vestedBenefitCheck-no"
                                name="vestedBenefitCheck"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                touched={!!formik.touched.vestedBenefitCheck}
                                label={window.sv_resource.get('plf_simulation_purchase_radio_no')}
                            />
                            <RadioButton
                                checked={formik.values.vestedBenefitCheck.toString() === 'vestedBenefitCheck-yes'}
                                errors={formik.errors.vestedBenefitCheck}
                                id="vestedBenefitCheck-yes"
                                name="vestedBenefitCheck"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                touched={!!formik.touched.vestedBenefitCheck}
                                label={window.sv_resource.get('plf_simulation_purchase_radio_yes')}
                            />
                        </div>
                    </div>
                    <div className={`${baseClassName}__radioRow row gap-xxs`}>
                        <Paragraph className="col-12 col-lg-6">{window.sv_resource.get('plf_simulation_purchase_radio_label_wef')}</Paragraph>
                        <div className={`${baseClassName}__radioWrapper col-12 col-lg-6`}>
                            <RadioButton
                                checked={formik.values.wefCheck.toString() === 'wefCheck-no'}
                                errors={formik.errors.wefCheck}
                                id="wefCheck-no"
                                name="wefCheck"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                touched={!!formik.touched.wefCheck}
                                label={window.sv_resource.get('plf_simulation_purchase_radio_no')}
                            />
                            <RadioButton
                                checked={formik.values.wefCheck.toString() === 'wefCheck-yes'}
                                errors={formik.errors.wefCheck}
                                id="wefCheck-yes"
                                name="wefCheck"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                touched={!!formik.touched.wefCheck}
                                label={window.sv_resource.get('plf_simulation_purchase_radio_yes')}
                            />
                        </div>
                    </div>
                    <div className={`${baseClassName}__radioRow row gap-xxs`}>
                        <Paragraph className="col-12 col-lg-6">{window.sv_resource.get('plf_simulation_purchase_radio_label_selfemployed')}</Paragraph>
                        <div className={`${baseClassName}__radioWrapper col-12 col-lg-6`}>
                            <RadioButton
                                checked={formik.values.selfEmployedCheck.toString() === 'selfEmployedCheck-no'}
                                errors={formik.errors.selfEmployedCheck}
                                id="selfEmployedCheck-no"
                                name="selfEmployedCheck"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                touched={!!formik.touched.selfEmployedCheck}
                                label={window.sv_resource.get('plf_simulation_purchase_radio_no')}
                            />
                            <RadioButton
                                checked={formik.values.selfEmployedCheck.toString() === 'selfEmployedCheck-yes'}
                                errors={formik.errors.selfEmployedCheck}
                                id="selfEmployedCheck-yes"
                                name="selfEmployedCheck"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                touched={!!formik.touched.selfEmployedCheck}
                                label={window.sv_resource.get('plf_simulation_purchase_radio_yes')}
                            />
                        </div>
                    </div>
                    <div className={`${baseClassName}__radioRow row gap-xxs`}>
                        <Paragraph className="col-12 col-lg-6">{window.sv_resource.get('plf_simulation_purchase_radio_label_aboard')}</Paragraph>
                        <div className={`${baseClassName}__radioWrapper col-12 col-lg-6`}>
                            <RadioButton
                                checked={formik.values.aboardCheck.toString() === 'aboardCheck-no'}
                                errors={formik.errors.aboardCheck}
                                id="aboardCheck-no"
                                name="aboardCheck"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                touched={!!formik.touched.aboardCheck}
                                label={window.sv_resource.get('plf_simulation_purchase_radio_no')}
                            />
                            <RadioButton
                                checked={formik.values.aboardCheck.toString() === 'aboardCheck-yes'}
                                errors={formik.errors.aboardCheck}
                                id="aboardCheck-yes"
                                name="aboardCheck"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                touched={!!formik.touched.aboardCheck}
                                label={window.sv_resource.get('plf_simulation_purchase_radio_yes')}
                            />
                        </div>
                    </div>
                    <div className={`${baseClassName}__radioRow row gap-xxs`}>
                        <Paragraph className="col-12 col-lg-6">{window.sv_resource.get('plf_simulation_purchase_radio_label_pillar_2')}</Paragraph>
                        <div className={`${baseClassName}__radioWrapper col-12 col-lg-6`}>
                            <RadioButton
                                checked={formik.values.pillar2Check.toString() === 'pillar2Check-no'}
                                errors={formik.errors.pillar2Check}
                                id="pillar2Check-no"
                                name="pillar2Check"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                touched={!!formik.touched.pillar2Check}
                                label={window.sv_resource.get('plf_simulation_purchase_radio_no')}
                            />
                            <RadioButton
                                checked={formik.values.pillar2Check.toString() === 'pillar2Check-yes'}
                                errors={formik.errors.pillar2Check}
                                id="pillar2Check-yes"
                                name="pillar2Check"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                touched={!!formik.touched.pillar2Check}
                                label={window.sv_resource.get('plf_simulation_purchase_radio_yes')}
                            />
                        </div>
                    </div>
                    <div className={`${baseClassName}__radioRow row gap-xxs`}>
                        <Paragraph className="col-12 col-lg-6">{window.sv_resource.get('plf_simulation_purchase_radio_label_3a')}</Paragraph>
                        <div className={`${baseClassName}__radioWrapper col-12 col-lg-6`}>
                            <RadioButton
                                checked={formik.values.pillar3aCheck.toString() === 'pillar3aCheck-no'}
                                errors={formik.errors.pillar3aCheck}
                                id="pillar3aCheck-no"
                                name="pillar3aCheck"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                touched={!!formik.touched.pillar3aCheck}
                                label={window.sv_resource.get('plf_simulation_purchase_radio_no')}
                            />
                            <RadioButton
                                checked={formik.values.pillar3aCheck.toString() === 'pillar3aCheck-yes'}
                                errors={formik.errors.pillar3aCheck}
                                id="pillar3aCheck-yes"
                                name="pillar3aCheck"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                touched={!!formik.touched.pillar3aCheck}
                                label={window.sv_resource.get('plf_simulation_purchase_radio_yes')}
                            />
                        </div>
                    </div>
                </div>
                <div className={`${baseClassName}__submitWrapper row`}>
                    <FormButton
                        disabled={Object.keys(formik.errors).length > 0 || Object.keys(formik.touched).length === 0 || datePickerError !== null || isMaxPurchaseLoading}
                        hasIcon={false}
                        text={window.sv_resource.get('plf_simulation_calc_button_label')}
                        type="submit"
                        variation={FormButtonVariation.Primary}
                    />
                </div>
            </form>
        </div>
    );
};

export default PurchaseSimulationForm;

import React from 'react';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import Form from '../../../../../../../../design/1/js/templates/organisms/form';
import './index.scss';
import Button, {ButtonType} from '../../../../../../../../design/1/js/templates/atoms/button';
import MasterDataResponseType from '../../../lib/entity/MasterDataResponseType';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import {AsyncWidgetProps} from '../../../../../base/ts/lib/AsyncWidgetProps';
import {formatSocialSecurityNumber} from '../../../../../../../../design/1/js/lib/formatData';
import StatusMessage, {MessageType} from "../../../../../../../../design/1/js/templates/molecules/status-message";
import StatusMessageVariation
    from "../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation";
import {hasInsuredMultipleIds} from "../../../../../../../../design/1/js/lib/util/InsuredUtils";

interface MasterDataProps extends AsyncWidgetProps {
    className?: string;
}

const MasterData = ({
    action,
    className,
}: MasterDataProps) => {
    const baseClassName = 'w-yourProfile-masterData';

    return (
        <div className={`${baseClassName} ${className}`}>
            <AsyncLoader
                action={action}
                renderContent={lazyChildren => (
                    <>
                        <h2 id={window.sv_resource.get('plf_yourprofile_master_data_title')}>{window.sv_resource.get('plf_yourprofile_master_data_title')}</h2>
                        {lazyChildren}
                    </>
                )}
            >
                {({
                    masterData, contactData, editButtons, description,
                }: MasterDataResponseType) => {
                    const initialValuesPersonalData = Object.freeze({
                        firstname: masterData.firstname.value,
                        lastname: masterData.lastname.value,
                        birthdate: masterData.birthdate.value,
                        gender: masterData.gender.value,
                        insuranceNr: masterData.insuranceNumber.value,
                        socialSecurityNumber: formatSocialSecurityNumber(masterData.socialSecurityNumber.value),
                    });

                    const initialValuesAddressData = Object.freeze({
                        street: masterData.address.street.value,
                        zipCode: masterData.address.zipCode.value,
                        city: masterData.address.city.value,
                        country: masterData.address.country.value,
                    });

                    const initialValuesPhoneData = Object.freeze({
                        phone: contactData?.phone.value,
                        mobile: contactData?.mobile.value,
                    });

                    const streetIsPlusSize = masterData.address.street.value.length > 30;

                    return (
                        <>
                            <Form actionUrl="/" name="personalData" initialValues={initialValuesPersonalData} validationSchema={null}>
                                {formik => (
                                    <div className="row gap-xxs">
                                        <TextField
                                            className={`${baseClassName}__field col-12 col-lg-6`}
                                            disabled
                                            errors={formik.errors.firstname}
                                            id={masterData.firstname.id}
                                            label={masterData.firstname.label}
                                            name={masterData.firstname.id}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            placeholder={formik.values.firstname}
                                            touched={formik.touched.firstname}
                                            type="text"
                                            value={formik.values.firstname}
                                        />
                                        <TextField
                                            className={`${baseClassName}__field col-12 col-lg-6`}
                                            disabled
                                            errors={formik.errors.lastname}
                                            id={masterData.lastname.id}
                                            label={masterData.lastname.label}
                                            name={masterData.lastname.id}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            placeholder={formik.values.lastname}
                                            touched={formik.touched.lastname}
                                            type="text"
                                            value={formik.values.lastname}
                                        />
                                        <TextField
                                            className={`${baseClassName}__field col-12 col-lg-6`}
                                            disabled
                                            errors={formik.errors.birthdate}
                                            id={masterData.birthdate.id}
                                            label={masterData.birthdate.label}
                                            name={masterData.birthdate.id}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            placeholder={formik.values.birthdate}
                                            touched={formik.touched.birthdate}
                                            type="text"
                                            value={formik.values.birthdate}
                                        />
                                        <TextField
                                            className={`${baseClassName}__field col-12 col-lg-6`}
                                            disabled
                                            errors={formik.errors.gender}
                                            id={masterData.gender.id}
                                            label={masterData.gender.label}
                                            name={masterData.gender.id}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            placeholder={formik.values.gender}
                                            touched={formik.touched.gender}
                                            type="text"
                                            value={formik.values.gender}
                                        />
                                        <TextField
                                            className={`${baseClassName}__field col-12 col-lg-6`}
                                            disabled
                                            errors={formik.errors.insuranceNr}
                                            id={masterData.insuranceNumber.id}
                                            label={masterData.insuranceNumber.label}
                                            name={masterData.insuranceNumber.id}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            placeholder={formik.values.insuranceNr}
                                            touched={formik.touched.insuranceNr}
                                            type="text"
                                            value={formik.values.insuranceNr}
                                        />
                                        <TextField
                                            className={`${baseClassName}__field col-12 col-lg-6`}
                                            disabled
                                            errors={formik.errors.socialSecurityNumber}
                                            id={masterData.socialSecurityNumber.id}
                                            label={masterData.socialSecurityNumber.label}
                                            name={masterData.socialSecurityNumber.id}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            placeholder={formik.values.socialSecurityNumber}
                                            touched={formik.touched.socialSecurityNumber}
                                            type="text"
                                            value={formik.values.socialSecurityNumber}
                                        />
                                    </div>
                                )}
                            </Form>
                            <Form actionUrl="/" name="civilState" initialValues={{ civilState: masterData.civilState.value }} validationSchema={null}>
                                {formik => (
                                    <div className="row gap-xxs">
                                        <TextField
                                            className={`${baseClassName}__field col-12 col-lg-6`}
                                            disabled
                                            errors={formik.errors.civilState}
                                            id={masterData.civilState.id}
                                            label={masterData.civilState.label}
                                            name={masterData.civilState.id}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            placeholder={formik.values.civilState}
                                            touched={formik.touched.civilState}
                                            type="text"
                                            value={formik.values.civilState}
                                        />
                                        {editButtons.changeCivilState && (
                                            <>
                                                <div className="col-12 col-lg-6">
                                                    <Button
                                                        className={`${baseClassName}__button col-12 col-lg-6`}
                                                        href={editButtons.changeCivilState.link}
                                                        text={editButtons.changeCivilState.label}
                                                        type={ButtonType.Orange}
                                                    />
                                                </div>
                                                {hasInsuredMultipleIds() && (
                                                    <div className="col-12 mb-5">
                                                        <StatusMessage
                                                            customMessageColor={MessageType.Info}
                                                            messageType={MessageType.Info}
                                                            description={window.sv_resource.get('plf_yourprofile_master_data_civil_state_description_multiple_insured_ids')}
                                                            variation={StatusMessageVariation.WithBackground}
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}
                            </Form>
                            <Form actionUrl="/" name="address" initialValues={initialValuesAddressData} validationSchema={null}>
                                {formik => (
                                    <div className="row gap-xxs justify-content-end">
                                        <TextField
                                            className={`${baseClassName}__field col-12 ${streetIsPlusSize ? 'col-lg-12' : 'col-lg-6'}`}
                                            disabled
                                            errors={formik.errors.street}
                                            id={masterData.address.street.id}
                                            label={masterData.address.street.label}
                                            name={masterData.address.street.id}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            placeholder={formik.values.street}
                                            touched={formik.touched.street}
                                            type="text"
                                            value={formik.values.street}
                                        />
                                        <div className="col-12 col-lg-6">
                                            <div className="row gap-xxs">
                                                <div className="col-4">
                                                    <TextField
                                                        className={`${baseClassName}__field `}
                                                        disabled
                                                        errors={formik.errors.zipCode}
                                                        id={masterData.address.zipCode.id}
                                                        label={masterData.address.zipCode.label}
                                                        name={masterData.address.zipCode.id}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        placeholder={formik.values.zipCode}
                                                        touched={formik.touched.zipCode}
                                                        type="text"
                                                        value={formik.values.zipCode}
                                                    />
                                                </div>
                                                <div className="col-8">
                                                    <TextField
                                                        className={`${baseClassName}__field`}
                                                        disabled
                                                        errors={formik.errors.city}
                                                        id={masterData.address.city.id}
                                                        label={masterData.address.city.label}
                                                        name={masterData.address.city.id}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        placeholder={formik.values.city}
                                                        touched={formik.touched.city}
                                                        type="text"
                                                        value={formik.values.city}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <TextField
                                            className={`${baseClassName}__field col-12 col-lg-6`}
                                            disabled
                                            errors={formik.errors.country}
                                            id={masterData.address.country.id}
                                            label={masterData.address.country.label}
                                            name={masterData.address.country.id}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            placeholder={formik.values.country}
                                            touched={formik.touched.country}
                                            type="text"
                                            value={formik.values.country}
                                        />
                                        {editButtons.changeAddress && (
                                            <>
                                                <div className="col-12 col-lg-6">
                                                    <Button
                                                        className={`${baseClassName}__button col-12 col-lg-6`}
                                                        href={editButtons.changeAddress.link}
                                                        text={editButtons.changeAddress.label}
                                                        type={ButtonType.Orange}
                                                    />
                                                </div>
                                                {hasInsuredMultipleIds() && (
                                                    <div className="col-12 mb-5">
                                                        <StatusMessage
                                                            customMessageColor={MessageType.Info}
                                                            messageType={MessageType.Info}
                                                            description={window.sv_resource.get('plf_yourprofile_master_data_address_description_multiple_insured_ids')}
                                                            variation={StatusMessageVariation.WithBackground}
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}
                            </Form>
                            {contactData && (
                                <Form actionUrl="/" name="phone" initialValues={initialValuesPhoneData} validationSchema={null}>
                                    {formik => (
                                        <div className="row gap-xxs">
                                            <TextField
                                                className={`${baseClassName}__field col-12 col-lg-6`}
                                                disabled
                                                errors={formik.errors.phone}
                                                id={contactData.phone.id}
                                                label={contactData.phone.label}
                                                name={contactData.phone.id}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                placeholder={formik.values.phone}
                                                touched={formik.touched.phone}
                                                type="text"
                                                value={formik.values.phone}
                                            />
                                            <TextField
                                                className={`${baseClassName}__field col-12 col-lg-6`}
                                                disabled
                                                errors={formik.errors.mobile}
                                                id={contactData.mobile.id}
                                                label={contactData.mobile.label}
                                                name={contactData.mobile.id}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                placeholder={formik.values.mobile}
                                                touched={formik.touched.mobile}
                                                type="text"
                                                value={formik.values.mobile}
                                            />
                                        </div>
                                    )}
                                </Form>
                            )}
                            {description && (
                                <div className="row gap-xxs">
                                    <StatusMessage
                                        customMessageColor={MessageType.Info}
                                        messageType={MessageType.Info}
                                        description={description}
                                        variation={StatusMessageVariation.WithBackground}
                                    />
                                </div>
                            )}
                        </>
                    );
                }}
            </AsyncLoader>
        </div>
    );
};

MasterData.defaultProps = {
    className: '',
};

export default MasterData;

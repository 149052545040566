import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import useForwardedRef from '@bedrock-layout/use-forwarded-ref';
import './index.scss';
import DatePicker from 'react-date-picker';
import { FormikErrors, FormikValues } from 'formik';
import Icon, { IconName } from '../../icon';
import HandleChangeType from '../../../../lib/types/HandleChangeType';
import { getIsoDate, offsetDate } from '../../../../lib/formatData';

export enum CalenderStartView {
    Day = 'day',
    Month = 'month',
}

enum CalenderPosition {
    Top = 'positionTop',
    Bottom = 'positionBottom',
    Hidden = 'positionHidden',
}

export interface DatePickerProps {
    className?: string;
    disabled?: boolean;
    endOfMonth?: boolean;
    errors?: string | string[] | FormikErrors<FormikValues> | FormikErrors<FormikValues>[];
    icon?: IconName;
    id: string;
    maxDate?: Date;
    startView?: CalenderStartView;
    minDate?: Date;
    onBlur: HandleChangeType<HTMLInputElement>;
    onCalendarClose?: () => void;
    onChange: HandleChangeType<HTMLInputElement>;
    onIsValid?: (isValid: boolean) => void;
    touched?: boolean;
    value: string;
}

const MpkDatePicker = forwardRef<HTMLDivElement, DatePickerProps>(({
    className, disabled, endOfMonth, errors, icon, id, maxDate, minDate, onBlur, onCalendarClose, onChange, onIsValid, startView, touched, value,
}: DatePickerProps, ref) => {
    const baseClassName = 'a-datePicker';
    const [dateValue, setDateValue] = useState(value ? offsetDate(new Date(value)) : null);
    const [calendarPosition, setCalendarPosition] = useState(CalenderPosition.Hidden);
    const [isCalendarOpen, setCalendarOpen] = useState(false);
    const innerDatePickerRef = useForwardedRef(ref);

    const setFormValue = useCallback((value: string|null) => {
        // We cannot create a real React.ChangeEvent, so we must simulate the properties Formik uses
        // @ts-ignore
        onChange({target: {id, value: value}});

        // @ts-ignore
        setTimeout(() => onBlur({ target: { id } }), 0);
    }, [id, onBlur, onChange]);

    const getMaxDetailFromView = (view: CalenderStartView) => {
        switch (view) {
        case CalenderStartView.Day:
            return 'month';
        case CalenderStartView.Month:
            return 'year';
        default:
            return 'month';
        }
    };

    useEffect(() => {
        if (innerDatePickerRef.current === null || !isCalendarOpen) {
            return;
        }
        if (window.innerHeight / 2 > innerDatePickerRef.current.getBoundingClientRect().y) {
            setCalendarPosition(CalenderPosition.Bottom);
        } else {
            setCalendarPosition(CalenderPosition.Top);
        }
    }, [isCalendarOpen]);

    const onClickItem = (itemValue: Date | null) => {
        if (endOfMonth) {
            itemValue = itemValue && new Date(itemValue.getFullYear(), itemValue.getMonth() + 1, 0);
        }
        setDateValue(itemValue);
        setFormValue(itemValue ? getIsoDate(itemValue) : null);
    };

    const handleCalenderOpen = () => {
        setCalendarOpen(true);
    };

    const handleCalenderClose = () => {
        if (onIsValid !== undefined) {
            const invalidElements = innerDatePickerRef.current.querySelectorAll('input.react-date-picker__inputGroup__input:invalid');
            if (invalidElements.length > 0) {
                onIsValid(false);
            } else {
                onIsValid(true);
            }
        }
        setCalendarPosition(CalenderPosition.Hidden);
        setCalendarOpen(false);
        if (onCalendarClose !== undefined) {
            onCalendarClose();
        }
    };

    return (
        <>
            <div ref={innerDatePickerRef} className={`${baseClassName} ${className} ${icon ? '-icon' : ''} ${touched && !disabled ? '-touched' : ''} ${errors ? '-error' : ''}  ${disabled ? '-disabled' : ''} -${calendarPosition}`}>
                <DatePicker
                    dayPlaceholder="dd"
                    disabled={disabled}
                    format={startView === CalenderStartView.Month ? 'MM.y' : 'dd.MM.y'}
                    maxDetail={getMaxDetailFromView(startView)}
                    maxDate={maxDate}
                    minDate={minDate}
                    monthPlaceholder="mm"
                    name={id}
                    onChange={onClickItem}
                    onCalendarClose={handleCalenderClose}
                    onCalendarOpen={handleCalenderOpen}
                    value={dateValue}
                    yearPlaceholder="yyyy"
                    locale={document.documentElement.lang}
                />
                {icon && (
                    <Icon name={icon} className={`${baseClassName}__input-icon ${disabled && '-disabled'}`} />
                )}

                {errors && touched && <Icon name={IconName.ErrorCircle} className={`${baseClassName}__error-icon`} />}
                {!errors && touched && <Icon name={IconName.SuccessCircle} className={`${baseClassName}__success-icon`} />}
            </div>
            {errors && touched && <div className={`${baseClassName}__msg small-text`}>{errors}</div>}
        </>
    );
});

MpkDatePicker.defaultProps = {
    className: '',
    disabled: false,
    endOfMonth: false,
    errors: null,
    maxDate: undefined,
    minDate: undefined,
    onCalendarClose: undefined,
    onIsValid: undefined,
    startView: CalenderStartView.Day,
    touched: null,
};

export default MpkDatePicker;

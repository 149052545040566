import React from 'react';
import './index.scss';
import * as Yup from 'yup';
import { StringSchema } from 'yup';
import DatePickerField from '../../../../../../../../design/1/js/templates/molecules/date-picker-field';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import { StepProps } from '../../../lib/StepProps';
import { IconName } from '../../../../../../../../design/1/js/templates/atoms/icon';
import StepNavigation from "../step-navigation";

export interface ExitDateType {
    resignationDate: string;
}

export const validationSchemaExitDateStep = Yup.object().shape({
    // @ts-ignore -> Yup is actually able to handle strings as date; it casts strings with new Date(string)
    resignationDate: Yup.date()
        .required(() => window.sv_resource.get('form_errormsg_date')) as StringSchema,
});

const ExitDateStep = (({
    disabled, errors, handleBack, id, touched, onChange, onBlur, values, isFirstStep
}: StepProps<ExitDateType>) => {
    const baseClassName = 'w-exitDateStep';
    const valuesAreSet = values.resignationDate !== '';

    return (
        <div className={`${baseClassName}`}>
            <h2 className={`${baseClassName}__title`} id={id}>{window.sv_resource.get('plf_onlineform_exitdate_title')}</h2>
            <div className="row gap-lg-s">
                <DatePickerField
                    className="col-12 col-lg-6"
                    disabled={disabled}
                    label={window.sv_resource.get('plf_onlineform_exitdate_exit')}
                    icon={IconName.Calendar}
                    id="resignationDate"
                    errors={errors.resignationDate}
                    touched={touched.resignationDate}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={values.resignationDate}
                />
            </div>
            <StepNavigation valuesAreSet={valuesAreSet} disabled={disabled} isFirstStep={isFirstStep} hasErrors={Object.keys(errors).length > 0} handleBack={handleBack} />
        </div>
    );
});

export default ExitDateStep;

import React from 'react';
import './index.scss';
import BenefitsType from '../../../lib/BenefitsType';
import Table from '../../../../../../../../design/1/js/templates/molecules/table';
import BenefitsRow from '../../molecules/benefits-row';
import TableBody from '../../../../../../../../design/1/js/templates/atoms/table-body';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import { AsyncWidgetProps } from '../../../../../base/ts/lib/AsyncWidgetProps';
import StatusMessageVariation
    from '../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation';

interface BenefitsProps extends AsyncWidgetProps{
    id: string;
}

const Benefits = ({ action, id }: BenefitsProps) => {
    const baseClassName = 'w-benefits';

    return (
        <div className={`${baseClassName}`}>
            <AsyncLoader
                action={action}
                renderContent={lazyChildren => (
                    <>
                        {lazyChildren}
                    </>
                )}
                variation={StatusMessageVariation.Subpage}
            >
                {(benefits: BenefitsType[]) => (
                    <>
                        {benefits.map((benefit, benefitIndex) => (
                            <React.Fragment key={`benefit-${benefitIndex.toString()}`}>
                                <h2 id={benefitIndex === 0 ? id : undefined}>{benefit.title}</h2>
                                <Table>
                                    <TableBody>
                                        {benefit.entries.map((entry, rowIndex) => (
                                            <BenefitsRow emptyMessage={benefit.emptyMessage} entry={entry} key={`documentRow-${rowIndex.toString()}`} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </React.Fragment>
                        ))}
                    </>
                )}
            </AsyncLoader>
        </div>
    );
};

export default Benefits;

import React, { ReactNode, useState } from 'react';

interface SimulationProps<T> {
    calculatingAction?: string;
    simulationDataTable?: (setMaxValue: (value) => void) => ReactNode;
    simulationAction?: (calculatingAction: string, handleReset, handleCreatePdf) => ReactNode;
    simulationForm: (handleSubmit: (response: T) => void, maxValue?) => ReactNode;
}

const Simulation = <T extends { [key in keyof T]: string }>(props: SimulationProps<T>) => {
    const baseClassName = 'w-simulation';
    const [isCalculated, setCalculated] = useState(false);
    const [calculatingGetAction, setCalculatingGetAction] = useState(props.calculatingAction);
    const [maxValue, setMaxValue] = useState(0);

    const handleCreatePdf = () => {
        const widgets = document.querySelectorAll<HTMLElement>('.sv-slot-content .sv-widget:not(.sv-widget-simulation)');
        const simulationWidgetDataTable = document.querySelector<HTMLElement>('.sv-widget-simulation .w-simulation__simulationDataTable');
        const printDetailSections = document.querySelectorAll<HTMLElement>('.plf-print-detail-section .plf-print-detail-section-wrapper');
        const printDetailSectionsSafari = document.querySelectorAll<HTMLElement>('.plf-print-detail-section-safari .plf-print-detail-section-wrapper');
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        window.addEventListener('beforeprint', () => {
            widgets.forEach(widget => widget.classList.add('d-none'));
            simulationWidgetDataTable.classList.add('d-none');
            if (isSafari) {
                printDetailSectionsSafari.forEach(element => element.classList.remove('d-none'));
                document.body.style.height = '29.7cm';
                document.body.style.width = '21cm';
            } else {
                printDetailSections.forEach(element => element.classList.remove('d-none'));
            }
        }, { once: true });
        window.addEventListener('afterprint', () => {
            widgets.forEach(widget => widget.classList.remove('d-none'));
            simulationWidgetDataTable.classList.remove('d-none');
            if (isSafari) {
                printDetailSectionsSafari.forEach(element => element.classList.add('d-none'));
                document.body.style.height = 'unset';
                document.body.style.width = 'unset';
            } else {
                printDetailSections.forEach(element => element.classList.add('d-none'));
            }
        }, { once: true });
        window.print();
    };

    const handleFormSubmit = (response: T) => {
        const searchParams = new URLSearchParams();
        for (const key in response) {
            if (Object.prototype.hasOwnProperty.call(response, key)) {
                if (typeof response[key] === 'string') {
                    const value: string = response[key];
                    searchParams.append(key, value);
                }
            }
        }
        setCalculatingGetAction(`${props.calculatingAction}?${searchParams}`);
        setCalculated(true);
    };

    const handleReset = () => {
        setCalculated(false);
    };

    return (
        <div className={`${baseClassName}`}>
            <div className={`${baseClassName}__simulationDataTable`}>
                {props.simulationDataTable && props.simulationDataTable(setMaxValue)}
            </div>
            {!isCalculated
                ? (
                    <div className={`${baseClassName}__simulationAction`}>
                        {props.simulationForm && props.simulationForm(handleFormSubmit, maxValue)}
                    </div>
                ) : (
                    <div className={`${baseClassName}__simulationAction`}>
                        {props.simulationAction && props.simulationAction(calculatingGetAction, handleReset, handleCreatePdf)}
                    </div>
                )}
        </div>
    );
};

Simulation.defaultProps = {
    calculatingAction: '',
    simulationDataTable: undefined,
    simulationAction: undefined,
};

export default Simulation;

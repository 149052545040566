import React from 'react';
import ReactDOM from 'react-dom';
import { loadWidget } from '@sitevision/react';
import FormsOverview from './templates/organisms/simulations-overview';
import SimulationType from './lib/SimulationType';

loadWidget('.plf-lifesituation-simulations', lifeSituationSimulationsWidget => {
    const simulations: SimulationType[] = JSON.parse(lifeSituationSimulationsWidget.dataset.simulations);
    const id: string = lifeSituationSimulationsWidget.dataset.headerId;
    const showPensionCalculatorDescription: boolean = lifeSituationSimulationsWidget.dataset.showPensionCalculatorDescription === 'true';
    ReactDOM.render(<FormsOverview id={id} simulations={simulations} showPensionCalculatorDescription={showPensionCalculatorDescription} />, lifeSituationSimulationsWidget);
});

import React from 'react';
import './index.scss';
import Icon, { IconName } from '../icon';

interface IconButtonProps {
    active?: boolean;
    className?: string;
    handleClick?: () => void;
    href?: string;
    icon: IconName;
    target?: string;
}

const IconButton = ({
    active,
    className,
    handleClick,
    href,
    icon,
    target,
}: IconButtonProps) => {
    const baseClassName = 'a-iconButton';

    const CustomTag = href ? 'a' : 'button';
    const btnType = href ? null : 'button';

    return (
        <CustomTag
            className={`${baseClassName} ${active ? '-active' : ''} ${className}`}
            href={href}
            onClick={handleClick}
            target={href ? target : null}
            type={btnType}
        >
            <Icon name={icon} />
        </CustomTag>
    );
};

IconButton.defaultProps = {
    active: false,
    className: '',
    handleClick: null,
    href: null,
    target: '_self',
};

export default IconButton;

import React from 'react';
import Document from '../../../lib/Document';
import './index.scss';
import Icon, { IconName } from '../../../../../../../../design/1/js/templates/atoms/icon';
import Link from '../../../../../../../../design/1/js/templates/atoms/link';
import { formatDate } from '../../../../../../../../design/1/js/lib/formatData';

interface Props {
    className?: string;
    document: Document;
}

const DocumentItem = ({ className, document }: Props) => {
    const baseClassName = 'w-document-documentItem';

    const documentDate = new Date(document.date);
    const formattedDate = formatDate(documentDate);

    return (
        <Link className={`${baseClassName} ${className}`} href={document.file} target="_blank" title={document.title}>
            <div className={`${baseClassName}__date light-text`}>{formattedDate}</div>
            <div className={`${baseClassName}__title`}>{document.title}</div>
            <div className={`${baseClassName}__downloadIcon`}>
                <Icon name={IconName.Download} />
            </div>
        </Link>
    );
};

DocumentItem.defaultProps = {
    className: '',
};

export default DocumentItem;

import React, {useEffect, useState} from 'react';
import InfoTableSavingPlan from "../../molecules/info-table-saving-plan";
import FormButton, {
    FormButtonVariation
} from "../../../../../../../../design/1/js/templates/atoms/form-fields/form-button";
import SavingPlanSimulationDataTable from "../saving-plan-simulation-data-table";
import SavingPlanChangeForm from "../saving-plan-change-form";
import './index.scss';
import AnswerText from "../../../../../../../../design/1/js/templates/molecules/answer-text";
import LoadSpinner from "../../../../../../../../design/1/js/templates/atoms/load-spinner";
import {formatDate} from "../../../../../../../../design/1/js/lib/formatData";
import GeneralInfo from "../../../lib/savingPlan/GeneralInfo";

interface SavingPlanSimulationProps {
    headerIdSimulation: string;
    infoTableAction: string;
    savingPlanSimulationDataAction: string;
    savingPlanChangeDataAction: string;
    savingPlanChangeSendAction: string;
    nextChangeDateAction: string;
    generalInfoAction: string;
}

const SavingPlanSimulation = ({
    headerIdSimulation, infoTableAction, savingPlanSimulationDataAction, savingPlanChangeDataAction, savingPlanChangeSendAction, nextChangeDateAction, generalInfoAction,
}: SavingPlanSimulationProps) => {
    const baseClassName = 'w-simulationSavingPlan';
    const [nextChangeYear, setNextChangeYear] = useState(null);
    const [loading, setLoading] = useState(false);
    const [failingResponse, setFailingResponse] = useState(null);
    const [calculatePlans, setCalculatePlans] = useState(false);
    const [infoTableDataFetched, setInfoTableDataFetched] = useState(false);
    const [planDataFetched, setPlanDataFetched] = useState(false);
    const [generalInfo, setGeneralInfo] = useState<GeneralInfo|null>(null);

    useEffect(() => {
        setLoading(true);
        fetch(nextChangeDateAction, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    setNextChangeYear(new Date(res.context.nextChangeDate).getFullYear());
                } else if (!res.success) {
                    setFailingResponse(res);
                }
            });

        fetch(generalInfoAction, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    setGeneralInfo(res.context);
                    setLoading(false);
                } else if (!res.success) {
                    setFailingResponse(res);
                }
            });
    }, []);

    const handleCalculationClick = () => {
        setCalculatePlans(true);
    }

    if (failingResponse !== null) {
        return (<AnswerText response={failingResponse} />);
    }

    if (loading) {
        return (<LoadSpinner />);
    }

    const minimumDataFetched = (null !== nextChangeYear && null !== generalInfo);

    return (minimumDataFetched  &&
        <div className={`${baseClassName}`}>
            <h2 className={`${baseClassName}_printHeader`}>
                 <span className="d-print-inline d-none">{generalInfo.insuredFirstName} {generalInfo.insuredLastName}{window.sv_resource.get('plf_simulation_print_title_link')}</span>
                {window.sv_resource.get('plf_simulation_title')}
                <span className="d-print-inline d-none">{` ${window.sv_resource.get('plf_simulation_print_title_date_link')} ${formatDate(new Date())} `}</span>
            </h2>

            <InfoTableSavingPlan action={infoTableAction} fetchingFinished={setInfoTableDataFetched} />
            <h2 className='d-print-none'>{headerIdSimulation}</h2>
            <div className={`${baseClassName}_calculationDateInfoText`}>
                <p>
                    {window.sv_resource.get('plf_simulation_saving_plan_calculation_description_text')
                        .replace('{{changeYear}}', String(nextChangeYear))}
                </p>
            </div>
            { !calculatePlans &&
                <>
                    <FormButton
                        text={window.sv_resource.get('plf_simulation_saving_plan_calculation_button_text')}
                        type={'button'}
                        variation={FormButtonVariation.Primary}
                        hasIcon={false}
                        handleClick={handleCalculationClick}
                        disabled={!infoTableDataFetched}
                    />
                </>
            }
            {/* Render the component (start fetching the simulation) if the user clicks the button or as soon as the info table was fetched, whichever comes first. */}
            { (calculatePlans || infoTableDataFetched) &&
                <SavingPlanSimulationDataTable action={savingPlanSimulationDataAction} visible={calculatePlans} fetchingFinished={setPlanDataFetched} />
            }
            { (planDataFetched) &&
                <SavingPlanChangeForm dataAction={savingPlanChangeDataAction} sendAction={savingPlanChangeSendAction} visible={calculatePlans} />
            }
        </div>
    );
}

export default SavingPlanSimulation;

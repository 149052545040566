import React, { useState } from 'react';
import IntroductionText from '../../../../../../../../design/1/js/templates/molecules/introduction-text';
import CivilStateDeceasedPartnerForm from '../../molecules/civil-state-deceased-partner-form';

interface CivilStateDeceasedPartnerProps {
    actionUrl: string;
}

const CivilStateDeceasedPartner = ({
    actionUrl,
}: CivilStateDeceasedPartnerProps) => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const baseClassName = 'w-changeCivilStateDeceasedPartner';

    const handleFormSubmit = () => {
        setFormSubmitted(!formSubmitted);
    };

    return (
        <div className={`${baseClassName}`}>
            { !formSubmitted && (
                <IntroductionText
                    description={window.sv_resource.get('plf_servicesform_civil_state_deceased_partner_description')}
                    title={window.sv_resource.get('plf_servicesform_civil_state_deceased_partner_title')}
                />
            )}
            <div className={`${baseClassName}-wrapper`}>
                <CivilStateDeceasedPartnerForm actionUrl={actionUrl} handleSubmit={handleFormSubmit} />
            </div>
        </div>
    );
};

export default CivilStateDeceasedPartner;

import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import * as Yup from 'yup';
import { StringSchema } from 'yup';
import { useFormik } from 'formik';
import DatePickerField from '../../../../../../../../design/1/js/templates/molecules/date-picker-field';
import { IconName } from '../../../../../../../../design/1/js/templates/atoms/icon';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import { formatCurrency, getIsoDate, offsetDate } from '../../../../../../../../design/1/js/lib/formatData';
import RadioButton from '../../../../../../../../design/1/js/templates/atoms/form-fields/radiobutton';
import JsonResponse from '../../../../../../../../design/1/js/lib/entity/response/JsonResponse';
import InitialDataType from '../../../lib/pensionCalculator/InitialDataType';
import StatusMessageVariation
    from '../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation';
import LoadSpinner from '../../../../../../../../design/1/js/templates/atoms/load-spinner';
import AnswerText from '../../../../../../../../design/1/js/templates/molecules/answer-text';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import MaxPurchaseRequestType from '../../../lib/purchaseAdditionalAccount/MaxPurchaseRequestType';
import { CalenderStartView } from '../../../../../../../../design/1/js/templates/atoms/form-fields/date-picker';
import PurchasePossibilityCheckEvaluationList from '../../molecules/purchase-possibility-check-evaluation-list';
import Checkbox from '../../../../../../../../design/1/js/templates/atoms/form-fields/checkbox';
import usePdfDownload from '../../../../../../../../design/1/js/lib/hooks/usePdfDownload';
import { baseIntegerSchema, cleanIntegerValue } from '../../../lib/yupFields/baseIntegerSchema';
import {getLastDayOfMonth} from "../../../../../../../../design/1/js/lib/util/DateUtils";

interface PurchaseSimulationFormProps {
    actionUrl: string;
    headerId: string;
    hidden: boolean;
    getMaxPurchaseAction: string;
    getBirthdateAction: string;
    linkToMessageTool: string;
    linkToRegulationsDocument: string;
}

const PurchaseSimulationAdditionalAccountForm = ({
    actionUrl, headerId, hidden, getMaxPurchaseAction, getBirthdateAction, linkToMessageTool, linkToRegulationsDocument,
}: PurchaseSimulationFormProps) => {
    const baseClassName = 'w-purchaseSimulationAdditionalAccountForm';
    const [datePickerErrorRetirement, setDatePickerErrorRetirement] = useState(null);
    const [datePickerErrorPurchase, setDatePickerErrorPurchase] = useState(null);
    const datePickerRef = useRef<HTMLDivElement>(null);
    const [birthdate, setBirthdate] = useState<Date>(null);
    const [isLoading, setLoading] = useState(false);
    const [failingResponse, setFailingResponse] = useState(null);
    const [age58, setAge58] = useState<Date>(null);
    const [lastMonthAge63, setLastMonthAge63] = useState<Date>(null);
    const [maxPurchaseAdditionalAccount, setMaxPurchaseAdditionalAccount] = useState<number>(null);
    const [purchaseWarnings, setPurchaseWarnings] = useState<string[]>(null);

    const testIsNotPast = (val: Date) => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        return val >= currentDate;
    }

    const isValidPurchase = (): boolean => {
        const hasWarnings = purchaseWarnings !== null && purchaseWarnings.length > 0;
        const hasPurchasePotential = maxPurchaseAdditionalAccount > 0;

        return !hasWarnings && hasPurchasePotential;
    };

    const validationSchema = Yup.object().shape({
        // @ts-ignore -> Yup is actually able to handle strings as date; it casts strings with new Date(string)
        purchaseDate: Yup.date()
            .typeError(window.sv_resource.get('form_errormsg_date'))
            .test('isThisYear', window.sv_resource.get('form_errormsg_date_this_year'), (val: Date) => val.getFullYear() === (new Date().getFullYear()))
            .test('isNotPast', window.sv_resource.get('form_errormsg_date_not_past'), testIsNotPast)
            .required(window.sv_resource.get('form_errormsg_date')) as StringSchema,
        plannedRetirementDate: Yup.date()
            .typeError(window.sv_resource.get('form_errormsg_date'))
            .test('isNotPast', window.sv_resource.get('form_errormsg_date_not_past'), testIsNotPast)
            .required(window.sv_resource.get('form_errormsg_date')),
        requestedPurchase: baseIntegerSchema()
            .max(maxPurchaseAdditionalAccount, window.sv_resource.get('plf_simulation_purchase_additional_account_errormsg_max_purchase')),
        vestedBenefitCheck: Yup.string().required(window.sv_resource.get('form_errormsg_radio')),
        wefCheck: Yup.string().required(window.sv_resource.get('form_errormsg_radio')),
        selfEmployedCheck: Yup.string().required(window.sv_resource.get('form_errormsg_radio')),
        abroadCheck: Yup.string().required(window.sv_resource.get('form_errormsg_radio')),
        pillar2Check: Yup.string().required(window.sv_resource.get('form_errormsg_radio')),
        pillar3aCheck: Yup.string().required(window.sv_resource.get('form_errormsg_radio')),
        confirmation: Yup.boolean()
            .oneOf([true], window.sv_resource.get('form_errormsg_radio'))
            .required(window.sv_resource.get('form_errormsg_radio')),
    });

    const handleSubmit = usePdfDownload(actionUrl, setLoading, undefined, true);

    const onSubmit = async (values) => {
        values.requestedPurchase = String(cleanIntegerValue(values.requestedPurchase));

        await handleSubmit(values);
    }

    const initialValues = Object({
        purchaseDate: getIsoDate(new Date()),
        requestedPurchase: '',
        vestedBenefitCheck: '',
        wefCheck: '',
        selfEmployedCheck: '',
        abroadCheck: '',
        pillar2Check: '',
        pillar3aCheck: '',
        confirmation: false,
    });

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });

    const handleIsValidRetirementDate = (isValid: boolean) => {
        setDatePickerErrorRetirement(isValid ? null : window.sv_resource.get('form_errormsg_date_between_ages')
            .replace('{{age1}}', '58')
            .replace('{{age2}}', '63')
        );
    };

    const handleIsValidPurchaseDate = (isValid: boolean) => {
        setDatePickerErrorPurchase(isValid ? null : window.sv_resource.get('form_errormsg_date_between_now_and_end_of_year'));
    };

    useEffect(() => {
        const warnings: string[] = [];
        if (formik.values.vestedBenefitCheck.includes('no')) {
            warnings.push(window.sv_resource.get('plf_simulation_purchase_additional_account_errormsg_vested_benefits'));
        }
        if (formik.values.wefCheck.includes('yes')) {
            warnings.push(window.sv_resource.get('plf_simulation_purchase_additional_account_errormsg_wef'));
        }
        if (formik.values.selfEmployedCheck.includes('yes')) {
            warnings.push(window.sv_resource.get('plf_simulation_purchase_additional_account_errormsg_self_employed'));
        }
        if (formik.values.abroadCheck.includes('yes')) {
            warnings.push(window.sv_resource.get('plf_simulation_purchase_additional_account_errormsg_abroad'));
        }
        if (formik.values.pillar2Check.includes('yes')) {
            warnings.push(window.sv_resource.get('plf_simulation_purchase_additional_account_errormsg_2a'));
        }
        setPurchaseWarnings(warnings);
    }, [formik.values.vestedBenefitCheck, formik.values.wefCheck, formik.values.selfEmployedCheck, formik.values.abroadCheck, formik.values.pillar2Check])


    /**
     * The planned retirement date the user can select must be between the user's ages 58-63.
     * So the placeholder value is either the day the user turns 58 or the current date, if the user is already 58+ years old.
     *
     * Since the input is a month picker, we must use the last day of the month.
     */
    const evaluateRetirementDatePlaceholder = () => {
        const date = age58 < new Date() ? new Date() : age58;
        const lastDayOfMonth = getLastDayOfMonth(date);

        return getIsoDate(lastDayOfMonth);
    }

    useEffect(() => {
        if (birthdate === null) {
            return;
        }

        setAge58(new Date(birthdate.getFullYear() + 58, birthdate.getMonth(), birthdate.getDate()));
        setLastMonthAge63(new Date(birthdate.getFullYear() + 63, birthdate.getMonth() + 11, birthdate.getDate()));
    }, [birthdate]);

    useEffect(() => {
       if (age58 === null) {
           return;
       }

       const date = evaluateRetirementDatePlaceholder();

        formik.setFieldValue('plannedRetirementDate', date);
    }, [age58]);

    useEffect(() => {
        if (!formik.values.plannedRetirementDate || !formik.values.purchaseDate) {
            return;
        }
        setLoading(true);
        let params: MaxPurchaseRequestType = {
            retirementDate: formik.values.plannedRetirementDate,
            purchaseDate: formik.values.purchaseDate,
        };
        fetch(getMaxPurchaseAction, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    setMaxPurchaseAdditionalAccount(res.context.maxPurchaseAdditionalAccount);
                    setFailingResponse(null);
                } else if (!res.success) {
                    setFailingResponse(res);
                }
                setLoading(false);
            });
    }, [formik.values.plannedRetirementDate, formik.values.purchaseDate]);

    return (
        <div className={`${baseClassName}`}>
            <AsyncLoader
                action={getBirthdateAction}
                onSuccess={(res: JsonResponse<InitialDataType>) => {
                    setBirthdate(offsetDate(new Date(res.context.birthdate)));
                }}
                renderContent={lazyChildren => (
                    <>
                        <Paragraph>{window.sv_resource.get('plf_simulation_purchase_additional_account_description')}</Paragraph>
                        {lazyChildren}
                    </>
                )}
                variation={StatusMessageVariation.Subpage}
            >
                {() => {
                    if (isLoading || lastMonthAge63 === null) {
                        return (<LoadSpinner/>);
                    }
                    if (failingResponse !== null && failingResponse.statusCode !== 409) {
                        return (<AnswerText response={failingResponse}/>);
                    }
                    return (
                        <div className={`${baseClassName} ${hidden ? 'd-none' : ''} `}>
                            <form name="purchaseSimulationAdditionalAccount" onSubmit={formik.handleSubmit}>
                                <div className="row gap-xxs">
                                    <DatePickerField
                                        className="col-12 col-lg-6"
                                        endOfMonth
                                        errors={datePickerErrorRetirement ?? formik.errors.plannedRetirementDate}
                                        icon={IconName.Calendar}
                                        id="plannedRetirementDate"
                                        label={window.sv_resource.get('plf_simulation_purchase_additional_account_requested_retirement_date')}
                                        maxDate={lastMonthAge63}
                                        minDate={age58 < new Date() ? new Date() : age58}
                                        ref={datePickerRef}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        onIsValid={handleIsValidRetirementDate}
                                        touched
                                        startView={CalenderStartView.Month}
                                        value={formik.values.plannedRetirementDate}
                                    />
                                    <DatePickerField
                                        className="col-12 col-lg-6"
                                        errors={datePickerErrorPurchase ?? formik.errors.purchaseDate}
                                        icon={IconName.Calendar}
                                        id="purchaseDate"
                                        label={window.sv_resource.get('plf_simulation_purchase_additional_account_purchase_date')}
                                        minDate={new Date()}
                                        maxDate={new Date(new Date().getFullYear(), 11, 31)}
                                        ref={datePickerRef}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        onIsValid={handleIsValidPurchaseDate}
                                        touched
                                        value={formik.values.purchaseDate}
                                    />
                                </div>
                                <div className="row gap-xxs">
                                    <TextField
                                        className="col-12 col-lg-6"
                                        disabled
                                        errors={null}
                                        icon={IconName.Chf}
                                        id="maxPurchase"
                                        label={window.sv_resource.get('plf_simulation_purchase_additional_account_label_max_purchase')}
                                        name="maxPurchase"
                                        onBlur={null}
                                        onChange={null}
                                        placeholder={null}
                                        touched={null}
                                        type="text"
                                        value={maxPurchaseAdditionalAccount !== null ? formatCurrency(maxPurchaseAdditionalAccount) : ''}
                                    />
                                    { maxPurchaseAdditionalAccount > 0 && (
                                            <TextField
                                                className="col-12 col-lg-6"
                                                errors={formik.errors.requestedPurchase}
                                                icon={IconName.Chf}
                                                id="requestedPurchase"
                                                label={window.sv_resource.get('plf_simulation_purchase_additional_account_label_requested_purchase')}
                                                name="requestedPurchase"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                placeholder={null}
                                                touched={!!formik.touched.requestedPurchase}
                                                type="text"
                                                value={formik.values.requestedPurchase}
                                            />
                                        )
                                    }
                                </div>
                                { maxPurchaseAdditionalAccount > 0 &&
                                    <div className={`${baseClassName}__radioChecks`}>
                                        <div className={`${baseClassName}__radioRow row gap-xxs`}>
                                            <Paragraph className="col-12 col-lg-6">{window.sv_resource.get('plf_simulation_purchase_additional_account_label_vested_benefits')}</Paragraph>
                                            <div className={`${baseClassName}__radioWrapper col-12 col-lg-6`}>
                                                <RadioButton
                                                    checked={formik.values.vestedBenefitCheck.toString() === 'vestedBenefitCheck-no'}
                                                    errors={formik.errors.vestedBenefitCheck}
                                                    id="vestedBenefitCheck-no"
                                                    name="vestedBenefitCheck"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    touched={!!formik.touched.vestedBenefitCheck}
                                                    label={window.sv_resource.get('plf_simulation_purchase_additional_account_radio_no')}
                                                />
                                                <RadioButton
                                                    checked={formik.values.vestedBenefitCheck.toString() === 'vestedBenefitCheck-yes'}
                                                    errors={formik.errors.vestedBenefitCheck}
                                                    id="vestedBenefitCheck-yes"
                                                    name="vestedBenefitCheck"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    touched={!!formik.touched.vestedBenefitCheck}
                                                    label={window.sv_resource.get('plf_simulation_purchase_additional_account_radio_yes')}
                                                />
                                            </div>
                                        </div>
                                        <div className={`${baseClassName}__radioRow row gap-xxs`}>
                                            <Paragraph className="col-12 col-lg-6">{window.sv_resource.get('plf_simulation_purchase_additional_account_label_wef')}</Paragraph>
                                            <div className={`${baseClassName}__radioWrapper col-12 col-lg-6`}>
                                                <RadioButton
                                                    checked={formik.values.wefCheck.toString() === 'wefCheck-no'}
                                                    errors={formik.errors.wefCheck}
                                                    id="wefCheck-no"
                                                    name="wefCheck"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    touched={!!formik.touched.wefCheck}
                                                    label={window.sv_resource.get('plf_simulation_purchase_additional_account_radio_no')}
                                                />
                                                <RadioButton
                                                    checked={formik.values.wefCheck.toString() === 'wefCheck-yes'}
                                                    errors={formik.errors.wefCheck}
                                                    id="wefCheck-yes"
                                                    name="wefCheck"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    touched={!!formik.touched.wefCheck}
                                                    label={window.sv_resource.get('plf_simulation_purchase_additional_account_radio_yes')}
                                                />
                                            </div>
                                        </div>
                                        <div className={`${baseClassName}__radioRow row gap-xxs`}>
                                            <Paragraph className="col-12 col-lg-6">{window.sv_resource.get('plf_simulation_purchase_additional_account_label_selfemployed')}</Paragraph>
                                            <div className={`${baseClassName}__radioWrapper col-12 col-lg-6`}>
                                                <RadioButton
                                                    checked={formik.values.selfEmployedCheck.toString() === 'selfEmployedCheck-no'}
                                                    errors={formik.errors.selfEmployedCheck}
                                                    id="selfEmployedCheck-no"
                                                    name="selfEmployedCheck"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    touched={!!formik.touched.selfEmployedCheck}
                                                    label={window.sv_resource.get('plf_simulation_purchase_additional_account_radio_no')}
                                                />
                                                <RadioButton
                                                    checked={formik.values.selfEmployedCheck.toString() === 'selfEmployedCheck-yes'}
                                                    errors={formik.errors.selfEmployedCheck}
                                                    id="selfEmployedCheck-yes"
                                                    name="selfEmployedCheck"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    touched={!!formik.touched.selfEmployedCheck}
                                                    label={window.sv_resource.get('plf_simulation_purchase_additional_account_radio_yes')}
                                                />
                                            </div>
                                        </div>
                                        <div className={`${baseClassName}__radioRow row gap-xxs`}>
                                            <Paragraph className="col-12 col-lg-6">{window.sv_resource.get('plf_simulation_purchase_additional_account_label_abroad')}</Paragraph>
                                            <div className={`${baseClassName}__radioWrapper col-12 col-lg-6`}>
                                                <RadioButton
                                                    checked={formik.values.abroadCheck.toString() === 'abroadCheck-no'}
                                                    errors={formik.errors.abroadCheck}
                                                    id="abroadCheck-no"
                                                    name="abroadCheck"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    touched={!!formik.touched.abroadCheck}
                                                    label={window.sv_resource.get('plf_simulation_purchase_additional_account_radio_no')}
                                                />
                                                <RadioButton
                                                    checked={formik.values.abroadCheck.toString() === 'abroadCheck-yes'}
                                                    errors={formik.errors.abroadCheck}
                                                    id="abroadCheck-yes"
                                                    name="abroadCheck"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    touched={!!formik.touched.abroadCheck}
                                                    label={window.sv_resource.get('plf_simulation_purchase_additional_account_radio_yes')}
                                                />
                                            </div>
                                        </div>
                                        <div className={`${baseClassName}__radioRow row gap-xxs`}>
                                            <Paragraph className="col-12 col-lg-6">{window.sv_resource.get('plf_simulation_purchase_additional_account_label_2a')}</Paragraph>
                                            <div className={`${baseClassName}__radioWrapper col-12 col-lg-6`}>
                                                <RadioButton
                                                    checked={formik.values.pillar2Check.toString() === 'pillar2Check-no'}
                                                    errors={formik.errors.pillar2Check}
                                                    id="pillar2Check-no"
                                                    name="pillar2Check"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    touched={!!formik.touched.pillar2Check}
                                                    label={window.sv_resource.get('plf_simulation_purchase_additional_account_radio_no')}
                                                />
                                                <RadioButton
                                                    checked={formik.values.pillar2Check.toString() === 'pillar2Check-yes'}
                                                    errors={formik.errors.pillar2Check}
                                                    id="pillar2Check-yes"
                                                    name="pillar2Check"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    touched={!!formik.touched.pillar2Check}
                                                    label={window.sv_resource.get('plf_simulation_purchase_additional_account_radio_yes')}
                                                />
                                            </div>
                                        </div>
                                        <div className={`${baseClassName}__radioRow row gap-xxs`}>
                                            <Paragraph className="col-12 col-lg-6">{window.sv_resource.get('plf_simulation_purchase_additional_account_label_3a')}</Paragraph>
                                            <div className={`${baseClassName}__radioWrapper col-12 col-lg-6`}>
                                                <RadioButton
                                                    checked={formik.values.pillar3aCheck.toString() === 'pillar3aCheck-no'}
                                                    errors={formik.errors.pillar3aCheck}
                                                    id="pillar3aCheck-no"
                                                    name="pillar3aCheck"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    touched={!!formik.touched.pillar3aCheck}
                                                    label={window.sv_resource.get('plf_simulation_purchase_additional_account_radio_no')}
                                                />
                                                <RadioButton
                                                    checked={formik.values.pillar3aCheck.toString() === 'pillar3aCheck-yes'}
                                                    errors={formik.errors.pillar3aCheck}
                                                    id="pillar3aCheck-yes"
                                                    name="pillar3aCheck"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    touched={!!formik.touched.pillar3aCheck}
                                                    label={window.sv_resource.get('plf_simulation_purchase_additional_account_radio_yes')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    purchaseWarnings?.length > 0 && (
                                        <div className={`${baseClassName}__warningWrapper row`}>
                                            <PurchasePossibilityCheckEvaluationList linkToMessageTool={linkToMessageTool} warnings={purchaseWarnings} />
                                        </div>
                                    )
                                }
                                { isValidPurchase() && (
                                    <>
                                        <div className={`${baseClassName}__infoRowWrapper row`}>
                                            <Paragraph>{window.sv_resource.get('plf_simulation_purchase_additional_account_completion_instructions_part_1')}</Paragraph>
                                            {
                                                <Paragraph>{window.sv_resource.get('plf_simulation_purchase_additional_account_completion_instructions_part_2')}</Paragraph>
                                            }
                                        </div>
                                        <div className={`${baseClassName}__confirmationRowWrapper row`}>
                                            <Checkbox
                                                checked={formik.values.confirmation}
                                                errors={formik.errors.confirmation}
                                                touched={!!formik.touched.confirmation}
                                                id="confirmation"
                                                name="confirmation"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                label={window.sv_resource.get('plf_simulation_purchase_additional_account_confirmation_description').replace('{{link_regulationsdocument}}', linkToRegulationsDocument)}
                                            />
                                        </div>
                                    </>
                                    )
                                }
                                <div className={`${baseClassName}__submitWrapper row`}>
                                    <FormButton
                                        disabled={Object.keys(formik.errors).length > 0 || Object.keys(formik.touched).length === 0 || datePickerErrorPurchase !== null || datePickerErrorRetirement !== null || purchaseWarnings.length > 0}
                                        hasIcon={false}
                                        text={window.sv_resource.get('plf_simulation_purchase_additional_account_btn_request_label')}
                                        type="submit"
                                        variation={FormButtonVariation.Primary}
                                    />
                                </div>
                            </form>
                        </div>
                    );
                }}
            </AsyncLoader>
        </div>
    );
};

export default PurchaseSimulationAdditionalAccountForm;

import React, { useState } from 'react';
import * as Yup from 'yup';
import FormButton, { FormButtonVariation } from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import './index.scss';
import FormWithAnswerText from '../../../../../../../../design/1/js/templates/organisms/form-with-answer-text';
import Checkbox from '../../../../../../../../design/1/js/templates/atoms/form-fields/checkbox';
import InitialDataType from '../../../lib/entity/changeOfAddress/InitialDataType';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import useCityAndZipAutosuggestFields, {
    CityAndZipActions,
    Country,
} from '../../../lib/hook/useCityAndZipAutosuggestFields';
import StatusMessageVariation
    from '../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation';
import {hasInsuredMultipleIds} from "../../../../../../../../design/1/js/lib/util/InsuredUtils";
import StatusMessage, {MessageType} from "../../../../../../../../design/1/js/templates/molecules/status-message";

export interface ChangeOfAddressFormProps {
    actionUrl: string,
    cityByCityAction: string,
    cityByZipAction: string,
    initialDataAction: string;
    handleSubmit: (status: number) => void,
    zipByCityAction: string
    zipByZipAction: string;
}

const ChangeOfAddressForm = ({
    actionUrl, cityByCityAction, cityByZipAction, initialDataAction, handleSubmit, zipByCityAction, zipByZipAction,
}:ChangeOfAddressFormProps) => {
    const baseClassName = 'w-changeOfAddressForm';

    const [citySuggestions, setCitySuggestions] = useState([]);
    const [zipCodeSuggestions, setZipCodeSuggestions] = useState([]);

    const cityAndZipActions: CityAndZipActions = {
        cityByCityAction,
        cityByZipAction,
        zipByCityAction,
        zipByZipAction,
    };

    const validationSchema = Yup.object().shape({
        lastname: Yup.string(),
        firstname: Yup.string(),
        street: Yup.string()
            .required(window.sv_resource.get('form_errormsg_string')),
        zipCode: Yup.string()
            .when('country', {
                is: country => country === Country.Switzerland,
                then: Yup.string()
                    .when('city', {
                        is: city => city === '' || city === undefined,
                        then: Yup.string()
                            .test('is-zipCode', window.sv_resource.get('plf_servicesform_changeofaddress_invalid_zipcode'), value => (value === undefined || zipCodeSuggestions.indexOf(value) !== -1))
                            .when('zipCode', {
                                is: zipCode => zipCode !== '',
                                then: Yup.string()
                                    .max(4, window.sv_resource.get('form_errormsg_number_max').replace('{{max}}', '4'))
                                    .required(() => window.sv_resource.get('form_errormsg_string')),
                            }),
                    }),

            })
            .required(window.sv_resource.get('form_errormsg_string')),
        city: Yup.string()
            .when('country', {
                is: country => country === Country.Switzerland,
                then: Yup.string()
                    .when('zipCode', {
                        is: zipCode => zipCode === '' || zipCode === undefined,
                        then: Yup.string()
                            .test('is-city', window.sv_resource.get('plf_servicesform_changeofaddress_invalid_city'), value => (value === undefined || citySuggestions.indexOf(value) !== -1)),
                    }),

            })
            .required(window.sv_resource.get('form_errormsg_string')),
        country: Yup.string()
            .required(window.sv_resource.get('form_errormsg_dropdown')),
        confirmation: Yup.boolean()
            .oneOf([true], window.sv_resource.get('form_errormsg_radio'))
            .required(window.sv_resource.get('form_errormsg_radio')),
    });

    const initialValues = Object({
        lastname: '',
        firstname: '',
        street: '',
        zipCode: '',
        city: '',
        country: Country.Switzerland,
        confirmation: false,
    });

    return (
        <AsyncLoader
            action={initialDataAction}
            renderContent={lazyChildren => (
                <>
                    {lazyChildren}
                </>
            )}
            variation={StatusMessageVariation.Subpage}
        >
            {({ userData, countries }: InitialDataType) => (
                <FormWithAnswerText
                    actionUrl={actionUrl}
                    className={`${baseClassName}`}
                    handleSubmit={handleSubmit}
                    handleValidate={validationSchema}
                    initialValues={initialValues}
                    name="ChangeOfAddress"
                >
                    {formik => {
                        const callbacks = { setCitySuggestions, setZipCodeSuggestions };
                        const {
                            countryField,
                            cityField,
                            zipField,
                        } = useCityAndZipAutosuggestFields(baseClassName, callbacks, cityAndZipActions, countries, formik);

                        return (
                            <>
                                <div className={`${baseClassName}__section -disabled row gap-xxs justify-content-end`}>
                                    <TextField
                                        className="col-12 col-lg-6"
                                        disabled
                                        errors={formik.errors.firstname}
                                        id="firstname"
                                        label={window.sv_resource.get('plf_servicesform_changeofaddress_firstname')}
                                        name="firstname"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        placeholder=""
                                        touched={!!formik.touched.firstname}
                                        type="text"
                                        value={userData.firstname}
                                    />
                                    <TextField
                                        className="col-12 col-lg-6"
                                        disabled
                                        errors={formik.errors.lastname}
                                        id="lastname"
                                        label={window.sv_resource.get('plf_servicesform_changeofaddress_lastname')}
                                        name="lastname"
                                        type="text"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        placeholder=""
                                        touched={!!formik.touched.lastname}
                                        value={userData.lastname}
                                    />
                                </div>
                                <div className={`${baseClassName}__section row gap-xxs`}>
                                    <TextField
                                        id="street"
                                        label={window.sv_resource.get('plf_servicesform_changeofaddress_street')}
                                        name="street"
                                        type="text"
                                        className="col-12 col-lg-6"
                                        placeholder=""
                                        touched={!!formik.touched.street}
                                        errors={formik.errors.street}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.street}
                                    />
                                </div>
                                <div className={`${baseClassName}__section row gap-xxs`}>
                                    {countryField}
                                    {zipField}
                                    {cityField}
                                </div>
                                <Checkbox
                                    className={`${baseClassName}__checkbox col-12`}
                                    errors={formik.errors.confirmation}
                                    touched={!!formik.touched.confirmation}
                                    id="confirmation"
                                    name="confirmation"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    label={window.sv_resource.get('plf_servicesform_changeofaddress_confirmation')}
                                />
                                {hasInsuredMultipleIds() && (
                                    <div className="mb-5">
                                        <StatusMessage
                                            customMessageColor={MessageType.Info}
                                            description={window.sv_resource.get('plf_yourprofile_master_data_address_description_multiple_insured_ids')}
                                            inline
                                            messageType={MessageType.Info}
                                            variation={StatusMessageVariation.WithBackground}
                                        />
                                    </div>
                                )}
                                <FormButton
                                    className={`${baseClassName}__submit`}
                                    disabled={Object.keys(formik.errors).length > 0 || Object.keys(formik.touched).length === 0}
                                    text={window.sv_resource.get('plf_servicesform_changeofaddress_form_submit_btn')}
                                    type="submit"
                                    variation={FormButtonVariation.Primary}
                                />
                            </>
                        );
                    }}
                </FormWithAnswerText>
            )}
        </AsyncLoader>
    );
};

export default ChangeOfAddressForm;

import React from 'react';
import './index.scss';
import { Tab } from '@headlessui/react';
import Paragraph from '../../../../../../../../design/1/js/templates/atoms/paragraph';
import nl2br from '../../../../../messagetool/ts/lib/nl2br';
import InsuranceCertificate from '../../../lib/InsuranceCertificate';
import TabPanel from '../../molecules/tab-panel';

interface DocumentHelperProps {
    insuranceCertificates: InsuranceCertificate[];
    title: string;
}

const DocumentHelper = ({ insuranceCertificates, title }: DocumentHelperProps) => {
    const baseClassName = 'w-documentHelper';

    const handleClick = (id: string) => {
        const accordionItem = document.getElementById(id);
        if (accordionItem !== null) {
            const accordionButton = accordionItem.querySelector('button');
            if (!accordionButton.classList.contains('-open')) {
                // Simulates a click on the accordion to display the selected content. Accordion open status can only be influenced in the component.
                accordionButton.click();
            }
        }
    };

    return (
        <div className={`${baseClassName}`}>
            <h1>{title}</h1>
            <Paragraph>{nl2br(window.sv_resource.get('plf_documenthelper_description'))}</Paragraph>
            <Tab.Group>
                <Tab.List className={`${baseClassName}__tabList`}>
                    {insuranceCertificates.map((certificate, index) => (
                        <Tab className={({ selected }) => `${baseClassName}__tab ${selected ? '-selected' : ''}`} key={`tab-${index.toString()}`}>
                            {certificate.title}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels className={`${baseClassName}__panels`}>
                    {insuranceCertificates.map((certificate, index) => (
                        <TabPanel certificate={certificate} key={`panel-${index.toString()}`} onClick={handleClick} />
                    ))}
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
};

export default DocumentHelper;

import React from 'react';

const ClientMessageListTitleRow = () => (
    <>
        <div className="row col-11">
            <div className="col-9 text-start">
                {window.sv_resource.get('plf_messagetool_head_title_and_subject')}
            </div>
            <div className="col-3">{window.sv_resource.get('plf_messagetool_head_date')}</div>
        </div>
        <div className="col-1" />
    </>
);

export default ClientMessageListTitleRow;

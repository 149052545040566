enum SimulationType {
    Wef = 'wef',
    PensionCertificate = 'pensionCertificate',
    PensionCalculator = 'pensionCalculator',
    Purchase = 'purchase',
    PurchaseAdditionalAccount = 'purchaseAdditionalAccount',
    SavingPlan = 'savingPlan',
}

export default SimulationType;

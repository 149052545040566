import React from 'react';
import YourDocumentType from '../../../lib/YourDocumentType';
import IconButton from '../../../../../../../../design/1/js/templates/atoms/icon-button';
import { IconName } from '../../../../../../../../design/1/js/templates/atoms/icon';
import TableHeightRow from '../../../../../../../../design/1/js/templates/atoms/table-height-row';

interface DownloadRowProps {
    document: YourDocumentType;
}

const DownloadRow = ({
    document,
}: DownloadRowProps) => {
    const baseClassName = 'w-downloadRow';
    return (
        <TableHeightRow className={`${baseClassName}`} href={document.link} download={document.name}>
            <div className={`${baseClassName}__column col-3 col-sm-2 light-text`}>
                {document.date}
            </div>
            <div className={`${baseClassName}__column col-7 col-sm-9`}>
                {document.name}
            </div>
            <div className={`${baseClassName}__column col-2 col-sm-1`}>
                <IconButton icon={IconName.Download} />
            </div>
        </TableHeightRow>
    );
};

export default DownloadRow;

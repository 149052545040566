import React from 'react';
import './index.scss';

interface FooterColumnProps {
    centerMedium?: boolean;
    centerMobile?: boolean;
    children: React.ReactNode;
}

const FooterColumn = ({ centerMedium, centerMobile, children }: FooterColumnProps) => {
    const baseClassName = 'm-footer-column';

    return (
        <div className={`${baseClassName} ${centerMobile ? '-centerMobile' : ''} ${centerMedium ? '-centerMedium' : ''} col-12 col-lg-6`}>
            {children}
        </div>
    );
};

FooterColumn.defaultProps = {
    centerMedium: false,
    centerMobile: false,
};

export default FooterColumn;

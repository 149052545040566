import { loadWidget } from '@sitevision/react';
import React from 'react';
import ReactDOM from 'react-dom';
import Onboarding from './templates/organisms/onboarding';

loadWidget('.plf-onboarding-area', onboardingWidget => {
    const actionURL = onboardingWidget.dataset.url;
    const agbText = onboardingWidget.dataset.agbText;
    const infoboxText = onboardingWidget.dataset.infoboxText;
    const swissIdEmail = onboardingWidget.dataset.swissIdEmail;
    const onboardingLink = onboardingWidget.dataset.onboardingLink;
    const swissIdEmailText = onboardingWidget.dataset.swissIdEmailText;
    const homepageLinkAction = onboardingWidget.dataset.homepageLinkAction;
    ReactDOM.render(<Onboarding actionUrl={actionURL} agbText={agbText} infoboxText={infoboxText} swissIdEmail={swissIdEmail} onboardingLink={onboardingLink} swissIdEmailText={swissIdEmailText} homepageLinkAction={homepageLinkAction} />, onboardingWidget);
});

import React from 'react';
import './index.scss';

export interface CardImageProps {
    image: string;
    cardName: string;
}

const CardImage = ({ cardName, image }: CardImageProps) => {
    const baseClassName = 'a-card-image';
    return (
        <div className={`${baseClassName}`}>
            <img src={image} alt={cardName} />
        </div>
    );
};

export default CardImage;

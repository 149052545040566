// AHV-Nummer
export const validateSocialSecurityNumber = (value: string) => {
    if (value === undefined) {
        return false;
    }

    const searchRegExp = /[. ]/g;
    const replaceWith = '';

    const number = value.replace(searchRegExp, replaceWith).split('').reverse();
    if (number.length !== 13) {
        return false;
    }

    let checkSum = 0;
    for (let index = 1; index < number.length; index++) {
        if (index % 2 === 1) {
            checkSum += +number[index] * 3;
        } else {
            checkSum += +number[index];
        }
    }

    const multipleOfTen = Math.ceil(checkSum / 10) * 10;

    return (multipleOfTen - checkSum) === +number[0];
};

export const validateIBAN = (value: string) => {
    if (value === undefined) {
        return false;
    }

    const searchRegExp = /\s/g;
    const replaceWith = '';
    const numberToCalc = value.replace(searchRegExp, replaceWith);

    if (numberToCalc === '') {
        return false;
    }

    const rearranged = numberToCalc.substring(4, numberToCalc.length) + numberToCalc.substring(0, 4);
    const numeric = Array.from(rearranged).map(c => (Number.isNaN(parseInt(c, 10)) ? (c.charCodeAt(0) - 55).toString() : c)).join('');
    const remainder = Array.from(numeric).map(c => parseInt(c, 10)).reduce((remainderAfter, valueAfter) => (remainderAfter * 10 + valueAfter) % 97.0);

    return remainder === 1;
};

export const bicSwiftRegex = /^([A-Z]{6}[A-Z2-9][A-NP-Z0-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/;


export const validateBirthdate = (value: string, livingPerson: boolean = true) => {
    if (value === undefined) {
        return false;
    }
    if (!value.match(/[0-3][0-9].[0-1][0-9].[0-9]{4}$/)) {
        return false;
    }

    const year = parseInt(value.slice(-4));

    if (year < 1900 && livingPerson) {
        return false;
    }

    if (year > (new Date().getFullYear())) {
        return false;
    }

    return true;
};

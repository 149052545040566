import React, { useState } from 'react';
import './index.scss';
import IntroductionText from '../../../../../../../../design/1/js/templates/molecules/introduction-text';
import ExitNoticeVestedBenefitsInstitutionForm from '../exit-notice-vested-benefits-institution-form';

interface ExitNoticeVestedBenefitsInstitutionProps {
    formAction: string;
    masterDataAction: string;
}

const ExitNoticeVestedBenefitsInstitution = ({ formAction, masterDataAction }: ExitNoticeVestedBenefitsInstitutionProps) => {
    const [formSubmit, setFormSubmit] = useState(false);
    const baseClassName = 'w-exitNoticeVestedBenefitsInstitution';

    const handleFormSubmit = () => {
        setFormSubmit(!formSubmit);
    };

    return (
        <div className={`${baseClassName}`}>
            { !formSubmit && <IntroductionText description={window.sv_resource.get('plf_onlineform_exit_notice_vested_benefits_institution_description')} title={window.sv_resource.get('plf_onlineform_exit_notice_vested_benefits_institution_title')} /> }
            <div className={`${baseClassName}__wrapper`}>
                <ExitNoticeVestedBenefitsInstitutionForm formAction={formAction} handleSubmit={handleFormSubmit} masterDataAction={masterDataAction} />
            </div>
        </div>
    );
};

export default ExitNoticeVestedBenefitsInstitution;

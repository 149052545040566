import React, { useMemo } from 'react';
import Label from '../../atoms/form-fields/label';
import './index.scss';
import FileUpload, { FileUploadProps } from '../../atoms/form-fields/file-upload';
import RichText from '../../atoms/richtext';

interface FileUploadFieldProps extends FileUploadProps {
    label: string;
}

const FileUploadField = ({ label, ...props }: FileUploadFieldProps) => {
    const baseClassName = 'm-fileUploadField';

    return (
        <div className={`${baseClassName} ${props.className}`}>
            {useMemo(() => (
                <Label
                    className={`${baseClassName}__label`}
                    htmlFor={props.id}
                >
                    <RichText text={label} />
                </Label>
            ), [baseClassName, props.id, label])}
            {useMemo(() => (
                <FileUpload
                    accept={props.accept}
                    disabled={props.disabled}
                    errors={props.errors}
                    id={props.id}
                    multiple={props.multiple}
                    name={props.name}
                    onBlur={props.onBlur}
                    onChange={props.onChange}
                    placeholder={props.placeholder}
                    touched={props.touched}
                    value={props.value}
                />
            ), [
                props.accept,
                props.disabled,
                props.errors,
                props.id,
                props.multiple,
                props.name,
                props.onBlur,
                props.onChange,
                props.placeholder,
                props.touched,
                props.value,
            ])}
        </div>
    );
};

export default FileUploadField;

import React from 'react';
import './index.scss';
import ContactCard from '../../../../../../../../design/1/js/templates/molecules/contact-card';
import MoreQuestions from '../../atoms/more-questions';
import PensionConsultant from '../../../../../welcome/ts/lib/PensionConsultant';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import { AsyncWidgetProps } from '../../../../../base/ts/lib/AsyncWidgetProps';

const ContactSection = ({ action }: AsyncWidgetProps) => {
    const baseClassName = 'm-contactSection';

    return (
        <div className={`${baseClassName}`}>
            <div className="container-lg">
                <div className={`${baseClassName}__wrapper`}>
                    <AsyncLoader
                        action={action}
                        renderContent={lazyChildren => (
                            <>
                                <MoreQuestions />
                                {lazyChildren}
                            </>
                        )}
                    >
                        {(consultant: PensionConsultant) => (
                            <ContactCard consultant={consultant} />
                        )}
                    </AsyncLoader>
                </div>
            </div>
        </div>
    );
};

export default ContactSection;

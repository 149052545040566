import React from 'react';
import './index.scss';

interface SectionTitleProps {
    frontpage?: boolean;
    headline?: boolean;
    id?: string;
    level?: 1 | 2 | 3;
    title: string;
}

const SectionTitle = ({
    frontpage,
    headline,
    id,
    level,
    title,
}: SectionTitleProps) => {
    const baseClassName = 'a-section-title';

    const chooseTitleLevel = () => {
        switch (level) {
        case 1:
        default:
            return <h1 className={`${baseClassName} headline`} data-level={level} id={id}>{title}</h1>;
        case 2:
            return <h2 className={`${baseClassName} ${frontpage ? '-indentation' : ''} ${headline ? 'headline' : ''}`} data-level={level} id={id}>{title}</h2>;
        case 3:
            return <h3 className={`${baseClassName} ${headline ? 'headline' : ''}`} data-level={level} id={id}>{title}</h3>;
        }
    };
    return (
        chooseTitleLevel()
    );
};

SectionTitle.defaultProps = {
    frontpage: false,
    headline: false,
    id: null,
    level: 1,
};

export default SectionTitle;

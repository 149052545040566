import {useFormik} from 'formik';
import React, {useState} from 'react';
import {toast} from 'react-hot-toast';
import JsonResponse from '../../../../../../../../design/1/js/lib/entity/response/JsonResponse';
import FileHelper from '../../../../../../../../design/1/js/lib/FileHelper';
import formatErrorToastMessage from '../../../../../../../../design/1/js/lib/formatErrorToastMessage';
import Checkbox from '../../../../../../../../design/1/js/templates/atoms/form-fields/checkbox';
import FormButton, {
    FormButtonVariation,
} from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import Select from '../../../../../../../../design/1/js/templates/atoms/form-fields/select';
import FileUploadField from '../../../../../../../../design/1/js/templates/molecules/file-upload-field';
import TextareaField from '../../../../../../../../design/1/js/templates/molecules/textarea-field';
import AnswerMessageJsonResponseType from '../../../lib/AnswerMessageJsonResponseType';
import AnswerType from '../../../lib/AnswerType';
import './index.scss';
import getConsultantFromArray from '../../../lib/utils/getConsultantFromArray';
import ConsultantType from '../../../lib/consultant/ConsultantType';
import getAnswerValidationSchema from "../../../lib/utils/getAnswerValidationSchema";

export interface ConsultantAnswerFormProps {
    addAnswer: (answer: AnswerType) => void;
    allowAnswerOnBehalfOf: boolean;
    answerAction: string;
    clientName: string;
    messageId: number;
    messageTheme: string;
    statusList: string[];
    themeList: string[];
    loggedInConsultantId: number;
    consultantList: ConsultantType[];
}

const ConsultantAnswerForm = ({
    addAnswer,
    allowAnswerOnBehalfOf,
    answerAction,
    clientName,
    messageId,
    messageTheme,
    statusList,
    themeList,
    loggedInConsultantId,
    consultantList,
}: ConsultantAnswerFormProps) => {
    const baseClassName = 'm-consultantAnswerForm';

    const loggedInConsultant = getConsultantFromArray(loggedInConsultantId, consultantList);
    const maxFileSize = 8 * 1024 * 1024;
    const allowedFileTypes = ['jpg', 'jpeg', 'png', 'jpe', 'pdf'];
    const fileHelper = new FileHelper(maxFileSize, allowedFileTypes);
    const [fileUploadError, setFileUploadError] = useState(null);
    const validationSchema = getAnswerValidationSchema(true);
    const initialValues = Object({
        answer: window.sv_resource.get('plf_messagetool_consultant_message_signature').replace('{{pensionConsultantName}}', loggedInConsultant?.consultantName),
        onBehalfOf: false,
        files: [],
        status: statusList[1],
        theme: messageTheme ?? '',
    });

    const onSubmit = async values => {
        const response: JsonResponse<AnswerMessageJsonResponseType> = await fetch(answerAction, {
            method: 'POST',
            body: JSON.stringify({inboxId: messageId, ...values}),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        }).then(res => res.json());

        if (!response.success) {
            toast.error(formatErrorToastMessage(window.sv_resource.get('plf_messagetool_answer_error'), response.traceId), {
                className: 'filledErrorToast',
            });
            return;
        }

        addAnswer(response.context.message);

        toast.success(window.sv_resource.get('plf_messagetool_consultant_answer_success').replace('{{clientName}}', clientName), {
            duration: 4000,
            className: 'filledSuccessToast',
        });

        initialValues.theme = values.theme;
        formik.resetForm({
            values: initialValues,
        });
    };

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });
    const handleFileChange = event => {
        fileHelper.getFileContent(event.target)
            .then(files => {
                formik.setFieldValue('files', files);
                setFileUploadError(null);
            })
            .catch((error: Error) => {
                setFileUploadError(error.message);
            })
            .finally(() => formik.setFieldTouched('files'));
    };

    return (
        <form className={`${baseClassName} row`} onSubmit={formik.handleSubmit}>
            <TextareaField
                id="answer"
                label={window.sv_resource.get('plf_messagetool_answer_label')}
                name="answer"
                className={`${baseClassName}__textarea col-12`}
                placeholder=""
                touched={!!formik.touched.answer}
                errors={formik.errors.answer}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                onFocus={formik.handleBlur}
                value={formik.values.answer}
                rows={8}
            />
            {
                allowAnswerOnBehalfOf
                && (
                    <div className="row">
                        <Checkbox
                            checked={formik.values.onBehalfOf}
                            errors={formik.errors.onBehalfOf}
                            touched={!!formik.touched.onBehalfOf}
                            id="onBehalfOf"
                            name="onBehalfOf"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            label={window.sv_resource.get('plf_messagetool_on_behalf')}
                        />
                    </div>
                )
            }
            <div className={`${baseClassName}__uploadSubmitWrapper row ${allowAnswerOnBehalfOf && '-hasOnBehalfCheckbox'}`}>
                <FileUploadField
                    accept="application/pdf, image/png, image/jpeg"
                    className={`${baseClassName}__fileUpload col-12`}
                    label={window.sv_resource.get('plf_messagetool_answer_upload_label')}
                    onChange={handleFileChange}
                    id="files"
                    placeholder=""
                    multiple
                    name="files"
                    errors={formik.touched.files ? fileUploadError ?? formik.errors.files : ''}
                    onBlur={formik.handleBlur}
                    touched={!!formik.touched.files}
                    value={formik.values.files}
                />
                <div className="col-12 row">
                    <Select
                        className={`${baseClassName}__answerSubjectSelect pe-2 col-6 col-sm-4 text-start`}
                        id="status"
                        label={window.sv_resource.get('plf_messagetool_consultant_status_label')}
                        name="status"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.status ?? ''}
                    >
                        <option value="" disabled>{window.sv_resource.get('plf_messagetool_consultant_status_label')}</option>
                        {
                            statusList.map(status => (
                                <option value={status} key={`statusOption-${status}`}>{window.sv_resource.get(`plf_messagetool_status_${status}`)}</option>
                            ))
                        }
                    </Select>
                    <Select
                        className={`${baseClassName} col-6 col-sm-4 text-start`}
                        id="theme"
                        label={window.sv_resource.get('plf_messagetool_consultant_subject_label')}
                        name="theme"
                        touched
                        errors={formik.errors.theme}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.theme ?? ''}
                    >
                        <option value="" disabled>{window.sv_resource.get('plf_messagetool_consultant_subject_label')}</option>
                        {
                            themeList.map(currentTheme => (
                                <option value={currentTheme} key={`themeOption-${currentTheme}`}>{window.sv_resource.get(`plf_messagetool_theme_${currentTheme}`)}</option>
                            ))
                        }
                    </Select>
                    <FormButton
                        className={`${baseClassName}__answerButton col-4`}
                        disabled={Object.keys(formik.errors).length > 0 || Object.keys(formik.touched).length === 0 || formik.isSubmitting}
                        text={window.sv_resource.get('plf_messagetool_answer_button')}
                        variation={FormButtonVariation.Primary}
                        hasIcon={false}
                        type="submit"
                    />
                </div>
            </div>
        </form>
    );
};

export default ConsultantAnswerForm;

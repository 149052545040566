import React from 'react';
import './index.scss';
import ServiceType from '../../../lib/ServiceType';
import Card, { CardType } from '../../../../../../../../design/1/js/templates/molecules/card';
import CardImage from '../../../../../../../../design/1/js/templates/atoms/card-image';

interface ServicesOverviewProps {
    id: string;
    service: ServiceType;
}

const ServicesOverview = ({
    id, service,
}: ServicesOverviewProps) => {
    const baseClassName = 'w-servicesOverview';

    return (
        <div className={`${baseClassName}`}>
            <h2 id={id}>{window.sv_resource.get('plf_lifesituation_services_title')}</h2>
            <div className="col-12 col-sm-6 col-lg-4">
                <Card title={service.name} type={CardType.More} url={service.link}>
                    <CardImage image={service.image ? service.image : '/design/img/illustrations/services/kontoaenderung.svg'} cardName={service.name} />
                </Card>
            </div>
        </div>
    );
};

export default ServicesOverview;

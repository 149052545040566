import React, { forwardRef, useMemo } from 'react';
import Label from '../../atoms/form-fields/label';
import TextInput, { TextInputProps } from '../../atoms/form-fields/text-input';
import './index.scss';

interface TextFieldProps extends TextInputProps {
    label: string;
    isLoading?: boolean,
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(({ label, ...props }: TextFieldProps, ref) => {
    const baseClassName = 'm-textField';

    return (
        <div className={`${baseClassName} ${props.className}`}>
            {useMemo(() => (
                <Label
                    className={`${baseClassName}__label`}
                    htmlFor={props.id}
                >
                    {label}
                </Label>
            ), [baseClassName, props.id, label]) }
            { useMemo(() => (
                <TextInput
                    autoComplete={props.autoComplete}
                    className={`${baseClassName}__input${props.isLoading ? ' -loading' : ''}`}
                    ref={ref}
                    disabled={props.disabled}
                    errors={props.errors}
                    icon={props.icon}
                    id={props.id}
                    name={props.name}
                    onBlur={props.onBlur}
                    onChange={props.onChange}
                    onFocus={props.onFocus}
                    placeholder={props.placeholder}
                    touched={props.touched}
                    type={props.type}
                    inputMode={props.inputMode}
                    value={props.value}
                    isLoading={props.isLoading}
                    defaultValue={props.defaultValue}
                />
            ), [
                props.isLoading,
                props.autoComplete,
                baseClassName,
                ref,
                props.disabled,
                props.errors,
                props.icon,
                props.id,
                props.name,
                props.onBlur,
                props.onChange,
                props.onFocus,
                props.placeholder,
                props.touched,
                props.type,
                props.value,
            ])}
        </div>
    );
});

TextField.defaultProps = TextInput.defaultProps;

export default TextField;
